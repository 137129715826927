import { RootContextType, useRootContext } from "@/RootLayout.tsx";
import CryptoSelect from "@/components/cryptoSelect/CryptoSelect";
import ModalLoadingIndicator from "@/components/loading/ModalLoadingIndicator.tsx";
import { btcExecutePrice, ceasarsRedemptionResponse, fetchMockDataPromiseWithDelay } from "@/services/mockData.ts";
import {
  ApiAmountCurrencyEnum,
  ApiEmbeddedCaesarsCryptoTransactionRequest,
  ApiEmbeddedCryptoExecutePriceRequest,
  ApiEmbeddedCryptoExecutePriceRequestDestinationCurrencyEnum,
  ApiEmbeddedCryptoExecutePriceResponse,
  ApiEmbeddedCryptoTransactionRequestTransactTypeEnum,
  ApiEmbeddedFundingAccount,
} from "@/services/openAPI/embedded";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Button, Dialog, Divider, Typography, formatDollarAmountUsd, themes } from "@bakkt/bakkt-ui-components";
import {
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Link,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useNavigation } from "react-router-dom";

interface RedeemTransactionReviewProps {
  selectedCrypto: ApiAmountCurrencyEnum;
  amount: string;
  rewardPoints: string;
  rewardsConversionRate: number;
  caesarsAccountId: string;
  cashAccount: ApiEmbeddedFundingAccount;
}

const RedeemTransactionReview = () => {
  const theme = useTheme();
  const fetcher = useFetcher();
  const navigation = useNavigation();
  const { selectedCrypto, amount, rewardPoints, caesarsAccountId, cashAccount } = useLocation()
    ?.state as RedeemTransactionReviewProps;
  const navigate = useNavigate();
  const { addAlert } = useRootContext() as RootContextType;
  const [open, setOpen] = useState(true);
  const [executePrice, setExecutePrice] = useState<ApiEmbeddedCryptoExecutePriceResponse>();
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const handleSubmit = () => {
    const request: ApiEmbeddedCaesarsCryptoTransactionRequest = {
      caesarsAccountId,
      externalAccountRef: cashAccount.externalAccountRef,
      caesarsRewardPoints: rewardPoints.toString(),
      transactType: ApiEmbeddedCryptoTransactionRequestTransactTypeEnum.Buy,
      quantity: { amount: executePrice?.quantity.amount, currency: selectedCrypto },
      price: { currency: "USD", amount: Number(amount) },
    };

    submitActionRequest(fetcher, request);
  };

  useEffect(() => {
    async function getExecutePrices() {
      const executeRequest: ApiEmbeddedCryptoExecutePriceRequest = {
        buySellIndicator: ApiEmbeddedCryptoTransactionRequestTransactTypeEnum.Buy,
        destinationCurrency: selectedCrypto,
        source: { amount: Number(amount), currency: ApiEmbeddedCryptoExecutePriceRequestDestinationCurrencyEnum.Usd },
      };

      const executePricePromise: any = shouldUseMockData
        ? fetchMockDataPromiseWithDelay(btcExecutePrice, 1000)
        : EmbeddedPartnerService.getExecutePrice(executeRequest);

      const executePrice = await executePricePromise;

      setExecutePrice(executePrice);
    }
    getExecutePrices();
  }, []);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        const responsePriceAmount = response.data?.price?.amount;
        addAlert({
          severity: "success",
          message: `${formatDollarAmountUsd(
            Number(responsePriceAmount),
          )} worth of ${selectedCrypto} successfully redeemed!`,
        });
        sessionStorage.setItem("CAESARS_POINTS_BALANCE", response.data?.rewardPointsBalance);
        navigate("/redeem/complete");
      } else {
        navigate("/redeem", {
          state: {
            errorMessage: response.message ? response.message : "Unable to complete transaction.",
          },
        });
      }
    }
  }, [fetcher.data]);

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="md" fullWidth fullScreen={isFullScreen}>
        <DialogContent>
          {isLoading && <ModalLoadingIndicator description={"Transaction Processing"} />}
          {!isLoading && (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid xs={12} sx={{ mb: 8 }}>
                <Typography variant="h3" sx={{ textAlign: "center", mt: 2 }}>
                  Review Transaction
                </Typography>
              </Grid>
              <Grid xs={12} sx={{ mb: 4 }}>
                <CryptoSelect preSelectedCrypto={selectedCrypto} disableExpand={true} />
              </Grid>
              <Grid xs={12} sm={10} sx={{ textAlign: "center", mb: 3 }}>
                <Typography variant="h2" sx={{ mb: 4 }}>
                  {`${formatDollarAmountUsd(Number(amount))}`}
                </Typography>
                <Box>
                  <Stack direction="row" justifyContent={"space-between"}>
                    <Grid>Estimated Asset Amount</Grid>
                    <Grid>
                      {executePrice?.quantity.amount} {selectedCrypto}
                    </Grid>
                  </Stack>
                  <Divider />
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ pt: 3 }}>
          {!isLoading && (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid xs={6}>
                <Stack spacing={2}>
                  <Button
                    variant="contained"
                    sx={{ whiteSpace: "nowrap", textTransform: "capitalize" }}
                    onClick={handleSubmit}
                  >
                    Redeem Now
                  </Button>
                  <Button disabled={false} variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                </Stack>
              </Grid>
              <Grid xs={12} sm={10}>
                <Typography variant="body2" sx={{ textAlign: "center", mt: 2 }}>
                  {`I authorize Bakkt Marketplace, LLC ("Bakkt") to pay for my purchase of ${formatDollarAmountUsd(
                    Number(amount),
                  )} in ${selectedCrypto}. The
                  actual value purchased may change due to volatility in the price of ${selectedCrypto}, but my order will be
                  executed based on the best price available to Bakkt. Cryptocurrency transactions are not FDIC or SIPC
                  insured. `}
                  <Link href={"https://bakkt.com/disclosures"}>View Disclosures</Link>.
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default RedeemTransactionReview;

export async function action({ request }: { request: Request }) {
  try {
    if (shouldUseMockData) {
      const resp = await fetchMockDataPromiseWithDelay(ceasarsRedemptionResponse, 3000);
      return formatActionSuccessResponse(resp);
    }

    const redeemPointsRequest = (await request.json()) as ApiEmbeddedCaesarsCryptoTransactionRequest;
    const response = await EmbeddedPartnerService.redeemPoints(redeemPointsRequest);

    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
