import { Alert, CircularProgress, Container } from "@mui/material";

interface LoadingIndicatorProps {
  description?: string;
}

export default function LoadingIndicator({ description }: LoadingIndicatorProps) {
  return (
    <>
      <Container>
        {description && <Alert severity={"info"}>{description}</Alert>}
        <CircularProgress />
      </Container>
    </>
  );
}
