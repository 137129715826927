import { fetchMockDataPromiseWithDelay, historicalCryptoCurrencyPrices } from "@/services/mockData.ts";
import {
  ApiEmbeddedCryptoPriceResponse,
  GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum,
  GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum,
  HistoricalPrice,
} from "@/services/openAPI/embedded";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { shouldUseMockData } from "@/utils/dataUtils.ts";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Icons,
  Skeleton,
  Typography,
  cryptoAssetsMap,
  formatDollarAmountUsd,
} from "@bakkt/bakkt-ui-components";
import { Box, Unstable_Grid2 as Grid, Stack, SvgIcon, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SparkLineChart } from "@mui/x-charts";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import MiniLineChart from "./MiniLineChart";

const DashboardCoin = ({
  ticker,
  eligibleToBuy = false,
  eligibleToSell = false,
}: {
  ticker: GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum;
  eligibleToBuy: boolean;
  eligibleToSell: boolean;
}) => {
  const { btcPricePromise, ethPricePromise } = useRouteLoaderData("root") as {
    btcPricePromise: Promise<ApiEmbeddedCryptoPriceResponse>;
    ethPricePromise: Promise<ApiEmbeddedCryptoPriceResponse>;
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const chartContainerRef = useRef<Element>();
  const chartPriceTextRef = useRef<Element>();
  const [timePeriod, setTimePeriod] = useState<GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum | "YTD">("DAY");
  // Mini chart always shows 1 day history
  const [oneDayHistoricalData, setOneDayHistoricalData] = useState<HistoricalPrice[]>([]);
  // historicalData is used for the larger chart and user can select its time period
  const [historicalData, setHistoricalData] = useState<HistoricalPrice[]>([]);
  const [averageHistoricalPrice, setAverageHistoricalPrice] = useState(0);
  const [btcPrice, setBtcPrice] = useState<number>();
  const [ethPrice, setEthPrice] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [chartWidth, setChartWidth] = useState<number>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const fiatCurrency = "USD";

  const getChartContainerWidth = () => {
    let newChartWidth = 0;
    if (chartContainerRef?.current && chartPriceTextRef?.current) {
      const chartContainerWidth = chartContainerRef.current.getBoundingClientRect().width;
      const chartPriceTextWidth = chartPriceTextRef.current.getBoundingClientRect().width;
      newChartWidth = chartContainerWidth - chartPriceTextWidth;
    }
    return newChartWidth;
  };

  // Handle page resize
  useEffect(() => {
    window.addEventListener("resize", () => setChartWidth(getChartContainerWidth()));
    // Remove the event listener before the component gets unmounted
    return () => window.removeEventListener("resize", getChartContainerWidth);
  }, []);

  // Handle size on accordion expand because chart is not displayed when page first loads, resulting in chart container width being 0
  useEffect(() => {
    setChartWidth(getChartContainerWidth());
  }, [expanded]);

  useEffect(() => {
    const fetchHistoricalData = async () => {
      const btcPrice = await btcPricePromise;
      setBtcPrice(btcPrice.indicativePrice?.amount);
      const ethPrice = await ethPricePromise;
      setEthPrice(ethPrice.indicativePrice?.amount);

      const isYTD = timePeriod === "YTD";
      const apiTimePeriod = (isYTD ? "YEAR" : timePeriod) as GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum;
      try {
        setIsLoading(true);
        const response: any = shouldUseMockData
          ? await fetchMockDataPromiseWithDelay(historicalCryptoCurrencyPrices, 3000)
          : await EmbeddedPartnerService.getHistoricalCryptoCurrencyPrice(apiTimePeriod, ticker, fiatCurrency);
        // Reverse the array because API returns entries newest -> oldest but to display left to right graphs we want oldest -> newest order
        let responseHistoricalPrices = response.historicalPrices.reverse();

        if (isYTD) {
          // Year To Date by filtering results to only the current year
          responseHistoricalPrices = responseHistoricalPrices.filter(
            (dataPoint: HistoricalPrice) => dayjs(dataPoint.time).year() === dayjs().year(),
          );
        }

        if (responseHistoricalPrices) {
          setHistoricalData(responseHistoricalPrices);
          const newAverageHistoricalPrice =
            responseHistoricalPrices
              .map((dataPoint: HistoricalPrice) => dataPoint.price)
              .reduce((a: number, b: number) => a + b) / responseHistoricalPrices.length;
          setAverageHistoricalPrice(newAverageHistoricalPrice);

          if (timePeriod === "DAY") {
            interface PricesGroupedByHourAcc {
              [key: string]: Array<number>;
            }
            // Group the historical data by hour
            const pricesGroupedByHour = responseHistoricalPrices.reduce(
              (acc: PricesGroupedByHourAcc, { price, time }: { price: number; time: string }) => {
                const hour = dayjs(time).format("YYYY-MM-DD HH");
                if (!acc[hour]) {
                  acc[hour] = [];
                }
                acc[hour].push(price);
                return acc;
              },
              {},
            );

            // // Calculate the average price for each hour
            const historicalPricesByHour = Object.keys(pricesGroupedByHour).map((hour) => {
              const prices = pricesGroupedByHour[hour];
              const priceSum = prices.reduce((acc: number, price: number) => acc + price, 0);
              const priceAverage = priceSum / prices.length;
              return { time: hour, price: priceAverage };
            });

            setOneDayHistoricalData(historicalPricesByHour);
          }
        }
        setIsLoading(false);
      } catch (err) {
        setError(true);
        setIsLoading(false);
      }
    };

    fetchHistoricalData();
  }, [timePeriod]);

  const accordionSummarySx = {
    pl: 0,
    "&:hover": {
      backgroundColor: "secondary.50",
    },
    [theme.breakpoints.down("md")]: {
      "&:hover": {
        backgroundColor: "primary.contrastText",
      },
    },
  };

  const accordionPanelSx = {
    [theme.breakpoints.down("md")]: {
      mb: 1,
      pr: 1,
      borderRadius: 2,
      border: "none",
    },
  };

  const cryptoIconSx = {
    width: 33,
    height: 33,
    mt: 0.8,
    [theme.breakpoints.down("md")]: {
      width: 26,
      height: 26,
      mt: 1.2,
    },
  };

  const upDownIconsSx = {
    height: "18px",
    position: "relative",
    top: 4.5,
    width: "18px",
    [theme.breakpoints.down("lg")]: {
      mb: 0.5,
    },
  };

  const coinAndTrendLinesSx = {
    width: "49%",
    [theme.breakpoints.down("lg")]: {
      width: "75%",
    },
  };

  const chartHeight = isMobile ? 150 : 200;

  const chartContainerSx = {
    width: isMobile ? "100%" : "80%",
  };

  const handleBuy = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigate("/transactions/buy", { state: { preSelectedCrypto: ticker } });
  };

  const handleSell = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigate("/transactions/sell", { state: { preSelectedCrypto: ticker } });
  };

  let currentCryptoPrice = 0;
  if (ticker === "BTC" && btcPrice) {
    currentCryptoPrice = btcPrice;
  } else if (ticker === "ETH" && ethPrice) {
    currentCryptoPrice = ethPrice;
  }

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={accordionPanelSx}>
      <AccordionSummary
        sx={[accordionSummarySx, !isMobile && { pointerEvents: "none", pr: 0.4 }]}
        expandIcon={
          <SvgIcon
            component={Icons.ChevronDownIcon}
            sx={{ display: isMobile ? "none" : "block", pointerEvents: "auto" }}
          />
        }
      >
        <Grid container justifyContent="space-between" alignContent="center" alignItems="center" sx={{ width: "100%" }}>
          <Grid sx={coinAndTrendLinesSx} alignItems="center">
            <Grid container>
              <Grid sx={{ width: 140 }}>
                <Grid container spacing={1.2} alignContent="center">
                  <Grid>
                    <SvgIcon component={cryptoAssetsMap[ticker].icon} inheritViewBox sx={cryptoIconSx} />
                  </Grid>
                  <Grid>
                    <Typography variant="h5" sx={{ fontWeight: 500 }}>
                      {cryptoAssetsMap[ticker].name}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary }}>
                      {ticker}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ display: isMobile ? "none" : "block" }}>
                {oneDayHistoricalData.length > 0 && (
                  <MiniLineChart
                    ticker={ticker}
                    chartData={oneDayHistoricalData.map((dataPoint: HistoricalPrice) => dataPoint.price)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {historicalData.length > 0 && (
            <Grid container justifyContent="flex-end" alignItems="center" sx={{ width: "25%" }}>
              <Typography variant="body1" sx={{ textAlign: "right" }}>
                {formatDollarAmountUsd(currentCryptoPrice)}
              </Typography>

              <Typography variant="subtitle1" sx={{ color: theme.palette.text.secondary, textWrap: "nowrap" }}>
                <Stack direction="row" alignItems={"center"}>
                  {historicalData[0].price > currentCryptoPrice ? (
                    <SvgIcon component={Icons.ArrowDownrightLargeIcon} sx={upDownIconsSx} />
                  ) : (
                    <SvgIcon component={Icons.ArrowUprightLargeIcon} sx={upDownIconsSx} />
                  )}
                  <Box>
                    {formatDollarAmountUsd(currentCryptoPrice - historicalData[0].price)} (
                    {(((currentCryptoPrice - historicalData[0].price) / (historicalData[0].price || 1)) * 100).toFixed(
                      2,
                    ) ?? 0}
                    %)
                  </Box>
                </Stack>
              </Typography>
            </Grid>
          )}
        </Grid>
        {!isMobile && (eligibleToBuy || eligibleToSell) && (
          <Grid>
            <Stack direction="row">
              <Grid sx={{ mr: 0.75 }}>
                <Button
                  sx={[{ pointerEvents: "auto" }]}
                  variant="contained"
                  disabled={!eligibleToBuy}
                  fullWidth
                  onClick={(e) => handleBuy(e)}
                >
                  Buy
                </Button>
              </Grid>
              <Grid sx={{ ml: 0.75 }}>
                <Button
                  sx={{ pointerEvents: "auto" }}
                  variant="outlined"
                  disabled={!eligibleToSell}
                  fullWidth
                  onClick={(e) => handleSell(e)}
                >
                  Sell
                </Button>
              </Grid>
            </Stack>
          </Grid>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ pl: 1 }}>
        {isMobile && (eligibleToBuy || eligibleToSell) && (
          <Grid>
            <Stack direction="row">
              <Grid xs={6} sx={{ mr: 0 }}>
                <Button variant="contained" disabled={!eligibleToBuy} fullWidth onClick={handleBuy}>
                  Buy
                </Button>
              </Grid>
              <Grid xs={6} sx={{ ml: 0 }}>
                <Button variant="outlined" disabled={!eligibleToSell} fullWidth onClick={handleSell}>
                  Sell
                </Button>
              </Grid>
            </Stack>
          </Grid>
        )}

        {isLoading && <Skeleton variant="rectangular" height={215} sx={chartContainerSx} />}
        {error && <Typography>An error occurred while loading data for {ticker}</Typography>}
        <Box
          ref={chartContainerRef}
          sx={{
            ...chartContainerSx,
            backgroundImage: "linear-gradient(rgba(255, 255, 255, 1), rgba(230, 230, 230, 0.3))",
          }}
        >
          {!isLoading && historicalData.length > 0 && (
            <Grid xs={12} md={10}>
              <Box sx={{ alignItems: "center", display: "flex" }}>
                <Box ref={chartPriceTextRef}>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {formatDollarAmountUsd(averageHistoricalPrice)}
                  </Typography>
                </Box>
                <Box>
                  <SparkLineChart
                    height={chartHeight}
                    width={chartWidth}
                    colors={[cryptoAssetsMap[ticker].color]}
                    data={historicalData.map((historicalDataPoint) => historicalDataPoint.price)}
                    valueFormatter={(value) => formatDollarAmountUsd(value)}
                    xAxis={{
                      scaleType: "time",
                      data: historicalData.map((historicalDataPoint) => Date.parse(historicalDataPoint.time)),
                      valueFormatter: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                    }}
                    showHighlight
                    showTooltip
                  />
                </Box>
              </Box>
            </Grid>
          )}
          <Box>
            <Tabs
              value={timePeriod}
              onChange={(_, value) => {
                setTimePeriod(value);
              }}
              aria-label="Historical price trend range"
              sx={{
                ".MuiTab-root": {
                  minWidth: isMobile ? "20%" : "60px",
                  width: isMobile ? "20%" : "60px",
                },
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "red",
                },
              }}
            >
              <Tab label="1D" value="DAY" />
              <Tab label="1W" value="WEEK" />
              <Tab label="1M" value="MONTH" />
              <Tab label="YTD" value="YTD" />
              <Tab label="1Y" value="YEAR" />
            </Tabs>
          </Box>
        </Box>

        <Box>
          <Typography variant="h5" sx={{ mb: 1, mt: 3 }}>
            About {cryptoAssetsMap[ticker].name}
          </Typography>
          {cryptoAssetsMap[ticker].description && (
            <Typography variant="body1">{cryptoAssetsMap[ticker].description}</Typography>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DashboardCoin;
