import { ApiEmbeddedFundingAccount, ApiEmbeddedPartnerPartyLinkInfo } from "@/services/openAPI/embedded";
import { USER_MUST_RELINK_STATUSES } from "@/utils/dataUtils.ts";
import { Button, Divider, Icons, Paper, Typography, formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import { Alert, Unstable_Grid2 as Grid, Skeleton, SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Await, useNavigate, useRouteLoaderData } from "react-router-dom";
import StickyBox from "react-sticky-box";
const AvailableFunds = () => {
  const { fundingAccountsPromise, userInfo } = useRouteLoaderData("root") as {
    fundingAccountsPromise: Promise<ApiEmbeddedFundingAccount[]>;
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };
  const userPartyLevel = userInfo?.party?.level;
  const theme = useTheme();
  const navigate = useNavigate();
  const iconSize = {
    width: 24,
    height: 24,
    pr: 1,
  };

  const totalsBoxSx = {
    [theme.breakpoints.down("sm")]: {
      mt: 4,
    },
  };

  const loadingFallback = (
    <Grid xs={12} sm={5} md={4}>
      <Skeleton variant={"rectangular"} height={300} />
    </Grid>
  );

  return (
    <React.Suspense fallback={loadingFallback}>
      <Await
        resolve={fundingAccountsPromise}
        errorElement={<Alert severity="error">Error loading funding accounts</Alert>}
      >
        {(fundingAccounts: ApiEmbeddedFundingAccount[]) => {
          const cashFundingAccount = fundingAccounts.find((account) => account.accountType === "CASH");
          const availableFunds = cashFundingAccount?.availableBalance?.amount || 0;
          const pendingFunds = cashFundingAccount?.pendingDepositAmount?.amount || 0;
          const bankAccounts = fundingAccounts.filter(
            (fundingAccount: ApiEmbeddedFundingAccount) =>
              fundingAccount.accountType === "CHECKING" || fundingAccount.accountType === "SAVING",
          );
          const userMustRelink = bankAccounts.some((bankAccount) =>
            USER_MUST_RELINK_STATUSES.includes(bankAccount.status || ""),
          );

          return (
            <>
              <Grid xs={12} sm={5} md={4} sx={totalsBoxSx}>
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <Paper sx={{ borderRadius: 0, p: 3 }}>
                    <Typography variant="h5">Your Funds</Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      Add funds to buy crypto
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: 1 }} justifyContent="space-between">
                      <Grid>
                        <Typography variant="body1">Available Funds</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="body1">{formatDollarAmountUsd(availableFunds)}</Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Divider />
                      </Grid>

                      <Grid>
                        <Typography variant="body1">Pending Funds</Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="body1">{formatDollarAmountUsd(pendingFunds)}</Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Divider />
                      </Grid>

                      <Grid>
                        <Typography variant="body1" sx={{ fontWeight: 700 }}>
                          Total
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography variant="body1" sx={{ fontWeight: 700 }}>
                          {formatDollarAmountUsd(availableFunds + pendingFunds)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Button
                      variant="outlined"
                      sx={{ mt: 2.5, width: "100%" }}
                      size="large"
                      onClick={() => navigate(`funding/deposit`)}
                      disabled={
                        bankAccounts?.length <= 0 ||
                        userPartyLevel === "LEVEL_4_LIQUIDATED" ||
                        userPartyLevel === "LEVEL_5_LIQUIDATED" ||
                        userMustRelink
                      }
                    >
                      <SvgIcon component={Icons.DepositIcon} inheritViewBox sx={iconSize} />
                      Add Funds
                    </Button>

                    <Button
                      variant="outlined"
                      sx={{ mt: 2, width: "100%" }}
                      size="large"
                      onClick={() => navigate(`funding/withdrawal`)}
                      disabled={bankAccounts?.length <= 0 || availableFunds <= 0 || userMustRelink}
                    >
                      <SvgIcon component={Icons.WithdrawIcon} inheritViewBox sx={iconSize} />
                      Withdraw Funds
                    </Button>
                  </Paper>
                </StickyBox>
              </Grid>
            </>
          );
        }}
      </Await>
    </React.Suspense>
  );
};

export default AvailableFunds;
