import RootLayout, { loader as rootLoader } from "@/RootLayout";
import FundingSourceAdded from "@/components/finance/FundingSourceAdded.tsx";
import LinkBankAccount from "@/components/finance/LinkBankAccount.tsx";
import PlaidBankLink, {
  action as plaidBankLinkAction,
  loader as plaidBankLinkLoader,
} from "@/components/finance/PlaidBankLink";
import RemoveBankAccount, { action as removeBankAccountAction } from "@/components/finance/RemoveBankAccount";
import DepositWithdrawComplete from "@/components/funding/DepositWithdrawComplete.tsx";
import DepositWithdrawReview, {
  action as depositWithdrawReviewAction,
} from "@/components/funding/DepositWithdrawReview.tsx";
import DepositWithdrawTransaction from "@/components/funding/DepositWithdrawTransaction.tsx";
import KycComplete from "@/components/kyc/KycComplete";
import TaxPayerCert, {
  action as taxPayerCertAction,
  loader as taxPayerCertLoader,
} from "@/components/kyc/TaxPayerCert";
import UserInfoInput, {
  action as UserInfoInputAction,
  loader as userInfoInputLoader,
} from "@/components/kyc/UserInfoInput";
import BuySellTransaction from "@/components/transactions/BuySellTransaction.tsx";
import { registerAxiosConfig } from "@/config/axiosConfig";
import ChangePassword, { action as changePasswordAction } from "@/pages/auth/ChangePassword.tsx";
import ChangePasswordEnterEmail, {
  action as changePasswordEnterEmailAction,
} from "@/pages/auth/ChangePasswordEnterEmail.tsx";
import ChangePasswordVerifyOtp, {
  action as changePasswordVerifyOtpAction,
} from "@/pages/auth/ChangePasswordVerifyOtp.tsx";
import Login, { action as loginAction } from "@/pages/auth/Login";
import TermsOfService, { action as termsOfServiceAction } from "@/pages/auth/TermsOfService";
import VerifyEmailAddress, { action as verifyEmailAddressAction } from "@/pages/auth/VerifyEmailAddress";
import CaesarsUserSignup, {
  action as caesarsUserSignupAction,
  loader as caesarsUserSignupLoader,
} from "@/pages/caesarsUserSignup/CaesarsUserSignup";
import Dashboard, { loader as dashboardLoader } from "@/pages/dashboard/Dashboard";
import AccessRedirectPage from "@/pages/error/AccessRedirectPage.tsx";
import ErrorPage from "@/pages/error/ErrorPage";
import Onboarding from "@/pages/onboarding/Onboarding";
import UserProfile, { loader as userProfileLoader } from "@/pages/profile/UserProfile";
import { configureCookieBanner } from "@/utils/cookieUtils.ts";
import ReactDOM from "react-dom/client";
import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";
import RedeemFeesWaived from "./components/redeem/RedeemFeesWaived.tsx";
import RedeemTransaction from "./components/redeem/RedeemTransaction.tsx";
import RedeemTransactionComplete from "./components/redeem/RedeemTransactionComplete.tsx";
import RedeemTransactionReview, {
  action as redeemTransactionReviewAction,
} from "./components/redeem/RedeemTransactionReview.tsx";
import BuySellTransactionComplete from "./components/transactions/BuySellTransactionComplete.tsx";
import BuySellTransactionReview, {
  action as buySellTransactionReviewAction,
  loader as buySellTransactionReviewLoader,
} from "./components/transactions/BuySellTransactionReview.tsx";

const modals: RouteObject[] = [
  {
    path: "kyc/user-info",
    element: <UserInfoInput />,
    loader: userInfoInputLoader,
    action: UserInfoInputAction,
  },
  {
    path: "kyc/tax-payer-cert",
    element: <TaxPayerCert />,
    loader: taxPayerCertLoader,
    action: taxPayerCertAction,
  },
  {
    path: "kyc/complete",
    element: <KycComplete />,
  },
  {
    path: "finance/link-bank-account",
    element: <LinkBankAccount />,
  },
  {
    path: "finance/funding-source-added",
    element: <FundingSourceAdded />,
  },
  {
    path: "finance/link-plaid",
    element: <PlaidBankLink />,
    loader: plaidBankLinkLoader,
    action: plaidBankLinkAction,
  },
  {
    path: "finance/remove-bank-account",
    element: <RemoveBankAccount />,
    action: removeBankAccountAction,
  },
  {
    path: "funding/:fundingTypeParam?",
    element: <DepositWithdrawTransaction />,
  },
  {
    path: "funding/review",
    element: <DepositWithdrawReview />,
    action: depositWithdrawReviewAction,
  },
  {
    path: "funding/complete",
    element: <DepositWithdrawComplete />,
  },
  {
    path: "transactions/:transactionTypeParam?",
    element: <BuySellTransaction />,
  },
  {
    path: "transactions/review-transaction",
    element: <BuySellTransactionReview />,
    action: buySellTransactionReviewAction,
    loader: buySellTransactionReviewLoader,
  },
  {
    path: "transactions/transaction-complete",
    element: <BuySellTransactionComplete />,
  },
  {
    path: "redeem",
    element: <RedeemTransaction />,
  },
  {
    path: "redeem/fees-waived",
    element: <RedeemFeesWaived />,
  },
  {
    path: "redeem/review",
    element: <RedeemTransactionReview />,
    action: redeemTransactionReviewAction,
  },
  {
    path: "redeem/complete",
    element: <RedeemTransactionComplete />,
  },
];

configureCookieBanner();
registerAxiosConfig();

const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    element: <RootLayout />,
    loader: rootLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        id: "dashboard",
        element: <Dashboard />,
        loader: dashboardLoader,
        shouldRevalidate: (shouldRevalidateArgs) => {
          return !!shouldRevalidateArgs.actionResult;
        },
        children: [...modals],
      },
      {
        path: "/profile",
        id: "profile",
        element: <UserProfile />,
        loader: userProfileLoader,
      },
    ],
  },
  {
    path: "/login",
    id: "login",
    element: <Login />,
    errorElement: <ErrorPage />,
    action: loginAction,
  },
  {
    path: "/create-account/:partnerName?/:tokenName?/:encodedToken?",
    id: "caesarsUserSignup",
    element: <CaesarsUserSignup />,
    errorElement: <ErrorPage />,
    loader: caesarsUserSignupLoader,
    action: caesarsUserSignupAction,
  },
  {
    path: "/verify-email-address",
    id: "verifyEmailAddress",
    element: <VerifyEmailAddress />,
    errorElement: <ErrorPage />,
    action: verifyEmailAddressAction,
  },
  {
    path: "/terms-of-service",
    id: "termsOfService",
    element: <TermsOfService />,
    action: termsOfServiceAction,
  },
  {
    path: "/onboarding",
    id: "onboarding",
    element: <Onboarding />,
  },
  {
    path: "/change-password-email",
    id: "changePasswordEnterEmail",
    errorElement: <ErrorPage />,
    element: <ChangePasswordEnterEmail />,
    action: changePasswordEnterEmailAction,
  },
  {
    path: "/change-password-otp",
    id: "changePasswordVerifyOtp",
    errorElement: <ErrorPage />,
    element: <ChangePasswordVerifyOtp />,
    action: changePasswordVerifyOtpAction,
  },
  {
    path: "/change-password",
    id: "changePassword",
    errorElement: <ErrorPage />,
    element: <ChangePassword />,
    action: changePasswordAction,
  },
  {
    path: "/access",
    id: "access",
    element: <AccessRedirectPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<RouterProvider router={router} />);
