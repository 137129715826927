import AmountInput from "@/components/finance/AmountInput";
import useAmountInput from "@/hooks/useAmountInput";
import {
  ApiEmbeddedCaesarsTokenResponse,
  ApiEmbeddedCryptoPriceResponse,
  ApiEmbeddedFundingAccount,
  ApiEmbeddedPartnerPartyLinkInfo,
} from "@/services/openAPI/embedded";
import { Button, Dialog, Typography, color, formatDollarAmountUsd, themes } from "@bakkt/bakkt-ui-components";
import {
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";

interface RedeemTransactionProps {
  errorMessage: string;
}

const RedeemTransaction = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { btcPricePromise, caesarsTokenInfoPromise, fundingAccountsPromise, userInfo } = useRouteLoaderData("root") as {
    btcPricePromise: Promise<ApiEmbeddedCryptoPriceResponse>;
    caesarsTokenInfoPromise: ApiEmbeddedCaesarsTokenResponse;
    fundingAccountsPromise: Promise<ApiEmbeddedFundingAccount[]>;
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };
  const { errorMessage } = (useLocation()?.state as RedeemTransactionProps) || {};
  const [btcPrice, setBtcPrice] = useState<number>();
  const [rewardsAccountId, setRewardsAccountId] = useState<string>();
  const [rewardsPoints, setRewardsPoints] = useState<number>();
  const [rewardsConversionRate, setRewardsConversionRate] = useState<number>();
  const [userRewardsBalanceUsd, setUserRewardsBalanceUsd] = useState<number>(0);
  const MIN_BUY_SELL_AMOUNT = 1;
  const DEFAULT_MAX_BUY_AMOUNT = 999;
  const [maxBuyAmount, setMaxBuyAmount] = useState<number>(DEFAULT_MAX_BUY_AMOUNT);
  const [cashAccount, setCashAccount] = useState<ApiEmbeddedFundingAccount>();
  const [open, setOpen] = useState<boolean>(true);
  const selectedCrypto = "BTC";
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const errorMessages = {
    invalidInput: "Invalid input.",
    minDeposit: `You must redeem at least ${formatDollarAmountUsd(MIN_BUY_SELL_AMOUNT)} worth of Reward Points.`,
    maxAmountExceeded: `Daily redemption may not exceed ${formatDollarAmountUsd(maxBuyAmount)}`,
    creditBalanceExceeded: `Transactions may not exceed your balance of ${formatDollarAmountUsd(
      userRewardsBalanceUsd,
    )}`,
  };

  useEffect(() => {
    async function awaitPromisesAndSetInitialState() {
      // Set BTC price
      const btcPrice = await btcPricePromise;
      setBtcPrice(btcPrice.indicativePrice?.amount);
      // Set user point balance and points conversion rate
      const caesarsTokenInfo = await caesarsTokenInfoPromise;
      setRewardsAccountId(caesarsTokenInfo.rewardsAccountId);

      const updatedRewardsPointsBalance = sessionStorage.getItem("CAESARS_POINTS_BALANCE");
      const rewardsPoints = updatedRewardsPointsBalance
        ? Number(updatedRewardsPointsBalance)
        : caesarsTokenInfo.rewardsPoints;
      setRewardsPoints(rewardsPoints);

      setRewardsConversionRate(caesarsTokenInfo.rewardsConversionRate);
      const newRewardsBalanceUsd = Math.floor(rewardsPoints / caesarsTokenInfo.rewardsConversionRate);
      setUserRewardsBalanceUsd(newRewardsBalanceUsd);
      const maxRedeemAmount =
        newRewardsBalanceUsd < DEFAULT_MAX_BUY_AMOUNT ? newRewardsBalanceUsd : DEFAULT_MAX_BUY_AMOUNT;
      setMaxBuyAmount(maxRedeemAmount);
      // Set cash account so that externalAccountRef can be sent with the redeem transaction request
      const fundingAccounts = await fundingAccountsPromise;
      const cashAccount = fundingAccounts.find((account) => account.accountType === "CASH");
      setCashAccount(cashAccount);
    }
    awaitPromisesAndSetInitialState();
  }, []);

  const {
    amount,
    helperText,
    parsedAmount,
    cryptoAmount,
    handleAmountChange,
    handlePresetAmount,
    handleFocus,
    handleBlur,
    maxError,
    minError,
    balanceError,
  } = useAmountInput(btcPrice, selectedCrypto, errorMessages, userRewardsBalanceUsd, MIN_BUY_SELL_AMOUNT, maxBuyAmount);

  const handleReviewTransaction = () => {
    navigate("/redeem/review", {
      state: {
        selectedCrypto,
        amount: parsedAmount,
        // "rewardsPoints" is returned from EmbeddedPartnerService.processToken, but
        // "rewardPoints" is used by EmbeddedPartnerService.redeemPoints
        rewardPoints: parsedAmount * Number(rewardsConversionRate),
        // Similarly, "rewardsAccountId" from EmbeddedPartnerService.processToken and "caesarsAccountId" for EmbeddedPartnerService.redeemPoints
        caesarsAccountId: rewardsAccountId?.toString(),
        cashAccount,
      },
    });
  };

  const handleClose = (_event?: object, reason?: string) => {
    if (reason && reason === "backdropClick") {
      return;
    } else {
      setOpen(false);
      navigate("/");
    }
  };

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="sm" fullWidth fullScreen={isFullScreen}>
        <DialogContent>
          <Grid container sx={{ mb: 8 }} alignItems={"center"} justifyContent={"center"}>
            <Tabs
              value="Redeem"
              TabIndicatorProps={{ style: { background: color.energizingLime.main } }}
              selectionFollowsFocus
            >
              <Tab value="Redeem" sx={{ textTransform: "capitalize", fontSize: "24px" }} label="Redeem" />
            </Tabs>
          </Grid>
          {errorMessage && (
            <Grid xs={12} sx={{ backgroundColor: "error.300", p: 2, mb: 3, textAlign: "center" }}>
              <Typography variant="body2" sx={{ color: "primary.contrastText" }}>
                {errorMessage}
              </Typography>
            </Grid>
          )}

          <AmountInput
            amount={amount}
            handleAmountChange={handleAmountChange}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            minError={minError}
            maxError={maxError}
            balanceError={balanceError}
            helperText={helperText}
            handlePresetAmount={handlePresetAmount}
            cryptoAmount={cryptoAmount}
            selectedCrypto={selectedCrypto}
            minAmount={MIN_BUY_SELL_AMOUNT}
            maxAmount={maxBuyAmount}
            disableExpand
            isRedeemTransaction
            rewardsPoints={rewardsPoints}
            rewardsConversionRate={rewardsConversionRate}
          />
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
          <Stack spacing={1} sx={{ width: "66%" }}>
            <Button
              variant="contained"
              fullWidth={true}
              disabled={
                minError || maxError || amount === "" || parsedAmount <= 0 || parsedAmount > userRewardsBalanceUsd
              }
              onClick={handleReviewTransaction}
            >
              Continue
            </Button>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default RedeemTransaction;
