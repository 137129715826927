import { pageFooterText } from "@/utils/dataUtils.ts";
import { Button, Dialog, TextField, themes, Typography } from "@bakkt/bakkt-ui-components";
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Unstable_Grid2 as Grid,
  Link,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BakktLogo from "../../assets/images/bakkt_logo.svg";
import RegistrationBg from "../../assets/images/registration_bg.svg";
import styles from "./Onboarding.module.css";

const backgroundSx = {
  backgroundImage: "url(" + RegistrationBg + ")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundSize: "3000px",
};

const Onboarding = () => {
  const today = new Date();
  const year = today.getFullYear();
  const theme = useTheme();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const enableBtn = () => {
    setDisabled(false);
  };

  const handleRedirectToLogin = () => {
    navigate("/login");
  };

  return (
    <ThemeProvider theme={themes.light}>
      <Dialog scroll="body" open={true} maxWidth="sm" fullScreen={fullScreen} sx={backgroundSx} hideBackdrop={true}>
        <DialogContent sx={{ alignItems: "center", textAlign: "center", overflowY: "hidden" }}>
          <Grid container xs={12} md={12} sx={{ mb: 5 }}>
            <Grid xs={12} md={12}>
              <img src={BakktLogo} alt="Bakkt logo" className={styles["login_logo"]} />
              <Typography variant="h3" sx={{ mt: 1.6 }}>
                Create an Account
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid xs={12}>
              <TextField type="text" label="First name" onFocus={enableBtn} variant="standard" fullWidth />
            </Grid>
            <Grid xs={12}>
              <TextField type="text" label="Last name" onFocus={enableBtn} variant="standard" fullWidth />
            </Grid>
            <Grid xs={12}>
              <TextField type="text" label="Email" onFocus={enableBtn} variant="standard" fullWidth />
            </Grid>
            <Grid xs={12}>
              <TextField
                type="password"
                inputProps={{ maxLength: 20 }}
                label="Password"
                variant="standard"
                fullWidth={true}
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                type="password"
                inputProps={{ maxLength: 20 }}
                label="Re-enter password"
                variant="standard"
                fullWidth={true}
              />
            </Grid>
            <Grid xs={12}>
              <FormGroup>
                <Typography variant="body2" sx={{ textAlign: "left" }}>
                  <FormControlLabel
                    sx={{ mr: 0 }}
                    control={<Checkbox sx={{ mt: -2, pt: 0 }} />}
                    label="I am at least 18 years old and agree to Bakkt’s Terms of Service and Privacy Policy."
                  />
                </Typography>
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pt: 5 }}>
          <Stack spacing={2} sx={{ width: "49%" }}>
            <Button variant="contained" disabled={disabled} fullWidth onClick={handleRedirectToLogin}>
              Continue
            </Button>
            <Link sx={{ cursor: "pointer" }}>
              <Grid spacing={2}>
                <Typography variant="body2" sx={{ textAlign: "center" }} onClick={handleRedirectToLogin}>
                  Already have an account? Log in
                </Typography>
              </Grid>
            </Link>
          </Stack>
        </DialogActions>
        <Grid sx={{ textAlign: "-webkit-center" }}>
          <Box sx={{ mt: "20px", mb: "20px", width: "70%" }}>
            <Box>
              <Grid xs={6}>
                <Typography variant="body2" sx={{ fontSize: "12px", textAlign: "center" }}>
                  {pageFooterText}
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
};

export default Onboarding;
