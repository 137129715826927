import BankIcon from "@/assets/images/bank.svg";
import AmountInput from "@/components/finance/AmountInput.tsx";
import useAmountInput from "@/hooks/useAmountInput";
import { ApiEmbeddedFundingAccount, TransactBankAccountAchTypeEnum } from "@/services/openAPI/embedded";
import {
  Button,
  Dialog,
  Divider,
  ThemeProvider,
  Typography,
  formatDollarAmountUsd,
  themes,
} from "@bakkt/bakkt-ui-components";
import {
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  capitalize,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

const DepositWithdrawTransaction = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { fundingAccountsPromise } = useRouteLoaderData("root") as {
    fundingAccountsPromise: Promise<ApiEmbeddedFundingAccount[]>;
  };
  const [open, setOpen] = useState<boolean>(true);
  const [selectedFundingAccount, setSelectedFundingAccount] = useState<ApiEmbeddedFundingAccount>();
  const [cashAccount, setCashAccount] = useState<ApiEmbeddedFundingAccount>();

  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { fundingTypeParam } = useParams() as { fundingTypeParam: string };
  const fundingType = fundingTypeParam.toUpperCase() as TransactBankAccountAchTypeEnum;
  const isWithdrawal = fundingType === TransactBankAccountAchTypeEnum.Withdrawal;

  // Minimum/maximum dollar amounts for deposit/withdraw transactions
  const MIN_AMOUNT = 10;
  const MAX_AMOUNT = 999;

  const errorMessages = {
    invalidInput: "Invalid input",
    minDeposit: `You must ${isWithdrawal ? "withdraw" : "deposit"} at least ${formatDollarAmountUsd(MIN_AMOUNT)}`,
    maxAmountExceeded: `Maximum amount exceeded ${formatDollarAmountUsd(MAX_AMOUNT)}`,
  };

  useEffect(() => {
    async function getSelectedFundingAccount() {
      const fundingAccounts = await fundingAccountsPromise;
      const selectedFundingAccount = fundingAccounts.find(
        (account) => account.accountType === "CHECKING" || account.accountType === "SAVING",
      );
      setSelectedFundingAccount(selectedFundingAccount);
      const cashAccount = fundingAccounts.find((account) => account.accountType === "CASH");
      setCashAccount(cashAccount);
    }

    getSelectedFundingAccount();
  }, []);

  const {
    amount,
    helperText,
    minError,
    maxError,
    parsedAmount,
    handleAmountChange,
    handlePresetAmount,
    handleFocus,
    handleBlur,
  } = useAmountInput(
    null,
    null,
    errorMessages,
    isWithdrawal ? cashAccount?.availableBalance?.amount : null,
    MIN_AMOUNT,
    MAX_AMOUNT,
  );

  const handleWithdrawAllTransaction = () => {
    navigate("/funding/review", {
      state: {
        amount: cashAccount?.availableBalance?.amount,
        fundingType: "WITHDRAWAL",
        fundingAccount: selectedFundingAccount,
      },
    });
  };

  const handleClose = (): void => {
    setOpen(false);
    navigate("/");
  };

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="sm" fullWidth fullScreen={isFullScreen}>
        <DialogContent>
          <Grid container direction="column" alignItems="center">
            <Typography variant="h4" textAlign="center">
              Bank Account
            </Typography>
            <Box component="span" sx={{ backgroundColor: "success.main", height: "2px", mt: 1, width: "35%" }} />
          </Grid>
          <Grid container justifyContent="center" alignItems="center" sx={{ mb: 4 }}>
            <AmountInput
              amount={amount}
              balance={cashAccount?.availableBalance?.amount}
              handleAmountChange={handleAmountChange}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              minError={minError}
              maxError={maxError}
              helperText={helperText}
              handlePresetAmount={handlePresetAmount}
              isWithdrawal={isWithdrawal}
              handleWithdrawAll={handleWithdrawAllTransaction}
              minAmount={MIN_AMOUNT}
              maxAmount={MAX_AMOUNT}
            />
            <Grid xs={12} md={8} pt={5} pb={6}>
              <Typography variant="caption">{isWithdrawal ? "Destination" : "Funding Source"}</Typography>
              <Grid container alignItems="center" justifyContent="space-between" pb={0.25} mb={0.25}>
                <Grid container alignItems="center">
                  <img src={BankIcon} alt="Check" />
                  <Typography variant="subtitle1" pl={0.5} pt={0.25}>
                    {selectedFundingAccount &&
                      selectedFundingAccount.name +
                        " - " +
                        capitalize(selectedFundingAccount.accountType.toLowerCase())}
                  </Typography>
                </Grid>
                <Typography variant="subtitle1" pl={0.5} pt={0.25}>
                  {selectedFundingAccount?.formattedAccountNumber}
                </Typography>
              </Grid>
              <Divider sx={{ mb: 0.25 }} />
              <Typography variant="caption">
                Funds will appear in your account within {isWithdrawal ? "2 to 3" : "3 to 5"} days
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
          <Stack spacing={2} sx={{ width: "66%" }}>
            <Button
              variant="contained"
              fullWidth={true}
              disabled={minError || maxError || amount === "" || parsedAmount <= 0}
              onClick={() =>
                navigate("/funding/review", {
                  state: { amount: amount, fundingType: fundingType, fundingAccount: selectedFundingAccount },
                })
              }
            >
              Continue
            </Button>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DepositWithdrawTransaction;
