import { ApiEmbeddedCaesarsTokenResponse } from "@/services/openAPI/embedded";
import { Button, Skeleton, Typography, color } from "@bakkt/bakkt-ui-components";
import { Alert, Box, Container, Unstable_Grid2 as Grid, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";

export const CaesarsBanner = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { caesarsTokenInfoPromise } = useLoaderData() as {
    caesarsTokenInfoPromise: ApiEmbeddedCaesarsTokenResponse;
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const loadingFallback = (
    <Grid xs={12} sm={7} md={8}>
      <Skeleton variant="rectangular" height={80} />
    </Grid>
  );

  return (
    // Only render banner if caesarsTokenInfoPromise is defined
    caesarsTokenInfoPromise ? (
      <React.Suspense fallback={loadingFallback}>
        <Await
          resolve={caesarsTokenInfoPromise}
          errorElement={<Alert severity="error">Unable to load Caesars Rewards Points</Alert>}
        >
          {(caesarsTokenInfo: ApiEmbeddedCaesarsTokenResponse) => {
            let rewardsBalance;
            if (sessionStorage.getItem("CAESARS_POINTS_BALANCE")) {
              rewardsBalance = Math.floor(Number(sessionStorage.getItem("CAESARS_POINTS_BALANCE")))?.toLocaleString();
            } else {
              rewardsBalance = Math.floor(caesarsTokenInfo.rewardsPoints)?.toLocaleString();
            }

            return (
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                }}
              >
                <Container
                  sx={{
                    pb: isMobile ? 3.5 : 2.5,
                    px: 3,
                    pt: isMobile ? 5 : 2.5,
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    maxWidth="lg"
                    sx={{ flexDirection: isMobile ? "column" : "row" }}
                  >
                    <Grid sx={{ cursor: "pointer", mb: isMobile ? 2 : 0 }} onClick={() => navigate("/")}>
                      <Typography variant="h4">Caesars Rewards® Members</Typography>
                      <Typography variant="body2">
                        Own bitcoin for as little as 250 Reward Credits. Click Redeem Now to get started!
                      </Typography>
                    </Grid>
                    <Grid>
                      <Stack
                        direction={isMobile ? "column" : "row"}
                        mt={isMobile ? 2 : 0}
                        spacing={3}
                        alignItems="left"
                      >
                        <Grid sx={{ textAlign: isMobile ? "left" : "right" }}>
                          <Typography variant="h3">{rewardsBalance || "N/A"}</Typography>
                          <Typography variant="body2" mt={-1}>
                            Reward Credits
                          </Typography>
                        </Grid>
                        <Button
                          onClick={() => navigate("/redeem/fees-waived")}
                          sx={{
                            backgroundColor: color.loyalLemon.main,
                            color: color.text.main,
                            height: 40,
                            maxWidth: isMobile ? "none" : 200,
                            px: 5,
                            "&:hover": {
                              backgroundColor: color.loyalLemon.dark,
                            },
                          }}
                        >
                          Redeem Now
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            );
          }}
        </Await>
      </React.Suspense>
    ) : (
      <></>
    )
  );
};
