import { RootContextType, useRootContext } from "@/RootLayout.tsx";
import CryptoSelect from "@/components/cryptoSelect/CryptoSelect";
import ModalLoadingIndicator from "@/components/loading/ModalLoadingIndicator.tsx";
import {
  btcExecutePrice,
  cryptoCurrencyTransaction,
  ethExecutePrice,
  fetchMockDataPromiseWithDelay,
  partnerPartyInfo,
} from "@/services/mockData.ts";
import {
  ApiAmountCurrencyEnum,
  ApiEmbeddedCryptoExecutePriceRequest,
  ApiEmbeddedCryptoExecutePriceRequestBuySellIndicatorEnum,
  ApiEmbeddedCryptoExecutePriceRequestDestinationCurrencyEnum,
  ApiEmbeddedCryptoExecutePriceResponse,
  ApiEmbeddedCryptoTransactionRequest,
  ApiEmbeddedCryptoTransactionRequestTransactTypeEnum,
  ApiEmbeddedFundingAccount,
  ApiEmbeddedPartnerPartyProfileResponse,
} from "@/services/openAPI/embedded";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Button, Dialog, Divider, Icons, Typography, formatDollarAmountUsd, themes } from "@bakkt/bakkt-ui-components";
import {
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Link,
  Stack,
  SvgIcon,
  ThemeProvider,
  Tooltip,
  capitalize,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useFetcher, useLoaderData, useLocation, useNavigate, useNavigation } from "react-router-dom";

interface BuySellTransactionReviewProps {
  transactionType: ApiEmbeddedCryptoTransactionRequestTransactTypeEnum;
  selectedCrypto: ApiAmountCurrencyEnum;
  amount: string;
  cashAccount: ApiEmbeddedFundingAccount;
  isSellAll: boolean;
  accountCryptoAmount: number;
}

const BuySellTransactionReview = () => {
  const { partnerPartyProfile } = useLoaderData() as {
    partnerPartyProfile: ApiEmbeddedPartnerPartyProfileResponse;
  };
  const theme = useTheme();
  const fetcher = useFetcher();
  const navigation = useNavigation();
  const { transactionType, selectedCrypto, amount, cashAccount, isSellAll, accountCryptoAmount } = useLocation()
    ?.state as BuySellTransactionReviewProps;
  const navigate = useNavigate();
  const { addAlert } = useRootContext() as RootContextType;
  const [open, setOpen] = useState(true);
  const [executePrice, setExecutePrice] = useState<ApiEmbeddedCryptoExecutePriceResponse>();
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const sellAllAmountUsd = Number((accountCryptoAmount * (executePrice?.unitPrice.amount || 0)).toFixed(2));
  const userState = partnerPartyProfile?.Party?.address?.region;
  //TODO: Backend will be eventually be providing an API for this instead
  const TOOLTIP_VISIBLE_STATES = ["NY"];

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const handleSubmit = () => {
    const request: ApiEmbeddedCryptoTransactionRequest = {
      transactType: transactionType,
      quantity: { amount: isSellAll ? accountCryptoAmount : executePrice?.quantity.amount, currency: selectedCrypto },
      price: { currency: "USD", amount: isSellAll ? sellAllAmountUsd : Number(amount) },
      externalAccountRef: cashAccount.externalAccountRef,
    };

    submitActionRequest(fetcher, request);
  };

  useEffect(() => {
    async function getExecutePrices() {
      const executeRequest: ApiEmbeddedCryptoExecutePriceRequest = {
        buySellIndicator:
          transactionType === ApiEmbeddedCryptoTransactionRequestTransactTypeEnum.Buy
            ? ApiEmbeddedCryptoExecutePriceRequestBuySellIndicatorEnum.Buy
            : ApiEmbeddedCryptoExecutePriceRequestBuySellIndicatorEnum.Sell,
        destinationCurrency: selectedCrypto,
        source: { amount: Number(amount), currency: ApiEmbeddedCryptoExecutePriceRequestDestinationCurrencyEnum.Usd },
      };

      const executePricePromise: any = shouldUseMockData
        ? fetchMockDataPromiseWithDelay(selectedCrypto === "BTC" ? btcExecutePrice : ethExecutePrice, 1000)
        : EmbeddedPartnerService.getExecutePrice(executeRequest);

      const executePrice = await executePricePromise;

      setExecutePrice(executePrice);
    }
    getExecutePrices();
  }, []);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        const responsePriceAmount = response.data?.price?.amount;
        addAlert({
          severity: "success",
          message: `${formatDollarAmountUsd(Number(responsePriceAmount))} worth of ${selectedCrypto} successfully ${
            transactionType === "BUY" ? "bought" : "sold"
          }!`,
        });
        navigate("/transactions/transaction-complete", {
          state: {
            transactionType: transactionType,
            selectedCrypto: selectedCrypto,
            amountUsd: responsePriceAmount,
            amountCrypto: response.data?.quantity?.amount,
          },
        });
      } else {
        navigate(`/transactions/${transactionType}`, {
          state: {
            preSelectedCrypto: selectedCrypto,
            errorMessage: response.message ? response.message : "Unable to complete transaction.",
          },
        });
      }
    }
  }, [fetcher.data]);

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="md" fullWidth fullScreen={isFullScreen}>
        <DialogContent>
          {isLoading && <ModalLoadingIndicator description={"Transaction Processing"} />}
          {!isLoading && (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid xs={12} sx={{ mb: 8 }}>
                <Typography variant="h3" sx={{ textAlign: "center", mt: 2 }}>
                  Review Transaction
                </Typography>
              </Grid>
              <Grid xs={12} sx={{ mb: 4 }}>
                <CryptoSelect preSelectedCrypto={selectedCrypto} disableExpand={true} />
              </Grid>
              <Grid xs={12} sm={10} sx={{ textAlign: "center", mb: 3 }}>
                <Typography variant="h2" sx={{ mb: 4 }}>
                  {isSellAll ? `${Number(accountCryptoAmount).toFixed(8)}` : `${formatDollarAmountUsd(Number(amount))}`}
                </Typography>
                <Box sx={{ mb: 4 }}>
                  <Stack direction="row" justifyContent={"space-between"}>
                    <Grid>{isSellAll ? "Estimated Total" : "Estimated Asset Amount"}</Grid>
                    <Grid>
                      {isSellAll
                        ? formatDollarAmountUsd(sellAllAmountUsd)
                        : `${executePrice?.quantity.amount} ${selectedCrypto}`}
                    </Grid>
                  </Stack>
                  <Divider />
                </Box>
                <Box>
                  <Stack direction="row" justifyContent={"space-between"}>
                    <Grid>
                      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Box>Estimated Market Price</Box>{" "}
                        {userState && TOOLTIP_VISIBLE_STATES.includes(userState) && (
                          <Tooltip
                            title={
                              "Bakkt does not charge a transaction fee. Bakkt applies a spread of up to 125 bps to all crypto transactions. This spread allows us to cover the costs associated with facilitating the transactions, such as market volatility, order execution speed, and the risks inherent in providing a liquidity service."
                            }
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            placement={isFullScreen ? "bottom" : "right-start"}
                          >
                            <IconButton sx={{ pb: 0.25, pt: 0, px: 0 }}>
                              <SvgIcon
                                component={Icons.InformationIcon}
                                inheritViewBox
                                sx={{ width: 24, height: 24 }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Grid>
                    <Grid>
                      {executePrice?.unitPrice.amount && `${formatDollarAmountUsd(executePrice.unitPrice.amount)}`}
                    </Grid>
                  </Stack>
                  <Divider />
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className="dialog-action-buttons">
          {!isLoading && (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid xs={12} sm={10}>
                <Typography variant="body2" sx={{ textAlign: "center", mb: 3 }}>
                  {`I authorize Bakkt Marketplace, LLC ("Bakkt") to ${
                    transactionType === "BUY" ? "pay for my purchase of" : "sell"
                  } ${formatDollarAmountUsd(
                    isSellAll ? Number(sellAllAmountUsd) : Number(amount),
                  )} in ${selectedCrypto}. The
                  actual value purchased may change due to volatility in the price of ${selectedCrypto}, but my order will be
                  executed based on the best price available to Bakkt. Cryptocurrency transactions are not FDIC or SIPC
                  insured. `}
                  <Link href={"https://bakkt.com/disclosures"}>View Disclosures</Link>.
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Stack spacing={2}>
                  <Button
                    variant="contained"
                    sx={{ whiteSpace: "nowrap", textTransform: "capitalize" }}
                    onClick={handleSubmit}
                  >
                    Confirm {capitalize(transactionType.toLowerCase())}
                  </Button>
                  <Button disabled={false} variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default BuySellTransactionReview;

export async function loader() {
  const partnerPartyProfile = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(partnerPartyInfo, 3000)
    : await EmbeddedPartnerService.fetchPartnerPartyProfile();

  return {
    partnerPartyProfile,
  };
}

export async function action({ request }: { request: Request }) {
  try {
    if (shouldUseMockData) {
      const resp = await fetchMockDataPromiseWithDelay(cryptoCurrencyTransaction, 3000);
      return formatActionSuccessResponse(resp);
    }

    const buySellRequest = (await request.json()) as ApiEmbeddedCryptoTransactionRequest;
    const response = await EmbeddedPartnerService.transactCryptoCurrency(buySellRequest);

    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
