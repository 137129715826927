import { ApiEmbeddedFundingAccount, ApiEmbeddedPartnerPartyLinkInfo } from "@/services/openAPI/embedded";
import { Button, ThemeProvider, Typography, themes } from "@bakkt/bakkt-ui-components";
import { Unstable_Grid2 as Grid, useTheme } from "@mui/material";
import { useNavigate, useRouteLoaderData } from "react-router-dom";

interface BannerGettingStartedProps {
  bankAccounts: ApiEmbeddedFundingAccount[];
}

export default function BannerGettingStarted({ bankAccounts }: BannerGettingStartedProps) {
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };
  const userPartyLevel = userInfo?.party?.level;
  const theme = useTheme();
  const navigate = useNavigate();
  const eligibleLinkLevels = ["LEVEL_4", "LEVEL_4_LIQUIDATED", "LEVEL_5", "LEVEL_5_LIQUIDATED"];

  const headerCtaSection = {
    mb: 3,
    mt: 1,
    [theme.breakpoints.down("sm")]: {
      mt: 34,
    },
  };

  let buttonNavUrl = "kyc/user-info";

  if (bankAccounts.length > 0) {
    buttonNavUrl = "funding/deposit";
  } else if (eligibleLinkLevels.includes(userPartyLevel || "")) {
    buttonNavUrl = "/finance/link-bank-account";
  }

  return (
    <Grid container>
      <Grid xs={12} sm={9} md={6}>
        <Typography variant="h3" sx={headerCtaSection}>
          Unlock crypto in just a few clicks. <br />
          Add funds via bank account or debit card to get started
        </Typography>
        <ThemeProvider theme={themes.dark}>
          <Button variant="outlined" onClick={() => navigate(buttonNavUrl)}>
            Add Funds
          </Button>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}
