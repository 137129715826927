import { AxiosError } from "axios";

export interface ActionResponse {
  success: boolean;
  title?: string;
  message?: string;
  data?: string;
}

function isAxiosError(error: AxiosError | any): error is AxiosError {
  const castedAxiosError = error as AxiosError;
  // AxiosError name field is always hardcoded to "AxiosError", so we can use this to check type
  return castedAxiosError?.name !== undefined && castedAxiosError?.name === "AxiosError";
}

// Tango error messages are returned in very inconsistent formats - this function attempts to standardize them
function getErrorMessage(error: AxiosError | any): string {
  // Field containing the error message from Tango APIs standardized error response
  const tangoApiErrorMessage = error?.data?.error?.message;
  if (isAxiosError(error)) {
    const errorResponse = error.response?.data as any;
    if (errorResponse.message) {
      return errorResponse.message;
    } else if (errorResponse.error?.message) {
      return errorResponse.error.message;
    }
    return error.message;
  } else if (tangoApiErrorMessage) {
    return tangoApiErrorMessage;
  } else {
    return "An unknown error occurred.";
  }
}

export function formatActionSuccessResponse(successResponseObject: any): ActionResponse {
  // Tango API returns a 200 response status even if {success: false} so this check is needed
  if (successResponseObject?.data?.success === false) {
    return formatActionErrorResponse(successResponseObject);
  }
  return {
    success: true,
    message: successResponseObject?.message || "",
    data: successResponseObject?.data ? successResponseObject?.data : { ...successResponseObject },
  };
}

export function formatActionErrorResponse(errorResponseObject?: AxiosError | any): ActionResponse {
  return { success: false, message: getErrorMessage(errorResponseObject) };
}
