import { ApiEmbeddedPartnerPartyProfileResponse } from "@/services/openAPI/embedded";
import { Paper, Skeleton, Typography } from "@bakkt/bakkt-ui-components";
import { Alert, Unstable_Grid2 as Grid } from "@mui/material";
import React from "react";
import { Await, useLoaderData } from "react-router-dom";

const PersonalInformation = () => {
  const { partyDetailsPromise } = useLoaderData() as {
    partyDetailsPromise: Promise<ApiEmbeddedPartnerPartyProfileResponse>;
  };

  return (
    <Grid>
      <Typography variant="h3">Login Information</Typography>
      <React.Suspense fallback={<Skeleton variant="rectangular" height={160} sx={{ mb: 5, mt: 2 }} />}>
        <Await
          resolve={partyDetailsPromise}
          errorElement={<Alert severity="error">Error loading personal information</Alert>}
        >
          {(partyDetails: ApiEmbeddedPartnerPartyProfileResponse) => (
            <Paper sx={{ borderRadius: 0, mb: 4, mt: 2, p: 5 }}>
              <Grid container sx={{ justifyContent: "space-between", p: 0 }}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                  Credentials
                </Typography>
                {/* <Link sx={{
                      mt: 0.5,
                      textDecoration: "underline",
                      "&:hover": {
                        color: color.text.secondary,
                      },
                    }}>Edit</Link> */}
              </Grid>
              <Typography>Email: {partyDetails?.Party?.email}</Typography>
              <Typography>Password: ●●●●●●●●●●●</Typography>
            </Paper>
          )}
        </Await>
      </React.Suspense>
    </Grid>
  );
};

export default PersonalInformation;
