import { RootContextType, useRootContext } from "@/RootLayout";
import ModalLoadingIndicator from "@/components/loading/ModalLoadingIndicator.tsx";
import { fetchMockDataPromiseWithDelay, mockSuccessResponse, partnerPartyInfo } from "@/services/mockData.ts";
import {
  ApiAddressCountryEnum,
  ApiEmbeddedPartnerPartyEnrollRequest,
  ApiEmbeddedPartnerPartyLinkInfo,
  ApiEmbeddedPartnerPartyProfileResponse,
  ApiPartnerPartyEnrollmentData,
} from "@/services/openAPI/embedded";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { omitFieldsFromObject, shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import {
  UserInfoFieldErrors,
  UserInfoFormData,
  allFieldsFilled,
  isValidAddress,
  isValidCity,
  isValidDateOfBirth,
  isValidSSN,
  isValidZipCode,
} from "@/utils/formValidation";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import {
  Button,
  Dialog,
  FormHelperText,
  Select,
  TextField,
  Typography,
  statesList,
  themes,
  usTerritoriesList,
} from "@bakkt/bakkt-ui-components";
import {
  DialogActions,
  DialogContent,
  FormControl,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useFetcher, useLoaderData, useNavigate, useNavigation, useRouteLoaderData } from "react-router-dom";

const UserInfoInput = () => {
  const { partnerPartyProfile } = useLoaderData() as {
    partnerPartyProfile: ApiEmbeddedPartnerPartyProfileResponse;
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const { addAlert } = useRootContext() as RootContextType;
  const { userInfo } = useRouteLoaderData("root") as { userInfo: ApiEmbeddedPartnerPartyLinkInfo };
  const [open, setOpen] = useState(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";

  // Profile API returns already submitted fields in uppercase underscore format e.g. FIRST_NAME
  // This converts these to camelcase since that is what the enroll API expects hence what our form fields are named
  const alreadySubmittedFieldsList = partnerPartyProfile?.fieldsAvailableFromPartner?.map((fieldName) =>
    fieldName.toLowerCase().replace(/_([a-z])/g, function (g) {
      return g[1].toUpperCase();
    }),
  );

  const stateAndTerritoryExclusions = ["FM", "HI", "MH", "MP", "PR", "PW"];
  const statesAndTerritoriesList = [...statesList, ...usTerritoriesList]
    .sort((item1, item2) => item1.abbreviation.localeCompare(item2.abbreviation))
    .filter((item) => !stateAndTerritoryExclusions.includes(item.abbreviation));

  useEffect(() => {
    const userPartyLevel = userInfo?.party?.level;
    if (userPartyLevel === "LEVEL_3") {
      navigate("/kyc/tax-payer-cert");
    } else if (userPartyLevel === "LEVEL_4" || userPartyLevel === "LEVEL_5") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        navigate(`/kyc/tax-payer-cert`);
      } else {
        addAlert({
          severity: "error",
          messageHeader: "There was an error saving your information.",
          message: "Failed to save user information. Please try again later or contact support.",
        });
        navigate("/");
      }
    }
  }, [fetcher.data]);

  const [formData, setFormData] = useState<UserInfoFormData>({
    firstName: partnerPartyProfile?.Party?.firstName || "",
    lastName: partnerPartyProfile?.Party?.lastName || "",
    address1: partnerPartyProfile?.Party?.address?.streetLine1 || "",
    address2: partnerPartyProfile?.Party?.address?.streetLine2 || "",
    city: partnerPartyProfile?.Party?.address?.locality || "",
    state: partnerPartyProfile?.Party?.address?.region || "",
    zipCode: partnerPartyProfile?.Party?.address?.postalCode || "",
    ssn: partnerPartyProfile?.Party?.taxIdentifier || "",
    phone: partnerPartyProfile?.Party?.phone || "",
    occupation: partnerPartyProfile?.Party?.occupation || "",
    dob: partnerPartyProfile?.Party?.dateOfBirth ? dayjs(partnerPartyProfile.Party.dateOfBirth) : null,
  });
  const [fieldErrors, setFieldErrors] = useState<UserInfoFieldErrors>({
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    ssn: "",
    phone: "",
    occupation: "",
    dob: "",
  });

  const validateField = (name: string, value: string): string => {
    // Validator mappings
    const validators: { [key: string]: (val: string) => string } = {
      address1: isValidAddress,
      city: isValidCity,
      zipCode: isValidZipCode,
      ssn: isValidSSN,
      dob: isValidDateOfBirth,
      phone: (val) => (matchIsValidTel("+1 " + val) ? "" : "Invalid Input"),
    };

    if (name in validators) {
      return validators[name](value);
    }
    return "";
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (!dayjs.isDayjs(date) && date !== null) {
      console.error("Unexpected date format:", date);
      return;
    }

    setFormData((prevData) => ({ ...prevData, dob: date }));
    const formattedDate = date ? date.format("MM/DD/YYYY") : "";
    const errorMessage = isValidDateOfBirth(formattedDate);
    setFieldErrors((prevErrors) => ({ ...prevErrors, dob: errorMessage }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    // Check if the name is a valid key
    if (name in formData) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));

      const errorMessage = validateField(name, value);
      setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (!name || value === undefined) {
      return;
    }

    const newFieldErrors = { ...fieldErrors };

    if (!value.trim() && name !== "address2") {
      newFieldErrors[name as keyof UserInfoFormData] = "Input Required";
    } else {
      const errorMessage = validateField(name, value);
      newFieldErrors[name as keyof UserInfoFormData] = errorMessage;
    }

    setFieldErrors(newFieldErrors);
  };

  const handlePhoneChange = (value: string) => {
    setFormData((prevData) => ({ ...prevData, phone: value }));
  };

  const handleDatePickerBlur = (): void => {
    if (!formData.dob) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, dob: "Date of Birth is required" }));
    }
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const handleSubmit = async () => {
    const profileId = userInfo.party?.id;
    const partnerPartyRef = userInfo.partnerPartyLink?.partnerPartyLink?.partnerPartyRef;

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000).toString();

    if (profileId && partnerPartyRef) {
      const enrollmentData: ApiPartnerPartyEnrollmentData = {
        partnerPartyRef,
        privacyPolicyPublishTime: currentTimestampInSeconds,
        termsAndConditionsPublishTime: currentTimestampInSeconds,
        address: {
          country: ApiAddressCountryEnum.Usa,
          locality: formData.city,
          postalCode: formData.zipCode,
          region: formData.state,
          streetLine1: formData.address1,
          streetLine2: formData.address2,
        },
        firstName: formData.firstName,
        lastName: formData.lastName,
        taxIdentifier: formData.ssn,
        // MuiTelInput includes spaces in its value
        phone: formData.phone.replace(/ /g, ""),
        dateOfBirth: formData.dob?.format("YYYY-MM-DD") || "",
        occupation: formData.occupation,
      };

      // Now remove the already submitted fields from a previous enrollment from the enrollmentData object
      const enrollmentDataWithPreviouslySubmittedFieldsRemoved = omitFieldsFromObject(
        alreadySubmittedFieldsList || [],
        enrollmentData,
      ) as ApiPartnerPartyEnrollmentData;

      const enrollPartnerPartyRequest: ApiEmbeddedPartnerPartyEnrollRequest = {
        profileId,
        missingFieldValue: enrollmentDataWithPreviouslySubmittedFieldsRemoved,
      };

      submitActionRequest(fetcher, enrollPartnerPartyRequest);
    }
  };

  return (
    <>
      <ThemeProvider theme={themes.dark}>
        <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="sm" fullScreen={fullScreen}>
          <DialogContent sx={{ textAlign: "center", overflowY: "hidden" }}>
            {isLoading ? (
              <ModalLoadingIndicator description={"Processing"} />
            ) : (
              <>
                <Grid container sx={{ mb: 5 }}>
                  <Grid>
                    <Typography variant="h3" sx={{ mt: 2.5 }}>
                      We need a few more details to get&nbsp;started
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      Before adding crypto to your wallet, we need to gather a few more details about you. The following
                      details are required to confirm your identity.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <TextField
                      type="text"
                      label="First Name"
                      name="firstName"
                      variant="standard"
                      fullWidth
                      required
                      value={formData.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(fieldErrors.firstName)}
                      helperText={fieldErrors.firstName}
                      disabled={alreadySubmittedFieldsList?.includes("firstName")}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      type="text"
                      label="Last Name"
                      name="lastName"
                      variant="standard"
                      fullWidth
                      required
                      value={formData.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(fieldErrors.lastName)}
                      helperText={fieldErrors.lastName}
                      disabled={alreadySubmittedFieldsList?.includes("lastName")}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      type="text"
                      label="Address 1"
                      name="address1"
                      variant="standard"
                      fullWidth
                      required
                      value={formData.address1}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(fieldErrors.address1)}
                      helperText={fieldErrors.address1}
                      disabled={alreadySubmittedFieldsList?.includes("address")}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      type="text"
                      label="Address 2"
                      name="address2"
                      variant="standard"
                      fullWidth
                      value={formData.address2}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(fieldErrors.address2)}
                      helperText={fieldErrors.address2}
                      disabled={alreadySubmittedFieldsList?.includes("address")}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      type="text"
                      label="City"
                      name="city"
                      variant="standard"
                      fullWidth
                      required
                      value={formData.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(fieldErrors.city)}
                      helperText={fieldErrors.city}
                      disabled={alreadySubmittedFieldsList?.includes("address")}
                    />
                  </Grid>
                  <Grid xs={6} pb={0}>
                    <FormControl
                      variant="standard"
                      sx={{ textAlign: "left", width: "100%", mt: "0" }}
                      error={Boolean(fieldErrors.state)}
                      onBlur={(e: React.FocusEvent<HTMLDivElement>) => handleBlur(e as any)}
                    >
                      <InputLabel id="small-select-id">State</InputLabel>
                      <Select
                        size="medium"
                        label="small-select-state"
                        labelId="small-select-id"
                        name="state"
                        value={formData.state}
                        disabled={alreadySubmittedFieldsList?.includes("address")}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFormData((prevData) => ({ ...prevData, [name]: value }));
                          setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {statesAndTerritoriesList.map(({ abbreviation }: { abbreviation: string }, index: number) => (
                          <MenuItem key={index} value={abbreviation}>
                            {abbreviation}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(fieldErrors.state) && <FormHelperText>{fieldErrors.state}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid xs={6} sx={{ pb: 0 }}>
                    <TextField
                      type="text"
                      inputProps={{ maxLength: 5 }}
                      label="Zip Code"
                      name="zipCode"
                      variant="standard"
                      fullWidth
                      required
                      value={formData.zipCode}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(fieldErrors.zipCode)}
                      helperText={fieldErrors.zipCode}
                      disabled={alreadySubmittedFieldsList?.includes("address")}
                    />
                  </Grid>
                  <Grid xs={12} pt={0}>
                    <Typography
                      sx={{
                        color: "#999999",
                        fontSize: "body2.fontSize",
                        textAlign: "left",
                      }}
                    >
                      HI, PR not currently supported by Bakkt
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <MuiTelInput
                      label="Phone Number"
                      name="phone"
                      variant="standard"
                      fullWidth
                      required
                      forceCallingCode
                      value={formData.phone}
                      onBlur={handleBlur}
                      onChange={handlePhoneChange}
                      error={Boolean(fieldErrors.phone)}
                      helperText={fieldErrors.phone}
                      inputProps={{ maxLength: 17 }}
                      defaultCountry="US"
                      disabled={alreadySubmittedFieldsList?.includes("phone")}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      type="text"
                      label="Occupation"
                      name="occupation"
                      variant="standard"
                      fullWidth
                      required
                      value={formData.occupation}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(fieldErrors.occupation)}
                      helperText={fieldErrors.occupation}
                      disabled={alreadySubmittedFieldsList?.includes("occupation")}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <TextField
                      type="password"
                      inputProps={{ maxLength: 9 }}
                      label="SSN"
                      name="ssn"
                      variant="standard"
                      fullWidth={true}
                      required
                      value={formData.ssn}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(fieldErrors.ssn)}
                      helperText={fieldErrors.ssn}
                      disabled={alreadySubmittedFieldsList?.includes("taxIdNumber")}
                    />
                  </Grid>
                  <Grid xs={6} pt={1.5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className="datePickerWrapper" onBlur={handleDatePickerBlur}>
                        <input
                          style={{ position: "absolute", opacity: 0, height: 0 }}
                          tabIndex={-1}
                          onBlur={handleDatePickerBlur}
                        />
                        <DatePicker
                          label="Date of Birth"
                          value={formData.dob as dayjs.Dayjs | null}
                          onChange={handleDateChange}
                          sx={{ width: "100%", mt: 2 }}
                          disabled={alreadySubmittedFieldsList?.includes("dateOfBirth")}
                        />
                      </div>

                      {fieldErrors.dob && <FormHelperText error>{fieldErrors.dob}</FormHelperText>}
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={12}>
                    <Typography
                      sx={{
                        color: "#999999",
                        fontSize: "body2.fontSize",
                        textAlign: "left",
                      }}
                    >
                      The U.S. government requires your Social Security Number to verify your identity.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", pt: 6.5 }}>
            {!isLoading && (
              <Stack spacing={2} sx={{ width: "66%" }}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  onClick={() => handleSubmit()}
                  disabled={!allFieldsFilled(formData, fieldErrors)}
                >
                  Continue
                </Button>
                <Button variant="text" onClick={() => navigate("/")}>
                  Cancel
                </Button>
              </Stack>
            )}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default UserInfoInput;

export async function loader() {
  const partnerPartyProfile = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(partnerPartyInfo, 3000)
    : await EmbeddedPartnerService.fetchPartnerPartyProfile();

  return {
    partnerPartyProfile,
  };
}

export async function action({ request }: { request: Request }) {
  try {
    if (shouldUseMockData) {
      const resp = await fetchMockDataPromiseWithDelay(mockSuccessResponse, 3000);
      return formatActionSuccessResponse(resp);
    }
    const userInfoRequest = (await request.json()) as ApiEmbeddedPartnerPartyEnrollRequest;
    const response = await EmbeddedPartnerService.enrollPartnerParty(userInfoRequest);
    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
