import ModalLoadingIndicator from "@/components/loading/ModalLoadingIndicator.tsx";
import {
  fetchMockDataPromiseWithDelay,
  partySignupAvailableResponse as partySignupAvailableResponseMock,
  partySignupValidateResponse as partySignupValidateResponseMock,
  userInfo as userInfoMock,
} from "@/services/mockData.ts";
import { ApiAvailabilityRequest, ApiValidateIdentifierRequest, Identifier } from "@/services/openAPI/embedded";
import { EmbeddedPartnerService, EmbeddedPartySignUpService } from "@/services/serviceLoader.ts";
import { pageFooterText, shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import {
  CaesarsUserSignupFieldErrors,
  CaesarsUserSignupFormData,
  allCaesarsUserSignupFieldsFilled,
  isValidEmail,
  isValidPassword,
} from "@/utils/formValidation";
import { formatActionErrorResponse } from "@/utils/responseHandlingUtils.ts";
import { Alert, Button, Dialog, TextField, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Unstable_Grid2 as Grid,
  Link,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ChangeEvent, useEffect, useState } from "react";
import { LoaderFunctionArgs, redirect, useFetcher, useNavigate, useNavigation } from "react-router-dom";
import BakktLogo from "../../assets/images/bakkt_logo.svg";
import CaesarsBg from "../../assets/images/caesars_bg.svg";
import CaesarsRewardsLogo from "../../assets/images/caesars_rewards_logo.png";
import LoginBg from "../../assets/images/login_bg.svg";
import styles from "../auth/Login.module.css";

const CaesarsUserSignup = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const [isFormValid, setIsformValid] = useState<boolean>();
  const [acceptTosChecked, setAcceptTosChecked] = useState<boolean>(false);
  const [identifierToken, setIdentifierToken] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";
  const caesarsToken = sessionStorage.getItem("CAESARS_TOKEN");

  const backgroundSx = caesarsToken
    ? {
        backgroundImage: "url(" + CaesarsBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }
    : {
        backgroundImage: "url(" + LoginBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "3000px",
      };
  const [formData, setFormData] = useState<CaesarsUserSignupFormData>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [fieldErrors, setFieldErrors] = useState<CaesarsUserSignupFieldErrors>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const validateField = (name: string, value: string): string => {
    // Validator mappings
    const validators: { [key: string]: (val: string) => string } = {
      email: isValidEmail,
      password: isValidPassword,
    };

    if (name in validators) {
      return validators[name](value);
    }
    return "";
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.identifierToken) {
        navigate("/verify-email-address", {
          state: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            password: formData.password,
            identifierToken: response.identifierToken,
          },
        });
      } else {
        setErrorMessage(response.message);
      }
    }
  }, [fetcher.data]);

  const handleSubmit = () => {
    if (isFormValid && acceptTosChecked) {
      setErrorMessage("");
      const currentTimestampInSeconds = Math.floor(Date.now() / 1000).toString();
      const validateIdentifierRequest: ApiValidateIdentifierRequest = {
        identifierToken,
        privacyPolicyPublishTime: currentTimestampInSeconds,
        termsAndConditionsPublishTime: currentTimestampInSeconds,
      };

      submitActionRequest(fetcher, validateIdentifierRequest);
    }
  };

  const handleSubmitKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && isFormValid) {
      handleSubmit();
    }
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!name || value === undefined) {
      return;
    }

    const newFieldErrors: CaesarsUserSignupFieldErrors = { ...fieldErrors };

    if (name === "confirmPassword" && value !== formData.password) {
      newFieldErrors[name as keyof CaesarsUserSignupFieldErrors] = "Passwords must match";
    } else if (!value.trim()) {
      newFieldErrors[name as keyof CaesarsUserSignupFieldErrors] = "Input required";
    } else {
      const validationErrorMessage = validateField(name, value);
      newFieldErrors[name as keyof CaesarsUserSignupFieldErrors] = validationErrorMessage;
    }

    // If the field is email and there are no email validation errors, check if the email address is available
    if (name === "email" && !newFieldErrors["email"]) {
      // Check if email is available and if so, get identifierToken
      const partySignupGetAvailableRequest: ApiAvailabilityRequest = {
        identifier: {
          email: value,
        },
      };
      const partySignupGetAvailableResponse = shouldUseMockData
        ? partySignupAvailableResponseMock
        : ((await EmbeddedPartySignUpService.available(partySignupGetAvailableRequest)) as Identifier);

      if (partySignupGetAvailableResponse?.available && partySignupGetAvailableResponse.identifierToken) {
        // Email is available
        setIdentifierToken(partySignupGetAvailableResponse.identifierToken);
      } else {
        // Email is unavailable
        newFieldErrors[name as keyof CaesarsUserSignupFieldErrors] =
          "Email address is already associated with an account";
      }
    }

    if (
      Object.keys(newFieldErrors).find(
        (fieldErrorKey) => newFieldErrors[fieldErrorKey as keyof CaesarsUserSignupFieldErrors] !== "",
      )
    ) {
      setIsformValid(false);
    } else {
      setIsformValid(true);
    }

    setFieldErrors(newFieldErrors);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    // Check if the name is a valid key
    if (name in formData) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  return (
    <ThemeProvider theme={themes.light}>
      <Dialog scroll="body" open={true} maxWidth="sm" fullScreen={isFullScreen} sx={backgroundSx} hideBackdrop={true}>
        {errorMessage && (
          <Grid sx={{ mb: 2, left: 0, position: "fixed", right: 0, top: 0 }}>
            <Alert
              severity="error"
              sx={{
                backgroundColor: "error.500",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">{errorMessage}</Typography>
            </Alert>
          </Grid>
        )}
        {isLoading ? (
          <ModalLoadingIndicator description={"Processing"} />
        ) : (
          <>
            <DialogContent sx={{ alignItems: "center", textAlign: "center", overflowY: "hidden" }}>
              <Grid container xs={12} sx={{ mb: 3 }}>
                <Grid xs={12}>
                  <Grid container justifyContent="center">
                    {caesarsToken && (
                      <Grid sx={{ borderRight: "1px solid black", mr: 3, pr: 2 }}>
                        <img src={CaesarsRewardsLogo} alt="Caesars Rewards logo" style={{ width: "106px" }} />
                      </Grid>
                    )}
                    <img src={BakktLogo} alt="Bakkt logo" className={styles["login_logo"]} />
                  </Grid>
                  <Typography variant="h3" sx={{ mt: 2 }}>
                    Create an Account
                  </Typography>
                  <Grid mt={2} sx={{ textWrap: "nowrap" }}>
                    <Typography>Redeem Caesars Reward Credits for bitcoin.</Typography>
                    <Typography>250 Reward Credits redeems $1 of bitcoin</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <TextField
                  type="text"
                  label="First name"
                  name="firstName"
                  variant="standard"
                  fullWidth
                  required
                  value={formData.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(fieldErrors.firstName)}
                  helperText={fieldErrors.firstName}
                  onKeyUp={handleSubmitKeyPress}
                />
                <TextField
                  type="text"
                  label="Last name"
                  name="lastName"
                  variant="standard"
                  fullWidth
                  required
                  value={formData.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(fieldErrors.lastName)}
                  helperText={fieldErrors.lastName}
                  onKeyUp={handleSubmitKeyPress}
                  sx={{ mt: 2 }}
                />
                <TextField
                  type="email"
                  label="Email"
                  name="email"
                  variant="standard"
                  fullWidth
                  required
                  value={formData.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(fieldErrors.email)}
                  helperText={fieldErrors.email}
                  onKeyUp={handleSubmitKeyPress}
                  sx={{ mt: 2 }}
                />
                <TextField
                  type="password"
                  label="Password"
                  name="password"
                  variant="standard"
                  fullWidth
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(fieldErrors.password)}
                  helperText={fieldErrors.password}
                  onKeyUp={handleSubmitKeyPress}
                  sx={{ mt: 2 }}
                />
                <TextField
                  type="password"
                  label="Re-enter password"
                  name="confirmPassword"
                  variant="standard"
                  fullWidth
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(fieldErrors.confirmPassword)}
                  helperText={fieldErrors.confirmPassword}
                  onKeyUp={handleSubmitKeyPress}
                  sx={{ mt: 2 }}
                />
                <FormGroup sx={{ mt: 4 }}>
                  <FormControlLabel
                    sx={{ mr: 0 }}
                    control={
                      <Checkbox
                        checked={acceptTosChecked}
                        onChange={() => {
                          setAcceptTosChecked(!acceptTosChecked);
                        }}
                        sx={{ mt: isFullScreen ? -3 : -1, pt: 0 }}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ textAlign: "left", mt: isFullScreen ? -2.5 : "auto" }}>
                        I am at least 18 years old and agree to Bakkt’s{" "}
                        <Link href="https://bakkt.com/terms-of-service" target="_blank">
                          Terms of Service
                        </Link>{" "}
                        and{" "}
                        <Link href="https://bakkt.com/privacy-policy" target="_blank">
                          Privacy Policy
                        </Link>
                      </Typography>
                    }
                    value={acceptTosChecked}
                    onChange={() => {
                      setAcceptTosChecked(!acceptTosChecked);
                    }}
                  />
                </FormGroup>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", pt: 5 }}>
              {!isLoading && (
                <Stack spacing={2} alignItems="center">
                  <Button
                    variant="contained"
                    disabled={!allCaesarsUserSignupFieldsFilled(formData, fieldErrors) || !acceptTosChecked}
                    onClick={handleSubmit}
                    sx={{ width: 190 }}
                  >
                    Create Account
                  </Button>
                  <Link sx={{ cursor: "pointer" }}>
                    <Grid spacing={2}>
                      <Typography variant="body2" sx={{ textAlign: "center" }} onClick={() => navigate("/login")}>
                        Already have an account? Log in
                      </Typography>
                    </Grid>
                  </Link>
                </Stack>
              )}
            </DialogActions>
          </>
        )}
        <Grid sx={{ textAlign: "-webkit-center" }}>
          <Box sx={{ mt: "20px", mb: "20px", width: "70%" }}>
            <Box>
              <Grid xs={6}>
                <Typography variant="caption" sx={{ textAlign: "center" }}>
                  {pageFooterText}
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
};

export default CaesarsUserSignup;

export async function loader({ params }: LoaderFunctionArgs) {
  if (params.partnerName === "caesars" && params.tokenName === "token" && params.encodedToken) {
    try {
      // We receive token as Base64 encoded, but APIs expect it to be decoded, so we decode here before storing:
      const base64DecodedToken = atob(params.encodedToken);
      sessionStorage.setItem("CAESARS_TOKEN", base64DecodedToken);
    } catch (e) {
      console.log("Invalid Base64 token received.");
    }
  }

  // Only Caesars users can create accounts
  const caesarsToken = sessionStorage.getItem("CAESARS_TOKEN");
  if (!caesarsToken) {
    return redirect("/login");
  }

  const userInfoPromise: any = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(userInfoMock, 200)
    : EmbeddedPartnerService.getPartnerPartyLinkInfo();
  try {
    const userInfo = await userInfoPromise;
    if (userInfo) {
      // If user already has a valid session, do not render this page - redirect to dashboard
      return redirect("/");
    }
  } catch {
    return null;
  }
}

export async function action({ request }: { request: Request }) {
  try {
    if (shouldUseMockData) {
      const resp = await fetchMockDataPromiseWithDelay(partySignupValidateResponseMock, 3000);
      return resp;
    }
    const signupRequest = (await request.json()) as ApiValidateIdentifierRequest;
    const response = await EmbeddedPartySignUpService.validate(signupRequest);
    return response;
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
