import AmountInput from "@/components/finance/AmountInput";
import useAmountInput from "@/hooks/useAmountInput";
import {
  ApiAmountCurrencyEnum,
  ApiEmbeddedCryptoPriceResponse,
  ApiEmbeddedCryptoTransactionRequestTransactTypeEnum,
  ApiEmbeddedFundingAccount,
  ApiEmbeddedPartnerPartyAccount,
  ApiEmbeddedPartnerPartyLinkInfo,
} from "@/services/openAPI/embedded";
import {
  Button,
  Dialog,
  Typography,
  color,
  cryptoAssetsMap,
  formatDollarAmountUsd,
  themes,
} from "@bakkt/bakkt-ui-components";
import {
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

interface BuySellTransactionProps {
  preSelectedCrypto: ApiAmountCurrencyEnum;
  errorMessage: string;
}

const BuySellTransaction = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { fundingAccountsPromise, userCryptoAccountsPromise, btcPricePromise, ethPricePromise, userInfo } =
    useRouteLoaderData("root") as {
      fundingAccountsPromise: Promise<ApiEmbeddedFundingAccount[]>;
      userCryptoAccountsPromise: Promise<ApiEmbeddedPartnerPartyAccount[]>;
      btcPricePromise: Promise<ApiEmbeddedCryptoPriceResponse>;
      ethPricePromise: Promise<ApiEmbeddedCryptoPriceResponse>;
      userInfo: ApiEmbeddedPartnerPartyLinkInfo;
    };
  const { preSelectedCrypto, errorMessage } = (useLocation()?.state as BuySellTransactionProps) || {};
  const [cashAccount, setCashAccount] = useState<ApiEmbeddedFundingAccount>();
  const [userCryptoAccounts, setUserCryptoAccounts] = useState<ApiEmbeddedPartnerPartyAccount[]>();
  const [btcPrice, setBtcPrice] = useState<number>();
  const [ethPrice, setEthPrice] = useState<number>();
  const [open, setOpen] = useState<boolean>(true);
  const [selectedCrypto, setSelectedCrypto] = useState<ApiAmountCurrencyEnum>(preSelectedCrypto || "BTC");
  const { transactionTypeParam } = useParams() as {
    transactionTypeParam: string;
  };
  const [transactionType, setTransactionType] = useState<ApiEmbeddedCryptoTransactionRequestTransactTypeEnum>(
    transactionTypeParam.toUpperCase() as ApiEmbeddedCryptoTransactionRequestTransactTypeEnum,
  );
  const isBuyTransaction = transactionType === "BUY";
  const isSellTransaction = transactionType === "SELL";
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const selectedCryptoCurrencyAccount = userCryptoAccounts?.find(
    (account) => account.accountBalance?.currency === selectedCrypto,
  );
  const selectedCryptoCurrencyAccountBalance = selectedCryptoCurrencyAccount?.buyBalance?.amount;
  const selectedCryptoCurrencyAccountCryptoQuantity = selectedCryptoCurrencyAccount?.accountBalance?.amount;

  const userPartyLevel = userInfo?.party?.level;

  const MIN_BUY_SELL_AMOUNT = 1;
  const MAX_BUY_AMOUNT = 999;

  const errorMessages = {
    invalidInput: "Invalid input.",
    minDeposit: `You must ${isBuyTransaction ? "purchase" : "sell"} at least ${formatDollarAmountUsd(
      MIN_BUY_SELL_AMOUNT,
    )} worth of crypto.`,
    maxAmountExceeded: `Daily crypto purchases may not exceed ${formatDollarAmountUsd(MAX_BUY_AMOUNT)}`,
  };

  useEffect(() => {
    async function awaitPromisesAndSetInitialState() {
      const fundingAccounts = await fundingAccountsPromise;
      const cashAccount = fundingAccounts.find((account) => account.accountType === "CASH");
      setCashAccount(cashAccount);

      const btcPrice = await btcPricePromise;
      const ethPrice = await ethPricePromise;
      if (transactionType === ApiEmbeddedCryptoTransactionRequestTransactTypeEnum.Buy) {
        setBtcPrice(btcPrice.sellPrice?.amount);
        setEthPrice(ethPrice.sellPrice?.amount);
      } else {
        setBtcPrice(btcPrice.buyPrice?.amount);
        setEthPrice(ethPrice.buyPrice?.amount);
      }

      const userCryptoAccounts = await userCryptoAccountsPromise;
      setUserCryptoAccounts(userCryptoAccounts);
    }
    awaitPromisesAndSetInitialState();
  }, []);

  const {
    amount,
    helperText,
    parsedAmount,
    cryptoAmount,
    handleAmountChange,
    handlePresetAmount,
    handleFocus,
    handleBlur,
    handleResetInputAndValidation,
    maxError,
    minError,
    balanceError,
  } = useAmountInput(
    selectedCrypto === "BTC" ? btcPrice : ethPrice,
    selectedCrypto,
    errorMessages,
    isBuyTransaction ? cashAccount?.availableBalance?.amount : selectedCryptoCurrencyAccountBalance,
    MIN_BUY_SELL_AMOUNT,
    isBuyTransaction ? MAX_BUY_AMOUNT : undefined,
  );
  const isSellAll = selectedCryptoCurrencyAccountBalance === Number(amount);

  const handleSwitchTab = (
    _event: React.SyntheticEvent,
    newValue: ApiEmbeddedCryptoTransactionRequestTransactTypeEnum,
  ) => {
    setTransactionType(newValue);
    handleResetInputAndValidation();
    if (newValue === ApiEmbeddedCryptoTransactionRequestTransactTypeEnum.Sell) {
      navigate("/transactions/sell");
    } else {
      navigate("/transactions/buy");
    }
  };

  const handleReviewTransaction = () => {
    navigate("/transactions/review-transaction", {
      state: {
        transactionType: transactionType,
        selectedCrypto: selectedCrypto,
        amount: parsedAmount,
        cashAccount: cashAccount,
        isSellAll: isSellAll,
        accountCryptoAmount: selectedCryptoCurrencyAccountCryptoQuantity,
      },
    });
  };

  const handleSellAllTransaction = () => {
    navigate("/transactions/review-transaction", {
      state: {
        transactionType: transactionType,
        selectedCrypto: selectedCrypto,
        amount: parsedAmount,
        cashAccount: cashAccount,
        isSellAll: true,
        accountCryptoAmount: selectedCryptoCurrencyAccountCryptoQuantity,
      },
    });
  };

  const handleClose = (_event?: object, reason?: string) => {
    if (reason && reason === "backdropClick") {
      return;
    } else {
      setOpen(false);
      navigate("/");
    }
  };

  const handleUpdateSelectedCrypto = (newSelectedCrypto: ApiAmountCurrencyEnum) => {
    setSelectedCrypto(newSelectedCrypto);
    handleResetInputAndValidation();
  };

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="sm" fullWidth fullScreen={isFullScreen}>
        <DialogContent>
          <Grid container sx={{ mb: 8 }} alignItems={"center"} justifyContent={"center"}>
            <Tabs
              value={transactionType}
              TabIndicatorProps={{ style: { background: color.energizingLime.main } }}
              onChange={handleSwitchTab}
              selectionFollowsFocus
            >
              {!userPartyLevel?.includes("LIQUIDATED") && (
                <Tab
                  value={ApiEmbeddedCryptoTransactionRequestTransactTypeEnum.Buy}
                  sx={{ textTransform: "capitalize", fontSize: "24px" }}
                  label="Buy"
                />
              )}
              <Tab
                value={ApiEmbeddedCryptoTransactionRequestTransactTypeEnum.Sell}
                sx={{ textTransform: "capitalize", fontSize: "24px" }}
                label="Sell"
              />
            </Tabs>
          </Grid>
          {errorMessage && (
            <Grid xs={12} sx={{ backgroundColor: "error.300", p: 2, mb: 3, textAlign: "center" }}>
              <Typography variant="body2" sx={{ color: "primary.contrastText" }}>
                {errorMessage}
              </Typography>
            </Grid>
          )}

          <AmountInput
            amount={amount}
            handleAmountChange={handleAmountChange}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            minError={minError}
            maxError={maxError}
            balanceError={balanceError}
            helperText={helperText}
            handlePresetAmount={handlePresetAmount}
            cryptoAmount={cryptoAmount}
            selectedCrypto={selectedCrypto}
            balance={cashAccount?.availableBalance?.amount}
            updateSelectedCrypto={handleUpdateSelectedCrypto}
            isSellTransaction={isSellTransaction}
            handleSellAll={handleSellAllTransaction}
            minAmount={MIN_BUY_SELL_AMOUNT}
            maxAmount={isBuyTransaction ? MAX_BUY_AMOUNT : undefined}
          />
          <Grid xs={3}>
            <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
              {isBuyTransaction &&
                `Available Balance ${formatDollarAmountUsd(cashAccount?.availableBalance?.amount || 0)}`}
              {isSellTransaction &&
                Boolean(selectedCryptoCurrencyAccountBalance) &&
                `${cryptoAssetsMap[selectedCrypto].name} Value Available ${formatDollarAmountUsd(
                  selectedCryptoCurrencyAccountBalance || 0,
                )}`}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
          <Stack spacing={1} sx={{ width: "66%" }}>
            <Button
              variant="contained"
              fullWidth={true}
              disabled={minError || maxError || amount === "" || parsedAmount <= 0}
              onClick={handleReviewTransaction}
            >
              Review
            </Button>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default BuySellTransaction;
