import { API_URL } from "@/utils/dataUtils.ts";
import axios from "axios";

export async function handleLogout() {
  try {
    sessionStorage.clear();
    await axios.post(`${API_URL}/partnerauth/bakkt/logout`);
    window.location.assign("/");
  } catch (e) {
    console.log(e);
  }
}
