import { USER_MUST_RELINK_STATUSES } from "@/utils/dataUtils.ts";
import { Button, Divider, Icons, Paper, Skeleton, Typography } from "@bakkt/bakkt-ui-components";
import { Alert, Box, Unstable_Grid2 as Grid, Link, Stack, SvgIcon, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Await, useNavigate, useRouteLoaderData } from "react-router-dom";
import { ApiEmbeddedFundingAccount, ApiEmbeddedPartnerPartyLinkInfo } from "../../services/openAPI/embedded";

const FundingSources = () => {
  const { fundingAccountsPromise, userInfo } = useRouteLoaderData("root") as {
    fundingAccountsPromise: Promise<ApiEmbeddedFundingAccount[]>;
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };
  const userPartyLevel = userInfo?.party?.level;
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const depositIconSize = {
    width: 24,
    height: 24,
    pr: 1,
  };

  const actionLinks = {
    justifyContent: "flex-end",

    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  };

  const hideOnMedium = {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  };

  const showOnMedium = {
    display: "none",

    [theme.breakpoints.down("md")]: {
      display: "inline-block",
    },
  };

  const gridHeader = {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
  };

  const loadingFallback = (
    <Grid xs={12} sm={7} md={8}>
      <Skeleton variant="rectangular" height={110} />
    </Grid>
  );

  const handleAddAccountClick = () => {
    const userPartyLevel = userInfo?.party?.level;

    switch (userPartyLevel) {
      case "LEVEL_5_LIQUIDATED":
        navigate("finance/link-bank-account");
        break;
      case "LEVEL_5":
        navigate("finance/link-bank-account");
        break;
      case "LEVEL_4_LIQUIDATED":
        navigate("finance/link-bank-account");
        break;
      case "LEVEL_4":
        navigate("finance/link-bank-account");
        break;
      case "LEVEL_3":
        navigate("kyc/tax-payer-cert");
        break;
      default:
        navigate("kyc/user-info");
        break;
    }
  };

  return (
    <>
      <Grid xs={12} sx={{ mt: { xs: 4, md: 0 } }}>
        <Typography variant="h3">Funding Sources</Typography>
      </Grid>
      <React.Suspense fallback={loadingFallback}>
        <Await resolve={fundingAccountsPromise} errorElement={<Alert severity="error">Error loading accounts</Alert>}>
          {(fundingAccounts: ApiEmbeddedFundingAccount[]) => {
            const bankAccounts = fundingAccounts.filter(
              (fundingAccount: ApiEmbeddedFundingAccount) =>
                fundingAccount.accountType === "CHECKING" || fundingAccount.accountType === "SAVING",
            );
            const userMustRelink = bankAccounts.some((bankAccount) =>
              USER_MUST_RELINK_STATUSES.includes(bankAccount.status || ""),
            );
            const cashAccount = fundingAccounts.find((account) => account.accountType === "CASH");

            return (
              <>
                <Grid xs={12} sm={7} md={8} mb={4} mt={2}>
                  <Paper sx={{ borderRadius: 0, p: 3 }}>
                    {bankAccounts.length > 0 ? (
                      <>
                        <Typography variant="h5" sx={{ mb: 0.5 }}>
                          Bank Account
                        </Typography>
                        <Typography variant="subtitle2" sx={{ mb: 2 }}>
                          Bank account funds will take 3-5 days to appear in your Available Funds.
                        </Typography>
                        {userMustRelink && (
                          <Alert severity={"error"} sx={{ mb: 2 }}>
                            Your bank account needs to be re-linked to deposit or withdraw funds. Please remove the
                            account and click 'Add Account' to re-link.
                          </Alert>
                        )}
                        <Grid container spacing={2} sx={[hideOnMedium, { mb: -1, mt: 0 }]}>
                          <Grid xs={12} md={2.5}>
                            <Typography variant="body1" sx={gridHeader}>
                              Issued By
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={4.5}>
                            <Typography variant="body1" sx={gridHeader}>
                              Account Ending
                            </Typography>
                          </Grid>
                          <Grid xs={12}>
                            <Divider sx={{ mt: -1 }} />
                          </Grid>
                        </Grid>
                        {bankAccounts?.map((bankAccount: ApiEmbeddedFundingAccount, index) => {
                          const userMustRelink = USER_MUST_RELINK_STATUSES.includes(bankAccount.status || "");

                          return (
                            <Grid key={index} container spacing={2} sx={{ mb: 3, mt: 0.25 }}>
                              <Grid xs={12} md={2.5}>
                                <Stack>
                                  <Typography variant="body1">{bankAccount?.name}</Typography>
                                  <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                                    {bankAccount?.status === "ACTIVE" ? "Linked" : bankAccount?.status}
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid xs={12} md={4.5}>
                                <Box component="span" sx={showOnMedium}>
                                  <Typography variant="body1">
                                    Account ending:&nbsp;{bankAccount?.formattedAccountNumber}
                                  </Typography>
                                </Box>
                                <Typography variant="body1" sx={hideOnMedium}>
                                  {bankAccount?.formattedAccountNumber}
                                </Typography>
                              </Grid>
                              <Grid xs={12} md={5}>
                                <Box display="flex" sx={actionLinks}>
                                  <Typography variant="body1">
                                    {userPartyLevel !== "LEVEL_4_LIQUIDATED" &&
                                      userPartyLevel !== "LEVEL_5_LIQUIDATED" &&
                                      !userMustRelink && (
                                        <>
                                          <Link color="info.main" onClick={() => navigate(`funding/deposit`)}>
                                            Deposit
                                          </Link>
                                          &nbsp; | &nbsp;
                                        </>
                                      )}
                                    {(cashAccount?.availableBalance?.amount || 0) > 0 && !userMustRelink && (
                                      <>
                                        <Link color="info.main" onClick={() => navigate(`funding/withdrawal`)}>
                                          Withdraw
                                        </Link>
                                        &nbsp; | &nbsp;
                                      </>
                                    )}
                                    <Link
                                      color="info.main"
                                      onClick={() =>
                                        navigate(`finance/remove-bank-account`, {
                                          state: { selectedFundingSource: bankAccount },
                                        })
                                      }
                                    >
                                      Remove
                                    </Link>
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid xs={12}>
                          <Typography variant="h5" sx={{ mb: 0.5 }}>
                            Bank Account
                          </Typography>
                          <Typography variant="subtitle2">
                            Manage added bank accounts here. Bank account funds will take 3-5 days to appear in your
                            Available Funds.
                          </Typography>
                          <Button
                            variant="outlined"
                            sx={{ mt: 2.5, width: isMobile ? "100%" : "200px" }}
                            size="large"
                            onClick={handleAddAccountClick}
                          >
                            <SvgIcon component={Icons.DepositIcon} inheritViewBox sx={depositIconSize} />
                            Add Account
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </Grid>
              </>
            );
          }}
        </Await>
      </React.Suspense>
    </>
  );
};

export default FundingSources;
