import DefaultHeaderBannerBg from "@/assets/images/dash_header_bg.png";
import FundsAvailableHeaderBannerBg from "@/assets/images/dash_header_funds_available_bg.png";
import FundsPendingHeaderBannerBg from "@/assets/images/dash_header_funds_pending_bg.png";
import BannerFundsAvailable from "@/components/header/banners/BannerFundsAvailable.tsx";
import BannerFundsPending from "@/components/header/banners/BannerFundsPending.tsx";
import BannerGettingStarted from "@/components/header/banners/BannerGettingStarted.tsx";
import Portfolio from "@/components/header/portfolio/Portfolio";
import {
  ApiEmbeddedFundingAccount,
  ApiEmbeddedPartnerPartyAccount,
  ApiEmbeddedPartnerPartyLinkInfo,
} from "@/services/openAPI/embedded";
import { Alert, Skeleton, themes } from "@bakkt/bakkt-ui-components";
import { Box, Container, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Suspense, useMemo } from "react";
import { Await, useRouteLoaderData } from "react-router-dom";

enum headerTypes {
  zeroBalance,
  fundsPending,
  fundsAvailable,
  hasPortfolio,
}

const DashboardHeader = () => {
  const theme = useTheme();
  const { userInfo, fundingAccountsPromise, userCryptoAccountsPromise } = useRouteLoaderData("root") as {
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
    fundingAccountsPromise: Promise<ApiEmbeddedFundingAccount[]>;
    userCryptoAccountsPromise: Promise<ApiEmbeddedPartnerPartyAccount[]>;
  };

  const userPartyLevel = userInfo?.party?.level;
  const eligibleLevels = ["LEVEL_4", "LEVEL_4_LIQUIDATED", "LEVEL_5", "LEVEL_5_LIQUIDATED"];

  const fetchAccountsPromise = useMemo(
    () => Promise.all([fundingAccountsPromise, userCryptoAccountsPromise]),
    [fundingAccountsPromise, userCryptoAccountsPromise],
  );

  const headerSx = {
    minHeight: "450px",
    p: 3,
  };

  return (
    <StyledEngineProvider injectFirst>
      <Box sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
        {/* Header Banner Content */}
        <Suspense
          fallback={
            <ThemeProvider theme={themes.dark}>
              <Skeleton variant="rectangular" height={450} />
            </ThemeProvider>
          }
        >
          <Await resolve={fetchAccountsPromise} errorElement={<Alert severity="error">Error loading accounts</Alert>}>
            {([fundingAccounts, userCryptoAccounts]) => {
              // Get bank accounts for part of route determination logic on the headerTypes.zeroBalance view's Add Funds button
              const userBankAccounts = fundingAccounts.filter(
                (fundingAccount: ApiEmbeddedFundingAccount) => fundingAccount.accountType === "CHECKING",
              );

              // Get cash accounts to determine available and pending cash sums
              const cashAccounts = fundingAccounts.filter(
                (fundingAccount: ApiEmbeddedFundingAccount) => fundingAccount.accountType === "CASH",
              );

              const cashAvailable = cashAccounts.reduce(
                (accumulator: number, cashAccount: ApiEmbeddedFundingAccount) => {
                  return accumulator + (cashAccount.availableBalance?.amount || 0);
                },
                0,
              );

              const cashPending = cashAccounts.reduce((accumulator: number, cashAccount: ApiEmbeddedFundingAccount) => {
                return accumulator + (cashAccount.pendingDepositAmount?.amount || 0);
              }, 0);

              let currentHeaderType = headerTypes.zeroBalance;

              if (eligibleLevels.includes(userPartyLevel || "")) {
                // Check if the user has crypto accounts and that their balance for at least one account is greater than 0
                if (
                  userCryptoAccounts.length > 0 &&
                  userCryptoAccounts.find(
                    (userCryptoAccount: ApiEmbeddedPartnerPartyAccount) =>
                      userCryptoAccount.buyBalance?.amount && userCryptoAccount.buyBalance?.amount > 0,
                  )
                ) {
                  currentHeaderType = headerTypes.hasPortfolio;
                } else if (cashAvailable > 0) {
                  currentHeaderType = headerTypes.fundsAvailable;
                } else if (cashPending > 0 && cashAvailable <= 0) {
                  currentHeaderType = headerTypes.fundsPending;
                }
              }

              const unlockCryptoBannerSx = {
                backgroundImage:
                  currentHeaderType === headerTypes.zeroBalance
                    ? `url(${DefaultHeaderBannerBg})`
                    : currentHeaderType === headerTypes.fundsPending
                    ? `url(${FundsPendingHeaderBannerBg})`
                    : currentHeaderType === headerTypes.fundsAvailable
                    ? `url(${FundsAvailableHeaderBannerBg})`
                    : "",
                backgroundRepeat: "no-repeat",
                backgroundSize:
                  currentHeaderType === headerTypes.zeroBalance
                    ? "auto 300px"
                    : currentHeaderType === headerTypes.fundsPending
                    ? "auto 300px"
                    : "auto 179px",
                backgroundPosition: "right",
                [theme.breakpoints.down("sm")]: {
                  backgroundPosition: currentHeaderType === headerTypes.fundsPending ? "-60px 100px" : "-160px 100px",
                  backgroundSize: "auto 200px",
                },
              };

              return (
                <Container
                  maxWidth="lg"
                  disableGutters
                  sx={[headerSx, currentHeaderType !== headerTypes.hasPortfolio && unlockCryptoBannerSx]}
                >
                  {currentHeaderType === headerTypes.zeroBalance && (
                    <BannerGettingStarted bankAccounts={userBankAccounts} />
                  )}
                  {currentHeaderType === headerTypes.fundsPending && <BannerFundsPending />}
                  {currentHeaderType === headerTypes.fundsAvailable && <BannerFundsAvailable />}
                  {currentHeaderType === headerTypes.hasPortfolio && <Portfolio />}
                </Container>
              );
            }}
          </Await>
        </Suspense>
        {/* End Header Banner Content */}
      </Box>
    </StyledEngineProvider>
  );
};

export default DashboardHeader;
