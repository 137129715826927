import { ThemeProvider, themes, Typography } from "@bakkt/bakkt-ui-components";
import { CircularProgress, Container, Unstable_Grid2 as Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import { AxiosError } from "axios";
import { useRouteError } from "react-router-dom";
import BakktLogo from "../../assets/images/bakkt_logo.svg";
import ErrorOwl from "../../assets/images/error-owl.png";

export default function ErrorPage() {
  const theme = useTheme();
  const error = useRouteError() as AxiosError | any;
  console.error(error);

  const textContent = {
    pt: "230px",
    [theme.breakpoints.down("md")]: {
      p: "30px",
    },
  };

  const footerLogo = {
    p: "20px",
    backgroundColor: "#000000",
    [theme.breakpoints.down("md")]: {
      pl: "42px",
    },
  };

  return (
    <ThemeProvider theme={themes.light}>
      <CssBaseline />
      {error?.response?.status === 401 ? (
        <Container
          maxWidth="xl"
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <>
          <Container maxWidth="md" sx={{ mt: "40px", p: "20px", backgroundColor: "#ffffff" }}>
            <Grid container spacing={2}>
              <Grid md={6} xs={12}>
                <img src={ErrorOwl} style={{ width: "400px" }} alt="Error Owl" />
              </Grid>
              <Grid md={6} xs={12} sx={textContent}>
                <Typography variant={"h2"} sx={{ ml: "-3px" }}>
                  Hold up!
                </Typography>
                <Typography variant={"body1"}>
                  Something happened. We are looking into it. Please be patient while we figure it out.
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="md" sx={footerLogo}>
            <img
              src={BakktLogo}
              alt="Bakkt Logo"
              style={{
                width: "95px",
                filter: "brightness(0) invert(1)",
                marginTop: "6px",
                marginRight: "16px",
              }}
            />
          </Container>
        </>
      )}
    </ThemeProvider>
  );
}
