import { RootContextType, useRootContext } from "@/RootLayout";
import ModalLoadingIndicator from "@/components/loading/ModalLoadingIndicator.tsx";
import { fetchMockDataPromiseWithDelay, mockSuccessResponse } from "@/services/mockData.ts";
import { ApiEmbeddedFundingAccount } from "@/services/openAPI/embedded";
import { EmbeddedBankLinkingService } from "@/services/serviceLoader.ts";
import { shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Button, Dialog, DialogTitle, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useNavigation } from "react-router-dom";
import RemoveFinSource from "../../assets/images/remove_fin_source.png";

interface RemoveBankAccountProps {
  selectedFundingSource: ApiEmbeddedFundingAccount;
}

const RemoveBankAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const { selectedFundingSource } = useLocation()?.state as RemoveBankAccountProps;
  const { addAlert } = useRootContext() as RootContextType;
  const [open, setOpen] = useState(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleRemoveBankAccount = () => {
    submitActionRequest(fetcher, selectedFundingSource?.externalAccountRef);
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        addAlert({
          severity: "success",
          message: "Account unlinking successful",
        });
        navigate("/");
      } else {
        addAlert({
          severity: "error",
          messageHeader: "There was an error saving your information.",
          message: "Failed to save user information. Please try again later or contact support.",
        });
        navigate("/");
      }
    }
  }, [fetcher.data]);

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="sm" fullWidth fullScreen={fullScreen}>
        <DialogTitle severity="error" style={{ backgroundColor: "black", paddingTop: 0, paddingBottom: 0 }}>
          &nbsp;
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <ModalLoadingIndicator description={"Processing"} />
          ) : (
            <Grid xs={12} container justifyContent={"center"} alignItems={"center"}>
              <img
                src={RemoveFinSource}
                alt="Remove Bank Account Image"
                style={{ filter: "brightness(1) invert(0)", maxWidth: "100%" }}
              />
              <Typography variant="h4" sx={{ mt: 4, textAlign: "center" }}>
                Are you sure you want to remove your bank account?
              </Typography>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className="dialog-action-buttons">
          {!isLoading && (
            <Grid xs={12} container justifyContent={"center"} alignItems={"center"}>
              <Stack spacing={1}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={handleRemoveBankAccount}
                >
                  Remove Account
                </Button>
                <Button variant="text" onClick={handleClose}>
                  Cancel
                </Button>
              </Stack>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default RemoveBankAccount;

export async function action({ request }: { request: Request }) {
  try {
    if (shouldUseMockData) {
      const resp = await fetchMockDataPromiseWithDelay(mockSuccessResponse, 3000);
      return formatActionSuccessResponse(resp);
    }
    const externalAccountRef = (await request.json()) as string;
    const response = await EmbeddedBankLinkingService.removeBankAccountByExternalAccountRef(externalAccountRef);
    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
