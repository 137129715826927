import { AlertDisplay } from "@/utils/customTypes.ts";
import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import React from "react";

interface AlertsProps {
  alerts: AlertDisplay[];
  setAlerts: React.Dispatch<React.SetStateAction<AlertDisplay[]>>;
}

export default function Alerts({ alerts, setAlerts }: AlertsProps) {
  const handleDeleteAlert = (index: number) => {
    setAlerts((prevAlerts: AlertDisplay[]) => {
      const updatedAlerts = [...prevAlerts];
      updatedAlerts.splice(index, 1);
      return updatedAlerts;
    });
  };

  return (
    <Stack spacing={0.5}>
      {alerts.map((alert, index) => (
        <Alert
          key={index}
          variant="filled"
          severity={alert.severity}
          sx={{
            width: "100%",
            "&.MuiAlert-filledSuccess": {
              backgroundColor: "success.500",
            },
            "&.MuiAlert-filledWarning": {
              backgroundColor: "warning.500",
            },
            "&.MuiAlert-filledError": {
              backgroundColor: "error.500",
            },
            "& .MuiAlert-message": { textAlign: "center", width: "inherit", ml: 5 },
          }}
          onClose={() => handleDeleteAlert(index)}
        >
          {alert.messageHeader && <AlertTitle>{alert.messageHeader}</AlertTitle>}
          <Typography variant={"body1"}> {alert.message}</Typography>
        </Alert>
      ))}
    </Stack>
  );
}
