import { RootContextType, useRootContext } from "@/RootLayout";
import ModalLoadingIndicator from "@/components/loading/ModalLoadingIndicator.tsx";
import usePlaidLinking, { PlaidAccount } from "@/hooks/usePlaidLinking";
import { bankAccount, fetchMockDataPromiseWithDelay, mockSuccessResponse } from "@/services/mockData.ts";
import { ApiCreateBankAccountRequest, ApiLinkTokenResponse } from "@/services/openAPI/embedded";
import { EmbeddedBankLinkingService } from "@/services/serviceLoader";
import { shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Dialog, DialogTitle, themes } from "@bakkt/bakkt-ui-components";
import { DialogContent, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { defer, useFetcher, useLoaderData, useNavigate, useNavigation } from "react-router-dom";

const PlaidBankLink = () => {
  const { linkToken } = useLoaderData() as { linkToken: string };
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const { addAlert } = useRootContext() as RootContextType;
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        addAlert({
          severity: "success",
          message: "Account linking successful! Add funds to buy crypto",
        });
        navigate("/finance/funding-source-added");
      } else {
        addAlert({
          severity: "error",
          messageHeader: "There was an error linking your account.",
          message: "Failed to link bank account. Please try again later or contact support.",
        });
        navigate("/");
      }
    }
  }, [fetcher.data]);

  const handlePlaidSuccess: any = async (plaidAccount: PlaidAccount) => {
    submitActionRequest(fetcher, plaidAccount);
  };

  const onPlaidExitHandler = () => {
    navigate("/");
  };

  const { open: plaidModalOpen, ready } = usePlaidLinking(linkToken, handlePlaidSuccess, onPlaidExitHandler);

  // Open plaid modal once link token is ready
  useEffect(() => {
    // Success by default when using mock data because we can't mock the Plaid modal locally
    if (shouldUseMockData) {
      handlePlaidSuccess(bankAccount);
    } else if (ready && linkToken) {
      plaidModalOpen();
    }
  }, [ready, linkToken]);

  return (
    <ThemeProvider theme={themes.dark}>
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle severity="success" style={{ backgroundColor: "black", paddingTop: 0, paddingBottom: 0 }}>
            &nbsp;
          </DialogTitle>
          <DialogContent>
            <ModalLoadingIndicator description={"Processing"} />
          </DialogContent>
        </Dialog>
      )}
    </ThemeProvider>
  );
};

export default PlaidBankLink;

export async function loader() {
  // Get link token
  const getLinkTokenPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay({ linkToken: "test-link-token" }, 3000)
    : EmbeddedBankLinkingService.getLinkToken({
        // Need to construct URL because loader can be initiated when navigating from other routes
        redirectUri: `${window.location.protocol}//${window.location.hostname}/finance/link-plaid`,
      });
  const linkTokenResponse = (await getLinkTokenPromise) as ApiLinkTokenResponse;

  return defer({ linkToken: linkTokenResponse.linkToken });
}

export async function action({ request }: { request: Request }) {
  try {
    if (shouldUseMockData) {
      const resp = await fetchMockDataPromiseWithDelay(mockSuccessResponse, 3000);
      return formatActionSuccessResponse(resp);
    }
    const plaidAccount = (await request.json()) as ApiCreateBankAccountRequest;
    const response = await EmbeddedBankLinkingService.createOrUpdateBankAccount(plaidAccount);
    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
