import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

export default function PortfolioPercentLinePlaceholder() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const percentLineGraySx = {
    height: 14,
    borderRadius: 7,
    mt: 0.64,
    mr: 1,
    backgroundColor: grey[900],
    width: "calc(100% - 77px)",
  };

  const cryptoIconGraySx = {
    width: 25,
    height: 24,
    mr: 1,
    borderRadius: 12,
    backgroundColor: grey[900],
  };

  return (
    <>
      {!isSmallScreen &&
        // Render 4 Gray Placeholder Lines per Figma Design
        [0, 1, 2, 3].map((item) => (
          <Stack direction="row" sx={{ mt: 1.2 }} key={item}>
            <Box sx={cryptoIconGraySx}>{/* Gray circle icon */}</Box>
            <Box sx={percentLineGraySx}>{/* Gray bar line */}</Box>
          </Stack>
        ))}
    </>
  );
}
