import {
  ApiAmountCurrencyEnum,
  ApiEmbeddedFundingTransferRequest,
  TransactBankAccountAchTypeEnum,
} from "@/services/openAPI/embedded";
import { AlertColor } from "@mui/material";

export type AlertDisplay = {
  severity: AlertColor;
  messageHeader?: string;
  message?: string;
};

export interface AvailableCoins {
  ticker: ApiAmountCurrencyEnum;
  name: string;
  description: string;
  marketValue: number;
  percentChange: number;
}

export enum PortfolioItemType {
  PORTFOLIO = "portfolio",
}

export interface DepositWithdrawRequest extends ApiEmbeddedFundingTransferRequest {
  fundingType: TransactBankAccountAchTypeEnum;
}
