import { ApiEmbeddedPartnerPartyLinkInfo } from "@/services/openAPI/embedded";
import { Typography } from "@bakkt/bakkt-ui-components";
import { Box, Container, StyledEngineProvider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRouteLoaderData } from "react-router-dom";

const DashboardHeader = () => {
  const theme = useTheme();
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };
  const userFirstName = userInfo.party?.firstName;
  const userLastName = userInfo.party?.lastName;

  const headerSx = {
    pb: 3,
    px: 3,
    pt: 1,
  };

  return (
    <StyledEngineProvider injectFirst>
      <Box sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
        {userFirstName && userLastName && (
          <Container maxWidth="lg" disableGutters sx={headerSx}>
            <Typography variant="h2">
              {userFirstName} {userLastName}
            </Typography>
          </Container>
        )}
      </Box>
    </StyledEngineProvider>
  );
};

export default DashboardHeader;
