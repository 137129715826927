import { Button, Dialog, DialogTitle, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LoginBg from "../../assets/images/login_bg.svg";
import CryptoGumball from "../../assets/images/redeem_complete.png";

const AccessRedirectPage = () => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const backgroundSx = {
    backgroundImage: isFullScreen ? "none !important" : "url(" + LoginBg + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "3000px",
    overflow: "hidden",
    "&::-webkit-scrollbar": { display: "none" },
  };

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog
        scroll="body"
        open={true}
        maxWidth="sm"
        fullScreen={isFullScreen}
        fullWidth
        sx={backgroundSx}
        hideBackdrop={true}
      >
        <DialogTitle severity="secondary" style={{ backgroundColor: "black", paddingTop: 0, paddingBottom: 0 }}>
          &nbsp;
        </DialogTitle>
        <DialogContent>
          <Grid xs={12} container justifyContent={"center"} alignItems={"center"}>
            <Box
              component="img"
              sx={{
                maxWidth: "200px",
              }}
              alt="Bakkt Access Redirect"
              src={CryptoGumball}
            />
            <Typography variant="h4" sx={{ mt: 4, textAlign: "center", fontWeight: 500 }}>
              We've upgraded! <br /> Use your Bakkt Access login credentials for an improved trade experience with Bakkt
              Link at link.bakkt.com
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
          <Stack spacing={1} sx={{ width: "66%" }}>
            <Button variant="contained" fullWidth={true} href={"https://link.bakkt.com"}>
              View Bakkt Link
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AccessRedirectPage;
