import { ChangeEvent, useEffect, useState } from "react";

interface ErrorMessages {
  invalidInput: string;
  minDeposit: string;
  maxAmountExceeded: string;
}

interface AmountInputHook {
  amount: string;
  helperText: string | null;
  minError: boolean;
  maxError: boolean;
  balanceError: boolean;
  parsedAmount: number;
  cryptoAmount: number;
  handleAmountChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handlePresetAmount: (presetAmount: string) => void;
  handleFocus: () => void;
  handleBlur: () => void;
  handleResetInputAndValidation: () => void;
}

const useAmountInput = (
  cryptoPrice: number | null = null,
  selectedCrypto: string | null = null,
  errorMessages: ErrorMessages | null = null,
  balance: number | null = null,
  minAmount = 1,
  maxAmount: number | undefined,
): AmountInputHook => {
  const [amount, setAmount] = useState<string>("0");
  const [helperText, setHelperText] = useState<string | null>(null);
  const [minError, setMinError] = useState<boolean>(false);
  const [maxError, setMaxError] = useState<boolean>(false);
  const [balanceError, setBalanceError] = useState<boolean>(false);
  const [parsedAmount, setParsedAmount] = useState<number>(0);
  const [cryptoAmount, setCryptoAmount] = useState<number>(0);

  useEffect(() => {
    const unformattedValue = parseFloat(amount || "0");

    if (!cryptoPrice || !selectedCrypto) {
      setCryptoAmount(0);
      setParsedAmount(unformattedValue);
      return;
    }

    if (cryptoPrice) {
      if (!isNaN(unformattedValue) && unformattedValue > 0) {
        let equivalentCryptoAmount = unformattedValue / cryptoPrice;

        // Limit to 8 decimals
        equivalentCryptoAmount = parseFloat(equivalentCryptoAmount.toFixed(8));

        setCryptoAmount(equivalentCryptoAmount);
        setParsedAmount(unformattedValue);
      } else {
        setCryptoAmount(0);
        setParsedAmount(unformattedValue);
      }
    }
  }, [amount, selectedCrypto, cryptoPrice]);

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newAmount = event.target.value.trim();
    const numericValue = newAmount.replace(/[^0-9.]/g, "");

    // Allow only up to 7 digits before the decimal point
    const parts = numericValue.split(".");
    if (parts[0].length > 7) {
      return;
    }

    // Allow only up to 2 decimal places
    if (parts[1] && parts[1].length > 2) {
      return;
    }

    const value = parseFloat(numericValue);

    let errorText: string | null = null;
    let isMinError = false;
    let isMaxError = false;
    let isBalanceError = false;

    if (isNaN(value) || value < minAmount) {
      errorText = isNaN(value) ? errorMessages?.invalidInput || null : errorMessages?.minDeposit || null;
      isMinError = true;
    } else if (maxAmount && value > maxAmount) {
      errorText = errorMessages?.maxAmountExceeded || null;
      isMaxError = true;
    } else if (balance !== null && value > balance) {
      errorText = "Transaction may not exceed your available balance";
      isBalanceError = true;
    }

    setAmount(numericValue);
    setHelperText(errorText);
    setMinError(isMinError);
    setMaxError(isMaxError);
    setBalanceError(isBalanceError);
  };

  const handleResetInputAndValidation = () => {
    // Reset component back to initial state on selected crypto change
    const syntheticEvent = {
      target: {
        value: "0",
      },
    } as ChangeEvent<HTMLInputElement>;
    handleAmountChange(syntheticEvent);
    setHelperText(null);
    setMinError(false);
    setMaxError(false);
    setBalanceError(false);
  };

  const handlePresetAmount = (presetAmount: string) => {
    setAmount(presetAmount);
    setHelperText(null);
    setMinError(false);
    setMaxError(false);
    setBalanceError(false);

    const syntheticEvent = {
      target: {
        value: presetAmount,
      },
    } as ChangeEvent<HTMLInputElement>;

    handleAmountChange(syntheticEvent);
  };

  const handleFocus = () => {
    if (!amount || amount === "0") {
      setAmount("");
    }
  };

  const handleBlur = () => {
    if (!amount) {
      setAmount("0");
    }
  };

  return {
    amount,
    helperText,
    minError,
    maxError,
    balanceError,
    parsedAmount,
    cryptoAmount,
    handleAmountChange,
    handlePresetAmount,
    handleFocus,
    handleBlur,
    handleResetInputAndValidation,
  };
};

export default useAmountInput;
