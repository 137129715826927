import ModalLoadingIndicator from "@/components/loading/ModalLoadingIndicator.tsx";
import {
  fetchMockDataPromiseWithDelay,
  partySignupOTPResponse as partySignupOTPResponseMock,
} from "@/services/mockData.ts";
import { ApiResendValidateIdentifierRequest, ApiValidateOTPRequest, Identifier } from "@/services/openAPI/embedded";
import { EmbeddedAuthService, EmbeddedPartySignUpService } from "@/services/serviceLoader.ts";
import { shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import { isValidOTP } from "@/utils/formValidation";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Alert, Button, Dialog, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  AlertColor,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Link,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useNavigation } from "react-router-dom";
import CaesarsBg from "../../assets/images/caesars_bg.svg";
import LoginBg from "../../assets/images/login_bg.svg";

interface ChangePasswordVerifyOtpProps {
  email: string;
  identifierToken: string;
}
const ChangePasswordVerifyOtp = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const [validationCode, setValidationCode] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("warning");
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";
  const { email, identifierToken } = useLocation()?.state as ChangePasswordVerifyOtpProps;
  const caesarsToken = sessionStorage.getItem("CAESARS_TOKEN");

  const backgroundSx = caesarsToken
    ? {
        backgroundImage: "url(" + CaesarsBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        minHeight: 470,
        minWidth: 500,
      }
    : {
        backgroundImage: "url(" + LoginBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "3000px",
        minHeight: 470,
        minWidth: 500,
      };

  const otpInputSx = {
    gap: "15px",
    ".MuiOtpInput-TextField": {
      input: {
        fontSize: isFullScreen ? "h4.fontSize" : "h3.fontSize",
        fontWeight: 700,
        paddingBottom: isFullScreen ? 1 : 0,
        paddingTop: isFullScreen ? 1 : 0,
      },
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: 0,
      borderBottom: "1px solid #000000",
      borderRadius: 0,
    },
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success && response.data?.identifierToken) {
        navigate("/change-password", {
          state: {
            identifierToken: response.data.identifierToken,
          },
        });
      } else {
        setAlertSeverity("error");
        setAlertMessage(response.message);
      }
    }
  }, [fetcher.data]);

  const handleSubmit = () => {
    setAlertMessage("");
    const validateOTPRequest: ApiValidateOTPRequest = {
      identifierToken,
      validationCode,
    };
    submitActionRequest(fetcher, validateOTPRequest);
  };

  const handleVerificationKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && isValidOTP(validationCode)) {
      handleSubmit();
    }
  };

  const resendCode = async () => {
    try {
      const resendOtpRequest: ApiResendValidateIdentifierRequest = {
        identifierToken,
      };
      const resendCodeResponse = await EmbeddedAuthService.resend(resendOtpRequest);

      if (resendCodeResponse?.data?.error) {
        setAlertMessage("Error resending verification code. Please try again.");
        setAlertSeverity("error");
      } else {
        setAlertMessage("Verification code resent.");
        setAlertSeverity("success");
      }
    } catch (error) {
      setAlertMessage("Error resending verification code. Please try again.");
      setAlertSeverity("error");
    }
  };

  const isFormValid = () => {
    return validationCode && validationCode.length === 6;
  };

  return (
    <ThemeProvider theme={themes.light}>
      <Dialog
        scroll="body"
        open={true}
        maxWidth="xs"
        fullScreen={isFullScreen}
        sx={isFullScreen ? undefined : backgroundSx}
        hideBackdrop={true}
      >
        {alertMessage && (
          <Grid sx={{ mb: 2, left: 0, position: "fixed", right: 0, top: 0 }}>
            <Alert
              severity={alertSeverity}
              sx={{
                backgroundColor: alertSeverity === "success" ? "success.500" : "error.500",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">{alertMessage}</Typography>
            </Alert>
          </Grid>
        )}
        {isLoading ? (
          <ModalLoadingIndicator description={"Processing"} />
        ) : (
          <>
            <DialogContent sx={{ alignItems: "center", textAlign: "center", overflowY: "hidden" }}>
              <Grid container xs={12} justifyContent="center" sx={{ mb: 5, pt: alertMessage && isFullScreen ? 6 : 0 }}>
                <Typography variant="h3" sx={{ mt: 1.6 }}>
                  Verify your account
                </Typography>
                <Grid mt={1} sx={{ textAlign: "center" }}>
                  <Typography>Please enter the one-time code sent to your email</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid xs={12}>
                  <MuiOtpInput
                    sx={otpInputSx}
                    length={6}
                    value={validationCode}
                    onChange={setValidationCode}
                    onKeyUp={handleVerificationKeyPress}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", py: 6 }}>
              {!isLoading && (
                <Stack spacing={2} sx={{ width: "49%" }}>
                  <Button variant="contained" disabled={!isFormValid()} fullWidth onClick={handleSubmit}>
                    Verify
                  </Button>
                  <Link sx={{ cursor: "pointer" }}>
                    <Grid spacing={2}>
                      <Typography variant="body2" sx={{ textAlign: "center" }} onClick={resendCode}>
                        Resend code
                      </Typography>
                    </Grid>
                  </Link>
                </Stack>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

export default ChangePasswordVerifyOtp;

export async function action({ request }: { request: Request }) {
  try {
    const { identifierToken, validationCode } = await request.json();

    const validateOTPRequest: ApiValidateOTPRequest = {
      identifierToken,
      validationCode,
    };
    const validateOtpResponse = shouldUseMockData
      ? ((await fetchMockDataPromiseWithDelay(partySignupOTPResponseMock, 1000)) as Identifier)
      : ((await EmbeddedPartySignUpService.otp(validateOTPRequest)) as Identifier);

    return formatActionSuccessResponse(validateOtpResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
