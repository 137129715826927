import { ApiCreateBankAccountRequestVerificationStatusEnum } from "@/services/openAPI/embedded";
import { useMemo } from "react";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";

export type PlaidAccount = {
  accountRef: string;
  publicToken: string;
  requestRef: string;
  institutionName: string;
  institutionRef: string;
  mask: string;
  verificationStatus: ApiCreateBankAccountRequestVerificationStatusEnum;
  linkSessionRef: string;
};

type UseSetupPlaid = (
  token: string,
  handleSuccess: (plaidAccount: PlaidAccount) => void,
  onPlaidExit: any,
) => {
  open: any;
  exit: any;
  ready: boolean;
  error: ErrorEvent | null;
};

export const hasOAuthState = () => window.location.href.includes("oauth_state_id");

export const useSetupPlaid: UseSetupPlaid = (token, handleSuccess, onPlaidExit) => {
  const onSuccess: PlaidLinkOnSuccess = (publicToken, plaidSuccess) => {
    const bankAccount = plaidSuccess?.accounts?.[0];
    const bakktPlaidAccount = {
      accountRef: bankAccount?.id ?? "",
      requestRef: plaidSuccess?.link_session_id ?? "",
      institutionName: plaidSuccess?.institution?.name ?? "",
      institutionRef: plaidSuccess?.institution?.institution_id ?? "",
      mask: bankAccount?.mask ?? "",
      verificationStatus: bankAccount?.verification_status?.toString()?.toUpperCase() as any,
      publicToken,
      linkSessionRef: plaidSuccess?.link_session_id ?? "",
    };
    handleSuccess(bakktPlaidAccount);
  };
  const onExit: PlaidLinkOnExit = (/* eventName, metadata */) => {
    // console.log("PlaidLinkOnExit", error, metadata)
    onPlaidExit();
  };
  const onEvent: PlaidLinkOnEvent = (/* eventName, metadata */) => {
    // console.log("PlaidLinkOnEvent", eventName, metadata)
  };
  const plaidConfig: PlaidLinkOptions = useMemo(() => {
    return {
      ...(hasOAuthState() && { receivedRedirectUri: window.location.href }),
      token,
      onSuccess,
      onExit,
      onEvent,
    } as PlaidLinkOptions;
  }, [token]);

  const { open, exit, ready, error } = usePlaidLink(plaidConfig);
  return { open, exit, ready, error };
};

export default useSetupPlaid;
