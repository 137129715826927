import { ApiAmountCurrencyEnum, ApiEmbeddedPartnerPartyLinkInfo } from "@/services/openAPI/embedded";
import { Icons, Typography, cryptoAssetsMap } from "@bakkt/bakkt-ui-components";
import { Box, Fab, Unstable_Grid2 as Grid, Stack, SvgIcon, useTheme } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";

interface CryptoSelectProps {
  disableExpand?: boolean;
  preSelectedCrypto?: ApiAmountCurrencyEnum;
  updateSelectedCrypto?: (selectedCrypto: ApiAmountCurrencyEnum) => void;
}

//TODO: Add hardcoded colors from this component to theme
export default function CryptoSelect({ disableExpand, preSelectedCrypto, updateSelectedCrypto }: CryptoSelectProps) {
  const theme = useTheme();
  const { userInfo } = useRouteLoaderData("root") as { userInfo: ApiEmbeddedPartnerPartyLinkInfo };
  const availableCurrencies = userInfo.partner?.cryptoCurrencies;
  const [open, setOpen] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState<ApiAmountCurrencyEnum>(preSelectedCrypto || "BTC");

  const handleCryptoSelect = (crypto: ApiAmountCurrencyEnum) => {
    setSelectedCrypto(crypto);
    setOpen(false);
  };

  useEffect(() => {
    if (updateSelectedCrypto) {
      updateSelectedCrypto(selectedCrypto);
    }
  }, [selectedCrypto]);

  const getButtonBackgroundColor = (selectedCrypto: ApiAmountCurrencyEnum): string => {
    if (selectedCrypto === "ETH" || selectedCrypto === "ETC") {
      return "#ECF0F4";
    } else {
      return cryptoAssetsMap[selectedCrypto].color;
    }
  };

  const getButtonTextColor = (selectedCrypto: ApiAmountCurrencyEnum): string => {
    if (selectedCrypto === "LTC" || selectedCrypto === "USDC") {
      return "primary.main";
    } else {
      return "primary.contrastText";
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        onClick={() => setOpen(!open)}
        sx={{ cursor: "pointer", pointerEvents: disableExpand ? "none" : "auto" }}
      >
        <Fab
          variant={"extended"}
          sx={{
            pl: 3,
            pr: 5,
            background: getButtonBackgroundColor(selectedCrypto),
            "&:hover": {
              background: getButtonBackgroundColor(selectedCrypto),
            },
            color: getButtonTextColor(selectedCrypto),
          }}
        >
          <SvgIcon component={cryptoAssetsMap[selectedCrypto].icon} inheritViewBox sx={{ width: 30, height: 30 }} />
          <Typography variant={"h5"}>{cryptoAssetsMap[selectedCrypto]?.name}</Typography>
        </Fab>
        <IconButton
          className="cryptoSelectExpandButton"
          aria-label="expand"
          size="small"
          disableRipple
          disableFocusRipple
          sx={{ p: 0, pt: 0.75, display: disableExpand ? "none" : "block" }}
        >
          {open ? (
            <SvgIcon component={Icons.ChevronUpIcon} inheritViewBox sx={{ color: "primary", bgcolor: "#111111" }} />
          ) : (
            <SvgIcon component={Icons.ChevronDownIcon} inheritViewBox sx={{ color: "primary", bgcolor: "#111111" }} />
          )}
        </IconButton>
      </Stack>
      <Grid xs={12} sx={{ bgcolor: "#111111", textAlign: "center" }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ py: 2 }}>
            {availableCurrencies &&
              availableCurrencies.map((currencyTicker, index) => (
                <Fab
                  variant={"extended"}
                  sx={{
                    pr: 4,
                    m: 1,
                    [theme.breakpoints.down("sm")]: {
                      width: "280px",
                    },
                    background: getButtonBackgroundColor(currencyTicker),
                    "&:hover": {
                      background: getButtonBackgroundColor(currencyTicker),
                    },
                    color: getButtonTextColor(currencyTicker),
                  }}
                  key={index}
                  onClick={() => handleCryptoSelect(currencyTicker)}
                >
                  <SvgIcon
                    component={cryptoAssetsMap[currencyTicker].icon}
                    inheritViewBox
                    sx={{ width: 30, height: 30 }}
                  />
                  <Typography variant={"h5"}>{cryptoAssetsMap[currencyTicker].name}</Typography>
                </Fab>
              ))}
          </Box>
        </Collapse>
      </Grid>
    </>
  );
}
