import ProgressCheckFilled from "@/assets/images/progress_indicator_filled.svg";
import { ApiEmbeddedPartnerPartyLinkInfo } from "@/services/openAPI/embedded";
import { Button, Dialog, DialogTitle, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";

const FundingSourceAdded = () => {
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };
  const userPartyLevel = userInfo?.party?.level;
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = (): void => {
    setOpen(false);
    navigate("/");
  };

  const fundingSourceAddedGridSx = {
    mb: 8,
    px: isMobile ? 0 : 10,
    textAlign: "center",
  };

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} fullScreen={isMobile}>
        {!isMobile && <DialogTitle severity="success">{}</DialogTitle>}
        <DialogContent sx={{ overflowY: "hidden" }}>
          <Grid container spacing={3} direction="column" alignItems="center" sx={fundingSourceAddedGridSx}>
            <Grid>
              <Typography variant={isMobile ? "h4" : "h3"} sx={{ mt: isMobile ? 3 : 0 }}>
                Funding source added
              </Typography>
            </Grid>
            <Grid>
              <img src={ProgressCheckFilled} alt="Check" style={{ width: "45px" }} />
            </Grid>
            <Grid>
              <Typography variant="h5" sx={{ maxWidth: 300, my: 2 }}>
                Your bank account has been verified.{" "}
                {!userPartyLevel?.includes("LIQUIDATED") && "You may now add funds to buy crypto."}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center", pt: 2 }}>
          <Stack spacing={2} sx={{ width: "60%" }}>
            {!userPartyLevel?.includes("LIQUIDATED") && (
              <Button variant="contained" onClick={() => navigate("/funding/deposit")}>
                Add funds
              </Button>
            )}
            <Button variant="text" onClick={() => navigate("/")}>
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default FundingSourceAdded;
