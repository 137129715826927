import { useRootContext } from "@/RootLayout";
import DashboardHeader from "@/pages/dashboard/DashboardHeader";
import {
  assetActivity as assetActivityMock,
  fetchMockDataPromiseWithDelay,
  historicalCryptoCurrencyPrices,
} from "@/services/mockData.ts";
import { GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum } from "@/services/openAPI/embedded";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { shouldUseMockData } from "@/utils/dataUtils.ts";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Outlet, defer, redirect } from "react-router-dom";
import AvailableCoins from "./AvailableCoins";
import AvailableFunds from "./AvailableFunds.tsx";
import FundingSources from "./FundingSources";
import TransactionHistory from "./TransactionHistory";

const Dashboard = () => {
  const [portalReady, setPortalReady] = useState(false);
  const pageHeaderPortalId = "page-header-portal";

  useEffect(() => {
    const pageHeaderPortal = document.getElementById(pageHeaderPortalId);

    if (pageHeaderPortal) {
      setPortalReady(true);
    }
  }, []);

  return (
    <>
      <Outlet context={useRootContext()} />
      {portalReady && createPortal(<DashboardHeader />, document.getElementById(pageHeaderPortalId) as Element)}
      <Grid container sx={{ mt: 4, mb: 3 }} spacing={3}>
        <AvailableCoins />
        <AvailableFunds />
        <FundingSources />
        <TransactionHistory />
      </Grid>
    </>
  );
};

export default Dashboard;

export async function loader() {
  //If the user comes from an Access domain, don't load anything and send them to our redirect page
  if (window.location.hostname.includes("access")) {
    return redirect("/access");
  }

  //TODO: Only returns first 25 records for now, does not paginate
  const transactionHistoryPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(assetActivityMock, 3000)
    : EmbeddedPartnerService.getAssetActivitiesHistory(
        undefined,
        [
          GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum.Transaction,
          GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum.Transfer,
        ],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        0,
        10,
      );

  const btcOneDayHistoricalPricesPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(historicalCryptoCurrencyPrices, 3000)
    : EmbeddedPartnerService.getHistoricalCryptoCurrencyPrice("DAY", "BTC", "USD");

  const ethOneDayHistoricalPricesPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(historicalCryptoCurrencyPrices, 3000)
    : EmbeddedPartnerService.getHistoricalCryptoCurrencyPrice("DAY", "ETH", "USD");

  return defer({ transactionHistoryPromise, btcOneDayHistoricalPricesPromise, ethOneDayHistoricalPricesPromise });
}
