import BankIcon from "@/assets/images/bank.svg";
import { ApiAssetActivity, ApiEmbeddedPartnerPartyProfileResponse } from "@/services/openAPI/embedded";
import { formatTransactionActivityText } from "@/utils/dataUtils.ts";
import {
  Icons,
  Pagination,
  Paper,
  Skeleton,
  cryptoAssetsMap,
  formatCryptoQuantity,
  formatDollarAmountUsd,
  formatUnixTime,
} from "@bakkt/bakkt-ui-components";
import {
  Alert,
  Box,
  Unstable_Grid2 as Grid,
  Snackbar,
  SvgIcon,
  TablePaginationProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  DataGridPremium,
  GridColDef,
  GridPagination,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Await, useLoaderData } from "react-router-dom";

const AllTransactionsGrid = () => {
  const { partyDetailsPromise, transactionHistoryPromise } = useLoaderData() as {
    partyDetailsPromise: Promise<ApiEmbeddedPartnerPartyProfileResponse>;
    transactionHistoryPromise: Promise<ApiAssetActivity[]>;
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openHash, setOpenHash] = useState(false);
  const [openQuantity, setOpenQuantity] = useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [sortModel, setSortModel] = useState([
    {
      field: "created",
      sort: "desc" as GridSortDirection,
    },
  ]);

  const [visibilityModel, setVisibilityModel] = useState({
    asset: true,
    type: true,
    created: true,
    quantity: true,
  });

  const paginationSx = {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
    },
  };

  // TODO: Update formatCryptoQuantity method to have a 'medium' length parameter that formats to 8 digits for ETH
  // We don't want to display 18 digits for ETH, so this is needed to hack the formatCryptoQuantity method to using 8 digits
  const eightDigitsType = "BTC";

  useEffect(() => {
    async function getPartyDetails() {
      const partyDetails = await partyDetailsPromise;
      setUserFullName(`${partyDetails?.Party?.firstName} ${partyDetails?.Party?.lastName}`);
    }
    getPartyDetails();
  }, []);

  useEffect(() => {
    setVisibilityModel({
      asset: true,
      type: isMobile ? false : true,
      created: isMobile ? false : true,
      quantity: true,
    });
  }, [isMobile]);

  function MuiPagination({ page, onPageChange }: Pick<TablePaginationProps, "page" | "onPageChange">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
        sx={paginationSx}
        showFirstButton
        showLastButton
      />
    );
  }

  const csvOptions = {
    fileName: `${userFullName} Transactions ${formatUnixTime(Date.now())}`,
    allColumns: true,
  };
  const CustomToolbar = () => {
    return (
      <Grid py={isMobile ? 3 : 2}>
        <GridToolbarContainer>
          {isMobile ? <></> : <GridToolbarFilterButton />}
          <GridToolbarExport csvOptions={csvOptions} excelOptions={csvOptions} />
        </GridToolbarContainer>
      </Grid>
    );
  };

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={MuiPagination} {...props} />;
  }

  const isCryptoTransaction = (transactionType: string): boolean => {
    return transactionType === "BUY" || transactionType === "SELL" || transactionType === "SELL_ALL";
  };

  const columns: GridColDef[] = [
    {
      field: "asset",
      headerName: "ASSET",
      width: 145,
      flex: isMobile ? 1 : undefined,
      valueGetter: (params) => {
        const transactionType = params.row.type;
        const transactionCryptoCurrency =
          transactionType === "BUY" ? params.row.destinationCurrency : params.row.sourceCurrency;

        let columnValue = "";

        switch (transactionType) {
          case "DEPOSIT":
            columnValue = "Funds";
            break;
          case "WITHDRAW":
            columnValue = "Funds";
            break;
          case "BUY":
            columnValue = cryptoAssetsMap[transactionCryptoCurrency]?.name;
            if (isMobile) {
              columnValue += " " + formatTransactionActivityText(transactionType);
            }
            break;
          case "SELL":
          case "SELL_ALL":
            columnValue = cryptoAssetsMap[transactionCryptoCurrency || ""]?.name;
            if (isMobile) {
              columnValue += " " + formatTransactionActivityText(transactionType);
            }
            break;
          case "LOYALTY_REDEEM":
            columnValue = "Rewards";
            if (isMobile) {
              columnValue = "Loyalty Redeem";
            }
        }

        return columnValue;
      },
      renderCell: (params) => {
        const transactionType = params.row.type;
        const transactionCryptoCurrency =
          transactionType === "BUY" ? params.row.destinationCurrency : params.row.sourceCurrency;

        let cellText = "";
        const cellSubtext = isMobile ? dayjs(params.row.created).format("DD MMM YYYY") : "";
        let svgIcon = Icons.UsdCoinIcon;

        switch (transactionType) {
          case "DEPOSIT":
            cellText = "Funds";
            svgIcon = Icons.CashIcon;
            break;
          case "WITHDRAW":
            cellText = "Funds";
            svgIcon = Icons.CashIcon;
            break;
          case "BUY":
            cellText = cryptoAssetsMap[transactionCryptoCurrency]?.name;
            if (isMobile) {
              cellText += " " + formatTransactionActivityText(transactionType);
            }
            svgIcon = cryptoAssetsMap[transactionCryptoCurrency]?.icon;
            break;
          case "SELL":
          case "SELL_ALL":
            cellText = cryptoAssetsMap[transactionCryptoCurrency || ""]?.name;
            if (isMobile) {
              cellText += " " + formatTransactionActivityText(transactionType);
            }
            svgIcon = cryptoAssetsMap[transactionCryptoCurrency || ""]?.icon;
            break;
          case "LOYALTY_REDEEM":
            cellText = "Rewards";
            if (isMobile) {
              cellText = "Loyalty Redeem";
            }
            svgIcon = Icons.RewardsIcon;
        }

        return (
          <>
            {isCryptoTransaction(transactionType) ||
            transactionType === "DEPOSIT" ||
            transactionType === "WITHDRAW" ||
            transactionType === "LOYALTY_REDEEM" ? (
              <Grid container>
                <SvgIcon component={svgIcon} inheritViewBox sx={{ width: 27, height: 27, pr: 1 }} />
                <Grid>
                  <Typography>{cellText}</Typography>
                  <Typography variant={"body2"} sx={{ position: "relative", top: -4 }}>
                    {cellSubtext}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <>
                <img
                  src={BankIcon}
                  alt="Bank"
                  style={{
                    width: 19,
                    height: 19,
                    padding: 3,
                    background: "#000000",
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                />
                <Typography>Bank Account</Typography>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "ACTIVITY",
      width: 140,
      renderCell: (params) => formatTransactionActivityText(params.row.type),
    },
    {
      field: "created",
      type: "date",
      headerName: "DATE",
      width: 110,
      valueGetter: (params) => params?.row?.created && new Date(params.row.created),
      renderCell: (params) => <>{dayjs(params.row.created).format("DD MMM YYYY")}</>,
    },
    {
      field: "quantity",
      headerName: "AMOUNT",
      filterable: false,
      headerAlign: "right",
      flex: 1,
      valueGetter: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          const transactionType = params.row.type;
          let usdAmount = "";

          switch (transactionType) {
            case "DEPOSIT":
              usdAmount = formatDollarAmountUsd(Math.abs(params.row.destinationAmount || 0));
              break;
            case "WITHDRAW":
              usdAmount = formatDollarAmountUsd(Math.abs(params.row.sourceAmount || 0));
              break;
            case "BUY":
              usdAmount = formatDollarAmountUsd(Math.abs(params.row.sourceAmount || 0));
              break;
            case "SELL":
            case "SELL_ALL":
              usdAmount = formatDollarAmountUsd(Math.abs(params.row.destinationAmount || 0));
              break;
            case "LOYALTY_REDEEM":
              usdAmount = formatDollarAmountUsd(Math.abs(params.row.destinationAmount || 0));
              break;
            default:
              usdAmount = "0";
              break;
          }

          const usdAmountNumber = Number(usdAmount.replace("$", ""));

          return usdAmountNumber;
        }
      },
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          const transactionType = params.row.type;
          const transactionCryptoCurrency =
            transactionType === "BUY" ? params.row.destinationCurrency : params.row.sourceCurrency;
          const transactionCryptoAmount =
            transactionType === "BUY" ? params.row.destinationAmount : Math.abs(params.row.sourceAmount);

          let cellText = "";
          let cellSubtext = "";

          switch (transactionType) {
            case "DEPOSIT":
              cellText = formatDollarAmountUsd(Math.abs(params.row.destinationAmount));
              if (params.row.status === "PENDING") {
                cellSubtext = "Pending - ";
              }
              if (params.row.assetType === "CASH") {
                cellSubtext += "Bank Deposit";
              } else if (params.row.assetType === "DEBIT_CARD") {
                cellSubtext += "Debit Card Deposit";
              }
              break;
            case "WITHDRAW":
              cellText = formatDollarAmountUsd(Math.abs(params.row.sourceAmount));
              if (params.row.status === "PENDING") {
                cellSubtext = `Pending - `;
              }
              if (params.row.assetType === "CASH") {
                cellSubtext += "Bank Withdraw";
              } else if (params.row.assetType === "DEBIT_CARD") {
                cellSubtext += "Debit Card Withdraw";
              }
              break;
            case "BUY":
              cellSubtext =
                formatCryptoQuantity(transactionCryptoAmount || 0, eightDigitsType, "long") +
                " " +
                transactionCryptoCurrency;
              cellText = formatDollarAmountUsd(Math.abs(params.row.sourceAmount));
              break;
            case "SELL":
            case "SELL_ALL":
              cellSubtext =
                formatCryptoQuantity(transactionCryptoAmount || 0, eightDigitsType, "long") +
                " " +
                transactionCryptoCurrency;
              cellText = formatDollarAmountUsd(Math.abs(params.row.destinationAmount));
              break;
            case "LOYALTY_REDEEM":
              cellText = formatDollarAmountUsd(params.row.destinationAmount || 0);
              cellSubtext = `${Math.abs(params.row.sourceAmount || 0)} Reward Credits`;
              break;
            default:
              cellText = "--";
              break;
          }

          return (
            <Box sx={{ textAlign: "right", width: "100%" }}>
              <>
                <span>{cellText}</span>
                <Typography variant={"body2"} sx={{ position: "relative", top: -4 }}>
                  {cellSubtext}
                </Typography>
              </>
            </Box>
          );
        }
      },
    },
  ];

  return (
    <Grid xs={12} md={8}>
      <Typography variant="h3">Activity Log</Typography>
      <React.Suspense fallback={<Skeleton variant="rectangular" height={500} sx={{ mt: 2 }} />}>
        <Await
          resolve={transactionHistoryPromise}
          errorElement={<Alert severity="error">Error loading transaction history</Alert>}
        >
          {(transactionHistory: ApiAssetActivity[]) => (
            <Paper sx={{ borderRadius: 0, mt: 2, p: isMobile ? 2 : 3 }}>
              <Grid container sx={{ p: 0 }}>
                <DataGridPremium
                  slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination,
                  }}
                  {...{ columns, rows: transactionHistory }}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 10, page: 0 },
                    },
                    sorting: {
                      sortModel: [{ field: "created", sort: "desc" }],
                    },
                  }}
                  getRowId={(row) => row.id}
                  pageSizeOptions={[10, 20, 50]}
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model)}
                  pagination
                  disableRowSelectionOnClick
                  disableColumnMenu
                  rowHeight={68}
                  columnVisibilityModel={visibilityModel}
                  sx={{
                    width: "100%",
                    ".MuiDataGrid-cell": {
                      border: 0,
                    },
                    ".MuiDataGrid-footerContainer": {
                      border: 0,
                      mt: 4,
                    },
                  }}
                />
              </Grid>
              <Snackbar
                open={openHash || openQuantity}
                onClose={() => {
                  setOpenHash(false);
                  setOpenQuantity(false);
                }}
                autoHideDuration={2000}
                message={openHash ? `Copied hash to clipboard` : `Copied quantity to clipboard`}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
              />
            </Paper>
          )}
        </Await>
      </React.Suspense>
    </Grid>
  );
};

export default AllTransactionsGrid;
