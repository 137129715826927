import { ApiEmbeddedPartnerPartyLinkInfo, ApiEmbeddedPartnerPartyProfileResponse } from "@/services/openAPI/embedded";
import { Paper, Skeleton, Typography } from "@bakkt/bakkt-ui-components";
import {
  Alert,
  Button,
  Unstable_Grid2 as Grid,
  // Link,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Await, useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";

const PersonalInformation = () => {
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };
  const userPartyLevel = userInfo?.party?.level;
  const eligibleLevels = ["LEVEL_4", "LEVEL_4_LIQUIDATED", "LEVEL_5", "LEVEL_5_LIQUIDATED"];

  const { partyDetailsPromise } = useLoaderData() as {
    partyDetailsPromise: Promise<ApiEmbeddedPartnerPartyProfileResponse>;
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid sx={{ mt: isMobile ? 4 : 0 }}>
      <Typography variant="h3">Personal Information</Typography>
      <React.Suspense fallback={<Skeleton variant="rectangular" height={330} sx={{ mb: 5, mt: 2 }} />}>
        <Await
          resolve={partyDetailsPromise}
          errorElement={<Alert severity="error">Error loading personal information</Alert>}
        >
          {(partyDetails: ApiEmbeddedPartnerPartyProfileResponse) => (
            <Paper sx={{ borderRadius: 0, mb: 4, mt: 2, p: 5 }}>
              {eligibleLevels.includes(userPartyLevel || "") ? (
                <>
                  <Grid container sx={{ justifyContent: "space-between", p: 0 }}>
                    <Typography
                      variant="h5"
                      sx={{ mb: 1 }}
                    >{`${partyDetails?.Party?.firstName} ${partyDetails?.Party?.lastName}`}</Typography>
                    {/* <Link sx={{
                          mt: 0.5,
                          textDecoration: "underline",
                          "&:hover": {
                            color: color.text.secondary,
                          },
                        }}>Edit</Link> */}
                  </Grid>
                  <Typography>{partyDetails?.Party?.address?.streetLine1}</Typography>
                  {partyDetails?.Party?.address?.streetLine2 && (
                    <Typography>{partyDetails?.Party?.address?.streetLine2}</Typography>
                  )}
                  <Typography>
                    {partyDetails?.Party?.address?.locality}, {partyDetails?.Party?.address?.region}{" "}
                    {partyDetails?.Party?.address?.postalCode}
                  </Typography>
                  <Typography sx={{ mt: 3 }}>DOB: {partyDetails?.Party?.dateOfBirth}</Typography>
                  <Typography>SSN: ●●●-●●-●●●</Typography>
                  <Typography sx={{ lineHeight: 1.4, mt: 2 }} variant="subtitle2">
                    If your date of birth or social security number are displayed incorrectly, please contact
                    help@bakkt.com.
                  </Typography>
                </>
              ) : (
                <Grid sx={{ p: 0, textAlign: "center" }}>
                  <Typography variant="h5" sx={{ mb: isMobile ? 2 : 3 }}>
                    Please add your personal details to begin trading crypto.
                  </Typography>
                  <Button variant="outlined" onClick={() => navigate("/kyc/user-info")} sx={{ fontWeight: 700 }}>
                    Add Info
                  </Button>
                </Grid>
              )}
            </Paper>
          )}
        </Await>
      </React.Suspense>
    </Grid>
  );
};

export default PersonalInformation;
