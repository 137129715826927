/* tslint:disable */
/* eslint-disable */
/**
 * Partner Embedded API
 * Partner Embedded API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'AccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'AccountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'AccountType'?: string;
}
/**
 * 
 * @export
 * @interface ApiAddress
 */
export interface ApiAddress {
    /**
     * 
     * @type {string}
     * @memberof ApiAddress
     */
    'country': ApiAddressCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiAddress
     */
    'googlePlaceRef'?: string;
    /**
     * Typically city or town
     * @type {string}
     * @memberof ApiAddress
     */
    'locality': string;
    /**
     * Melissa Address ID breakout:  30318, 2786, 40
     * @type {string}
     * @memberof ApiAddress
     */
    'melissaAddressId'?: string;
    /**
     * Zip code in the US
     * @type {string}
     * @memberof ApiAddress
     */
    'postalCode': string;
    /**
     * Typically state or prefecture
     * @type {string}
     * @memberof ApiAddress
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof ApiAddress
     */
    'streetLine1': string;
    /**
     * 
     * @type {string}
     * @memberof ApiAddress
     */
    'streetLine2'?: string;
}

export const ApiAddressCountryEnum = {
    And: 'AND',
    Are: 'ARE',
    Afg: 'AFG',
    Atg: 'ATG',
    Aia: 'AIA',
    Alb: 'ALB',
    Arm: 'ARM',
    Ago: 'AGO',
    Ata: 'ATA',
    Arg: 'ARG',
    Asm: 'ASM',
    Aut: 'AUT',
    Aus: 'AUS',
    Abw: 'ABW',
    Ala: 'ALA',
    Aze: 'AZE',
    Bih: 'BIH',
    Brb: 'BRB',
    Bgd: 'BGD',
    Bel: 'BEL',
    Bfa: 'BFA',
    Bgr: 'BGR',
    Bhr: 'BHR',
    Bdi: 'BDI',
    Ben: 'BEN',
    Blm: 'BLM',
    Bmu: 'BMU',
    Brn: 'BRN',
    Bol: 'BOL',
    Bes: 'BES',
    Bra: 'BRA',
    Bhs: 'BHS',
    Btn: 'BTN',
    Bvt: 'BVT',
    Bwa: 'BWA',
    Blr: 'BLR',
    Blz: 'BLZ',
    Can: 'CAN',
    Cck: 'CCK',
    Cod: 'COD',
    Caf: 'CAF',
    Cog: 'COG',
    Che: 'CHE',
    Civ: 'CIV',
    Cok: 'COK',
    Chl: 'CHL',
    Cmr: 'CMR',
    Chn: 'CHN',
    Col: 'COL',
    Cri: 'CRI',
    Cub: 'CUB',
    Cpv: 'CPV',
    Cuw: 'CUW',
    Cxr: 'CXR',
    Cyp: 'CYP',
    Cze: 'CZE',
    Deu: 'DEU',
    Dji: 'DJI',
    Dnk: 'DNK',
    Dma: 'DMA',
    Dom: 'DOM',
    Dza: 'DZA',
    Ecu: 'ECU',
    Est: 'EST',
    Egy: 'EGY',
    Esh: 'ESH',
    Eri: 'ERI',
    Esp: 'ESP',
    Eth: 'ETH',
    Fin: 'FIN',
    Fji: 'FJI',
    Flk: 'FLK',
    Fsm: 'FSM',
    Fro: 'FRO',
    Fra: 'FRA',
    Gab: 'GAB',
    Gbr: 'GBR',
    Grd: 'GRD',
    Geo: 'GEO',
    Guf: 'GUF',
    Ggy: 'GGY',
    Gha: 'GHA',
    Gib: 'GIB',
    Grl: 'GRL',
    Gmb: 'GMB',
    Gin: 'GIN',
    Glp: 'GLP',
    Gnq: 'GNQ',
    Grc: 'GRC',
    Sgs: 'SGS',
    Gtm: 'GTM',
    Gum: 'GUM',
    Gnb: 'GNB',
    Guy: 'GUY',
    Hkg: 'HKG',
    Hmd: 'HMD',
    Hnd: 'HND',
    Hrv: 'HRV',
    Hti: 'HTI',
    Hun: 'HUN',
    Idn: 'IDN',
    Irl: 'IRL',
    Isr: 'ISR',
    Imn: 'IMN',
    Ind: 'IND',
    Iot: 'IOT',
    Irq: 'IRQ',
    Irn: 'IRN',
    Isl: 'ISL',
    Ita: 'ITA',
    Jey: 'JEY',
    Jam: 'JAM',
    Jor: 'JOR',
    Jpn: 'JPN',
    Ken: 'KEN',
    Kgz: 'KGZ',
    Khm: 'KHM',
    Kir: 'KIR',
    Com: 'COM',
    Kna: 'KNA',
    Prk: 'PRK',
    Kor: 'KOR',
    Kwt: 'KWT',
    Cym: 'CYM',
    Kaz: 'KAZ',
    Lao: 'LAO',
    Lbn: 'LBN',
    Lca: 'LCA',
    Lie: 'LIE',
    Lka: 'LKA',
    Lbr: 'LBR',
    Lso: 'LSO',
    Ltu: 'LTU',
    Lux: 'LUX',
    Lva: 'LVA',
    Lby: 'LBY',
    Mar: 'MAR',
    Mco: 'MCO',
    Mda: 'MDA',
    Mne: 'MNE',
    Maf: 'MAF',
    Mdg: 'MDG',
    Mhl: 'MHL',
    Mkd: 'MKD',
    Mli: 'MLI',
    Mmr: 'MMR',
    Mng: 'MNG',
    Mac: 'MAC',
    Mnp: 'MNP',
    Mtq: 'MTQ',
    Mrt: 'MRT',
    Msr: 'MSR',
    Mlt: 'MLT',
    Mus: 'MUS',
    Mdv: 'MDV',
    Mwi: 'MWI',
    Mex: 'MEX',
    Mys: 'MYS',
    Moz: 'MOZ',
    Nam: 'NAM',
    Ncl: 'NCL',
    Ner: 'NER',
    Nfk: 'NFK',
    Nga: 'NGA',
    Nic: 'NIC',
    Nld: 'NLD',
    Nor: 'NOR',
    Npl: 'NPL',
    Nru: 'NRU',
    Niu: 'NIU',
    Nzl: 'NZL',
    Omn: 'OMN',
    Pan: 'PAN',
    Per: 'PER',
    Pyf: 'PYF',
    Png: 'PNG',
    Phl: 'PHL',
    Pak: 'PAK',
    Pol: 'POL',
    Spm: 'SPM',
    Pcn: 'PCN',
    Pri: 'PRI',
    Pse: 'PSE',
    Prt: 'PRT',
    Plw: 'PLW',
    Pry: 'PRY',
    Qat: 'QAT',
    Reu: 'REU',
    Rou: 'ROU',
    Srb: 'SRB',
    Rus: 'RUS',
    Rwa: 'RWA',
    Sau: 'SAU',
    Slb: 'SLB',
    Syc: 'SYC',
    Sdn: 'SDN',
    Swe: 'SWE',
    Sgp: 'SGP',
    Shn: 'SHN',
    Svn: 'SVN',
    Sjm: 'SJM',
    Svk: 'SVK',
    Sle: 'SLE',
    Smr: 'SMR',
    Sen: 'SEN',
    Som: 'SOM',
    Sur: 'SUR',
    Ssd: 'SSD',
    Stp: 'STP',
    Slv: 'SLV',
    Sxm: 'SXM',
    Syr: 'SYR',
    Swz: 'SWZ',
    Tca: 'TCA',
    Tcd: 'TCD',
    Atf: 'ATF',
    Tgo: 'TGO',
    Tha: 'THA',
    Tjk: 'TJK',
    Tkl: 'TKL',
    Tls: 'TLS',
    Tkm: 'TKM',
    Tun: 'TUN',
    Ton: 'TON',
    Tur: 'TUR',
    Tto: 'TTO',
    Tuv: 'TUV',
    Twn: 'TWN',
    Tza: 'TZA',
    Ukr: 'UKR',
    Uga: 'UGA',
    Umi: 'UMI',
    Usa: 'USA',
    Ury: 'URY',
    Uzb: 'UZB',
    Vat: 'VAT',
    Vct: 'VCT',
    Ven: 'VEN',
    Vbg: 'VBG',
    Vir: 'VIR',
    Vnm: 'VNM',
    Vut: 'VUT',
    Wlf: 'WLF',
    Wsm: 'WSM',
    Yem: 'YEM',
    Myt: 'MYT',
    Zaf: 'ZAF',
    Zmb: 'ZMB',
    Zwe: 'ZWE'
} as const;

export type ApiAddressCountryEnum = typeof ApiAddressCountryEnum[keyof typeof ApiAddressCountryEnum];

/**
 * The cryptocurrency amount to buy or sell
 * @export
 * @interface ApiAmount
 */
export interface ApiAmount {
    /**
     * Value of the amount
     * @type {number}
     * @memberof ApiAmount
     */
    'amount'?: number;
    /**
     * Currency of the amount
     * @type {string}
     * @memberof ApiAmount
     */
    'currency'?: ApiAmountCurrencyEnum;
}

export const ApiAmountCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;

export type ApiAmountCurrencyEnum = typeof ApiAmountCurrencyEnum[keyof typeof ApiAmountCurrencyEnum];

/**
 * 
 * @export
 * @interface ApiAssetActivity
 */
export interface ApiAssetActivity {
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'accountRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'actor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'assetType'?: ApiAssetActivityAssetTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'costBasis'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'costBasisCurrency'?: ApiAssetActivityCostBasisCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'declineReason'?: ApiAssetActivityDeclineReasonEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'destinationAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'destinationAssetType'?: ApiAssetActivityDestinationAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'destinationCurrency'?: ApiAssetActivityDestinationCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'destinationDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'destinationName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'destinationPadding'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'destinationPaddingAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'externalRef'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'fee'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'feeCurrency'?: ApiAssetActivityFeeCurrencyEnum;
    /**
     * 
     * @type {ApiGiftCardDetail}
     * @memberof ApiAssetActivity
     */
    'giftCardDetails'?: ApiGiftCardDetail;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'groupActivityType'?: ApiAssetActivityGroupActivityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'groupAssetType'?: ApiAssetActivityGroupAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'groupIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'groupName'?: string;
    /**
     * 
     * @type {Array<ApiAssetActivity>}
     * @memberof ApiAssetActivity
     */
    'groupedActivities'?: Array<ApiAssetActivity>;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'internalNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'partnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'partyId'?: string;
    /**
     * 
     * @type {ApiPrincipalActivityDetail}
     * @memberof ApiAssetActivity
     */
    'principalActivityDetail'?: ApiPrincipalActivityDetail;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'sourceAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'sourceAssetType'?: ApiAssetActivitySourceAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'sourceCurrency'?: ApiAssetActivitySourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'sourceDetails'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'sourceDiscountAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'sourceDiscountPercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'sourceName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'sourcePadding'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'sourcePaddingAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'status'?: ApiAssetActivityStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiAssetActivity
     */
    'type'?: ApiAssetActivityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiAssetActivity
     */
    'unitPrice'?: number;
}

export const ApiAssetActivityAssetTypeEnum = {
    Cash: 'CASH',
    Crypto: 'CRYPTO',
    Rewards: 'REWARDS',
    GiftCard: 'GIFT_CARD',
    BakktCard: 'BAKKT_CARD',
    DebitCard: 'DEBIT_CARD'
} as const;

export type ApiAssetActivityAssetTypeEnum = typeof ApiAssetActivityAssetTypeEnum[keyof typeof ApiAssetActivityAssetTypeEnum];
export const ApiAssetActivityCostBasisCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;

export type ApiAssetActivityCostBasisCurrencyEnum = typeof ApiAssetActivityCostBasisCurrencyEnum[keyof typeof ApiAssetActivityCostBasisCurrencyEnum];
export const ApiAssetActivityDeclineReasonEnum = {
    AccountNotFound: 'ACCOUNT_NOT_FOUND',
    FundingPreferenceNotFound: 'FUNDING_PREFERENCE_NOT_FOUND',
    CardInvalidStatus: 'CARD_INVALID_STATUS',
    CardNotFound: 'CARD_NOT_FOUND',
    CardNotActivated: 'CARD_NOT_ACTIVATED',
    DailyLimitExceeded: 'DAILY_LIMIT_EXCEEDED',
    MonthlyLimitExceeded: 'MONTHLY_LIMIT_EXCEEDED',
    OverLimit: 'OVER_LIMIT',
    NotSufficientFunds: 'NOT_SUFFICIENT_FUNDS',
    SuspectedFraud: 'SUSPECTED_FRAUD',
    UnsupportedOperation: 'UNSUPPORTED_OPERATION',
    CardIssuerPartyNotFound: 'CARD_ISSUER_PARTY_NOT_FOUND',
    CardIssuerPartyInvalidStatus: 'CARD_ISSUER_PARTY_INVALID_STATUS',
    PartyNotFound: 'PARTY_NOT_FOUND',
    PartyInvalidStatus: 'PARTY_INVALID_STATUS',
    CurrencyMismatch: 'CURRENCY_MISMATCH',
    DataMismatch: 'DATA_MISMATCH',
    GenericPaymentProcessingError: 'GENERIC_PAYMENT_PROCESSING_ERROR',
    PreconditionNotMet: 'PRECONDITION_NOT_MET',
    InsufficientFundsForBakktAccountDeposit: 'INSUFFICIENT_FUNDS_FOR_BAKKT_ACCOUNT_DEPOSIT',
    BlacklistedDebitCard: 'BLACKLISTED_DEBIT_CARD',
    DebitCardBinDenyListed: 'DEBIT_CARD_BIN_DENY_LISTED',
    DebitCardLinkedToOtherParty: 'DEBIT_CARD_LINKED_TO_OTHER_PARTY',
    DebitCardUnsupportedCountry: 'DEBIT_CARD_UNSUPPORTED_COUNTRY',
    DebitCardInvalid: 'DEBIT_CARD_INVALID',
    BlacklistedMerchantCategory: 'BLACKLISTED_MERCHANT_CATEGORY',
    BlacklistedBankAccount: 'BLACKLISTED_BANK_ACCOUNT',
    BankAccountBlacklistedLinkingFailed: 'BANK_ACCOUNT_BLACKLISTED_LINKING_FAILED',
    TransactionFailed: 'TRANSACTION_FAILED',
    ReauthenticationRequired: 'REAUTHENTICATION_REQUIRED',
    UnknownError: 'UNKNOWN_ERROR',
    ValidationError: 'VALIDATION_ERROR',
    ProcessorDeclined: 'PROCESSOR_DECLINED',
    SettlementDeclined: 'SETTLEMENT_DECLINED',
    GatewayRejected: 'GATEWAY_REJECTED',
    RewardsGatewayRejected: 'REWARDS_GATEWAY_REJECTED',
    AvsError: 'AVS_ERROR',
    CvvError: 'CVV_ERROR',
    AvsGatewayError: 'AVS_GATEWAY_ERROR',
    AvsVerificationFailed: 'AVS_VERIFICATION_FAILED',
    CvvVerificationFailed: 'CVV_VERIFICATION_FAILED',
    AmountVerificationFailed: 'AMOUNT_VERIFICATION_FAILED',
    DebitCardNotFound: 'DEBIT_CARD_NOT_FOUND',
    SdkError: 'SDK_ERROR',
    PartyTermsAndConditionsExpired: 'PARTY_TERMS_AND_CONDITIONS_EXPIRED',
    DebitCardGivenDetailsDoNotMatch: 'DEBIT_CARD_GIVEN_DETAILS_DO_NOT_MATCH',
    PartyInvalidLevel: 'PARTY_INVALID_LEVEL',
    MissingWireWithdrawDetails: 'MISSING_WIRE_WITHDRAW_DETAILS',
    InvalidWireRoutingNumber: 'INVALID_WIRE_ROUTING_NUMBER',
    InvalidWireAccountNumber: 'INVALID_WIRE_ACCOUNT_NUMBER',
    InvalidWireBankName: 'INVALID_WIRE_BANK_NAME',
    InvalidWireBankAddress: 'INVALID_WIRE_BANK_ADDRESS'
} as const;

export type ApiAssetActivityDeclineReasonEnum = typeof ApiAssetActivityDeclineReasonEnum[keyof typeof ApiAssetActivityDeclineReasonEnum];
export const ApiAssetActivityDestinationAssetTypeEnum = {
    Cash: 'CASH',
    Crypto: 'CRYPTO',
    Rewards: 'REWARDS',
    GiftCard: 'GIFT_CARD',
    BakktCard: 'BAKKT_CARD',
    DebitCard: 'DEBIT_CARD'
} as const;

export type ApiAssetActivityDestinationAssetTypeEnum = typeof ApiAssetActivityDestinationAssetTypeEnum[keyof typeof ApiAssetActivityDestinationAssetTypeEnum];
export const ApiAssetActivityDestinationCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;

export type ApiAssetActivityDestinationCurrencyEnum = typeof ApiAssetActivityDestinationCurrencyEnum[keyof typeof ApiAssetActivityDestinationCurrencyEnum];
export const ApiAssetActivityFeeCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;

export type ApiAssetActivityFeeCurrencyEnum = typeof ApiAssetActivityFeeCurrencyEnum[keyof typeof ApiAssetActivityFeeCurrencyEnum];
export const ApiAssetActivityGroupActivityTypeEnum = {
    Deposit: 'DEPOSIT',
    Withdraw: 'WITHDRAW',
    MerchantPayment: 'MERCHANT_PAYMENT',
    CardPayment: 'CARD_PAYMENT'
} as const;

export type ApiAssetActivityGroupActivityTypeEnum = typeof ApiAssetActivityGroupActivityTypeEnum[keyof typeof ApiAssetActivityGroupActivityTypeEnum];
export const ApiAssetActivityGroupAssetTypeEnum = {
    Cash: 'CASH',
    Crypto: 'CRYPTO',
    Rewards: 'REWARDS',
    GiftCard: 'GIFT_CARD',
    BakktCard: 'BAKKT_CARD',
    DebitCard: 'DEBIT_CARD'
} as const;

export type ApiAssetActivityGroupAssetTypeEnum = typeof ApiAssetActivityGroupAssetTypeEnum[keyof typeof ApiAssetActivityGroupAssetTypeEnum];
export const ApiAssetActivitySourceAssetTypeEnum = {
    Cash: 'CASH',
    Crypto: 'CRYPTO',
    Rewards: 'REWARDS',
    GiftCard: 'GIFT_CARD',
    BakktCard: 'BAKKT_CARD',
    DebitCard: 'DEBIT_CARD'
} as const;

export type ApiAssetActivitySourceAssetTypeEnum = typeof ApiAssetActivitySourceAssetTypeEnum[keyof typeof ApiAssetActivitySourceAssetTypeEnum];
export const ApiAssetActivitySourceCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;

export type ApiAssetActivitySourceCurrencyEnum = typeof ApiAssetActivitySourceCurrencyEnum[keyof typeof ApiAssetActivitySourceCurrencyEnum];
export const ApiAssetActivityStatusEnum = {
    Canceled: 'CANCELED',
    Complete: 'COMPLETE',
    Expired: 'EXPIRED',
    Pending: 'PENDING',
    Rejected: 'REJECTED',
    Replaced: 'REPLACED',
    Failed: 'FAILED',
    Provisional: 'PROVISIONAL'
} as const;

export type ApiAssetActivityStatusEnum = typeof ApiAssetActivityStatusEnum[keyof typeof ApiAssetActivityStatusEnum];
export const ApiAssetActivityTypeEnum = {
    Add: 'ADD',
    Buy: 'BUY',
    CardChargeback: 'CARD_CHARGEBACK',
    CardPayment: 'CARD_PAYMENT',
    CardRefund: 'CARD_REFUND',
    ConvenienceCredit: 'CONVENIENCE_CREDIT',
    Credit: 'CREDIT',
    Convert: 'CONVERT',
    ConvertCancel: 'CONVERT_CANCEL',
    DepositFromFailedTransaction: 'DEPOSIT_FROM_FAILED_TRANSACTION',
    Deposit: 'DEPOSIT',
    DepositReturn: 'DEPOSIT_RETURN',
    DepositReturnFee: 'DEPOSIT_RETURN_FEE',
    DepositReturned: 'DEPOSIT_RETURNED',
    DepositReverse: 'DEPOSIT_REVERSE',
    DisputeCredit: 'DISPUTE_CREDIT',
    DisputeCreditReverse: 'DISPUTE_CREDIT_REVERSE',
    Freeze: 'FREEZE',
    Incentive: 'INCENTIVE',
    IncentiveCancel: 'INCENTIVE_CANCEL',
    LoyaltyBuy: 'LOYALTY_BUY',
    LoyaltyBuyCancel: 'LOYALTY_BUY_CANCEL',
    LoyaltyCreditCancel: 'LOYALTY_CREDIT_CANCEL',
    LoyaltyRedeem: 'LOYALTY_REDEEM',
    LoyaltyCryptoRedeemFailed: 'LOYALTY_CRYPTO_REDEEM_FAILED',
    LoyaltyPaymentReversal: 'LOYALTY_PAYMENT_REVERSAL',
    Link: 'LINK',
    MerchantCredit: 'MERCHANT_CREDIT',
    MerchantPayment: 'MERCHANT_PAYMENT',
    MerchantPaymentAuthorize: 'MERCHANT_PAYMENT_AUTHORIZE',
    MerchantPaymentAuthorizeDeclined: 'MERCHANT_PAYMENT_AUTHORIZE_DECLINED',
    MerchantPaymentCancel: 'MERCHANT_PAYMENT_CANCEL',
    MerchantPaymentCapture: 'MERCHANT_PAYMENT_CAPTURE',
    MerchantPaymentCredit: 'MERCHANT_PAYMENT_CREDIT',
    MerchantPaymentRefund: 'MERCHANT_PAYMENT_REFUND',
    MerchantPaymentDispute: 'MERCHANT_PAYMENT_DISPUTE',
    MerchantRefund: 'MERCHANT_REFUND',
    Payout: 'PAYOUT',
    PromotionalCredit: 'PROMOTIONAL_CREDIT',
    Receive: 'RECEIVE',
    Redeem: 'REDEEM',
    Remove: 'REMOVE',
    Restore: 'RESTORE',
    Reverse: 'REVERSE',
    Sell: 'SELL',
    SellAll: 'SELL_ALL',
    Send: 'SEND',
    Terminate: 'TERMINATE',
    Unfreeze: 'UNFREEZE',
    Unlink: 'UNLINK',
    WireDeposit: 'WIRE_DEPOSIT',
    WireWithdraw: 'WIRE_WITHDRAW',
    Withdraw: 'WITHDRAW',
    WithdrawReverse: 'WITHDRAW_REVERSE',
    WithdrawReturn: 'WITHDRAW_RETURN',
    WithdrawReturned: 'WITHDRAW_RETURNED',
    PartnerPayout: 'PARTNER_PAYOUT',
    PartnerRewardCrypto: 'PARTNER_REWARD_CRYPTO',
    PartnerRewardCash: 'PARTNER_REWARD_CASH',
    PartnerTpjCredit: 'PARTNER_TPJ_CREDIT',
    PartnerTpjDebit: 'PARTNER_TPJ_DEBIT',
    PartyPartnerWalletAccountTransfer: 'PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER'
} as const;

export type ApiAssetActivityTypeEnum = typeof ApiAssetActivityTypeEnum[keyof typeof ApiAssetActivityTypeEnum];

/**
 * 
 * @export
 * @interface ApiAvailabilityRequest
 */
export interface ApiAvailabilityRequest {
    /**
     * 
     * @type {ApiIdentifier}
     * @memberof ApiAvailabilityRequest
     */
    'identifier'?: ApiIdentifier;
}
/**
 * 
 * @export
 * @interface ApiBankAccount
 */
export interface ApiBankAccount {
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'accountId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiBankAccount
     */
    'blacklisted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'currency'?: ApiBankAccountCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'institutionLogo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'institutionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'institutionPrimaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'institutionUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'kycStatus'?: ApiBankAccountKycStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'linkStatus'?: ApiBankAccountLinkStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'mask'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'maskedRoutingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'officialName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'partnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'partyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'plaidAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'status'?: ApiBankAccountStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccount
     */
    'verificationType'?: ApiBankAccountVerificationTypeEnum;
}

export const ApiBankAccountCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;

export type ApiBankAccountCurrencyEnum = typeof ApiBankAccountCurrencyEnum[keyof typeof ApiBankAccountCurrencyEnum];
export const ApiBankAccountKycStatusEnum = {
    Success: 'SUCCESS',
    Failed: 'FAILED',
    Pending: 'PENDING'
} as const;

export type ApiBankAccountKycStatusEnum = typeof ApiBankAccountKycStatusEnum[keyof typeof ApiBankAccountKycStatusEnum];
export const ApiBankAccountLinkStatusEnum = {
    Current: 'CURRENT',
    RefreshRequired: 'REFRESH_REQUIRED',
    PendingExpiration: 'PENDING_EXPIRATION'
} as const;

export type ApiBankAccountLinkStatusEnum = typeof ApiBankAccountLinkStatusEnum[keyof typeof ApiBankAccountLinkStatusEnum];
export const ApiBankAccountStatusEnum = {
    Active: 'ACTIVE',
    Pending: 'PENDING',
    Suspended: 'SUSPENDED'
} as const;

export type ApiBankAccountStatusEnum = typeof ApiBankAccountStatusEnum[keyof typeof ApiBankAccountStatusEnum];
export const ApiBankAccountVerificationTypeEnum = {
    AutomatedMicrodeposit: 'AUTOMATED_MICRODEPOSIT',
    SameDayMicrodeposit: 'SAME_DAY_MICRODEPOSIT',
    ProcessorAutomaticVerification: 'PROCESSOR_AUTOMATIC_VERIFICATION',
    ProcessorManualVerification: 'PROCESSOR_MANUAL_VERIFICATION',
    Instant: 'INSTANT',
    Manual: 'MANUAL'
} as const;

export type ApiBankAccountVerificationTypeEnum = typeof ApiBankAccountVerificationTypeEnum[keyof typeof ApiBankAccountVerificationTypeEnum];

/**
 * 
 * @export
 * @interface ApiBankAccountLinkStatus
 */
export interface ApiBankAccountLinkStatus {
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccountLinkStatus
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccountLinkStatus
     */
    'accountRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccountLinkStatus
     */
    'linkStatus': ApiBankAccountLinkStatusLinkStatusEnum;
}

export const ApiBankAccountLinkStatusLinkStatusEnum = {
    Current: 'CURRENT',
    RefreshRequired: 'REFRESH_REQUIRED',
    PendingExpiration: 'PENDING_EXPIRATION'
} as const;

export type ApiBankAccountLinkStatusLinkStatusEnum = typeof ApiBankAccountLinkStatusLinkStatusEnum[keyof typeof ApiBankAccountLinkStatusLinkStatusEnum];

/**
 * 
 * @export
 * @interface ApiBankAccountLinkStatusUpdateRequest
 */
export interface ApiBankAccountLinkStatusUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiBankAccountLinkStatusUpdateRequest
     */
    'accountRef'?: string;
}
/**
 * 
 * @export
 * @interface ApiClientSession
 */
export interface ApiClientSession {
    /**
     * 
     * @type {string}
     * @memberof ApiClientSession
     */
    'sessionRef'?: string;
}
/**
 * 
 * @export
 * @interface ApiCreateBankAccountRequest
 */
export interface ApiCreateBankAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCreateBankAccountRequest
     */
    'accountRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateBankAccountRequest
     */
    'institutionRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateBankAccountRequest
     */
    'linkSessionRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateBankAccountRequest
     */
    'mask'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateBankAccountRequest
     */
    'publicToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateBankAccountRequest
     */
    'verificationStatus'?: ApiCreateBankAccountRequestVerificationStatusEnum;
}

export const ApiCreateBankAccountRequestVerificationStatusEnum = {
    PendingAutomaticVerification: 'PENDING_AUTOMATIC_VERIFICATION',
    PendingManualVerification: 'PENDING_MANUAL_VERIFICATION',
    AutomaticallyVerified: 'AUTOMATICALLY_VERIFIED',
    ManuallyVerified: 'MANUALLY_VERIFIED',
    VerificationExpired: 'VERIFICATION_EXPIRED',
    VerificationFailed: 'VERIFICATION_FAILED'
} as const;

export type ApiCreateBankAccountRequestVerificationStatusEnum = typeof ApiCreateBankAccountRequestVerificationStatusEnum[keyof typeof ApiCreateBankAccountRequestVerificationStatusEnum];

/**
 * 
 * @export
 * @interface ApiCryptoCurrencyConfig
 */
export interface ApiCryptoCurrencyConfig {
    /**
     * 
     * @type {string}
     * @memberof ApiCryptoCurrencyConfig
     */
    'bgColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCryptoCurrencyConfig
     */
    'currency'?: ApiCryptoCurrencyConfigCurrencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApiCryptoCurrencyConfig
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiCryptoCurrencyConfig
     */
    'functions'?: Array<ApiCryptoCurrencyConfigFunctionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiCryptoCurrencyConfig
     */
    'iconColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCryptoCurrencyConfig
     */
    'iconURL'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiCryptoCurrencyConfig
     */
    'minimumBuy'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCryptoCurrencyConfig
     */
    'minimumSell'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCryptoCurrencyConfig
     */
    'minimumTransfer'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiCryptoCurrencyConfig
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiCryptoCurrencyConfig
     */
    'precision'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCryptoCurrencyConfig
     */
    'release'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCryptoCurrencyConfig
     */
    'supportedPrecision'?: number;
}

export const ApiCryptoCurrencyConfigCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;

export type ApiCryptoCurrencyConfigCurrencyEnum = typeof ApiCryptoCurrencyConfigCurrencyEnum[keyof typeof ApiCryptoCurrencyConfigCurrencyEnum];
export const ApiCryptoCurrencyConfigFunctionsEnum = {
    Trade: 'trade',
    P2p: 'p2p',
    MerchantPayment: 'merchant-payment'
} as const;

export type ApiCryptoCurrencyConfigFunctionsEnum = typeof ApiCryptoCurrencyConfigFunctionsEnum[keyof typeof ApiCryptoCurrencyConfigFunctionsEnum];

/**
 * 
 * @export
 * @interface ApiDebitCard
 */
export interface ApiDebitCard {
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCard
     */
    'cardType'?: ApiDebitCardCardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCard
     */
    'created'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDebitCard
     */
    'dailyAmountLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCard
     */
    'dateLinked'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCard
     */
    'debitCardId'?: string;
    /**
     * 
     * @type {ApiDebitCardExpiryDate}
     * @memberof ApiDebitCard
     */
    'expiryDate'?: ApiDebitCardExpiryDate;
    /**
     * 
     * @type {boolean}
     * @memberof ApiDebitCard
     */
    'isBlacklisted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCard
     */
    'issuingBank'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDebitCard
     */
    'monthlyAmountLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCard
     */
    'panLastFour'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDebitCard
     */
    'processingTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCard
     */
    'status'?: ApiDebitCardStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiDebitCard
     */
    'transactionLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCard
     */
    'withdrawProcessingTime'?: string;
}

export const ApiDebitCardCardTypeEnum = {
    Mastercard: 'MASTERCARD',
    Visa: 'VISA',
    Unsupported: 'UNSUPPORTED'
} as const;

export type ApiDebitCardCardTypeEnum = typeof ApiDebitCardCardTypeEnum[keyof typeof ApiDebitCardCardTypeEnum];
export const ApiDebitCardStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    PendingVerification: 'PENDING_VERIFICATION',
    VerificationExpired: 'VERIFICATION_EXPIRED'
} as const;

export type ApiDebitCardStatusEnum = typeof ApiDebitCardStatusEnum[keyof typeof ApiDebitCardStatusEnum];

/**
 * 
 * @export
 * @interface ApiDebitCardExpiryDate
 */
export interface ApiDebitCardExpiryDate {
    /**
     * 
     * @type {number}
     * @memberof ApiDebitCardExpiryDate
     */
    'year'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCardExpiryDate
     */
    'month'?: ApiDebitCardExpiryDateMonthEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiDebitCardExpiryDate
     */
    'monthValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiDebitCardExpiryDate
     */
    'leapYear'?: boolean;
}

export const ApiDebitCardExpiryDateMonthEnum = {
    January: 'JANUARY',
    February: 'FEBRUARY',
    March: 'MARCH',
    April: 'APRIL',
    May: 'MAY',
    June: 'JUNE',
    July: 'JULY',
    August: 'AUGUST',
    September: 'SEPTEMBER',
    October: 'OCTOBER',
    November: 'NOVEMBER',
    December: 'DECEMBER'
} as const;

export type ApiDebitCardExpiryDateMonthEnum = typeof ApiDebitCardExpiryDateMonthEnum[keyof typeof ApiDebitCardExpiryDateMonthEnum];

/**
 * 
 * @export
 * @interface ApiDebitCardLinkRequest
 */
export interface ApiDebitCardLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCardLinkRequest
     */
    'bin'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCardLinkRequest
     */
    'lastFour'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCardLinkRequest
     */
    'sessionRef': string;
}
/**
 * 
 * @export
 * @interface ApiDebitCardVerificationRequest
 */
export interface ApiDebitCardVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiDebitCardVerificationRequest
     */
    'debitCardId': string;
    /**
     * Verification amounts provided to confirm micro-withdrawals (should be 2).
     * @type {Array<ApiAmount>}
     * @memberof ApiDebitCardVerificationRequest
     */
    'verificationAmounts': Array<ApiAmount>;
}
/**
 * ApiEmbeddedCaesarsCryptoTransactionRequest
 * @export
 * @interface ApiEmbeddedCaesarsCryptoTransactionRequest
 */
export interface ApiEmbeddedCaesarsCryptoTransactionRequest {
    /**
     * caesars account id from token
     * @type {string}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionRequest
     */
    'caesarsAccountId': string;
    /**
     * caesars reward points from token
     * @type {string}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionRequest
     */
    'caesarsRewardPoints': string;
    /**
     * account id to reference in partner system
     * @type {string}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionRequest
     */
    'externalAccountRef': string;
    /**
     * 
     * @type {FiatAmount}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionRequest
     */
    'price': FiatAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionRequest
     */
    'quantity'?: ApiAmount;
    /**
     * An optional timestamp representing when the terms and conditions were accepted.This field is ignored unless the partner has the field allowOptionalTermsAndConditionsPublishTime set to true.
     * @type {string}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionRequest
     */
    'termsAndConditionsAcceptedTime'?: string;
    /**
     * 
     * @type {FiatAmount}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionRequest
     */
    'totalPrice'?: FiatAmount;
    /**
     * Determines whether to buy or sell crypto currency.
     * @type {string}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionRequest
     */
    'transactType': ApiEmbeddedCaesarsCryptoTransactionRequestTransactTypeEnum;
}

export const ApiEmbeddedCaesarsCryptoTransactionRequestTransactTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL',
    SellAll: 'SELL_ALL'
} as const;

export type ApiEmbeddedCaesarsCryptoTransactionRequestTransactTypeEnum = typeof ApiEmbeddedCaesarsCryptoTransactionRequestTransactTypeEnum[keyof typeof ApiEmbeddedCaesarsCryptoTransactionRequestTransactTypeEnum];

/**
 * ApiEmbeddedCaesarsCryptoTransactionResponse
 * @export
 * @interface ApiEmbeddedCaesarsCryptoTransactionResponse
 */
export interface ApiEmbeddedCaesarsCryptoTransactionResponse {
    /**
     * 
     * @type {CryptoCurrencyPriceResponse}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'nextValidPrice'?: CryptoCurrencyPriceResponse;
    /**
     * 
     * @type {FiatAmount}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'price'?: FiatAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'quantity'?: ApiAmount;
    /**
     * 
     * @type {number}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'rewardPointsBalance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'transactionType'?: ApiEmbeddedCaesarsCryptoTransactionResponseTransactionTypeEnum;
}

export const ApiEmbeddedCaesarsCryptoTransactionResponseTransactionTypeEnum = {
    CryptoCurrency: 'CRYPTO_CURRENCY',
    Reward: 'REWARD',
    Incentive: 'INCENTIVE',
    Loyalty: 'LOYALTY',
    Payout: 'PAYOUT',
    FundTransfer: 'FUND_TRANSFER'
} as const;

export type ApiEmbeddedCaesarsCryptoTransactionResponseTransactionTypeEnum = typeof ApiEmbeddedCaesarsCryptoTransactionResponseTransactionTypeEnum[keyof typeof ApiEmbeddedCaesarsCryptoTransactionResponseTransactionTypeEnum];

/**
 * ApiEmbeddedCaesarsTokenResponse
 * @export
 * @interface ApiEmbeddedCaesarsTokenResponse
 */
export interface ApiEmbeddedCaesarsTokenResponse {
    /**
     * Caesars rewards customer accountId
     * @type {string}
     * @memberof ApiEmbeddedCaesarsTokenResponse
     */
    'rewardsAccountId': string;
    /**
     * Caesars conversion rate
     * @type {number}
     * @memberof ApiEmbeddedCaesarsTokenResponse
     */
    'rewardsConversionRate': number;
    /**
     * Caesars points for the user
     * @type {number}
     * @memberof ApiEmbeddedCaesarsTokenResponse
     */
    'rewardsPoints': number;
    /**
     * Caesars rewards tokenId
     * @type {string}
     * @memberof ApiEmbeddedCaesarsTokenResponse
     */
    'tokenId': string;
}
/**
 * CryptoExecutePriceResponse
 * @export
 * @interface ApiEmbeddedCryptoExecutePriceRequest
 */
export interface ApiEmbeddedCryptoExecutePriceRequest {
    /**
     * Buy and Sell Indicator
     * @type {string}
     * @memberof ApiEmbeddedCryptoExecutePriceRequest
     */
    'buySellIndicator': ApiEmbeddedCryptoExecutePriceRequestBuySellIndicatorEnum;
    /**
     * Destination Currency
     * @type {string}
     * @memberof ApiEmbeddedCryptoExecutePriceRequest
     */
    'destinationCurrency'?: ApiEmbeddedCryptoExecutePriceRequestDestinationCurrencyEnum;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoExecutePriceRequest
     */
    'source'?: ApiAmount;
}

export const ApiEmbeddedCryptoExecutePriceRequestBuySellIndicatorEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type ApiEmbeddedCryptoExecutePriceRequestBuySellIndicatorEnum = typeof ApiEmbeddedCryptoExecutePriceRequestBuySellIndicatorEnum[keyof typeof ApiEmbeddedCryptoExecutePriceRequestBuySellIndicatorEnum];
export const ApiEmbeddedCryptoExecutePriceRequestDestinationCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;

export type ApiEmbeddedCryptoExecutePriceRequestDestinationCurrencyEnum = typeof ApiEmbeddedCryptoExecutePriceRequestDestinationCurrencyEnum[keyof typeof ApiEmbeddedCryptoExecutePriceRequestDestinationCurrencyEnum];

/**
 * CryptoExecutePriceResponse
 * @export
 * @interface ApiEmbeddedCryptoExecutePriceResponse
 */
export interface ApiEmbeddedCryptoExecutePriceResponse {
    /**
     * Buy and Sell Indicator
     * @type {string}
     * @memberof ApiEmbeddedCryptoExecutePriceResponse
     */
    'buySellIndicator': ApiEmbeddedCryptoExecutePriceResponseBuySellIndicatorEnum;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoExecutePriceResponse
     */
    'fees': ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoExecutePriceResponse
     */
    'indicativeValue': ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoExecutePriceResponse
     */
    'price': ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoExecutePriceResponse
     */
    'quantity': ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoExecutePriceResponse
     */
    'total': ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoExecutePriceResponse
     */
    'unitPrice': ApiAmount;
}

export const ApiEmbeddedCryptoExecutePriceResponseBuySellIndicatorEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type ApiEmbeddedCryptoExecutePriceResponseBuySellIndicatorEnum = typeof ApiEmbeddedCryptoExecutePriceResponseBuySellIndicatorEnum[keyof typeof ApiEmbeddedCryptoExecutePriceResponseBuySellIndicatorEnum];

/**
 * ApiEmbeddedCryptoPriceResponse
 * @export
 * @interface ApiEmbeddedCryptoPriceResponse
 */
export interface ApiEmbeddedCryptoPriceResponse {
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoPriceResponse
     */
    'buyPrice': ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoPriceResponse
     */
    'indicativePrice': ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoPriceResponse
     */
    'sellPrice': ApiAmount;
}
/**
 * ApiEmbeddedCryptoTransactionRequest
 * @export
 * @interface ApiEmbeddedCryptoTransactionRequest
 */
export interface ApiEmbeddedCryptoTransactionRequest {
    /**
     * account id to reference in partner system
     * @type {string}
     * @memberof ApiEmbeddedCryptoTransactionRequest
     */
    'externalAccountRef': string;
    /**
     * 
     * @type {FiatAmount}
     * @memberof ApiEmbeddedCryptoTransactionRequest
     */
    'price': FiatAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoTransactionRequest
     */
    'quantity'?: ApiAmount;
    /**
     * An optional timestamp representing when the terms and conditions were accepted.This field is ignored unless the partner has the field allowOptionalTermsAndConditionsPublishTime set to true.
     * @type {string}
     * @memberof ApiEmbeddedCryptoTransactionRequest
     */
    'termsAndConditionsAcceptedTime'?: string;
    /**
     * 
     * @type {FiatAmount}
     * @memberof ApiEmbeddedCryptoTransactionRequest
     */
    'totalPrice'?: FiatAmount;
    /**
     * Determines whether to buy or sell crypto currency.
     * @type {string}
     * @memberof ApiEmbeddedCryptoTransactionRequest
     */
    'transactType': ApiEmbeddedCryptoTransactionRequestTransactTypeEnum;
}

export const ApiEmbeddedCryptoTransactionRequestTransactTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL',
    SellAll: 'SELL_ALL'
} as const;

export type ApiEmbeddedCryptoTransactionRequestTransactTypeEnum = typeof ApiEmbeddedCryptoTransactionRequestTransactTypeEnum[keyof typeof ApiEmbeddedCryptoTransactionRequestTransactTypeEnum];

/**
 * ApiEmbeddedCryptoTransactionResponse
 * @export
 * @interface ApiEmbeddedCryptoTransactionResponse
 */
export interface ApiEmbeddedCryptoTransactionResponse {
    /**
     * 
     * @type {CryptoCurrencyPriceResponse}
     * @memberof ApiEmbeddedCryptoTransactionResponse
     */
    'nextValidPrice'?: CryptoCurrencyPriceResponse;
    /**
     * 
     * @type {FiatAmount}
     * @memberof ApiEmbeddedCryptoTransactionResponse
     */
    'price'?: FiatAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedCryptoTransactionResponse
     */
    'quantity'?: ApiAmount;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedCryptoTransactionResponse
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedCryptoTransactionResponse
     */
    'transactionType'?: ApiEmbeddedCryptoTransactionResponseTransactionTypeEnum;
}

export const ApiEmbeddedCryptoTransactionResponseTransactionTypeEnum = {
    CryptoCurrency: 'CRYPTO_CURRENCY',
    Reward: 'REWARD',
    Incentive: 'INCENTIVE',
    Loyalty: 'LOYALTY',
    Payout: 'PAYOUT',
    FundTransfer: 'FUND_TRANSFER'
} as const;

export type ApiEmbeddedCryptoTransactionResponseTransactionTypeEnum = typeof ApiEmbeddedCryptoTransactionResponseTransactionTypeEnum[keyof typeof ApiEmbeddedCryptoTransactionResponseTransactionTypeEnum];

/**
 * ApiEmbeddedFundingAccount
 * @export
 * @interface ApiEmbeddedFundingAccount
 */
export interface ApiEmbeddedFundingAccount {
    /**
     * Account Type eg: Checking|Saving|Internal
     * @type {string}
     * @memberof ApiEmbeddedFundingAccount
     */
    'accountType': string;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedFundingAccount
     */
    'availableBalance'?: ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedFundingAccount
     */
    'currentBalance'?: ApiAmount;
    /**
     * account id to refrence in partner system
     * @type {string}
     * @memberof ApiEmbeddedFundingAccount
     */
    'externalAccountRef': string;
    /**
     * Account number to dispay
     * @type {string}
     * @memberof ApiEmbeddedFundingAccount
     */
    'formattedAccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedFundingAccount
     */
    'name'?: string;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedFundingAccount
     */
    'pendingDepositAmount'?: ApiAmount;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedFundingAccount
     */
    'status'?: string;
}
/**
 * ApiEmbeddedFundingTransferRequest
 * @export
 * @interface ApiEmbeddedFundingTransferRequest
 */
export interface ApiEmbeddedFundingTransferRequest {
    /**
     * 
     * @type {FiatAmount}
     * @memberof ApiEmbeddedFundingTransferRequest
     */
    'amount'?: FiatAmount;
    /**
     * account id to reference in partner system / bank account id / debit card account id
     * @type {string}
     * @memberof ApiEmbeddedFundingTransferRequest
     */
    'externalAccountRef': string;
}
/**
 * ApiEmbeddedFundingTransferRequest
 * @export
 * @interface ApiEmbeddedFundingTransferResponse
 */
export interface ApiEmbeddedFundingTransferResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedFundingTransferResponse
     */
    'subPartnerTransactionRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedFundingTransferResponse
     */
    'transactionRef'?: string;
}
/**
 * ApiPartnerFeaturedFlag
 * @export
 * @interface ApiEmbeddedPartnerFeatureFlag
 */
export interface ApiEmbeddedPartnerFeatureFlag {
    /**
     * 
     * @type {boolean}
     * @memberof ApiEmbeddedPartnerFeatureFlag
     */
    'achLinkEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiEmbeddedPartnerFeatureFlag
     */
    'cashActivityReportEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiEmbeddedPartnerFeatureFlag
     */
    'cashBalanceReportEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiEmbeddedPartnerFeatureFlag
     */
    'debitCardLinkEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiEmbeddedPartnerFeatureFlag
     */
    'fundingAccessOnly'?: boolean;
}
/**
 * ApiEmbeddedPartnerLimitsResponse
 * @export
 * @interface ApiEmbeddedPartnerLimitsResponse
 */
export interface ApiEmbeddedPartnerLimitsResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiEmbeddedPartnerLimitsResponse
     */
    'buyDailyLimit': number;
    /**
     * 
     * @type {number}
     * @memberof ApiEmbeddedPartnerLimitsResponse
     */
    'buyMonthlyLimit': number;
    /**
     * 
     * @type {number}
     * @memberof ApiEmbeddedPartnerLimitsResponse
     */
    'minimumBuyLimit': number;
    /**
     * 
     * @type {number}
     * @memberof ApiEmbeddedPartnerLimitsResponse
     */
    'minimumSellLimit': number;
}
/**
 * ApiEmbeddedPartnerPartyAccount
 * @export
 * @interface ApiEmbeddedPartnerPartyAccount
 */
export interface ApiEmbeddedPartnerPartyAccount {
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedPartnerPartyAccount
     */
    'accountBalance'?: ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedPartnerPartyAccount
     */
    'buyBalance'?: ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedPartnerPartyAccount
     */
    'indicativeBalance'?: ApiAmount;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerPartyAccount
     */
    'partnerPartyLinkId': string;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedPartnerPartyAccount
     */
    'sellBalance'?: ApiAmount;
}
/**
 * ApiEmbeddedPartnerPartyEnrollRequest
 * @export
 * @interface ApiEmbeddedPartnerPartyEnrollRequest
 */
export interface ApiEmbeddedPartnerPartyEnrollRequest {
    /**
     * 
     * @type {ApiPartnerPartyEnrollmentData}
     * @memberof ApiEmbeddedPartnerPartyEnrollRequest
     */
    'missingFieldValue': ApiPartnerPartyEnrollmentData;
    /**
     * profile id in which to update
     * @type {string}
     * @memberof ApiEmbeddedPartnerPartyEnrollRequest
     */
    'profileId': string;
}
/**
 * ApiEmbeddedPartnerPartyLinkInfo
 * @export
 * @interface ApiEmbeddedPartnerPartyLinkInfo
 */
export interface ApiEmbeddedPartnerPartyLinkInfo {
    /**
     * 
     * @type {Array<ApiCryptoCurrencyConfig>}
     * @memberof ApiEmbeddedPartnerPartyLinkInfo
     */
    'currencyConfigs': Array<ApiCryptoCurrencyConfig>;
    /**
     * 
     * @type {BasicPartnerInfo}
     * @memberof ApiEmbeddedPartnerPartyLinkInfo
     */
    'partner'?: BasicPartnerInfo;
    /**
     * 
     * @type {BasicPartnerPartyLink}
     * @memberof ApiEmbeddedPartnerPartyLinkInfo
     */
    'partnerPartyLink'?: BasicPartnerPartyLink;
    /**
     * 
     * @type {BasicPartyInfo}
     * @memberof ApiEmbeddedPartnerPartyLinkInfo
     */
    'party'?: BasicPartyInfo;
}
/**
 * ApiEmbeddedPartnerPartyProfileResponse
 * @export
 * @interface ApiEmbeddedPartnerPartyProfileResponse
 */
export interface ApiEmbeddedPartnerPartyProfileResponse {
    /**
     * 
     * @type {PartnerParty}
     * @memberof ApiEmbeddedPartnerPartyProfileResponse
     */
    'Party'?: PartnerParty;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiEmbeddedPartnerPartyProfileResponse
     */
    'fieldsAvailableFromPartner'?: Array<ApiEmbeddedPartnerPartyProfileResponseFieldsAvailableFromPartnerEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiEmbeddedPartnerPartyProfileResponse
     */
    'missingFields'?: Array<ApiEmbeddedPartnerPartyProfileResponseMissingFieldsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerPartyProfileResponse
     */
    'profileId'?: string;
}

export const ApiEmbeddedPartnerPartyProfileResponseFieldsAvailableFromPartnerEnum = {
    TaxIdNumber: 'TAX_ID_NUMBER',
    Occupation: 'OCCUPATION',
    FirstName: 'FIRST_NAME',
    LastName: 'LAST_NAME',
    Address: 'ADDRESS',
    Phone: 'PHONE',
    DateOfBirth: 'DATE_OF_BIRTH',
    Email: 'EMAIL'
} as const;

export type ApiEmbeddedPartnerPartyProfileResponseFieldsAvailableFromPartnerEnum = typeof ApiEmbeddedPartnerPartyProfileResponseFieldsAvailableFromPartnerEnum[keyof typeof ApiEmbeddedPartnerPartyProfileResponseFieldsAvailableFromPartnerEnum];
export const ApiEmbeddedPartnerPartyProfileResponseMissingFieldsEnum = {
    TaxIdNumber: 'TAX_ID_NUMBER',
    Occupation: 'OCCUPATION',
    FirstName: 'FIRST_NAME',
    LastName: 'LAST_NAME',
    Address: 'ADDRESS',
    Phone: 'PHONE',
    DateOfBirth: 'DATE_OF_BIRTH',
    Email: 'EMAIL'
} as const;

export type ApiEmbeddedPartnerPartyProfileResponseMissingFieldsEnum = typeof ApiEmbeddedPartnerPartyProfileResponseMissingFieldsEnum[keyof typeof ApiEmbeddedPartnerPartyProfileResponseMissingFieldsEnum];

/**
 * ApiEmbeddedPartnerPartyTaxIdResponse
 * @export
 * @interface ApiEmbeddedPartnerPartyTaxIdResponse
 */
export interface ApiEmbeddedPartnerPartyTaxIdResponse {
    /**
     * Full Tax Identification Number (SSN)
     * @type {string}
     * @memberof ApiEmbeddedPartnerPartyTaxIdResponse
     */
    'taxIdentifier'?: string;
}
/**
 * ApiEmbeddedPartnerTransaction
 * @export
 * @interface ApiEmbeddedPartnerTransaction
 */
export interface ApiEmbeddedPartnerTransaction {
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'created': string;
    /**
     * cryptocurrency source - PARTNER or PARTY
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'cryptoCurrencySource': ApiEmbeddedPartnerTransactionCryptoCurrencySourceEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'fee': number;
    /**
     * Formatted account number
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'formattedFundingAccountNumber': string;
    /**
     * Funding source account type
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'fundingSource': string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'operationType': ApiEmbeddedPartnerTransactionOperationTypeEnum;
    /**
     * partner id
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'partnerId': string;
    /**
     * Bakkt Partner\'s primary key for Party
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'partnerPartyRef': string;
    /**
     * Bakkt Partner\'s reference id used for a given transaction
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'partnerTransactionRef': string;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'source': ApiAmount;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'status': ApiEmbeddedPartnerTransactionStatusEnum;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'target': ApiAmount;
    /**
     * 
     * @type {number}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'total': number;
    /**
     * Bakkt\'s transaction id
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'transactionId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'transactionType': ApiEmbeddedPartnerTransactionTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransaction
     */
    'updated'?: string;
}

export const ApiEmbeddedPartnerTransactionCryptoCurrencySourceEnum = {
    Partner: 'PARTNER',
    Party: 'PARTY'
} as const;

export type ApiEmbeddedPartnerTransactionCryptoCurrencySourceEnum = typeof ApiEmbeddedPartnerTransactionCryptoCurrencySourceEnum[keyof typeof ApiEmbeddedPartnerTransactionCryptoCurrencySourceEnum];
export const ApiEmbeddedPartnerTransactionOperationTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL',
    SellAll: 'SELL_ALL',
    Reward: 'REWARD',
    Incentive: 'INCENTIVE',
    LoyaltyBuy: 'LOYALTY_BUY',
    LoyaltyCredit: 'LOYALTY_CREDIT',
    LoyaltyRedeem: 'LOYALTY_REDEEM',
    LoyaltyBuyCancel: 'LOYALTY_BUY_CANCEL',
    LoyaltyRedeemCancel: 'LOYALTY_REDEEM_CANCEL',
    Payout: 'PAYOUT',
    Deposit: 'DEPOSIT',
    Withdraw: 'WITHDRAW',
    TpjDeposit: 'TPJ_DEPOSIT',
    TpjWithdraw: 'TPJ_WITHDRAW',
    WireDeposit: 'WIRE_DEPOSIT',
    WireWithdraw: 'WIRE_WITHDRAW',
    DepositReturn: 'DEPOSIT_RETURN',
    WithdrawReturn: 'WITHDRAW_RETURN'
} as const;

export type ApiEmbeddedPartnerTransactionOperationTypeEnum = typeof ApiEmbeddedPartnerTransactionOperationTypeEnum[keyof typeof ApiEmbeddedPartnerTransactionOperationTypeEnum];
export const ApiEmbeddedPartnerTransactionStatusEnum = {
    Captured: 'CAPTURED',
    Declined: 'DECLINED',
    Disputed: 'DISPUTED',
    Cancelled: 'CANCELLED',
    Pending: 'PENDING',
    Completed: 'COMPLETED'
} as const;

export type ApiEmbeddedPartnerTransactionStatusEnum = typeof ApiEmbeddedPartnerTransactionStatusEnum[keyof typeof ApiEmbeddedPartnerTransactionStatusEnum];
export const ApiEmbeddedPartnerTransactionTransactionTypeEnum = {
    CryptoCurrency: 'CRYPTO_CURRENCY',
    Reward: 'REWARD',
    Incentive: 'INCENTIVE',
    Loyalty: 'LOYALTY',
    Payout: 'PAYOUT',
    FundTransfer: 'FUND_TRANSFER'
} as const;

export type ApiEmbeddedPartnerTransactionTransactionTypeEnum = typeof ApiEmbeddedPartnerTransactionTransactionTypeEnum[keyof typeof ApiEmbeddedPartnerTransactionTransactionTypeEnum];

/**
 * ApiEmbeddedPartnerTransactionSummary
 * @export
 * @interface ApiEmbeddedPartnerTransactionSummary
 */
export interface ApiEmbeddedPartnerTransactionSummary {
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'fee': number;
    /**
     * Partners account ref
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'formattedAccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'fundingSource': string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'operationType': ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum;
    /**
     * partner id
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'partnerId': string;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'source': ApiAmount;
    /**
     * 
     * @type {ApiAmount}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'target': ApiAmount;
    /**
     * 
     * @type {number}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'total': number;
    /**
     * Bakkt\'s transaction id
     * @type {string}
     * @memberof ApiEmbeddedPartnerTransactionSummary
     */
    'transactionId': string;
}

export const ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum = {
    Buy: 'BUY',
    Sell: 'SELL',
    SellAll: 'SELL_ALL',
    Reward: 'REWARD',
    Incentive: 'INCENTIVE',
    LoyaltyBuy: 'LOYALTY_BUY',
    LoyaltyCredit: 'LOYALTY_CREDIT',
    LoyaltyRedeem: 'LOYALTY_REDEEM',
    LoyaltyBuyCancel: 'LOYALTY_BUY_CANCEL',
    LoyaltyRedeemCancel: 'LOYALTY_REDEEM_CANCEL',
    Payout: 'PAYOUT',
    Deposit: 'DEPOSIT',
    Withdraw: 'WITHDRAW',
    TpjDeposit: 'TPJ_DEPOSIT',
    TpjWithdraw: 'TPJ_WITHDRAW',
    WireDeposit: 'WIRE_DEPOSIT',
    WireWithdraw: 'WIRE_WITHDRAW',
    DepositReturn: 'DEPOSIT_RETURN',
    WithdrawReturn: 'WITHDRAW_RETURN'
} as const;

export type ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum = typeof ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum[keyof typeof ApiEmbeddedPartnerTransactionSummaryOperationTypeEnum];

/**
 * Add email or phone number to a party
 * @export
 * @interface ApiEmbeddedPartyAddIdentifierRequest
 */
export interface ApiEmbeddedPartyAddIdentifierRequest {
    /**
     * 
     * @type {ApiIdentifier}
     * @memberof ApiEmbeddedPartyAddIdentifierRequest
     */
    'identifier': ApiIdentifier;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartyAddIdentifierRequest
     */
    'identifierToken'?: string;
}
/**
 * ApiEmbeddedPartyUpdateEw9Request
 * @export
 * @interface ApiEmbeddedPartyUpdateEw9Request
 */
export interface ApiEmbeddedPartyUpdateEw9Request {
    /**
     * Signature provided by user to indicate acceptance of ew9 details
     * @type {string}
     * @memberof ApiEmbeddedPartyUpdateEw9Request
     */
    'ew9Signature'?: string;
}
/**
 * Update a party\'s name and address Request
 * @export
 * @interface ApiEmbeddedPartyUpdateNameAndAddressRequest
 */
export interface ApiEmbeddedPartyUpdateNameAndAddressRequest {
    /**
     * 
     * @type {ApiAddress}
     * @memberof ApiEmbeddedPartyUpdateNameAndAddressRequest
     */
    'address'?: ApiAddress;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartyUpdateNameAndAddressRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmbeddedPartyUpdateNameAndAddressRequest
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'code'?: string;
    /**
     * 
     * @type {Array<ApiErrorDetail>}
     * @memberof ApiError
     */
    'details'?: Array<ApiErrorDetail>;
    /**
     * Human readable error message
     * @type {string}
     * @memberof ApiError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'trace'?: string;
}
/**
 * 
 * @export
 * @interface ApiErrorDetail
 */
export interface ApiErrorDetail {
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDetail
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDetail
     */
    'field'?: string;
    /**
     * Human readable error message
     * @type {string}
     * @memberof ApiErrorDetail
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiErrorDetail
     */
    'rejectedValue'?: object;
}
/**
 * 
 * @export
 * @interface ApiFinalizeRequest
 */
export interface ApiFinalizeRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiFinalizeRequest
     */
    'firstName'?: string;
    /**
     * Encrypted identfier returned from the previous step in the sign up flow
     * @type {string}
     * @memberof ApiFinalizeRequest
     */
    'identifierToken': string;
    /**
     * 
     * @type {string}
     * @memberof ApiFinalizeRequest
     */
    'lastName'?: string;
    /**
     * Password used to login to authenticate with the Bakkt system
     * @type {string}
     * @memberof ApiFinalizeRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ApiGiftCardDetail
 */
export interface ApiGiftCardDetail {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCardDetail
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCardDetail
     */
    'brandImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCardDetail
     */
    'brandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCardDetail
     */
    'giftCardProductId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCardDetail
     */
    'productFrontImageUrl'?: string;
}
/**
 * 
 * @export
 * @interface ApiIdentifier
 */
export interface ApiIdentifier {
    /**
     * Country code associated with the specified phone number
     * @type {string}
     * @memberof ApiIdentifier
     */
    'country'?: string;
    /**
     * Email address being checked for availability
     * @type {string}
     * @memberof ApiIdentifier
     */
    'email'?: string;
    /**
     * Phone number being checked for availability
     * @type {string}
     * @memberof ApiIdentifier
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface ApiKycDocumentVerificationDetailsResponse
 */
export interface ApiKycDocumentVerificationDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiKycDocumentVerificationDetailsResponse
     */
    'verificationURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKycDocumentVerificationDetailsResponse
     */
    'verificationURLExpiry'?: string;
}
/**
 * 
 * @export
 * @interface ApiLinkTokenRequest
 */
export interface ApiLinkTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiLinkTokenRequest
     */
    'androidPackageName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiLinkTokenRequest
     */
    'forUpdate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkTokenRequest
     */
    'redirectUri'?: string;
}
/**
 * 
 * @export
 * @interface ApiLinkTokenResponse
 */
export interface ApiLinkTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiLinkTokenResponse
     */
    'expiration': string;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkTokenResponse
     */
    'linkToken': string;
    /**
     * 
     * @type {string}
     * @memberof ApiLinkTokenResponse
     */
    'requestId': string;
}
/**
 * 
 * @export
 * @interface ApiPartnerBasicInfo
 */
export interface ApiPartnerBasicInfo {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerBasicInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerBasicInfo
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ApiPartnerEvent
 */
export interface ApiPartnerEvent {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerEvent
     */
    'actualRequest'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerEvent
     */
    'actualResponse'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerEvent
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerEvent
     */
    'eventDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerEvent
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerEvent
     */
    'externalRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerEvent
     */
    'partnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerEvent
     */
    'partyId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPartnerEvent
     */
    'successful'?: boolean;
}
/**
 * any missing fields needed by party enrollment
 * @export
 * @interface ApiPartnerPartyEnrollmentData
 */
export interface ApiPartnerPartyEnrollmentData {
    /**
     * 
     * @type {ApiAddress}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'address'?: ApiAddress;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'ew9Signature'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'occupation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'partnerPartyRef': string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'privacyPolicyPublishTime': string;
    /**
     * The tax identifier for the person. In the USA this would be their social security number (SSN)
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'taxIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyEnrollmentData
     */
    'termsAndConditionsPublishTime': string;
}
/**
 * Partner Party Link
 * @export
 * @interface ApiPartnerPartyLink
 */
export interface ApiPartnerPartyLink {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'acceptanceTimeEw9'?: string;
    /**
     * 
     * @type {ApiAddress}
     * @memberof ApiPartnerPartyLink
     */
    'address'?: ApiAddress;
    /**
     * Bakkt ID. Customizable alias used to identify a Party.
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'alias'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPartnerPartyLink
     */
    'asyncbuyAutoconfirm'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'businessPartnerRef'?: string;
    /**
     * Datetime the Cardholder Agreement was accepted by the Party
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'cardholderAgreementAcceptedTime'?: string;
    /**
     * Datetime the Cardholder Agreement was published
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'cardholderAgreementPublishTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'dateOfBirth'?: string;
    /**
     * Email address, verified by OTP
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'enrollmentSource'?: ApiPartnerPartyLinkEnrollmentSourceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPartnerPartyLink
     */
    'ew9CertificationRequired'?: boolean;
    /**
     * First name
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'fullNameOrEmpty'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'lastAchPrefundingExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'lastAchReturnDate'?: string;
    /**
     * Last name
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'level'?: ApiPartnerPartyLinkLevelEnum;
    /**
     * Bakkt ID. Customizable alias used to identify a Party.
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'newAlias'?: string;
    /**
     * Email address, yet to be verified by OTP
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'newEmail'?: string;
    /**
     * Phone number, yet to be verified by OTP
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'newPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'occupation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'otp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'partnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'partnerPartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'partyId'?: string;
    /**
     * Phone number, verified by OTP
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'photoIdVerificationStatus'?: ApiPartnerPartyLinkPhotoIdVerificationStatusEnum;
    /**
     * Datetime the Privacy Policy was accepted by the Party
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'privacyPolicyAcceptedTime'?: string;
    /**
     * Datetime the Privacy Policy was published
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'privacyPolicyPublishTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'segmentDeleteRef'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof ApiPartnerPartyLink
     */
    'serviceTypes'?: Set<ApiPartnerPartyLinkServiceTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'signatureEw9'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'status'?: ApiPartnerPartyLinkStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'subjectRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'taxIdNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'taxIdType'?: ApiPartnerPartyLinkTaxIdTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerPartyLink
     */
    'taxIdVerificationAttempts'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'taxIdVerificationStatus'?: ApiPartnerPartyLinkTaxIdVerificationStatusEnum;
    /**
     * Datetime the Terms and Conditions were accepted by the Party
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'termsAndConditionsAcceptedTime'?: string;
    /**
     * Datetime the Terms and Conditions were published
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'termsAndConditionsPublishTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyLink
     */
    'updated'?: string;
}

export const ApiPartnerPartyLinkEnrollmentSourceEnum = {
    Consumer: 'CONSUMER',
    Partner: 'PARTNER'
} as const;

export type ApiPartnerPartyLinkEnrollmentSourceEnum = typeof ApiPartnerPartyLinkEnrollmentSourceEnum[keyof typeof ApiPartnerPartyLinkEnrollmentSourceEnum];
export const ApiPartnerPartyLinkLevelEnum = {
    Unknown: 'UNKNOWN',
    UnknownSuspended: 'UNKNOWN_SUSPENDED',
    Level1: 'LEVEL_1',
    Level1Suspended: 'LEVEL_1_SUSPENDED',
    Level1Liquidated: 'LEVEL_1_LIQUIDATED',
    Level2: 'LEVEL_2',
    Level2Suspended: 'LEVEL_2_SUSPENDED',
    Level2Liquidated: 'LEVEL_2_LIQUIDATED',
    Level3: 'LEVEL_3',
    Level3Suspended: 'LEVEL_3_SUSPENDED',
    Level3Liquidated: 'LEVEL_3_LIQUIDATED',
    Level4: 'LEVEL_4',
    Level4Suspended: 'LEVEL_4_SUSPENDED',
    Level4Liquidated: 'LEVEL_4_LIQUIDATED',
    Level5: 'LEVEL_5',
    Level5Suspended: 'LEVEL_5_SUSPENDED',
    Level5Liquidated: 'LEVEL_5_LIQUIDATED',
    Level99: 'LEVEL_99',
    Level99Suspended: 'LEVEL_99_SUSPENDED',
    Level99Liquidated: 'LEVEL_99_LIQUIDATED',
    Closed: 'CLOSED'
} as const;

export type ApiPartnerPartyLinkLevelEnum = typeof ApiPartnerPartyLinkLevelEnum[keyof typeof ApiPartnerPartyLinkLevelEnum];
export const ApiPartnerPartyLinkPhotoIdVerificationStatusEnum = {
    Locked: 'LOCKED',
    Pending: 'PENDING',
    VerificationFailed: 'VERIFICATION_FAILED',
    Verified: 'VERIFIED',
    Skipped: 'SKIPPED'
} as const;

export type ApiPartnerPartyLinkPhotoIdVerificationStatusEnum = typeof ApiPartnerPartyLinkPhotoIdVerificationStatusEnum[keyof typeof ApiPartnerPartyLinkPhotoIdVerificationStatusEnum];
export const ApiPartnerPartyLinkServiceTypesEnum = {
    CryptoCurrency: 'CRYPTO_CURRENCY',
    Reward: 'REWARD',
    Incentive: 'INCENTIVE',
    Party: 'PARTY',
    Payout: 'PAYOUT',
    Loyalty: 'LOYALTY',
    FundTransfer: 'FUND_TRANSFER'
} as const;

export type ApiPartnerPartyLinkServiceTypesEnum = typeof ApiPartnerPartyLinkServiceTypesEnum[keyof typeof ApiPartnerPartyLinkServiceTypesEnum];
export const ApiPartnerPartyLinkStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    OtpRequired: 'OTP_REQUIRED',
    Pending: 'PENDING',
    Transferred: 'TRANSFERRED',
    UnderReview: 'UNDER_REVIEW',
    Liquidated: 'LIQUIDATED',
    Closed: 'CLOSED'
} as const;

export type ApiPartnerPartyLinkStatusEnum = typeof ApiPartnerPartyLinkStatusEnum[keyof typeof ApiPartnerPartyLinkStatusEnum];
export const ApiPartnerPartyLinkTaxIdTypeEnum = {
    Ssn: 'SSN',
    Itin: 'ITIN',
    Ein: 'EIN',
    Tin: 'TIN'
} as const;

export type ApiPartnerPartyLinkTaxIdTypeEnum = typeof ApiPartnerPartyLinkTaxIdTypeEnum[keyof typeof ApiPartnerPartyLinkTaxIdTypeEnum];
export const ApiPartnerPartyLinkTaxIdVerificationStatusEnum = {
    Locked: 'LOCKED',
    Pending: 'PENDING',
    VerificationFailed: 'VERIFICATION_FAILED',
    Verified: 'VERIFIED',
    Skipped: 'SKIPPED'
} as const;

export type ApiPartnerPartyLinkTaxIdVerificationStatusEnum = typeof ApiPartnerPartyLinkTaxIdVerificationStatusEnum[keyof typeof ApiPartnerPartyLinkTaxIdVerificationStatusEnum];

/**
 * 
 * @export
 * @interface ApiPartnerPartyProfileData
 */
export interface ApiPartnerPartyProfileData {
    /**
     * 
     * @type {ApiAddress}
     * @memberof ApiPartnerPartyProfileData
     */
    'address'?: ApiAddress;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyProfileData
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyProfileData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyProfileData
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyProfileData
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyProfileData
     */
    'occupation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyProfileData
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyProfileData
     */
    'taxIdentifier'?: string;
}
/**
 * ApiPartnerPartyProfileUpdateRequest
 * @export
 * @interface ApiPartnerPartyProfileUpdateRequest
 */
export interface ApiPartnerPartyProfileUpdateRequest {
    /**
     * 
     * @type {Array<Account>}
     * @memberof ApiPartnerPartyProfileUpdateRequest
     */
    'accounts'?: Array<Account>;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyProfileUpdateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {ApiPartnerPartyProfileData}
     * @memberof ApiPartnerPartyProfileUpdateRequest
     */
    'partnerPartyProfileData'?: ApiPartnerPartyProfileData;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartyProfileUpdateRequest
     */
    'tenant'?: string;
}
/**
 * ApiPartnerPartySamlProfileResponse
 * @export
 * @interface ApiPartnerPartySamlProfileResponse
 */
export interface ApiPartnerPartySamlProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerPartySamlProfileResponse
     */
    'partnerPartyRef': string;
}
/**
 * Partner Request
 * @export
 * @interface ApiPartnerRequest
 */
export interface ApiPartnerRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'expiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'partnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'partnerPartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'partnerTransactionRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'requestData'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'requestDataType'?: ApiPartnerRequestRequestDataTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerRequest
     */
    'sequence'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerRequest
     */
    'status'?: ApiPartnerRequestStatusEnum;
}

export const ApiPartnerRequestRequestDataTypeEnum = {
    Apex: 'APEX',
    Asyncbuy: 'ASYNCBUY',
    AsyncTransferFiatCurrency: 'ASYNC_TRANSFER_FIAT_CURRENCY',
    Asyncenroll: 'ASYNCENROLL',
    AsyncLinkBankAccount: 'ASYNC_LINK_BANK_ACCOUNT'
} as const;

export type ApiPartnerRequestRequestDataTypeEnum = typeof ApiPartnerRequestRequestDataTypeEnum[keyof typeof ApiPartnerRequestRequestDataTypeEnum];
export const ApiPartnerRequestStatusEnum = {
    Received: 'RECEIVED',
    Pending: 'PENDING',
    Completed: 'COMPLETED',
    Failed: 'FAILED',
    Expired: 'EXPIRED'
} as const;

export type ApiPartnerRequestStatusEnum = typeof ApiPartnerRequestStatusEnum[keyof typeof ApiPartnerRequestStatusEnum];

/**
 * Partner Request Response
 * @export
 * @interface ApiPartnerRequestResponse
 */
export interface ApiPartnerRequestResponse {
    /**
     * 
     * @type {ApiPartnerBasicInfo}
     * @memberof ApiPartnerRequestResponse
     */
    'partnerBasicInfo'?: ApiPartnerBasicInfo;
    /**
     * 
     * @type {ApiPartnerPartyLink}
     * @memberof ApiPartnerRequestResponse
     */
    'partnerPartyLink'?: ApiPartnerPartyLink;
    /**
     * 
     * @type {ApiPartnerRequest}
     * @memberof ApiPartnerRequestResponse
     */
    'partnerRequest'?: ApiPartnerRequest;
}
/**
 * speedbumpMessage
 * @export
 * @interface ApiPartnerSpeedbumpMessage
 */
export interface ApiPartnerSpeedbumpMessage {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpeedbumpMessage
     */
    'cancelText'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpeedbumpMessage
     */
    'saveText'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpeedbumpMessage
     */
    'speedbumpMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerSpeedbumpMessage
     */
    'speedbumpMessageTitle'?: string;
}
/**
 * 
 * @export
 * @interface ApiParty
 */
export interface ApiParty {
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'acceptanceTimeEw9'?: string;
    /**
     * 
     * @type {ApiAddress}
     * @memberof ApiParty
     */
    'address'?: ApiAddress;
    /**
     * Bakkt ID. Customizable alias used to identify a Party.
     * @type {string}
     * @memberof ApiParty
     */
    'alias'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'alreadyExists'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'brazePartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'businessPartnerRef'?: string;
    /**
     * Datetime the Cardholder Agreement was accepted by the Party
     * @type {string}
     * @memberof ApiParty
     */
    'cardholderAgreementAcceptedTime'?: string;
    /**
     * Datetime the Cardholder Agreement was published
     * @type {string}
     * @memberof ApiParty
     */
    'cardholderAgreementPublishTime'?: string;
    /**
     * Datetime the Party was created
     * @type {string}
     * @memberof ApiParty
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'dateOfBirth'?: string;
    /**
     * Email address, verified by OTP
     * @type {string}
     * @memberof ApiParty
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'emailOrPhone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'ew9CertificationRequired'?: boolean;
    /**
     * First name
     * @type {string}
     * @memberof ApiParty
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'fullName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'hasBoughtGiftCard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'hasCreatedVirtualCard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'hasDeposited'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'hasLinkedBankAccount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'hasLinkedDebitCard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'hasLinkedLoyalty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'hasPendingBankAccount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'hasTradedBTC'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'hasTradedCrypto'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'lastAchPrefundingExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'lastAchReturnDate'?: string;
    /**
     * Last name
     * @type {string}
     * @memberof ApiParty
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'level'?: ApiPartyLevelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'mismatch'?: boolean;
    /**
     * Email address, yet to be verified by OTP
     * @type {string}
     * @memberof ApiParty
     */
    'newEmail'?: string;
    /**
     * Phone number, yet to be verified by OTP
     * @type {string}
     * @memberof ApiParty
     */
    'newPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'occupation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'otp'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'otpRequired'?: boolean;
    /**
     * Party Type
     * @type {string}
     * @memberof ApiParty
     */
    'partyType'?: ApiPartyPartyTypeEnum;
    /**
     * Phone number, verified by OTP
     * @type {string}
     * @memberof ApiParty
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'photoIdVerificationStatus'?: ApiPartyPhotoIdVerificationStatusEnum;
    /**
     * Datetime the Privacy Policy was accepted by the Party
     * @type {string}
     * @memberof ApiParty
     */
    'privacyPolicyAcceptedTime'?: string;
    /**
     * Datetime the Privacy Policy was published
     * @type {string}
     * @memberof ApiParty
     */
    'privacyPolicyPublishTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'referralId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'sardinePartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'siftPartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'signatureEw9'?: string;
    /**
     * Status
     * @type {string}
     * @memberof ApiParty
     */
    'status'?: ApiPartyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'subjectRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'taxIdNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiParty
     */
    'taxIdVerificationAttempts'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'taxIdVerificationStatus'?: ApiPartyTaxIdVerificationStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApiParty
     */
    'termsAndConditionAcceptanceRequired'?: boolean;
    /**
     * Datetime the Terms and Conditions were accepted by the Party
     * @type {string}
     * @memberof ApiParty
     */
    'termsAndConditionsAcceptedTime'?: string;
    /**
     * Datetime the Terms and Conditions were published
     * @type {string}
     * @memberof ApiParty
     */
    'termsAndConditionsPublishTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiParty
     */
    'unit21PartyRef'?: string;
    /**
     * Datetime the Party last updated
     * @type {string}
     * @memberof ApiParty
     */
    'updated'?: string;
}

export const ApiPartyLevelEnum = {
    Unknown: 'UNKNOWN',
    UnknownSuspended: 'UNKNOWN_SUSPENDED',
    Level1: 'LEVEL_1',
    Level1Suspended: 'LEVEL_1_SUSPENDED',
    Level1Liquidated: 'LEVEL_1_LIQUIDATED',
    Level2: 'LEVEL_2',
    Level2Suspended: 'LEVEL_2_SUSPENDED',
    Level2Liquidated: 'LEVEL_2_LIQUIDATED',
    Level3: 'LEVEL_3',
    Level3Suspended: 'LEVEL_3_SUSPENDED',
    Level3Liquidated: 'LEVEL_3_LIQUIDATED',
    Level4: 'LEVEL_4',
    Level4Suspended: 'LEVEL_4_SUSPENDED',
    Level4Liquidated: 'LEVEL_4_LIQUIDATED',
    Level5: 'LEVEL_5',
    Level5Suspended: 'LEVEL_5_SUSPENDED',
    Level5Liquidated: 'LEVEL_5_LIQUIDATED',
    Level99: 'LEVEL_99',
    Level99Suspended: 'LEVEL_99_SUSPENDED',
    Level99Liquidated: 'LEVEL_99_LIQUIDATED',
    Closed: 'CLOSED'
} as const;

export type ApiPartyLevelEnum = typeof ApiPartyLevelEnum[keyof typeof ApiPartyLevelEnum];
export const ApiPartyPartyTypeEnum = {
    Service: 'SERVICE',
    Consumer: 'CONSUMER',
    Merchant: 'MERCHANT'
} as const;

export type ApiPartyPartyTypeEnum = typeof ApiPartyPartyTypeEnum[keyof typeof ApiPartyPartyTypeEnum];
export const ApiPartyPhotoIdVerificationStatusEnum = {
    Locked: 'LOCKED',
    Pending: 'PENDING',
    VerificationFailed: 'VERIFICATION_FAILED',
    Verified: 'VERIFIED',
    Skipped: 'SKIPPED'
} as const;

export type ApiPartyPhotoIdVerificationStatusEnum = typeof ApiPartyPhotoIdVerificationStatusEnum[keyof typeof ApiPartyPhotoIdVerificationStatusEnum];
export const ApiPartyStatusEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED',
    Suspended: 'SUSPENDED',
    Liquidated: 'LIQUIDATED'
} as const;

export type ApiPartyStatusEnum = typeof ApiPartyStatusEnum[keyof typeof ApiPartyStatusEnum];
export const ApiPartyTaxIdVerificationStatusEnum = {
    Locked: 'LOCKED',
    Pending: 'PENDING',
    VerificationFailed: 'VERIFICATION_FAILED',
    Verified: 'VERIFIED',
    Skipped: 'SKIPPED'
} as const;

export type ApiPartyTaxIdVerificationStatusEnum = typeof ApiPartyTaxIdVerificationStatusEnum[keyof typeof ApiPartyTaxIdVerificationStatusEnum];

/**
 * 
 * @export
 * @interface ApiPendingBankAccount
 */
export interface ApiPendingBankAccount {
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'institutionLogo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'institutionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'institutionPrimaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'institutionUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'mask'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'partnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'partyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'pendingBankAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'verificationStatus'?: ApiPendingBankAccountVerificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPendingBankAccount
     */
    'verificationType'?: ApiPendingBankAccountVerificationTypeEnum;
}

export const ApiPendingBankAccountVerificationStatusEnum = {
    Complete: 'COMPLETE',
    Expired: 'EXPIRED',
    Pending: 'PENDING'
} as const;

export type ApiPendingBankAccountVerificationStatusEnum = typeof ApiPendingBankAccountVerificationStatusEnum[keyof typeof ApiPendingBankAccountVerificationStatusEnum];
export const ApiPendingBankAccountVerificationTypeEnum = {
    AutomatedMicrodeposit: 'AUTOMATED_MICRODEPOSIT',
    SameDayMicrodeposit: 'SAME_DAY_MICRODEPOSIT',
    ProcessorAutomaticVerification: 'PROCESSOR_AUTOMATIC_VERIFICATION',
    ProcessorManualVerification: 'PROCESSOR_MANUAL_VERIFICATION',
    Instant: 'INSTANT',
    Manual: 'MANUAL'
} as const;

export type ApiPendingBankAccountVerificationTypeEnum = typeof ApiPendingBankAccountVerificationTypeEnum[keyof typeof ApiPendingBankAccountVerificationTypeEnum];

/**
 * 
 * @export
 * @interface ApiPrincipalActivityDetail
 */
export interface ApiPrincipalActivityDetail {
    /**
     * 
     * @type {string}
     * @memberof ApiPrincipalActivityDetail
     */
    'fundingType'?: ApiPrincipalActivityDetailFundingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPrincipalActivityDetail
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPrincipalActivityDetail
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPrincipalActivityDetail
     */
    'transactionRef'?: string;
}

export const ApiPrincipalActivityDetailFundingTypeEnum = {
    Partner: 'PARTNER',
    Party: 'PARTY'
} as const;

export type ApiPrincipalActivityDetailFundingTypeEnum = typeof ApiPrincipalActivityDetailFundingTypeEnum[keyof typeof ApiPrincipalActivityDetailFundingTypeEnum];

/**
 * 
 * @export
 * @interface ApiReportQueue
 */
export interface ApiReportQueue {
    /**
     * 
     * @type {string}
     * @memberof ApiReportQueue
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReportQueue
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReportQueue
     */
    'partyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReportQueue
     */
    'reportType'?: ApiReportQueueReportTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiReportQueue
     */
    'reportYear'?: number;
}

export const ApiReportQueueReportTypeEnum = {
    AssetActivty: 'ASSET_ACTIVTY',
    CaesarsActivity: 'CAESARS_ACTIVITY',
    Chargeback: 'CHARGEBACK',
    Prefunding: 'PREFUNDING',
    VirtualCurrency: 'VIRTUAL_CURRENCY',
    Sift: 'SIFT',
    IimReconcilationReport: 'IIM_RECONCILATION_REPORT',
    MerchantReconciliationReport: 'MERCHANT_RECONCILIATION_REPORT',
    StarbucksReconciliationReport: 'STARBUCKS_RECONCILIATION_REPORT',
    PartnerSettlementReport: 'PARTNER_SETTLEMENT_REPORT',
    DisputeCredit: 'DISPUTE_CREDIT',
    AccountBalance: 'ACCOUNT_BALANCE',
    AccountBalanceV2: 'ACCOUNT_BALANCE_V2',
    AccountBalanceSummary: 'ACCOUNT_BALANCE_SUMMARY',
    AllCurrencyMtl: 'ALL_CURRENCY_MTL',
    Mtl: 'MTL',
    Adotmtl: 'ADOTMTL',
    BtcTransactionReport: 'BTC_TRANSACTION_REPORT',
    CryptoTransactionReport: 'CRYPTO_TRANSACTION_REPORT',
    PartnerPartyCryptoTransactionReport: 'PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT',
    SuspendAccount: 'SUSPEND_ACCOUNT',
    GiftCardRecon: 'GIFT_CARD_RECON',
    GiftCardRedeemRecon: 'GIFT_CARD_REDEEM_RECON',
    GiftCardReconMonth: 'GIFT_CARD_RECON_MONTH',
    GiftCardRedeemReconMonth: 'GIFT_CARD_REDEEM_RECON_MONTH',
    AchFileReturns: 'ACH_FILE_RETURNS',
    AchFileOutbound: 'ACH_FILE_OUTBOUND',
    PartnerReconciliationReport: 'PARTNER_RECONCILIATION_REPORT',
    Choice: 'CHOICE',
    Quiznos: 'QUIZNOS',
    PartnerIncentive: 'PARTNER_INCENTIVE',
    PartnerCashActivity: 'PARTNER_CASH_ACTIVITY',
    WireTransferActivityReview: 'WIRE_TRANSFER_ACTIVITY_REVIEW',
    PartnerCashActivityApex: 'PARTNER_CASH_ACTIVITY_APEX',
    PartnerCashBalance: 'PARTNER_CASH_BALANCE',
    PartyCsiOfacCheck: 'PARTY_CSI_OFAC_CHECK',
    FiservPaymentStatus: 'FISERV_PAYMENT_STATUS',
    DepositeBalanceCheckReport: 'DEPOSITE_BALANCE_CHECK_REPORT',
    NydfsCustomerReport: 'NYDFS_CUSTOMER_REPORT'
} as const;

export type ApiReportQueueReportTypeEnum = typeof ApiReportQueueReportTypeEnum[keyof typeof ApiReportQueueReportTypeEnum];

/**
 * 
 * @export
 * @interface ApiResendValidateIdentifierRequest
 */
export interface ApiResendValidateIdentifierRequest {
    /**
     * Encrypted identfier returned from the previous step in the sign up flow
     * @type {string}
     * @memberof ApiResendValidateIdentifierRequest
     */
    'identifierToken'?: string;
}
/**
 * 
 * @export
 * @interface ApiResetPasswordRequest
 */
export interface ApiResetPasswordRequest {
    /**
     * 
     * @type {ApiIdentifier}
     * @memberof ApiResetPasswordRequest
     */
    'identifier': ApiIdentifier;
}
/**
 * 
 * @export
 * @interface ApiResponseApiBankAccount
 */
export interface ApiResponseApiBankAccount {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiBankAccount
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiBankAccount
     */
    'message'?: string;
    /**
     * 
     * @type {ApiBankAccount}
     * @memberof ApiResponseApiBankAccount
     */
    'payload'?: ApiBankAccount;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiBankAccount
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiClientSession
 */
export interface ApiResponseApiClientSession {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiClientSession
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiClientSession
     */
    'message'?: string;
    /**
     * 
     * @type {ApiClientSession}
     * @memberof ApiResponseApiClientSession
     */
    'payload'?: ApiClientSession;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiClientSession
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiDebitCard
 */
export interface ApiResponseApiDebitCard {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiDebitCard
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiDebitCard
     */
    'message'?: string;
    /**
     * 
     * @type {ApiDebitCard}
     * @memberof ApiResponseApiDebitCard
     */
    'payload'?: ApiDebitCard;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiDebitCard
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedCaesarsCryptoTransactionResponse
 */
export interface ApiResponseApiEmbeddedCaesarsCryptoTransactionResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedCaesarsCryptoTransactionResponse}
     * @memberof ApiResponseApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'payload'?: ApiEmbeddedCaesarsCryptoTransactionResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedCaesarsCryptoTransactionResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedCaesarsTokenResponse
 */
export interface ApiResponseApiEmbeddedCaesarsTokenResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedCaesarsTokenResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedCaesarsTokenResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedCaesarsTokenResponse}
     * @memberof ApiResponseApiEmbeddedCaesarsTokenResponse
     */
    'payload'?: ApiEmbeddedCaesarsTokenResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedCaesarsTokenResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedCryptoExecutePriceResponse
 */
export interface ApiResponseApiEmbeddedCryptoExecutePriceResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedCryptoExecutePriceResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedCryptoExecutePriceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedCryptoExecutePriceResponse}
     * @memberof ApiResponseApiEmbeddedCryptoExecutePriceResponse
     */
    'payload'?: ApiEmbeddedCryptoExecutePriceResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedCryptoExecutePriceResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedCryptoPriceResponse
 */
export interface ApiResponseApiEmbeddedCryptoPriceResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedCryptoPriceResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedCryptoPriceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedCryptoPriceResponse}
     * @memberof ApiResponseApiEmbeddedCryptoPriceResponse
     */
    'payload'?: ApiEmbeddedCryptoPriceResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedCryptoPriceResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedCryptoTransactionResponse
 */
export interface ApiResponseApiEmbeddedCryptoTransactionResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedCryptoTransactionResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedCryptoTransactionResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedCryptoTransactionResponse}
     * @memberof ApiResponseApiEmbeddedCryptoTransactionResponse
     */
    'payload'?: ApiEmbeddedCryptoTransactionResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedCryptoTransactionResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedFundingTransferResponse
 */
export interface ApiResponseApiEmbeddedFundingTransferResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedFundingTransferResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedFundingTransferResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedFundingTransferResponse}
     * @memberof ApiResponseApiEmbeddedFundingTransferResponse
     */
    'payload'?: ApiEmbeddedFundingTransferResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedFundingTransferResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedPartnerLimitsResponse
 */
export interface ApiResponseApiEmbeddedPartnerLimitsResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedPartnerLimitsResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedPartnerLimitsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedPartnerLimitsResponse}
     * @memberof ApiResponseApiEmbeddedPartnerLimitsResponse
     */
    'payload'?: ApiEmbeddedPartnerLimitsResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedPartnerLimitsResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedPartnerPartyAccount
 */
export interface ApiResponseApiEmbeddedPartnerPartyAccount {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedPartnerPartyAccount
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedPartnerPartyAccount
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedPartnerPartyAccount}
     * @memberof ApiResponseApiEmbeddedPartnerPartyAccount
     */
    'payload'?: ApiEmbeddedPartnerPartyAccount;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedPartnerPartyAccount
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedPartnerPartyLinkInfo
 */
export interface ApiResponseApiEmbeddedPartnerPartyLinkInfo {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedPartnerPartyLinkInfo
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedPartnerPartyLinkInfo
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedPartnerPartyLinkInfo}
     * @memberof ApiResponseApiEmbeddedPartnerPartyLinkInfo
     */
    'payload'?: ApiEmbeddedPartnerPartyLinkInfo;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedPartnerPartyLinkInfo
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedPartnerPartyProfileResponse
 */
export interface ApiResponseApiEmbeddedPartnerPartyProfileResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedPartnerPartyProfileResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedPartnerPartyProfileResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedPartnerPartyProfileResponse}
     * @memberof ApiResponseApiEmbeddedPartnerPartyProfileResponse
     */
    'payload'?: ApiEmbeddedPartnerPartyProfileResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedPartnerPartyProfileResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedPartnerPartyTaxIdResponse
 */
export interface ApiResponseApiEmbeddedPartnerPartyTaxIdResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedPartnerPartyTaxIdResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedPartnerPartyTaxIdResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedPartnerPartyTaxIdResponse}
     * @memberof ApiResponseApiEmbeddedPartnerPartyTaxIdResponse
     */
    'payload'?: ApiEmbeddedPartnerPartyTaxIdResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedPartnerPartyTaxIdResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiEmbeddedPartnerTransaction
 */
export interface ApiResponseApiEmbeddedPartnerTransaction {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiEmbeddedPartnerTransaction
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiEmbeddedPartnerTransaction
     */
    'message'?: string;
    /**
     * 
     * @type {ApiEmbeddedPartnerTransaction}
     * @memberof ApiResponseApiEmbeddedPartnerTransaction
     */
    'payload'?: ApiEmbeddedPartnerTransaction;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiEmbeddedPartnerTransaction
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiKycDocumentVerificationDetailsResponse
 */
export interface ApiResponseApiKycDocumentVerificationDetailsResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiKycDocumentVerificationDetailsResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiKycDocumentVerificationDetailsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiKycDocumentVerificationDetailsResponse}
     * @memberof ApiResponseApiKycDocumentVerificationDetailsResponse
     */
    'payload'?: ApiKycDocumentVerificationDetailsResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiKycDocumentVerificationDetailsResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiLinkTokenResponse
 */
export interface ApiResponseApiLinkTokenResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiLinkTokenResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiLinkTokenResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiLinkTokenResponse}
     * @memberof ApiResponseApiLinkTokenResponse
     */
    'payload'?: ApiLinkTokenResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiLinkTokenResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartnerPartyLink
 */
export interface ApiResponseApiPartnerPartyLink {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartnerPartyLink
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartnerPartyLink
     */
    'message'?: string;
    /**
     * 
     * @type {ApiPartnerPartyLink}
     * @memberof ApiResponseApiPartnerPartyLink
     */
    'payload'?: ApiPartnerPartyLink;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartnerPartyLink
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartnerPartySamlProfileResponse
 */
export interface ApiResponseApiPartnerPartySamlProfileResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartnerPartySamlProfileResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartnerPartySamlProfileResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiPartnerPartySamlProfileResponse}
     * @memberof ApiResponseApiPartnerPartySamlProfileResponse
     */
    'payload'?: ApiPartnerPartySamlProfileResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartnerPartySamlProfileResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartnerRequestResponse
 */
export interface ApiResponseApiPartnerRequestResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartnerRequestResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartnerRequestResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ApiPartnerRequestResponse}
     * @memberof ApiResponseApiPartnerRequestResponse
     */
    'payload'?: ApiPartnerRequestResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartnerRequestResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiParty
 */
export interface ApiResponseApiParty {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiParty
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiParty
     */
    'message'?: string;
    /**
     * 
     * @type {ApiParty}
     * @memberof ApiResponseApiParty
     */
    'payload'?: ApiParty;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiParty
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseGetHistoricalCryptoCurrencyPriceResponse
 */
export interface ApiResponseGetHistoricalCryptoCurrencyPriceResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseGetHistoricalCryptoCurrencyPriceResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseGetHistoricalCryptoCurrencyPriceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetHistoricalCryptoCurrencyPriceResponse}
     * @memberof ApiResponseGetHistoricalCryptoCurrencyPriceResponse
     */
    'payload'?: GetHistoricalCryptoCurrencyPriceResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseGetHistoricalCryptoCurrencyPriceResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseIdentifier
 */
export interface ApiResponseIdentifier {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseIdentifier
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseIdentifier
     */
    'message'?: string;
    /**
     * 
     * @type {Identifier}
     * @memberof ApiResponseIdentifier
     */
    'payload'?: Identifier;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseIdentifier
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseLimitResponse
 */
export interface ApiResponseLimitResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseLimitResponse
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseLimitResponse
     */
    'message'?: string;
    /**
     * 
     * @type {LimitResponse}
     * @memberof ApiResponseLimitResponse
     */
    'payload'?: LimitResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseLimitResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiBankAccount
 */
export interface ApiResponseListApiBankAccount {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiBankAccount
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiBankAccount
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ApiBankAccount>}
     * @memberof ApiResponseListApiBankAccount
     */
    'payload'?: Array<ApiBankAccount>;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiBankAccount
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiEmbeddedFundingAccount
 */
export interface ApiResponseListApiEmbeddedFundingAccount {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiEmbeddedFundingAccount
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiEmbeddedFundingAccount
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ApiEmbeddedFundingAccount>}
     * @memberof ApiResponseListApiEmbeddedFundingAccount
     */
    'payload'?: Array<ApiEmbeddedFundingAccount>;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiEmbeddedFundingAccount
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiEmbeddedPartnerPartyAccount
 */
export interface ApiResponseListApiEmbeddedPartnerPartyAccount {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiEmbeddedPartnerPartyAccount
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiEmbeddedPartnerPartyAccount
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ApiEmbeddedPartnerPartyAccount>}
     * @memberof ApiResponseListApiEmbeddedPartnerPartyAccount
     */
    'payload'?: Array<ApiEmbeddedPartnerPartyAccount>;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiEmbeddedPartnerPartyAccount
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiPendingBankAccount
 */
export interface ApiResponseListApiPendingBankAccount {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiPendingBankAccount
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiPendingBankAccount
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ApiPendingBankAccount>}
     * @memberof ApiResponseListApiPendingBankAccount
     */
    'payload'?: Array<ApiPendingBankAccount>;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiPendingBankAccount
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiReportQueue
 */
export interface ApiResponseListApiReportQueue {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiReportQueue
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiReportQueue
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ApiReportQueue>}
     * @memberof ApiResponseListApiReportQueue
     */
    'payload'?: Array<ApiReportQueue>;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiReportQueue
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseObject
 */
export interface ApiResponseObject {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseObject
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseObject
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiResponseObject
     */
    'payload'?: object;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseObject
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiAssetActivity
 */
export interface ApiResponsePageApiAssetActivity {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiAssetActivity
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiAssetActivity
     */
    'message'?: string;
    /**
     * 
     * @type {PageApiAssetActivity}
     * @memberof ApiResponsePageApiAssetActivity
     */
    'payload'?: PageApiAssetActivity;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiAssetActivity
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiEmbeddedPartnerTransactionSummary
 */
export interface ApiResponsePageApiEmbeddedPartnerTransactionSummary {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiEmbeddedPartnerTransactionSummary
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiEmbeddedPartnerTransactionSummary
     */
    'message'?: string;
    /**
     * 
     * @type {PageApiEmbeddedPartnerTransactionSummary}
     * @memberof ApiResponsePageApiEmbeddedPartnerTransactionSummary
     */
    'payload'?: PageApiEmbeddedPartnerTransactionSummary;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiEmbeddedPartnerTransactionSummary
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiPartnerEvent
 */
export interface ApiResponsePageApiPartnerEvent {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiPartnerEvent
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiPartnerEvent
     */
    'message'?: string;
    /**
     * 
     * @type {PageApiPartnerEvent}
     * @memberof ApiResponsePageApiPartnerEvent
     */
    'payload'?: PageApiPartnerEvent;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiPartnerEvent
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseString
 */
export interface ApiResponseString {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseString
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseString
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseString
     */
    'payload'?: string;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseString
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseToken
 */
export interface ApiResponseToken {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseToken
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseToken
     */
    'message'?: string;
    /**
     * 
     * @type {Token}
     * @memberof ApiResponseToken
     */
    'payload'?: Token;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseToken
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseVoid
 */
export interface ApiResponseVoid {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseVoid
     */
    'error'?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseVoid
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiResponseVoid
     */
    'payload'?: object;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseVoid
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiValidateIdentifierRequest
 */
export interface ApiValidateIdentifierRequest {
    /**
     * Encrypted identifier returned from the previous step in the sign up flow
     * @type {string}
     * @memberof ApiValidateIdentifierRequest
     */
    'identifierToken'?: string;
    /**
     * Datetime when the consumer accepted the Bakkt Privacy Policy
     * @type {string}
     * @memberof ApiValidateIdentifierRequest
     */
    'privacyPolicyPublishTime'?: string;
    /**
     * Datetime when the consumer accepted the Bakkt Terms and Conditions
     * @type {string}
     * @memberof ApiValidateIdentifierRequest
     */
    'termsAndConditionsPublishTime'?: string;
}
/**
 * 
 * @export
 * @interface ApiValidateOTPRequest
 */
export interface ApiValidateOTPRequest {
    /**
     * Encrypted identfier returned from the previous step in the sign up flow
     * @type {string}
     * @memberof ApiValidateOTPRequest
     */
    'identifierToken'?: string;
    /**
     * OTP validation code, requested from the previous step in the sign up flow and received vie phone or email based on the identifier provided
     * @type {string}
     * @memberof ApiValidateOTPRequest
     */
    'validationCode'?: string;
}
/**
 * 
 * @export
 * @interface BasicPartnerInfo
 */
export interface BasicPartnerInfo {
    /**
     * The list of accepted accountTypes that are supported for this Partner
     * @type {Array<string>}
     * @memberof BasicPartnerInfo
     */
    'acceptedAccountTypes'?: Array<string>;
    /**
     * 
     * @type {ServiceContact}
     * @memberof BasicPartnerInfo
     */
    'contactInfo': ServiceContact;
    /**
     * The set of cryptoCurrencies that are supported for this Partner
     * @type {Array<string>}
     * @memberof BasicPartnerInfo
     */
    'cryptoCurrencies': Array<BasicPartnerInfoCryptoCurrenciesEnum>;
    /**
     * 
     * @type {Theme}
     * @memberof BasicPartnerInfo
     */
    'darkTheme'?: Theme;
    /**
     * 
     * @type {RedirectUrls}
     * @memberof BasicPartnerInfo
     */
    'desktopUrls'?: RedirectUrls;
    /**
     * 
     * @type {ApiEmbeddedPartnerFeatureFlag}
     * @memberof BasicPartnerInfo
     */
    'featureFlag'?: ApiEmbeddedPartnerFeatureFlag;
    /**
     * 
     * @type {string}
     * @memberof BasicPartnerInfo
     */
    'id': string;
    /**
     * Activity timeout in seconds for the partner
     * @type {number}
     * @memberof BasicPartnerInfo
     */
    'inactivityTimeoutInSeconds'?: number;
    /**
     * 
     * @type {Theme}
     * @memberof BasicPartnerInfo
     */
    'lightTheme'?: Theme;
    /**
     * The list of missing values that are supported for this Partner
     * @type {Array<string>}
     * @memberof BasicPartnerInfo
     */
    'missingFields'?: Array<BasicPartnerInfoMissingFieldsEnum>;
    /**
     * 
     * @type {RedirectUrls}
     * @memberof BasicPartnerInfo
     */
    'mobileUrls'?: RedirectUrls;
    /**
     * The name of the partner
     * @type {string}
     * @memberof BasicPartnerInfo
     */
    'name': string;
    /**
     * The set of service types that are supported for this Partner
     * @type {Array<string>}
     * @memberof BasicPartnerInfo
     */
    'serviceTypes': Array<BasicPartnerInfoServiceTypesEnum>;
    /**
     * 
     * @type {ApiPartnerSpeedbumpMessage}
     * @memberof BasicPartnerInfo
     */
    'speedbumpMessage'?: ApiPartnerSpeedbumpMessage;
    /**
     * The status of the partner
     * @type {string}
     * @memberof BasicPartnerInfo
     */
    'status': BasicPartnerInfoStatusEnum;
    /**
     * trusted
     * @type {boolean}
     * @memberof BasicPartnerInfo
     */
    'trusted': boolean;
}

export const BasicPartnerInfoCryptoCurrenciesEnum = {
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC'
} as const;

export type BasicPartnerInfoCryptoCurrenciesEnum = typeof BasicPartnerInfoCryptoCurrenciesEnum[keyof typeof BasicPartnerInfoCryptoCurrenciesEnum];
export const BasicPartnerInfoMissingFieldsEnum = {
    TaxIdNumber: 'TAX_ID_NUMBER',
    Occupation: 'OCCUPATION',
    FirstName: 'FIRST_NAME',
    LastName: 'LAST_NAME',
    Address: 'ADDRESS',
    Phone: 'PHONE',
    DateOfBirth: 'DATE_OF_BIRTH',
    Email: 'EMAIL'
} as const;

export type BasicPartnerInfoMissingFieldsEnum = typeof BasicPartnerInfoMissingFieldsEnum[keyof typeof BasicPartnerInfoMissingFieldsEnum];
export const BasicPartnerInfoServiceTypesEnum = {
    CryptoCurrency: 'CRYPTO_CURRENCY',
    Reward: 'REWARD',
    Incentive: 'INCENTIVE',
    Party: 'PARTY',
    Payout: 'PAYOUT',
    Loyalty: 'LOYALTY',
    FundTransfer: 'FUND_TRANSFER'
} as const;

export type BasicPartnerInfoServiceTypesEnum = typeof BasicPartnerInfoServiceTypesEnum[keyof typeof BasicPartnerInfoServiceTypesEnum];
export const BasicPartnerInfoStatusEnum = {
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED',
    Closed: 'CLOSED',
    Pending: 'PENDING'
} as const;

export type BasicPartnerInfoStatusEnum = typeof BasicPartnerInfoStatusEnum[keyof typeof BasicPartnerInfoStatusEnum];

/**
 * 
 * @export
 * @interface BasicPartnerPartyLink
 */
export interface BasicPartnerPartyLink {
    /**
     * 
     * @type {PartnerPartyLink}
     * @memberof BasicPartnerPartyLink
     */
    'partnerPartyLink'?: PartnerPartyLink;
    /**
     * 
     * @type {string}
     * @memberof BasicPartnerPartyLink
     */
    'partnerSsoRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicPartnerPartyLink
     */
    'tenant'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicPartnerPartyLink
     */
    'userName'?: string;
}
/**
 * 
 * @export
 * @interface BasicPartyInfo
 */
export interface BasicPartyInfo {
    /**
     * Bakkt ID. Customizable alias used to identify a Party.
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'alias'?: string;
    /**
     * Unique id given to Blaze for the Party.
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'brazePartyRef'?: string;
    /**
     * Email address, verified by OTP
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'email'?: string;
    /**
     * Flag true when EW9 recertification is required.
     * @type {boolean}
     * @memberof BasicPartyInfo
     */
    'ew9CertificationRequired'?: boolean;
    /**
     * First name
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'id'?: string;
    /**
     * Last name
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'lastName'?: string;
    /**
     * PartyLevel for the Party.
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'level'?: BasicPartyInfoLevelEnum;
    /**
     * Phone number, verified by OTP
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'phone'?: string;
    /**
     * Unique id given to Sardine for the Party.
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'sardinePartyRef'?: string;
    /**
     * Unique id given to Sift for the Party.
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'siftPartyRef'?: string;
    /**
     * PartyStatus for the Party.
     * @type {string}
     * @memberof BasicPartyInfo
     */
    'status'?: BasicPartyInfoStatusEnum;
    /**
     * Flag true when terms and conditions required.
     * @type {boolean}
     * @memberof BasicPartyInfo
     */
    'termsAndConditionAcceptanceRequired'?: boolean;
}

export const BasicPartyInfoLevelEnum = {
    Unknown: 'UNKNOWN',
    UnknownSuspended: 'UNKNOWN_SUSPENDED',
    Level1: 'LEVEL_1',
    Level1Suspended: 'LEVEL_1_SUSPENDED',
    Level1Liquidated: 'LEVEL_1_LIQUIDATED',
    Level2: 'LEVEL_2',
    Level2Suspended: 'LEVEL_2_SUSPENDED',
    Level2Liquidated: 'LEVEL_2_LIQUIDATED',
    Level3: 'LEVEL_3',
    Level3Suspended: 'LEVEL_3_SUSPENDED',
    Level3Liquidated: 'LEVEL_3_LIQUIDATED',
    Level4: 'LEVEL_4',
    Level4Suspended: 'LEVEL_4_SUSPENDED',
    Level4Liquidated: 'LEVEL_4_LIQUIDATED',
    Level5: 'LEVEL_5',
    Level5Suspended: 'LEVEL_5_SUSPENDED',
    Level5Liquidated: 'LEVEL_5_LIQUIDATED',
    Level99: 'LEVEL_99',
    Level99Suspended: 'LEVEL_99_SUSPENDED',
    Level99Liquidated: 'LEVEL_99_LIQUIDATED',
    Closed: 'CLOSED'
} as const;

export type BasicPartyInfoLevelEnum = typeof BasicPartyInfoLevelEnum[keyof typeof BasicPartyInfoLevelEnum];
export const BasicPartyInfoStatusEnum = {
    Open: 'OPEN',
    Closed: 'CLOSED',
    Suspended: 'SUSPENDED',
    Liquidated: 'LIQUIDATED'
} as const;

export type BasicPartyInfoStatusEnum = typeof BasicPartyInfoStatusEnum[keyof typeof BasicPartyInfoStatusEnum];

/**
 * 
 * @export
 * @interface CryptoCurrencyPriceResponse
 */
export interface CryptoCurrencyPriceResponse {
    /**
     * 
     * @type {CryptoOrFiatAmount}
     * @memberof CryptoCurrencyPriceResponse
     */
    'indicativePrice': CryptoOrFiatAmount;
    /**
     * 
     * @type {Array<CryptoCurrencyPriceWithSpread>}
     * @memberof CryptoCurrencyPriceResponse
     */
    'pricesWithSpreads'?: Array<CryptoCurrencyPriceWithSpread>;
}
/**
 * 
 * @export
 * @interface CryptoCurrencyPriceWithSpread
 */
export interface CryptoCurrencyPriceWithSpread {
    /**
     * 
     * @type {CryptoOrFiatAmount}
     * @memberof CryptoCurrencyPriceWithSpread
     */
    'buyPrice': CryptoOrFiatAmount;
    /**
     * 
     * @type {CryptoOrFiatAmount}
     * @memberof CryptoCurrencyPriceWithSpread
     */
    'end': CryptoOrFiatAmount;
    /**
     * 
     * @type {CryptoOrFiatAmount}
     * @memberof CryptoCurrencyPriceWithSpread
     */
    'sellPrice': CryptoOrFiatAmount;
    /**
     * 
     * @type {CryptoOrFiatAmount}
     * @memberof CryptoCurrencyPriceWithSpread
     */
    'start': CryptoOrFiatAmount;
}
/**
 * The price of one unit of cryptocurrency in fiat currency. As an example if one BTC was worth USD50,000 then buyPrice would be $50,000. This is used when selling cryptocurrency. 
 * @export
 * @interface CryptoOrFiatAmount
 */
export interface CryptoOrFiatAmount {
    /**
     * Value of the amount
     * @type {number}
     * @memberof CryptoOrFiatAmount
     */
    'amount'?: number;
    /**
     * Currency of the amount
     * @type {string}
     * @memberof CryptoOrFiatAmount
     */
    'currency'?: CryptoOrFiatAmountCurrencyEnum;
}

export const CryptoOrFiatAmountCurrencyEnum = {
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Usd: 'USD',
    Cet: 'CET',
    Lcp: 'LCP',
    Lko: 'LKO',
    Lmo: 'LMO',
    Lwr: 'LWR',
    Lxx: 'LXX',
    Led: 'LED'
} as const;

export type CryptoOrFiatAmountCurrencyEnum = typeof CryptoOrFiatAmountCurrencyEnum[keyof typeof CryptoOrFiatAmountCurrencyEnum];

/**
 * price plus fees
 * @export
 * @interface FiatAmount
 */
export interface FiatAmount {
    /**
     * Value of the amount
     * @type {number}
     * @memberof FiatAmount
     */
    'amount': number;
    /**
     * Currency of the amount
     * @type {string}
     * @memberof FiatAmount
     */
    'currency': FiatAmountCurrencyEnum;
}

export const FiatAmountCurrencyEnum = {
    Usd: 'USD'
} as const;

export type FiatAmountCurrencyEnum = typeof FiatAmountCurrencyEnum[keyof typeof FiatAmountCurrencyEnum];

/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'fieldName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    'fieldValues'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'searchCriteria'?: FilterSearchCriteriaEnum;
}

export const FilterSearchCriteriaEnum = {
    StartsWith: 'STARTS_WITH',
    EndsWith: 'ENDS_WITH',
    Contains: 'CONTAINS',
    NotContains: 'NOT_CONTAINS',
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    In: 'IN',
    Before: 'BEFORE',
    BeforeOrNull: 'BEFORE_OR_NULL',
    After: 'AFTER',
    AfterOrNull: 'AFTER_OR_NULL',
    Between: 'BETWEEN',
    RelativeTime: 'RELATIVE_TIME',
    LessThan: 'LESS_THAN',
    LessThanOrEqual: 'LESS_THAN_OR_EQUAL',
    LessThanOrEqualOrNull: 'LESS_THAN_OR_EQUAL_OR_NULL',
    GreaterThan: 'GREATER_THAN',
    GreaterThanOrEqual: 'GREATER_THAN_OR_EQUAL',
    GreaterThanOrEqualOrNull: 'GREATER_THAN_OR_EQUAL_OR_NULL',
    InRange: 'IN_RANGE',
    NotNull: 'NOT_NULL',
    NotIn: 'NOT_IN'
} as const;

export type FilterSearchCriteriaEnum = typeof FilterSearchCriteriaEnum[keyof typeof FilterSearchCriteriaEnum];

/**
 * 
 * @export
 * @interface GetHistoricalCryptoCurrencyPriceResponse
 */
export interface GetHistoricalCryptoCurrencyPriceResponse {
    /**
     * List of past prices.
     * @type {Array<HistoricalPrice>}
     * @memberof GetHistoricalCryptoCurrencyPriceResponse
     */
    'historicalPrices': Array<HistoricalPrice>;
    /**
     * The source currency to get the price history for.
     * @type {string}
     * @memberof GetHistoricalCryptoCurrencyPriceResponse
     */
    'sourceCurrency': GetHistoricalCryptoCurrencyPriceResponseSourceCurrencyEnum;
    /**
     * The target currency to get the price history for.
     * @type {string}
     * @memberof GetHistoricalCryptoCurrencyPriceResponse
     */
    'targetCurrency': GetHistoricalCryptoCurrencyPriceResponseTargetCurrencyEnum;
}

export const GetHistoricalCryptoCurrencyPriceResponseSourceCurrencyEnum = {
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC'
} as const;

export type GetHistoricalCryptoCurrencyPriceResponseSourceCurrencyEnum = typeof GetHistoricalCryptoCurrencyPriceResponseSourceCurrencyEnum[keyof typeof GetHistoricalCryptoCurrencyPriceResponseSourceCurrencyEnum];
export const GetHistoricalCryptoCurrencyPriceResponseTargetCurrencyEnum = {
    Usd: 'USD'
} as const;

export type GetHistoricalCryptoCurrencyPriceResponseTargetCurrencyEnum = typeof GetHistoricalCryptoCurrencyPriceResponseTargetCurrencyEnum[keyof typeof GetHistoricalCryptoCurrencyPriceResponseTargetCurrencyEnum];

/**
 * List of past prices.
 * @export
 * @interface HistoricalPrice
 */
export interface HistoricalPrice {
    /**
     * The price at a particular time.
     * @type {number}
     * @memberof HistoricalPrice
     */
    'price': number;
    /**
     * The time when the asset was this price.
     * @type {string}
     * @memberof HistoricalPrice
     */
    'time': string;
}
/**
 * 
 * @export
 * @interface Identifier
 */
export interface Identifier {
    /**
     * true|false to indicate that the given email or phone is available or not
     * @type {boolean}
     * @memberof Identifier
     */
    'available'?: boolean;
    /**
     * If available, this is the encrypted form of the email or phone
     * @type {string}
     * @memberof Identifier
     */
    'identifierToken'?: string;
    /**
     * For non-production environments the OTP will be passed back to the caller. Note this is most definitely *NOT* what we want in production but we\'re doing this for testing and to help with development for our non-US based folks
     * @type {string}
     * @memberof Identifier
     */
    'otp'?: string;
    /**
     * userId of sardine service for the party
     * @type {string}
     * @memberof Identifier
     */
    'sardinePartyRef'?: string;
    /**
     * Type of user : APP_USER, PARTNER_ENROLLED_USER, etc
     * @type {Array<string>}
     * @memberof Identifier
     */
    'userTypes'?: Array<IdentifierUserTypesEnum>;
}

export const IdentifierUserTypesEnum = {
    AppUser: 'APP_USER',
    PartnerEnrolledUser: 'PARTNER_ENROLLED_USER'
} as const;

export type IdentifierUserTypesEnum = typeof IdentifierUserTypesEnum[keyof typeof IdentifierUserTypesEnum];

/**
 * 
 * @export
 * @interface Limit
 */
export interface Limit {
    /**
     * The service type that this balance corresponds to
     * @type {string}
     * @memberof Limit
     */
    'category': LimitCategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof Limit
     */
    'daily': number;
    /**
     * Party\'s current available daily balance for this category
     * @type {number}
     * @memberof Limit
     */
    'dailyAvailable'?: number;
    /**
     * 
     * @type {number}
     * @memberof Limit
     */
    'monthly': number;
    /**
     * Party\'s current available monthly balance for this service type
     * @type {number}
     * @memberof Limit
     */
    'monthlyAvailable'?: number;
}

export const LimitCategoryEnum = {
    Cash: 'CASH',
    DigitalAssetConversions: 'DIGITAL_ASSET_CONVERSIONS'
} as const;

export type LimitCategoryEnum = typeof LimitCategoryEnum[keyof typeof LimitCategoryEnum];

/**
 * 
 * @export
 * @interface LimitResponse
 */
export interface LimitResponse {
    /**
     * 
     * @type {Array<Limit>}
     * @memberof LimitResponse
     */
    'limits'?: Array<Limit>;
    /**
     * 
     * @type {Array<MinimumAmount>}
     * @memberof LimitResponse
     */
    'minimums'?: Array<MinimumAmount>;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'userName'?: string;
}
/**
 * 
 * @export
 * @interface MinimumAmount
 */
export interface MinimumAmount {
    /**
     * 
     * @type {number}
     * @memberof MinimumAmount
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MinimumAmount
     */
    'currency'?: MinimumAmountCurrencyEnum;
}

export const MinimumAmountCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;

export type MinimumAmountCurrencyEnum = typeof MinimumAmountCurrencyEnum[keyof typeof MinimumAmountCurrencyEnum];

/**
 * 
 * @export
 * @interface PageApiAssetActivity
 */
export interface PageApiAssetActivity {
    /**
     * 
     * @type {Array<ApiAssetActivity>}
     * @memberof PageApiAssetActivity
     */
    'content'?: Array<ApiAssetActivity>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiAssetActivity
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiAssetActivity
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiAssetActivity
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiAssetActivity
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiAssetActivity
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiAssetActivity
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageApiAssetActivity
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageApiAssetActivity
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageApiAssetActivity
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiAssetActivity
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageApiEmbeddedPartnerTransactionSummary
 */
export interface PageApiEmbeddedPartnerTransactionSummary {
    /**
     * 
     * @type {Array<ApiEmbeddedPartnerTransactionSummary>}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'content'?: Array<ApiEmbeddedPartnerTransactionSummary>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiEmbeddedPartnerTransactionSummary
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageApiPartnerEvent
 */
export interface PageApiPartnerEvent {
    /**
     * 
     * @type {Array<ApiPartnerEvent>}
     * @memberof PageApiPartnerEvent
     */
    'content'?: Array<ApiPartnerEvent>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerEvent
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerEvent
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerEvent
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerEvent
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerEvent
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageApiPartnerEvent
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerEvent
     */
    'size'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageApiPartnerEvent
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerEvent
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerEvent
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface PartnerParty
 */
export interface PartnerParty {
    /**
     * 
     * @type {ApiAddress}
     * @memberof PartnerParty
     */
    'address'?: ApiAddress;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'ew9Signature'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'occupation'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'partnerPartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'privacyPolicyPublishTime'?: string;
    /**
     * Full Tax Identification Number applicable for the country - for US it is full SSN
     * @type {string}
     * @memberof PartnerParty
     */
    'taxIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerParty
     */
    'termsAndConditionsPublishTime'?: string;
}
/**
 * 
 * @export
 * @interface PartnerPartyLink
 */
export interface PartnerPartyLink {
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink
     */
    'partnerPartyRef'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink
     */
    'status'?: PartnerPartyLinkStatusEnum;
}

export const PartnerPartyLinkStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    OtpRequired: 'OTP_REQUIRED',
    Pending: 'PENDING',
    Transferred: 'TRANSFERRED',
    UnderReview: 'UNDER_REVIEW',
    Liquidated: 'LIQUIDATED',
    Closed: 'CLOSED'
} as const;

export type PartnerPartyLinkStatusEnum = typeof PartnerPartyLinkStatusEnum[keyof typeof PartnerPartyLinkStatusEnum];

/**
 * 
 * @export
 * @interface PublicApiError
 */
export interface PublicApiError {
    /**
     * Message from the exception
     * @type {string}
     * @memberof PublicApiError
     */
    'description'?: string;
    /**
     * Description of the type
     * @type {string}
     * @memberof PublicApiError
     */
    'detail'?: string;
    /**
     * 
     * @type {Array<PublicApiErrorDetail>}
     * @memberof PublicApiError
     */
    'details'?: Array<PublicApiErrorDetail>;
    /**
     * 
     * @type {string}
     * @memberof PublicApiError
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface PublicApiErrorDetail
 */
export interface PublicApiErrorDetail {
    /**
     * Human readable error description
     * @type {string}
     * @memberof PublicApiErrorDetail
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicApiErrorDetail
     */
    'field'?: string;
    /**
     * 
     * @type {object}
     * @memberof PublicApiErrorDetail
     */
    'rejectedValue'?: object;
    /**
     * 
     * @type {string}
     * @memberof PublicApiErrorDetail
     */
    'type'?: string;
}
/**
 * The URL to redirect user from desktop agent
 * @export
 * @interface RedirectUrls
 */
export interface RedirectUrls {
    /**
     * 
     * @type {string}
     * @memberof RedirectUrls
     */
    'failureUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectUrls
     */
    'successUrl'?: string;
}
/**
 * 
 * @export
 * @interface SearchParameters
 */
export interface SearchParameters {
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchParameters
     */
    'excludeFromSearchText'?: Set<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters
     */
    'filter'?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    'fromDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters
     */
    'searchText'?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters
     */
    'sort'?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    'toDate'?: number;
}
/**
 * The contact information for the partner
 * @export
 * @interface ServiceContact
 */
export interface ServiceContact {
    /**
     * 
     * @type {string}
     * @memberof ServiceContact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceContact
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceContact
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface SortBy
 */
export interface SortBy {
    /**
     * 
     * @type {string}
     * @memberof SortBy
     */
    'fieldName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SortBy
     */
    'sortOrder'?: SortBySortOrderEnum;
}

export const SortBySortOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type SortBySortOrderEnum = typeof SortBySortOrderEnum[keyof typeof SortBySortOrderEnum];

/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface Theme
 */
export interface Theme {
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'backgroundColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'backgroundFontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'emailLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'externalBackgroundColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'externalBackgroundFontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'faviconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'font'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'primaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'primaryFontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'secondaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'secondaryFontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'tertiaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'tertiaryFontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'themeName'?: ThemeThemeNameEnum;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'unvisitedLinkColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof Theme
     */
    'visitedLinkColor'?: string;
}

export const ThemeThemeNameEnum = {
    Light: 'LIGHT',
    Dark: 'DARK'
} as const;

export type ThemeThemeNameEnum = typeof ThemeThemeNameEnum[keyof typeof ThemeThemeNameEnum];

/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * Access token for the logged in Party
     * @type {string}
     * @memberof Token
     */
    'access_token'?: string;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    'expires_in'?: number;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    'expires_on'?: number;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'id_token'?: string;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    'not_before'?: number;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'token_type'?: string;
}

/**
 * EmbeddedPartnerAPIApi - axios parameter creator
 * @export
 */
export const EmbeddedPartnerAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accept Partner Request (Payout) from Party
         * @summary Accept Partner Request from Party
         * @param {string} partnerPartyLinkId 
         * @param {string} partnerRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPartnerRequest: async (partnerPartyLinkId: string, partnerRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerPartyLinkId' is not null or undefined
            assertParamExists('acceptPartnerRequest', 'partnerPartyLinkId', partnerPartyLinkId)
            // verify required parameter 'partnerRequestId' is not null or undefined
            assertParamExists('acceptPartnerRequest', 'partnerRequestId', partnerRequestId)
            const localVarPath = `/partner/embedded/party/partnerRequest/{partnerRequestId}/accept`
                .replace(`{${"partnerRequestId"}}`, encodeURIComponent(String(partnerRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerPartyLinkId !== undefined) {
                localVarQueryParameter['partnerPartyLinkId'] = partnerPartyLinkId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Api removes a user profile from redis cache when user exits the onboarding flow
         * @summary Delete profile from cache
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('deleteProfile', 'profileId', profileId)
            const localVarPath = `/partner/embedded/profile/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch profile for party enroll
         * @summary Fetch profile for party enroll
         * @param {ApiEmbeddedPartnerPartyEnrollRequest} apiEmbeddedPartnerPartyEnrollRequest 
         * @param {string} [xTrackingId] 
         * @param {string} [xUserAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollPartnerParty: async (apiEmbeddedPartnerPartyEnrollRequest: ApiEmbeddedPartnerPartyEnrollRequest, xTrackingId?: string, xUserAgent?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiEmbeddedPartnerPartyEnrollRequest' is not null or undefined
            assertParamExists('enrollPartnerParty', 'apiEmbeddedPartnerPartyEnrollRequest', apiEmbeddedPartnerPartyEnrollRequest)
            const localVarPath = `/partner/embedded/enroll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }

            if (xUserAgent != null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiEmbeddedPartnerPartyEnrollRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch accounts
         * @param {FetchAccountCryptoCurrencyEnum} cryptoCurrency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccount: async (cryptoCurrency: FetchAccountCryptoCurrencyEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cryptoCurrency' is not null or undefined
            assertParamExists('fetchAccount', 'cryptoCurrency', cryptoCurrency)
            const localVarPath = `/partner/embedded/account/{cryptoCurrency}`
                .replace(`{${"cryptoCurrency"}}`, encodeURIComponent(String(cryptoCurrency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch Full Taxpayer Identifier Number
         * @summary Fetch Full Taxpayer Identifier Number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFullTaxId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/profile/taxId/unmask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch accounts
         * @param {FetchFundingAccountsFiatCurrencyEnum} fiatCurrency The funding currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFundingAccounts: async (fiatCurrency: FetchFundingAccountsFiatCurrencyEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiatCurrency' is not null or undefined
            assertParamExists('fetchFundingAccounts', 'fiatCurrency', fiatCurrency)
            const localVarPath = `/partner/embedded/funding/account/{fiatCurrency}`
                .replace(`{${"fiatCurrency"}}`, encodeURIComponent(String(fiatCurrency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch partner limits
         * @summary Fetch partner limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerLimits: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch profile for party enroll
         * @summary Fetch profile for party enroll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerPartyProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch Partner Request
         * @summary Fetch Partner Request
         * @param {string} partnerRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerRequest: async (partnerRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerRequestId' is not null or undefined
            assertParamExists('fetchPartnerRequest', 'partnerRequestId', partnerRequestId)
            const localVarPath = `/partner/embedded/party/partnerRequest/{partnerRequestId}`
                .replace(`{${"partnerRequestId"}}`, encodeURIComponent(String(partnerRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<GetAssetActivitiesHistoryAssetTypesEnum>} [assetTypes] 
         * @param {Array<GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum>} [assetActivityTypeGroups] 
         * @param {Array<GetAssetActivitiesHistoryAssetActivityStatusEnum>} [assetActivityStatus] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<GetAssetActivitiesHistoryCurrencyEnum>} [currency] 
         * @param {string} [partnerId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetActivitiesHistory: async (assetTypes?: Array<GetAssetActivitiesHistoryAssetTypesEnum>, assetActivityTypeGroups?: Array<GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum>, assetActivityStatus?: Array<GetAssetActivitiesHistoryAssetActivityStatusEnum>, fromDate?: string, toDate?: string, currency?: Array<GetAssetActivitiesHistoryCurrencyEnum>, partnerId?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/assets/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetTypes) {
                localVarQueryParameter['assetTypes'] = assetTypes;
            }

            if (assetActivityTypeGroups) {
                localVarQueryParameter['assetActivityTypeGroups'] = assetActivityTypeGroups;
            }

            if (assetActivityStatus) {
                localVarQueryParameter['assetActivityStatus'] = assetActivityStatus;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (currency) {
                localVarQueryParameter['currency'] = currency;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the pricing between a fiat currency and all supported cryptocurrencies. Both buy and sell prices are returned.
         * @summary Retrieve Cryptocurrency Pricing
         * @param {GetCryptoCurrencyPricesCryptoCurrencyEnum} cryptoCurrency The cryptocurrency we want the price in
         * @param {GetCryptoCurrencyPricesFiatCurrencyEnum} fiatCurrency The price we want the cryptocurrency in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCryptoCurrencyPrices: async (cryptoCurrency: GetCryptoCurrencyPricesCryptoCurrencyEnum, fiatCurrency: GetCryptoCurrencyPricesFiatCurrencyEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cryptoCurrency' is not null or undefined
            assertParamExists('getCryptoCurrencyPrices', 'cryptoCurrency', cryptoCurrency)
            // verify required parameter 'fiatCurrency' is not null or undefined
            assertParamExists('getCryptoCurrencyPrices', 'fiatCurrency', fiatCurrency)
            const localVarPath = `/partner/embedded/cryptocurrency/price/{cryptoCurrency}/{fiatCurrency}`
                .replace(`{${"cryptoCurrency"}}`, encodeURIComponent(String(cryptoCurrency)))
                .replace(`{${"fiatCurrency"}}`, encodeURIComponent(String(fiatCurrency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to get user\'s document verification details
         * @summary Get document verification details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentVerificationDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/party/kyc/documentVerificationDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves value of currency converted from source to target.
         * @summary Retieve Execute Price
         * @param {ApiEmbeddedCryptoExecutePriceRequest} apiEmbeddedCryptoExecutePriceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutePrice: async (apiEmbeddedCryptoExecutePriceRequest: ApiEmbeddedCryptoExecutePriceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiEmbeddedCryptoExecutePriceRequest' is not null or undefined
            assertParamExists('getExecutePrice', 'apiEmbeddedCryptoExecutePriceRequest', apiEmbeddedCryptoExecutePriceRequest)
            const localVarPath = `/partner/embedded/cryptocurrency/execute-price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiEmbeddedCryptoExecutePriceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the historical prices of a given cryptocurrency for a given period.
         * @summary Retrieve Historical Cryptocurrency Price
         * @param {GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum} historicalPeriod Period for which to retrieve price data
         * @param {GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum} cryptoCurrency The cryptocurrency we\&#39;re looking for
         * @param {GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum} fiatCurrency The price we want the cryptocurrency in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricalCryptoCurrencyPrice: async (historicalPeriod: GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum, cryptoCurrency: GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum, fiatCurrency: GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'historicalPeriod' is not null or undefined
            assertParamExists('getHistoricalCryptoCurrencyPrice', 'historicalPeriod', historicalPeriod)
            // verify required parameter 'cryptoCurrency' is not null or undefined
            assertParamExists('getHistoricalCryptoCurrencyPrice', 'cryptoCurrency', cryptoCurrency)
            // verify required parameter 'fiatCurrency' is not null or undefined
            assertParamExists('getHistoricalCryptoCurrencyPrice', 'fiatCurrency', fiatCurrency)
            const localVarPath = `/partner/embedded/cryptocurrency/price/{historicalPeriod}/{cryptoCurrency}/{fiatCurrency}`
                .replace(`{${"historicalPeriod"}}`, encodeURIComponent(String(historicalPeriod)))
                .replace(`{${"cryptoCurrency"}}`, encodeURIComponent(String(cryptoCurrency)))
                .replace(`{${"fiatCurrency"}}`, encodeURIComponent(String(fiatCurrency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Api provides details to embedded web ui to make initial decision based on partner, party and link information
         * @summary Get Partner Party Link Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerPartyLinkInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/link/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to get user\'s details
         * @summary Get party details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParty: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/party`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {string} partyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartyLimits: async (partnerId: string, partyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getPartyLimits', 'partnerId', partnerId)
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getPartyLimits', 'partyId', partyId)
            const localVarPath = `/partner/embedded/party/limits/{partnerId}/{partyId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available taxId reports for year
         * @param {string} reportName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxReportDownloadLink: async (reportName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportName' is not null or undefined
            assertParamExists('getTaxReportDownloadLink', 'reportName', reportName)
            const localVarPath = `/partner/embedded/report/downloadLink/reportName/{reportName}`
                .replace(`{${"reportName"}}`, encodeURIComponent(String(reportName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of available taxId reports for year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxReportList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/report/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a single Cryptocurrency Transaction
         * @summary Retrieve Single Cryptocurrency Transaction
         * @param {string} partnerTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionDetails: async (partnerTransactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerTransactionId' is not null or undefined
            assertParamExists('getTransactionDetails', 'partnerTransactionId', partnerTransactionId)
            const localVarPath = `/partner/embedded/transaction/{partnerTransactionId}`
                .replace(`{${"partnerTransactionId"}}`, encodeURIComponent(String(partnerTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves Customers crypto currency transactions for a given period.
         * @summary Retrieves Customers crypto currency transactions
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionSummary: async (fromDate?: string, toDate?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/transaction/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Decrypt a Ceasars token and return contents.
         * @summary Decrypt a Caesars token.
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processToken: async (token?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/caesars/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accept Caesars token to receive points or remaining points
         * @summary Accept Caesars token
         * @param {ApiEmbeddedCaesarsCryptoTransactionRequest} apiEmbeddedCaesarsCryptoTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemPoints: async (apiEmbeddedCaesarsCryptoTransactionRequest: ApiEmbeddedCaesarsCryptoTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiEmbeddedCaesarsCryptoTransactionRequest' is not null or undefined
            assertParamExists('redeemPoints', 'apiEmbeddedCaesarsCryptoTransactionRequest', apiEmbeddedCaesarsCryptoTransactionRequest)
            const localVarPath = `/partner/embedded/caesars/redemption`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiEmbeddedCaesarsCryptoTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Buy or sell cryptocurrency for a given Party.
         * @summary Buy or Sell Cryptocurrency
         * @param {ApiEmbeddedCryptoTransactionRequest} apiEmbeddedCryptoTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactCryptoCurrency: async (apiEmbeddedCryptoTransactionRequest: ApiEmbeddedCryptoTransactionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiEmbeddedCryptoTransactionRequest' is not null or undefined
            assertParamExists('transactCryptoCurrency', 'apiEmbeddedCryptoTransactionRequest', apiEmbeddedCryptoTransactionRequest)
            const localVarPath = `/partner/embedded/transaction/cryptocurrency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiEmbeddedCryptoTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Identifier
         * @param {ApiEmbeddedPartyAddIdentifierRequest} apiEmbeddedPartyAddIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentifier: async (apiEmbeddedPartyAddIdentifierRequest: ApiEmbeddedPartyAddIdentifierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiEmbeddedPartyAddIdentifierRequest' is not null or undefined
            assertParamExists('updateIdentifier', 'apiEmbeddedPartyAddIdentifierRequest', apiEmbeddedPartyAddIdentifierRequest)
            const localVarPath = `/partner/embedded/party/identifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiEmbeddedPartyAddIdentifierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the first and last name along with address on a given party.
         * @summary Update Name and Address
         * @param {ApiEmbeddedPartyUpdateNameAndAddressRequest} apiEmbeddedPartyUpdateNameAndAddressRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNameAndAddress: async (apiEmbeddedPartyUpdateNameAndAddressRequest: ApiEmbeddedPartyUpdateNameAndAddressRequest, xTrackingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiEmbeddedPartyUpdateNameAndAddressRequest' is not null or undefined
            assertParamExists('updateNameAndAddress', 'apiEmbeddedPartyUpdateNameAndAddressRequest', apiEmbeddedPartyUpdateNameAndAddressRequest)
            const localVarPath = `/partner/embedded/party/name-and-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiEmbeddedPartyUpdateNameAndAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Api provides details to update auto confirm for partner async buy
         * @summary Update auto confirm for partner async buy
         * @param {string} partnerPartyLinkId 
         * @param {boolean} autoConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyAutoconfirm: async (partnerPartyLinkId: string, autoConfirm: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerPartyLinkId' is not null or undefined
            assertParamExists('updatePartnerPartyAutoconfirm', 'partnerPartyLinkId', partnerPartyLinkId)
            // verify required parameter 'autoConfirm' is not null or undefined
            assertParamExists('updatePartnerPartyAutoconfirm', 'autoConfirm', autoConfirm)
            const localVarPath = `/partner/embedded/link/autoConfirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerPartyLinkId !== undefined) {
                localVarQueryParameter['partnerPartyLinkId'] = partnerPartyLinkId;
            }

            if (autoConfirm !== undefined) {
                localVarQueryParameter['autoConfirm'] = autoConfirm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to update user\'s Ew9 acceptance details
         * @summary Update party Ew9 details
         * @param {ApiEmbeddedPartyUpdateEw9Request} apiEmbeddedPartyUpdateEw9Request 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParty: async (apiEmbeddedPartyUpdateEw9Request: ApiEmbeddedPartyUpdateEw9Request, xTrackingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiEmbeddedPartyUpdateEw9Request' is not null or undefined
            assertParamExists('updateParty', 'apiEmbeddedPartyUpdateEw9Request', apiEmbeddedPartyUpdateEw9Request)
            const localVarPath = `/partner/embedded/party/updateEw9`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiEmbeddedPartyUpdateEw9Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Latest Terms and Conditions Time for Party
         * @summary Update T&C Time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartyTermsAndConditions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/party/acceptTermsAndConditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API to update user\'s profile and verify the profile if already exists
         * @summary Update User\'s SAML Profile
         * @param {ApiPartnerPartyProfileUpdateRequest} apiPartnerPartyProfileUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAndUpdateProfile: async (apiPartnerPartyProfileUpdateRequest: ApiPartnerPartyProfileUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiPartnerPartyProfileUpdateRequest' is not null or undefined
            assertParamExists('verifyAndUpdateProfile', 'apiPartnerPartyProfileUpdateRequest', apiPartnerPartyProfileUpdateRequest)
            const localVarPath = `/partner/embedded/profile/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerPartyProfileUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmbeddedPartnerAPIApi - functional programming interface
 * @export
 */
export const EmbeddedPartnerAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmbeddedPartnerAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Accept Partner Request (Payout) from Party
         * @summary Accept Partner Request from Party
         * @param {string} partnerPartyLinkId 
         * @param {string} partnerRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptPartnerRequest(partnerPartyLinkId: string, partnerRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptPartnerRequest(partnerPartyLinkId, partnerRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Api removes a user profile from redis cache when user exits the onboarding flow
         * @summary Delete profile from cache
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfile(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfile(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch profile for party enroll
         * @summary Fetch profile for party enroll
         * @param {ApiEmbeddedPartnerPartyEnrollRequest} apiEmbeddedPartnerPartyEnrollRequest 
         * @param {string} [xTrackingId] 
         * @param {string} [xUserAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrollPartnerParty(apiEmbeddedPartnerPartyEnrollRequest: ApiEmbeddedPartnerPartyEnrollRequest, xTrackingId?: string, xUserAgent?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrollPartnerParty(apiEmbeddedPartnerPartyEnrollRequest, xTrackingId, xUserAgent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch accounts
         * @param {FetchAccountCryptoCurrencyEnum} cryptoCurrency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAccount(cryptoCurrency: FetchAccountCryptoCurrencyEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedPartnerPartyAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAccount(cryptoCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiEmbeddedPartnerPartyAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch Full Taxpayer Identifier Number
         * @summary Fetch Full Taxpayer Identifier Number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchFullTaxId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedPartnerPartyTaxIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchFullTaxId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch accounts
         * @param {FetchFundingAccountsFiatCurrencyEnum} fiatCurrency The funding currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchFundingAccounts(fiatCurrency: FetchFundingAccountsFiatCurrencyEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiEmbeddedFundingAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchFundingAccounts(fiatCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch partner limits
         * @summary Fetch partner limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerLimits(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedPartnerLimitsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartnerLimits(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch profile for party enroll
         * @summary Fetch profile for party enroll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerPartyProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedPartnerPartyProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartnerPartyProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch Partner Request
         * @summary Fetch Partner Request
         * @param {string} partnerRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerRequest(partnerRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartnerRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPartnerRequest(partnerRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<GetAssetActivitiesHistoryAssetTypesEnum>} [assetTypes] 
         * @param {Array<GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum>} [assetActivityTypeGroups] 
         * @param {Array<GetAssetActivitiesHistoryAssetActivityStatusEnum>} [assetActivityStatus] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<GetAssetActivitiesHistoryCurrencyEnum>} [currency] 
         * @param {string} [partnerId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetActivitiesHistory(assetTypes?: Array<GetAssetActivitiesHistoryAssetTypesEnum>, assetActivityTypeGroups?: Array<GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum>, assetActivityStatus?: Array<GetAssetActivitiesHistoryAssetActivityStatusEnum>, fromDate?: string, toDate?: string, currency?: Array<GetAssetActivitiesHistoryCurrencyEnum>, partnerId?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiAssetActivity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetActivitiesHistory(assetTypes, assetActivityTypeGroups, assetActivityStatus, fromDate, toDate, currency, partnerId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the pricing between a fiat currency and all supported cryptocurrencies. Both buy and sell prices are returned.
         * @summary Retrieve Cryptocurrency Pricing
         * @param {GetCryptoCurrencyPricesCryptoCurrencyEnum} cryptoCurrency The cryptocurrency we want the price in
         * @param {GetCryptoCurrencyPricesFiatCurrencyEnum} fiatCurrency The price we want the cryptocurrency in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCryptoCurrencyPrices(cryptoCurrency: GetCryptoCurrencyPricesCryptoCurrencyEnum, fiatCurrency: GetCryptoCurrencyPricesFiatCurrencyEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedCryptoPriceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCryptoCurrencyPrices(cryptoCurrency, fiatCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to get user\'s document verification details
         * @summary Get document verification details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentVerificationDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiKycDocumentVerificationDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentVerificationDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves value of currency converted from source to target.
         * @summary Retieve Execute Price
         * @param {ApiEmbeddedCryptoExecutePriceRequest} apiEmbeddedCryptoExecutePriceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExecutePrice(apiEmbeddedCryptoExecutePriceRequest: ApiEmbeddedCryptoExecutePriceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedCryptoExecutePriceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExecutePrice(apiEmbeddedCryptoExecutePriceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the historical prices of a given cryptocurrency for a given period.
         * @summary Retrieve Historical Cryptocurrency Price
         * @param {GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum} historicalPeriod Period for which to retrieve price data
         * @param {GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum} cryptoCurrency The cryptocurrency we\&#39;re looking for
         * @param {GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum} fiatCurrency The price we want the cryptocurrency in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoricalCryptoCurrencyPrice(historicalPeriod: GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum, cryptoCurrency: GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum, fiatCurrency: GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetHistoricalCryptoCurrencyPriceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricalCryptoCurrencyPrice(historicalPeriod, cryptoCurrency, fiatCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Api provides details to embedded web ui to make initial decision based on partner, party and link information
         * @summary Get Partner Party Link Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerPartyLinkInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedPartnerPartyLinkInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerPartyLinkInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to get user\'s details
         * @summary Get party details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParty(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiParty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParty(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {string} partyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartyLimits(partnerId: string, partyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseLimitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartyLimits(partnerId, partyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of available taxId reports for year
         * @param {string} reportName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxReportDownloadLink(reportName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxReportDownloadLink(reportName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of available taxId reports for year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxReportList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiReportQueue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxReportList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a single Cryptocurrency Transaction
         * @summary Retrieve Single Cryptocurrency Transaction
         * @param {string} partnerTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionDetails(partnerTransactionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedPartnerTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionDetails(partnerTransactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves Customers crypto currency transactions for a given period.
         * @summary Retrieves Customers crypto currency transactions
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionSummary(fromDate?: string, toDate?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiEmbeddedPartnerTransactionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionSummary(fromDate, toDate, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Decrypt a Ceasars token and return contents.
         * @summary Decrypt a Caesars token.
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processToken(token?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedCaesarsTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accept Caesars token to receive points or remaining points
         * @summary Accept Caesars token
         * @param {ApiEmbeddedCaesarsCryptoTransactionRequest} apiEmbeddedCaesarsCryptoTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemPoints(apiEmbeddedCaesarsCryptoTransactionRequest: ApiEmbeddedCaesarsCryptoTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedCaesarsCryptoTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemPoints(apiEmbeddedCaesarsCryptoTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Buy or sell cryptocurrency for a given Party.
         * @summary Buy or Sell Cryptocurrency
         * @param {ApiEmbeddedCryptoTransactionRequest} apiEmbeddedCryptoTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactCryptoCurrency(apiEmbeddedCryptoTransactionRequest: ApiEmbeddedCryptoTransactionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedCryptoTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactCryptoCurrency(apiEmbeddedCryptoTransactionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Identifier
         * @param {ApiEmbeddedPartyAddIdentifierRequest} apiEmbeddedPartyAddIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdentifier(apiEmbeddedPartyAddIdentifierRequest: ApiEmbeddedPartyAddIdentifierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdentifier(apiEmbeddedPartyAddIdentifierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the first and last name along with address on a given party.
         * @summary Update Name and Address
         * @param {ApiEmbeddedPartyUpdateNameAndAddressRequest} apiEmbeddedPartyUpdateNameAndAddressRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNameAndAddress(apiEmbeddedPartyUpdateNameAndAddressRequest: ApiEmbeddedPartyUpdateNameAndAddressRequest, xTrackingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNameAndAddress(apiEmbeddedPartyUpdateNameAndAddressRequest, xTrackingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Api provides details to update auto confirm for partner async buy
         * @summary Update auto confirm for partner async buy
         * @param {string} partnerPartyLinkId 
         * @param {boolean} autoConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnerPartyAutoconfirm(partnerPartyLinkId: string, autoConfirm: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartnerPartyLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnerPartyAutoconfirm(partnerPartyLinkId, autoConfirm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to update user\'s Ew9 acceptance details
         * @summary Update party Ew9 details
         * @param {ApiEmbeddedPartyUpdateEw9Request} apiEmbeddedPartyUpdateEw9Request 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParty(apiEmbeddedPartyUpdateEw9Request: ApiEmbeddedPartyUpdateEw9Request, xTrackingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParty(apiEmbeddedPartyUpdateEw9Request, xTrackingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Latest Terms and Conditions Time for Party
         * @summary Update T&C Time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartyTermsAndConditions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartyTermsAndConditions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API to update user\'s profile and verify the profile if already exists
         * @summary Update User\'s SAML Profile
         * @param {ApiPartnerPartyProfileUpdateRequest} apiPartnerPartyProfileUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyAndUpdateProfile(apiPartnerPartyProfileUpdateRequest: ApiPartnerPartyProfileUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartnerPartySamlProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyAndUpdateProfile(apiPartnerPartyProfileUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmbeddedPartnerAPIApi - factory interface
 * @export
 */
export const EmbeddedPartnerAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmbeddedPartnerAPIApiFp(configuration)
    return {
        /**
         * Accept Partner Request (Payout) from Party
         * @summary Accept Partner Request from Party
         * @param {string} partnerPartyLinkId 
         * @param {string} partnerRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPartnerRequest(partnerPartyLinkId: string, partnerRequestId: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.acceptPartnerRequest(partnerPartyLinkId, partnerRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Api removes a user profile from redis cache when user exits the onboarding flow
         * @summary Delete profile from cache
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile(profileId: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.deleteProfile(profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch profile for party enroll
         * @summary Fetch profile for party enroll
         * @param {ApiEmbeddedPartnerPartyEnrollRequest} apiEmbeddedPartnerPartyEnrollRequest 
         * @param {string} [xTrackingId] 
         * @param {string} [xUserAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollPartnerParty(apiEmbeddedPartnerPartyEnrollRequest: ApiEmbeddedPartnerPartyEnrollRequest, xTrackingId?: string, xUserAgent?: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.enrollPartnerParty(apiEmbeddedPartnerPartyEnrollRequest, xTrackingId, xUserAgent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch accounts
         * @param {FetchAccountCryptoCurrencyEnum} cryptoCurrency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccount(cryptoCurrency: FetchAccountCryptoCurrencyEnum, options?: any): AxiosPromise<ApiResponseApiEmbeddedPartnerPartyAccount> {
            return localVarFp.fetchAccount(cryptoCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccounts(options?: any): AxiosPromise<ApiResponseListApiEmbeddedPartnerPartyAccount> {
            return localVarFp.fetchAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch Full Taxpayer Identifier Number
         * @summary Fetch Full Taxpayer Identifier Number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFullTaxId(options?: any): AxiosPromise<ApiResponseApiEmbeddedPartnerPartyTaxIdResponse> {
            return localVarFp.fetchFullTaxId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch accounts
         * @param {FetchFundingAccountsFiatCurrencyEnum} fiatCurrency The funding currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFundingAccounts(fiatCurrency: FetchFundingAccountsFiatCurrencyEnum, options?: any): AxiosPromise<ApiResponseListApiEmbeddedFundingAccount> {
            return localVarFp.fetchFundingAccounts(fiatCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch partner limits
         * @summary Fetch partner limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerLimits(options?: any): AxiosPromise<ApiResponseApiEmbeddedPartnerLimitsResponse> {
            return localVarFp.fetchPartnerLimits(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch profile for party enroll
         * @summary Fetch profile for party enroll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerPartyProfile(options?: any): AxiosPromise<ApiResponseApiEmbeddedPartnerPartyProfileResponse> {
            return localVarFp.fetchPartnerPartyProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch Partner Request
         * @summary Fetch Partner Request
         * @param {string} partnerRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerRequest(partnerRequestId: string, options?: any): AxiosPromise<ApiResponseApiPartnerRequestResponse> {
            return localVarFp.fetchPartnerRequest(partnerRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<GetAssetActivitiesHistoryAssetTypesEnum>} [assetTypes] 
         * @param {Array<GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum>} [assetActivityTypeGroups] 
         * @param {Array<GetAssetActivitiesHistoryAssetActivityStatusEnum>} [assetActivityStatus] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {Array<GetAssetActivitiesHistoryCurrencyEnum>} [currency] 
         * @param {string} [partnerId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetActivitiesHistory(assetTypes?: Array<GetAssetActivitiesHistoryAssetTypesEnum>, assetActivityTypeGroups?: Array<GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum>, assetActivityStatus?: Array<GetAssetActivitiesHistoryAssetActivityStatusEnum>, fromDate?: string, toDate?: string, currency?: Array<GetAssetActivitiesHistoryCurrencyEnum>, partnerId?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<ApiResponsePageApiAssetActivity> {
            return localVarFp.getAssetActivitiesHistory(assetTypes, assetActivityTypeGroups, assetActivityStatus, fromDate, toDate, currency, partnerId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the pricing between a fiat currency and all supported cryptocurrencies. Both buy and sell prices are returned.
         * @summary Retrieve Cryptocurrency Pricing
         * @param {GetCryptoCurrencyPricesCryptoCurrencyEnum} cryptoCurrency The cryptocurrency we want the price in
         * @param {GetCryptoCurrencyPricesFiatCurrencyEnum} fiatCurrency The price we want the cryptocurrency in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCryptoCurrencyPrices(cryptoCurrency: GetCryptoCurrencyPricesCryptoCurrencyEnum, fiatCurrency: GetCryptoCurrencyPricesFiatCurrencyEnum, options?: any): AxiosPromise<ApiResponseApiEmbeddedCryptoPriceResponse> {
            return localVarFp.getCryptoCurrencyPrices(cryptoCurrency, fiatCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * API to get user\'s document verification details
         * @summary Get document verification details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentVerificationDetails(options?: any): AxiosPromise<ApiResponseApiKycDocumentVerificationDetailsResponse> {
            return localVarFp.getDocumentVerificationDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves value of currency converted from source to target.
         * @summary Retieve Execute Price
         * @param {ApiEmbeddedCryptoExecutePriceRequest} apiEmbeddedCryptoExecutePriceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutePrice(apiEmbeddedCryptoExecutePriceRequest: ApiEmbeddedCryptoExecutePriceRequest, options?: any): AxiosPromise<ApiResponseApiEmbeddedCryptoExecutePriceResponse> {
            return localVarFp.getExecutePrice(apiEmbeddedCryptoExecutePriceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the historical prices of a given cryptocurrency for a given period.
         * @summary Retrieve Historical Cryptocurrency Price
         * @param {GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum} historicalPeriod Period for which to retrieve price data
         * @param {GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum} cryptoCurrency The cryptocurrency we\&#39;re looking for
         * @param {GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum} fiatCurrency The price we want the cryptocurrency in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricalCryptoCurrencyPrice(historicalPeriod: GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum, cryptoCurrency: GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum, fiatCurrency: GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum, options?: any): AxiosPromise<ApiResponseGetHistoricalCryptoCurrencyPriceResponse> {
            return localVarFp.getHistoricalCryptoCurrencyPrice(historicalPeriod, cryptoCurrency, fiatCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * Api provides details to embedded web ui to make initial decision based on partner, party and link information
         * @summary Get Partner Party Link Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerPartyLinkInfo(options?: any): AxiosPromise<ApiResponseApiEmbeddedPartnerPartyLinkInfo> {
            return localVarFp.getPartnerPartyLinkInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * API to get user\'s details
         * @summary Get party details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParty(options?: any): AxiosPromise<ApiResponseApiParty> {
            return localVarFp.getParty(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partnerId 
         * @param {string} partyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartyLimits(partnerId: string, partyId: string, options?: any): AxiosPromise<ApiResponseLimitResponse> {
            return localVarFp.getPartyLimits(partnerId, partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of available taxId reports for year
         * @param {string} reportName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxReportDownloadLink(reportName: string, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.getTaxReportDownloadLink(reportName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of available taxId reports for year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxReportList(options?: any): AxiosPromise<ApiResponseListApiReportQueue> {
            return localVarFp.getTaxReportList(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a single Cryptocurrency Transaction
         * @summary Retrieve Single Cryptocurrency Transaction
         * @param {string} partnerTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionDetails(partnerTransactionId: string, options?: any): AxiosPromise<ApiResponseApiEmbeddedPartnerTransaction> {
            return localVarFp.getTransactionDetails(partnerTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves Customers crypto currency transactions for a given period.
         * @summary Retrieves Customers crypto currency transactions
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionSummary(fromDate?: string, toDate?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<ApiResponsePageApiEmbeddedPartnerTransactionSummary> {
            return localVarFp.getTransactionSummary(fromDate, toDate, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Decrypt a Ceasars token and return contents.
         * @summary Decrypt a Caesars token.
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processToken(token?: string, options?: any): AxiosPromise<ApiResponseApiEmbeddedCaesarsTokenResponse> {
            return localVarFp.processToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Accept Caesars token to receive points or remaining points
         * @summary Accept Caesars token
         * @param {ApiEmbeddedCaesarsCryptoTransactionRequest} apiEmbeddedCaesarsCryptoTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemPoints(apiEmbeddedCaesarsCryptoTransactionRequest: ApiEmbeddedCaesarsCryptoTransactionRequest, options?: any): AxiosPromise<ApiResponseApiEmbeddedCaesarsCryptoTransactionResponse> {
            return localVarFp.redeemPoints(apiEmbeddedCaesarsCryptoTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Buy or sell cryptocurrency for a given Party.
         * @summary Buy or Sell Cryptocurrency
         * @param {ApiEmbeddedCryptoTransactionRequest} apiEmbeddedCryptoTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactCryptoCurrency(apiEmbeddedCryptoTransactionRequest: ApiEmbeddedCryptoTransactionRequest, options?: any): AxiosPromise<ApiResponseApiEmbeddedCryptoTransactionResponse> {
            return localVarFp.transactCryptoCurrency(apiEmbeddedCryptoTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Identifier
         * @param {ApiEmbeddedPartyAddIdentifierRequest} apiEmbeddedPartyAddIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdentifier(apiEmbeddedPartyAddIdentifierRequest: ApiEmbeddedPartyAddIdentifierRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.updateIdentifier(apiEmbeddedPartyAddIdentifierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the first and last name along with address on a given party.
         * @summary Update Name and Address
         * @param {ApiEmbeddedPartyUpdateNameAndAddressRequest} apiEmbeddedPartyUpdateNameAndAddressRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNameAndAddress(apiEmbeddedPartyUpdateNameAndAddressRequest: ApiEmbeddedPartyUpdateNameAndAddressRequest, xTrackingId?: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.updateNameAndAddress(apiEmbeddedPartyUpdateNameAndAddressRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Api provides details to update auto confirm for partner async buy
         * @summary Update auto confirm for partner async buy
         * @param {string} partnerPartyLinkId 
         * @param {boolean} autoConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyAutoconfirm(partnerPartyLinkId: string, autoConfirm: boolean, options?: any): AxiosPromise<ApiResponseApiPartnerPartyLink> {
            return localVarFp.updatePartnerPartyAutoconfirm(partnerPartyLinkId, autoConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * API to update user\'s Ew9 acceptance details
         * @summary Update party Ew9 details
         * @param {ApiEmbeddedPartyUpdateEw9Request} apiEmbeddedPartyUpdateEw9Request 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParty(apiEmbeddedPartyUpdateEw9Request: ApiEmbeddedPartyUpdateEw9Request, xTrackingId?: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.updateParty(apiEmbeddedPartyUpdateEw9Request, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Latest Terms and Conditions Time for Party
         * @summary Update T&C Time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartyTermsAndConditions(options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.updatePartyTermsAndConditions(options).then((request) => request(axios, basePath));
        },
        /**
         * API to update user\'s profile and verify the profile if already exists
         * @summary Update User\'s SAML Profile
         * @param {ApiPartnerPartyProfileUpdateRequest} apiPartnerPartyProfileUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAndUpdateProfile(apiPartnerPartyProfileUpdateRequest: ApiPartnerPartyProfileUpdateRequest, options?: any): AxiosPromise<ApiResponseApiPartnerPartySamlProfileResponse> {
            return localVarFp.verifyAndUpdateProfile(apiPartnerPartyProfileUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmbeddedPartnerAPIApi - object-oriented interface
 * @export
 * @class EmbeddedPartnerAPIApi
 * @extends {BaseAPI}
 */
export class EmbeddedPartnerAPIApi extends BaseAPI {
    /**
     * Accept Partner Request (Payout) from Party
     * @summary Accept Partner Request from Party
     * @param {string} partnerPartyLinkId 
     * @param {string} partnerRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public acceptPartnerRequest(partnerPartyLinkId: string, partnerRequestId: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).acceptPartnerRequest(partnerPartyLinkId, partnerRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Api removes a user profile from redis cache when user exits the onboarding flow
     * @summary Delete profile from cache
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public deleteProfile(profileId: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).deleteProfile(profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch profile for party enroll
     * @summary Fetch profile for party enroll
     * @param {ApiEmbeddedPartnerPartyEnrollRequest} apiEmbeddedPartnerPartyEnrollRequest 
     * @param {string} [xTrackingId] 
     * @param {string} [xUserAgent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public enrollPartnerParty(apiEmbeddedPartnerPartyEnrollRequest: ApiEmbeddedPartnerPartyEnrollRequest, xTrackingId?: string, xUserAgent?: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).enrollPartnerParty(apiEmbeddedPartnerPartyEnrollRequest, xTrackingId, xUserAgent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch accounts
     * @param {FetchAccountCryptoCurrencyEnum} cryptoCurrency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public fetchAccount(cryptoCurrency: FetchAccountCryptoCurrencyEnum, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).fetchAccount(cryptoCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public fetchAccounts(options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).fetchAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch Full Taxpayer Identifier Number
     * @summary Fetch Full Taxpayer Identifier Number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public fetchFullTaxId(options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).fetchFullTaxId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch accounts
     * @param {FetchFundingAccountsFiatCurrencyEnum} fiatCurrency The funding currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public fetchFundingAccounts(fiatCurrency: FetchFundingAccountsFiatCurrencyEnum, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).fetchFundingAccounts(fiatCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch partner limits
     * @summary Fetch partner limits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public fetchPartnerLimits(options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).fetchPartnerLimits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch profile for party enroll
     * @summary Fetch profile for party enroll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public fetchPartnerPartyProfile(options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).fetchPartnerPartyProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch Partner Request
     * @summary Fetch Partner Request
     * @param {string} partnerRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public fetchPartnerRequest(partnerRequestId: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).fetchPartnerRequest(partnerRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<GetAssetActivitiesHistoryAssetTypesEnum>} [assetTypes] 
     * @param {Array<GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum>} [assetActivityTypeGroups] 
     * @param {Array<GetAssetActivitiesHistoryAssetActivityStatusEnum>} [assetActivityStatus] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {Array<GetAssetActivitiesHistoryCurrencyEnum>} [currency] 
     * @param {string} [partnerId] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getAssetActivitiesHistory(assetTypes?: Array<GetAssetActivitiesHistoryAssetTypesEnum>, assetActivityTypeGroups?: Array<GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum>, assetActivityStatus?: Array<GetAssetActivitiesHistoryAssetActivityStatusEnum>, fromDate?: string, toDate?: string, currency?: Array<GetAssetActivitiesHistoryCurrencyEnum>, partnerId?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getAssetActivitiesHistory(assetTypes, assetActivityTypeGroups, assetActivityStatus, fromDate, toDate, currency, partnerId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the pricing between a fiat currency and all supported cryptocurrencies. Both buy and sell prices are returned.
     * @summary Retrieve Cryptocurrency Pricing
     * @param {GetCryptoCurrencyPricesCryptoCurrencyEnum} cryptoCurrency The cryptocurrency we want the price in
     * @param {GetCryptoCurrencyPricesFiatCurrencyEnum} fiatCurrency The price we want the cryptocurrency in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getCryptoCurrencyPrices(cryptoCurrency: GetCryptoCurrencyPricesCryptoCurrencyEnum, fiatCurrency: GetCryptoCurrencyPricesFiatCurrencyEnum, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getCryptoCurrencyPrices(cryptoCurrency, fiatCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to get user\'s document verification details
     * @summary Get document verification details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getDocumentVerificationDetails(options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getDocumentVerificationDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves value of currency converted from source to target.
     * @summary Retieve Execute Price
     * @param {ApiEmbeddedCryptoExecutePriceRequest} apiEmbeddedCryptoExecutePriceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getExecutePrice(apiEmbeddedCryptoExecutePriceRequest: ApiEmbeddedCryptoExecutePriceRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getExecutePrice(apiEmbeddedCryptoExecutePriceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the historical prices of a given cryptocurrency for a given period.
     * @summary Retrieve Historical Cryptocurrency Price
     * @param {GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum} historicalPeriod Period for which to retrieve price data
     * @param {GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum} cryptoCurrency The cryptocurrency we\&#39;re looking for
     * @param {GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum} fiatCurrency The price we want the cryptocurrency in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getHistoricalCryptoCurrencyPrice(historicalPeriod: GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum, cryptoCurrency: GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum, fiatCurrency: GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getHistoricalCryptoCurrencyPrice(historicalPeriod, cryptoCurrency, fiatCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Api provides details to embedded web ui to make initial decision based on partner, party and link information
     * @summary Get Partner Party Link Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getPartnerPartyLinkInfo(options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getPartnerPartyLinkInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to get user\'s details
     * @summary Get party details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getParty(options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getParty(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partnerId 
     * @param {string} partyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getPartyLimits(partnerId: string, partyId: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getPartyLimits(partnerId, partyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of available taxId reports for year
     * @param {string} reportName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getTaxReportDownloadLink(reportName: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getTaxReportDownloadLink(reportName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of available taxId reports for year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getTaxReportList(options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getTaxReportList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a single Cryptocurrency Transaction
     * @summary Retrieve Single Cryptocurrency Transaction
     * @param {string} partnerTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getTransactionDetails(partnerTransactionId: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getTransactionDetails(partnerTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves Customers crypto currency transactions for a given period.
     * @summary Retrieves Customers crypto currency transactions
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public getTransactionSummary(fromDate?: string, toDate?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).getTransactionSummary(fromDate, toDate, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Decrypt a Ceasars token and return contents.
     * @summary Decrypt a Caesars token.
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public processToken(token?: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).processToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accept Caesars token to receive points or remaining points
     * @summary Accept Caesars token
     * @param {ApiEmbeddedCaesarsCryptoTransactionRequest} apiEmbeddedCaesarsCryptoTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public redeemPoints(apiEmbeddedCaesarsCryptoTransactionRequest: ApiEmbeddedCaesarsCryptoTransactionRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).redeemPoints(apiEmbeddedCaesarsCryptoTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Buy or sell cryptocurrency for a given Party.
     * @summary Buy or Sell Cryptocurrency
     * @param {ApiEmbeddedCryptoTransactionRequest} apiEmbeddedCryptoTransactionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public transactCryptoCurrency(apiEmbeddedCryptoTransactionRequest: ApiEmbeddedCryptoTransactionRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).transactCryptoCurrency(apiEmbeddedCryptoTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Identifier
     * @param {ApiEmbeddedPartyAddIdentifierRequest} apiEmbeddedPartyAddIdentifierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public updateIdentifier(apiEmbeddedPartyAddIdentifierRequest: ApiEmbeddedPartyAddIdentifierRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).updateIdentifier(apiEmbeddedPartyAddIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the first and last name along with address on a given party.
     * @summary Update Name and Address
     * @param {ApiEmbeddedPartyUpdateNameAndAddressRequest} apiEmbeddedPartyUpdateNameAndAddressRequest 
     * @param {string} [xTrackingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public updateNameAndAddress(apiEmbeddedPartyUpdateNameAndAddressRequest: ApiEmbeddedPartyUpdateNameAndAddressRequest, xTrackingId?: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).updateNameAndAddress(apiEmbeddedPartyUpdateNameAndAddressRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Api provides details to update auto confirm for partner async buy
     * @summary Update auto confirm for partner async buy
     * @param {string} partnerPartyLinkId 
     * @param {boolean} autoConfirm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public updatePartnerPartyAutoconfirm(partnerPartyLinkId: string, autoConfirm: boolean, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).updatePartnerPartyAutoconfirm(partnerPartyLinkId, autoConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to update user\'s Ew9 acceptance details
     * @summary Update party Ew9 details
     * @param {ApiEmbeddedPartyUpdateEw9Request} apiEmbeddedPartyUpdateEw9Request 
     * @param {string} [xTrackingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public updateParty(apiEmbeddedPartyUpdateEw9Request: ApiEmbeddedPartyUpdateEw9Request, xTrackingId?: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).updateParty(apiEmbeddedPartyUpdateEw9Request, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Latest Terms and Conditions Time for Party
     * @summary Update T&C Time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public updatePartyTermsAndConditions(options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).updatePartyTermsAndConditions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API to update user\'s profile and verify the profile if already exists
     * @summary Update User\'s SAML Profile
     * @param {ApiPartnerPartyProfileUpdateRequest} apiPartnerPartyProfileUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerAPIApi
     */
    public verifyAndUpdateProfile(apiPartnerPartyProfileUpdateRequest: ApiPartnerPartyProfileUpdateRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerAPIApiFp(this.configuration).verifyAndUpdateProfile(apiPartnerPartyProfileUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FetchAccountCryptoCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;
export type FetchAccountCryptoCurrencyEnum = typeof FetchAccountCryptoCurrencyEnum[keyof typeof FetchAccountCryptoCurrencyEnum];
/**
 * @export
 */
export const FetchFundingAccountsFiatCurrencyEnum = {
    Usd: 'USD'
} as const;
export type FetchFundingAccountsFiatCurrencyEnum = typeof FetchFundingAccountsFiatCurrencyEnum[keyof typeof FetchFundingAccountsFiatCurrencyEnum];
/**
 * @export
 */
export const GetAssetActivitiesHistoryAssetTypesEnum = {
    Cash: 'CASH',
    Crypto: 'CRYPTO',
    Rewards: 'REWARDS',
    GiftCard: 'GIFT_CARD',
    BakktCard: 'BAKKT_CARD',
    DebitCard: 'DEBIT_CARD'
} as const;
export type GetAssetActivitiesHistoryAssetTypesEnum = typeof GetAssetActivitiesHistoryAssetTypesEnum[keyof typeof GetAssetActivitiesHistoryAssetTypesEnum];
/**
 * @export
 */
export const GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum = {
    Transfer: 'TRANSFER',
    Transaction: 'TRANSACTION',
    Other: 'OTHER'
} as const;
export type GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum = typeof GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum[keyof typeof GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum];
/**
 * @export
 */
export const GetAssetActivitiesHistoryAssetActivityStatusEnum = {
    Canceled: 'CANCELED',
    Complete: 'COMPLETE',
    Expired: 'EXPIRED',
    Pending: 'PENDING',
    Rejected: 'REJECTED',
    Replaced: 'REPLACED',
    Failed: 'FAILED',
    Provisional: 'PROVISIONAL'
} as const;
export type GetAssetActivitiesHistoryAssetActivityStatusEnum = typeof GetAssetActivitiesHistoryAssetActivityStatusEnum[keyof typeof GetAssetActivitiesHistoryAssetActivityStatusEnum];
/**
 * @export
 */
export const GetAssetActivitiesHistoryCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;
export type GetAssetActivitiesHistoryCurrencyEnum = typeof GetAssetActivitiesHistoryCurrencyEnum[keyof typeof GetAssetActivitiesHistoryCurrencyEnum];
/**
 * @export
 */
export const GetCryptoCurrencyPricesCryptoCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;
export type GetCryptoCurrencyPricesCryptoCurrencyEnum = typeof GetCryptoCurrencyPricesCryptoCurrencyEnum[keyof typeof GetCryptoCurrencyPricesCryptoCurrencyEnum];
/**
 * @export
 */
export const GetCryptoCurrencyPricesFiatCurrencyEnum = {
    Usd: 'USD',
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC',
    Cet: 'CET',
    Lxx: 'LXX',
    Lcp: 'LCP',
    Lwr: 'LWR',
    Lmo: 'LMO',
    Lko: 'LKO',
    Led: 'LED'
} as const;
export type GetCryptoCurrencyPricesFiatCurrencyEnum = typeof GetCryptoCurrencyPricesFiatCurrencyEnum[keyof typeof GetCryptoCurrencyPricesFiatCurrencyEnum];
/**
 * @export
 */
export const GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum = {
    Year: 'YEAR',
    Month: 'MONTH',
    Week: 'WEEK',
    Day: 'DAY',
    Hour: 'HOUR'
} as const;
export type GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum = typeof GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum[keyof typeof GetHistoricalCryptoCurrencyPriceHistoricalPeriodEnum];
/**
 * @export
 */
export const GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum = {
    Bch: 'BCH',
    Btc: 'BTC',
    Doge: 'DOGE',
    Etc: 'ETC',
    Eth: 'ETH',
    Ltc: 'LTC',
    Shib: 'SHIB',
    Usdc: 'USDC'
} as const;
export type GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum = typeof GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum[keyof typeof GetHistoricalCryptoCurrencyPriceCryptoCurrencyEnum];
/**
 * @export
 */
export const GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum = {
    Usd: 'USD'
} as const;
export type GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum = typeof GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum[keyof typeof GetHistoricalCryptoCurrencyPriceFiatCurrencyEnum];


/**
 * EmbeddedPartnerDebitCardAPIApi - axios parameter creator
 * @export
 */
export const EmbeddedPartnerDebitCardAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new debit card client session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/debit-account/client-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update debit card status to expired
         * @param {string} debitCardExternalRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireDebitCardByPartyId: async (debitCardExternalRef: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'debitCardExternalRef' is not null or undefined
            assertParamExists('expireDebitCardByPartyId', 'debitCardExternalRef', debitCardExternalRef)
            const localVarPath = `/partner/embedded/debit-account/{debitCardExternalRef}/expire`
                .replace(`{${"debitCardExternalRef"}}`, encodeURIComponent(String(debitCardExternalRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link a new debit card
         * @param {ApiDebitCardLinkRequest} apiDebitCardLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkDebitCard: async (apiDebitCardLinkRequest: ApiDebitCardLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiDebitCardLinkRequest' is not null or undefined
            assertParamExists('linkDebitCard', 'apiDebitCardLinkRequest', apiDebitCardLinkRequest)
            const localVarPath = `/partner/embedded/debit-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiDebitCardLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unlink debit card
         * @param {string} debitCardExternalRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDebitCard: async (debitCardExternalRef: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'debitCardExternalRef' is not null or undefined
            assertParamExists('removeDebitCard', 'debitCardExternalRef', debitCardExternalRef)
            const localVarPath = `/partner/embedded/debit-account/{debitCardExternalRef}/remove`
                .replace(`{${"debitCardExternalRef"}}`, encodeURIComponent(String(debitCardExternalRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deposit from debit card
         * @param {TransactDebitCardAchTypeEnum} achType 
         * @param {ApiEmbeddedFundingTransferRequest} apiEmbeddedFundingTransferRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactDebitCard: async (achType: TransactDebitCardAchTypeEnum, apiEmbeddedFundingTransferRequest: ApiEmbeddedFundingTransferRequest, xTrackingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'achType' is not null or undefined
            assertParamExists('transactDebitCard', 'achType', achType)
            // verify required parameter 'apiEmbeddedFundingTransferRequest' is not null or undefined
            assertParamExists('transactDebitCard', 'apiEmbeddedFundingTransferRequest', apiEmbeddedFundingTransferRequest)
            const localVarPath = `/partner/embedded/debit-card/transact/{achType}`
                .replace(`{${"achType"}}`, encodeURIComponent(String(achType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiEmbeddedFundingTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate debit card and set to ACTIVE if amounts match
         * @param {ApiDebitCardVerificationRequest} apiDebitCardVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyDebitCard: async (apiDebitCardVerificationRequest: ApiDebitCardVerificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiDebitCardVerificationRequest' is not null or undefined
            assertParamExists('verifyDebitCard', 'apiDebitCardVerificationRequest', apiDebitCardVerificationRequest)
            const localVarPath = `/partner/embedded/debit-account/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiDebitCardVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmbeddedPartnerDebitCardAPIApi - functional programming interface
 * @export
 */
export const EmbeddedPartnerDebitCardAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmbeddedPartnerDebitCardAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new debit card client session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiClientSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update debit card status to expired
         * @param {string} debitCardExternalRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expireDebitCardByPartyId(debitCardExternalRef: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expireDebitCardByPartyId(debitCardExternalRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Link a new debit card
         * @param {ApiDebitCardLinkRequest} apiDebitCardLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkDebitCard(apiDebitCardLinkRequest: ApiDebitCardLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiDebitCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkDebitCard(apiDebitCardLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary unlink debit card
         * @param {string} debitCardExternalRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDebitCard(debitCardExternalRef: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiDebitCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDebitCard(debitCardExternalRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deposit from debit card
         * @param {TransactDebitCardAchTypeEnum} achType 
         * @param {ApiEmbeddedFundingTransferRequest} apiEmbeddedFundingTransferRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactDebitCard(achType: TransactDebitCardAchTypeEnum, apiEmbeddedFundingTransferRequest: ApiEmbeddedFundingTransferRequest, xTrackingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedFundingTransferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactDebitCard(achType, apiEmbeddedFundingTransferRequest, xTrackingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate debit card and set to ACTIVE if amounts match
         * @param {ApiDebitCardVerificationRequest} apiDebitCardVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyDebitCard(apiDebitCardVerificationRequest: ApiDebitCardVerificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiDebitCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyDebitCard(apiDebitCardVerificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmbeddedPartnerDebitCardAPIApi - factory interface
 * @export
 */
export const EmbeddedPartnerDebitCardAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmbeddedPartnerDebitCardAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new debit card client session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientSession(options?: any): AxiosPromise<ApiResponseApiClientSession> {
            return localVarFp.createClientSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update debit card status to expired
         * @param {string} debitCardExternalRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireDebitCardByPartyId(debitCardExternalRef: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.expireDebitCardByPartyId(debitCardExternalRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link a new debit card
         * @param {ApiDebitCardLinkRequest} apiDebitCardLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkDebitCard(apiDebitCardLinkRequest: ApiDebitCardLinkRequest, options?: any): AxiosPromise<ApiResponseApiDebitCard> {
            return localVarFp.linkDebitCard(apiDebitCardLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary unlink debit card
         * @param {string} debitCardExternalRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDebitCard(debitCardExternalRef: string, options?: any): AxiosPromise<ApiResponseApiDebitCard> {
            return localVarFp.removeDebitCard(debitCardExternalRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deposit from debit card
         * @param {TransactDebitCardAchTypeEnum} achType 
         * @param {ApiEmbeddedFundingTransferRequest} apiEmbeddedFundingTransferRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactDebitCard(achType: TransactDebitCardAchTypeEnum, apiEmbeddedFundingTransferRequest: ApiEmbeddedFundingTransferRequest, xTrackingId?: string, options?: any): AxiosPromise<ApiResponseApiEmbeddedFundingTransferResponse> {
            return localVarFp.transactDebitCard(achType, apiEmbeddedFundingTransferRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate debit card and set to ACTIVE if amounts match
         * @param {ApiDebitCardVerificationRequest} apiDebitCardVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyDebitCard(apiDebitCardVerificationRequest: ApiDebitCardVerificationRequest, options?: any): AxiosPromise<ApiResponseApiDebitCard> {
            return localVarFp.verifyDebitCard(apiDebitCardVerificationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmbeddedPartnerDebitCardAPIApi - object-oriented interface
 * @export
 * @class EmbeddedPartnerDebitCardAPIApi
 * @extends {BaseAPI}
 */
export class EmbeddedPartnerDebitCardAPIApi extends BaseAPI {
    /**
     * 
     * @summary Create new debit card client session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerDebitCardAPIApi
     */
    public createClientSession(options?: AxiosRequestConfig) {
        return EmbeddedPartnerDebitCardAPIApiFp(this.configuration).createClientSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update debit card status to expired
     * @param {string} debitCardExternalRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerDebitCardAPIApi
     */
    public expireDebitCardByPartyId(debitCardExternalRef: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerDebitCardAPIApiFp(this.configuration).expireDebitCardByPartyId(debitCardExternalRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link a new debit card
     * @param {ApiDebitCardLinkRequest} apiDebitCardLinkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerDebitCardAPIApi
     */
    public linkDebitCard(apiDebitCardLinkRequest: ApiDebitCardLinkRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerDebitCardAPIApiFp(this.configuration).linkDebitCard(apiDebitCardLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary unlink debit card
     * @param {string} debitCardExternalRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerDebitCardAPIApi
     */
    public removeDebitCard(debitCardExternalRef: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerDebitCardAPIApiFp(this.configuration).removeDebitCard(debitCardExternalRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deposit from debit card
     * @param {TransactDebitCardAchTypeEnum} achType 
     * @param {ApiEmbeddedFundingTransferRequest} apiEmbeddedFundingTransferRequest 
     * @param {string} [xTrackingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerDebitCardAPIApi
     */
    public transactDebitCard(achType: TransactDebitCardAchTypeEnum, apiEmbeddedFundingTransferRequest: ApiEmbeddedFundingTransferRequest, xTrackingId?: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerDebitCardAPIApiFp(this.configuration).transactDebitCard(achType, apiEmbeddedFundingTransferRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate debit card and set to ACTIVE if amounts match
     * @param {ApiDebitCardVerificationRequest} apiDebitCardVerificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerDebitCardAPIApi
     */
    public verifyDebitCard(apiDebitCardVerificationRequest: ApiDebitCardVerificationRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerDebitCardAPIApiFp(this.configuration).verifyDebitCard(apiDebitCardVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const TransactDebitCardAchTypeEnum = {
    Withdrawal: 'WITHDRAWAL',
    Deposit: 'DEPOSIT'
} as const;
export type TransactDebitCardAchTypeEnum = typeof TransactDebitCardAchTypeEnum[keyof typeof TransactDebitCardAchTypeEnum];


/**
 * EmbeddedPartnerSharedWalletAuthAPIApi - axios parameter creator
 * @export
 */
export const EmbeddedPartnerSharedWalletAuthAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available: async (apiAvailabilityRequest: ApiAvailabilityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiAvailabilityRequest' is not null or undefined
            assertParamExists('available', 'apiAvailabilityRequest', apiAvailabilityRequest)
            const localVarPath = `/partner/embedded/party/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAvailabilityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize: async (apiFinalizeRequest: ApiFinalizeRequest, xTrackingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('finalize', 'apiFinalizeRequest', apiFinalizeRequest)
            const localVarPath = `/partner/embedded/party/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginParty: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('loginParty', 'loginRequest', loginRequest)
            const localVarPath = `/partner/embedded/party/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp: async (apiValidateOTPRequest: ApiValidateOTPRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiValidateOTPRequest' is not null or undefined
            assertParamExists('otp', 'apiValidateOTPRequest', apiValidateOTPRequest)
            const localVarPath = `/partner/embedded/party/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateOTPRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend an OTP for the identifier given. This function will reuse the party created during validate. This call will invalidate any other OTPs that have been sent for this particular identifier. If the client passes the encryptedIdentifier from POST /party/available the system will NOT send the otp and the system will return the same encryptedIdentifier to the client without an error.
         * @summary Resend One Time Password (OTP)
         * @param {ApiResendValidateIdentifierRequest} apiResendValidateIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resend: async (apiResendValidateIdentifierRequest: ApiResendValidateIdentifierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiResendValidateIdentifierRequest' is not null or undefined
            assertParamExists('resend', 'apiResendValidateIdentifierRequest', apiResendValidateIdentifierRequest)
            const localVarPath = `/partner/embedded/party/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiResendValidateIdentifierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (apiResetPasswordRequest: ApiResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiResetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'apiResetPasswordRequest', apiResetPasswordRequest)
            const localVarPath = `/partner/embedded/party/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize: async (apiFinalizeRequest: ApiFinalizeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('resetPasswordFinalize', 'apiFinalizeRequest', apiFinalizeRequest)
            const localVarPath = `/partner/embedded/party/password/reset/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is step after consumer sign-up. This call is to send opt to mobile based on email identifier after reset password link expired
         * @summary Send OTP to user mobile
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordPhone: async (apiValidateOTPRequest: ApiValidateOTPRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiValidateOTPRequest' is not null or undefined
            assertParamExists('resetPasswordPhone', 'apiValidateOTPRequest', apiValidateOTPRequest)
            const localVarPath = `/partner/embedded/party/password/reset/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateOTPRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate: async (apiValidateIdentifierRequest: ApiValidateIdentifierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiValidateIdentifierRequest' is not null or undefined
            assertParamExists('validate', 'apiValidateIdentifierRequest', apiValidateIdentifierRequest)
            const localVarPath = `/partner/embedded/party/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateIdentifierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmbeddedPartnerSharedWalletAuthAPIApi - functional programming interface
 * @export
 */
export const EmbeddedPartnerSharedWalletAuthAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmbeddedPartnerSharedWalletAuthAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async available(apiAvailabilityRequest: ApiAvailabilityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.available(apiAvailabilityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalize(apiFinalizeRequest: ApiFinalizeRequest, xTrackingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalize(apiFinalizeRequest, xTrackingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginParty(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginParty(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async otp(apiValidateOTPRequest: ApiValidateOTPRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.otp(apiValidateOTPRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resend an OTP for the identifier given. This function will reuse the party created during validate. This call will invalidate any other OTPs that have been sent for this particular identifier. If the client passes the encryptedIdentifier from POST /party/available the system will NOT send the otp and the system will return the same encryptedIdentifier to the client without an error.
         * @summary Resend One Time Password (OTP)
         * @param {ApiResendValidateIdentifierRequest} apiResendValidateIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resend(apiResendValidateIdentifierRequest: ApiResendValidateIdentifierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resend(apiResendValidateIdentifierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(apiResetPasswordRequest: ApiResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(apiResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordFinalize(apiFinalizeRequest: ApiFinalizeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordFinalize(apiFinalizeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is step after consumer sign-up. This call is to send opt to mobile based on email identifier after reset password link expired
         * @summary Send OTP to user mobile
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordPhone(apiValidateOTPRequest: ApiValidateOTPRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordPhone(apiValidateOTPRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validate(apiValidateIdentifierRequest: ApiValidateIdentifierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validate(apiValidateIdentifierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmbeddedPartnerSharedWalletAuthAPIApi - factory interface
 * @export
 */
export const EmbeddedPartnerSharedWalletAuthAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmbeddedPartnerSharedWalletAuthAPIApiFp(configuration)
    return {
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available(apiAvailabilityRequest: ApiAvailabilityRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.available(apiAvailabilityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize(apiFinalizeRequest: ApiFinalizeRequest, xTrackingId?: string, options?: any): AxiosPromise<ApiResponseToken> {
            return localVarFp.finalize(apiFinalizeRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginParty(loginRequest: LoginRequest, options?: any): AxiosPromise<ApiResponseToken> {
            return localVarFp.loginParty(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp(apiValidateOTPRequest: ApiValidateOTPRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.otp(apiValidateOTPRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Resend an OTP for the identifier given. This function will reuse the party created during validate. This call will invalidate any other OTPs that have been sent for this particular identifier. If the client passes the encryptedIdentifier from POST /party/available the system will NOT send the otp and the system will return the same encryptedIdentifier to the client without an error.
         * @summary Resend One Time Password (OTP)
         * @param {ApiResendValidateIdentifierRequest} apiResendValidateIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resend(apiResendValidateIdentifierRequest: ApiResendValidateIdentifierRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.resend(apiResendValidateIdentifierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(apiResetPasswordRequest: ApiResetPasswordRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.resetPassword(apiResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize(apiFinalizeRequest: ApiFinalizeRequest, options?: any): AxiosPromise<ApiResponseToken> {
            return localVarFp.resetPasswordFinalize(apiFinalizeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This is step after consumer sign-up. This call is to send opt to mobile based on email identifier after reset password link expired
         * @summary Send OTP to user mobile
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordPhone(apiValidateOTPRequest: ApiValidateOTPRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.resetPasswordPhone(apiValidateOTPRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(apiValidateIdentifierRequest: ApiValidateIdentifierRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.validate(apiValidateIdentifierRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmbeddedPartnerSharedWalletAuthAPIApi - object-oriented interface
 * @export
 * @class EmbeddedPartnerSharedWalletAuthAPIApi
 * @extends {BaseAPI}
 */
export class EmbeddedPartnerSharedWalletAuthAPIApi extends BaseAPI {
    /**
     * First step in consumer sign-up. Verifies that the identifier in request is available for use
     * @summary Check Party identifier availability
     * @param {ApiAvailabilityRequest} apiAvailabilityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerSharedWalletAuthAPIApi
     */
    public available(apiAvailabilityRequest: ApiAvailabilityRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerSharedWalletAuthAPIApiFp(this.configuration).available(apiAvailabilityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
     * @summary Finalize Party Sign Up
     * @param {ApiFinalizeRequest} apiFinalizeRequest 
     * @param {string} [xTrackingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerSharedWalletAuthAPIApi
     */
    public finalize(apiFinalizeRequest: ApiFinalizeRequest, xTrackingId?: string, options?: AxiosRequestConfig) {
        return EmbeddedPartnerSharedWalletAuthAPIApiFp(this.configuration).finalize(apiFinalizeRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerSharedWalletAuthAPIApi
     */
    public loginParty(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerSharedWalletAuthAPIApiFp(this.configuration).loginParty(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
     * @summary Validate One Time Password (OTP)
     * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerSharedWalletAuthAPIApi
     */
    public otp(apiValidateOTPRequest: ApiValidateOTPRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerSharedWalletAuthAPIApiFp(this.configuration).otp(apiValidateOTPRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resend an OTP for the identifier given. This function will reuse the party created during validate. This call will invalidate any other OTPs that have been sent for this particular identifier. If the client passes the encryptedIdentifier from POST /party/available the system will NOT send the otp and the system will return the same encryptedIdentifier to the client without an error.
     * @summary Resend One Time Password (OTP)
     * @param {ApiResendValidateIdentifierRequest} apiResendValidateIdentifierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerSharedWalletAuthAPIApi
     */
    public resend(apiResendValidateIdentifierRequest: ApiResendValidateIdentifierRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerSharedWalletAuthAPIApiFp(this.configuration).resend(apiResendValidateIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
     * @summary Start Reset Password
     * @param {ApiResetPasswordRequest} apiResetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerSharedWalletAuthAPIApi
     */
    public resetPassword(apiResetPasswordRequest: ApiResetPasswordRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerSharedWalletAuthAPIApiFp(this.configuration).resetPassword(apiResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Second and final step when resetting a password.
     * @summary Finalize Reset Password
     * @param {ApiFinalizeRequest} apiFinalizeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerSharedWalletAuthAPIApi
     */
    public resetPasswordFinalize(apiFinalizeRequest: ApiFinalizeRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerSharedWalletAuthAPIApiFp(this.configuration).resetPasswordFinalize(apiFinalizeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is step after consumer sign-up. This call is to send opt to mobile based on email identifier after reset password link expired
     * @summary Send OTP to user mobile
     * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerSharedWalletAuthAPIApi
     */
    public resetPasswordPhone(apiValidateOTPRequest: ApiValidateOTPRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerSharedWalletAuthAPIApiFp(this.configuration).resetPasswordPhone(apiValidateOTPRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
     * @summary Send One Time Password (OTP)
     * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddedPartnerSharedWalletAuthAPIApi
     */
    public validate(apiValidateIdentifierRequest: ApiValidateIdentifierRequest, options?: AxiosRequestConfig) {
        return EmbeddedPartnerSharedWalletAuthAPIApiFp(this.configuration).validate(apiValidateIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PartnerEventControllerApi - axios parameter creator
 * @export
 */
export const PartnerEventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search Partner Events
         * @param {SearchParameters} searchParameters 
         * @param {boolean} [includeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartnerEvents: async (searchParameters: SearchParameters, includeRequest?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchPartnerEvents', 'searchParameters', searchParameters)
            const localVarPath = `/partner/embedded/events/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeRequest !== undefined) {
                localVarQueryParameter['includeRequest'] = includeRequest;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Partner Events
         * @param {SearchParameters} searchParameters 
         * @param {boolean} [includeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartnerEvents2: async (searchParameters: SearchParameters, includeRequest?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchPartnerEvents2', 'searchParameters', searchParameters)
            const localVarPath = `/partner/embedded/events/search2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeRequest !== undefined) {
                localVarQueryParameter['includeRequest'] = includeRequest;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerEventControllerApi - functional programming interface
 * @export
 */
export const PartnerEventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerEventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search Partner Events
         * @param {SearchParameters} searchParameters 
         * @param {boolean} [includeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPartnerEvents(searchParameters: SearchParameters, includeRequest?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartnerEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPartnerEvents(searchParameters, includeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Partner Events
         * @param {SearchParameters} searchParameters 
         * @param {boolean} [includeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPartnerEvents2(searchParameters: SearchParameters, includeRequest?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartnerEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPartnerEvents2(searchParameters, includeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerEventControllerApi - factory interface
 * @export
 */
export const PartnerEventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerEventControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search Partner Events
         * @param {SearchParameters} searchParameters 
         * @param {boolean} [includeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartnerEvents(searchParameters: SearchParameters, includeRequest?: boolean, options?: any): AxiosPromise<ApiResponsePageApiPartnerEvent> {
            return localVarFp.searchPartnerEvents(searchParameters, includeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Partner Events
         * @param {SearchParameters} searchParameters 
         * @param {boolean} [includeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartnerEvents2(searchParameters: SearchParameters, includeRequest?: boolean, options?: any): AxiosPromise<ApiResponsePageApiPartnerEvent> {
            return localVarFp.searchPartnerEvents2(searchParameters, includeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerEventControllerApi - object-oriented interface
 * @export
 * @class PartnerEventControllerApi
 * @extends {BaseAPI}
 */
export class PartnerEventControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search Partner Events
     * @param {SearchParameters} searchParameters 
     * @param {boolean} [includeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEventControllerApi
     */
    public searchPartnerEvents(searchParameters: SearchParameters, includeRequest?: boolean, options?: AxiosRequestConfig) {
        return PartnerEventControllerApiFp(this.configuration).searchPartnerEvents(searchParameters, includeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Partner Events
     * @param {SearchParameters} searchParameters 
     * @param {boolean} [includeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEventControllerApi
     */
    public searchPartnerEvents2(searchParameters: SearchParameters, includeRequest?: boolean, options?: AxiosRequestConfig) {
        return PartnerEventControllerApiFp(this.configuration).searchPartnerEvents2(searchParameters, includeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PartnerPartyBankAccountApi - axios parameter creator
 * @export
 */
export const PartnerPartyBankAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Links/Relink a new bank account via Plaid. Calls Plaid to get the account details, creates the account in AoF and fetches the Plaid Institution for the account if we don\'t have it already or if it\'s out of date.
         * @summary Create or Update Bank Account
         * @param {ApiCreateBankAccountRequest} apiCreateBankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateBankAccount: async (apiCreateBankAccountRequest: ApiCreateBankAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiCreateBankAccountRequest' is not null or undefined
            assertParamExists('createOrUpdateBankAccount', 'apiCreateBankAccountRequest', apiCreateBankAccountRequest)
            const localVarPath = `/partner/embedded/bank-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCreateBankAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expirePendingBankAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/bank-account/pending-bank-account/expire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of accounts that have been linked in Plaid (i.e. account.plaid is non-null) for a given status, if any.  If status is not passed the active and all suspended Plaid accounts will be returned.
         * @summary Get Bank Accounts
         * @param {GetBankAccountsStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccounts: async (status?: GetBankAccountsStatusEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/bank-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * new type of token that is created by your app\'s server and passed to your app\'s client to initialize Link.
         * @summary Get Link Token
         * @param {ApiLinkTokenRequest} apiLinkTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToken: async (apiLinkTokenRequest: ApiLinkTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiLinkTokenRequest' is not null or undefined
            assertParamExists('getLinkToken', 'apiLinkTokenRequest', apiLinkTokenRequest)
            const localVarPath = `/partner/embedded/link-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiLinkTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank accounts pending verification.
         * @param {Set<GetPendingBankAccountsVerificationStatusEnum>} [verificationStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingBankAccounts: async (verificationStatus?: Set<GetPendingBankAccountsVerificationStatusEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/embedded/bank-account/pending-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (verificationStatus) {
                localVarQueryParameter['verificationStatus'] = Array.from(verificationStatus);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Bank Account
         * @param {string} externalAccountRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBankAccountByExternalAccountRef: async (externalAccountRef: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalAccountRef' is not null or undefined
            assertParamExists('removeBankAccountByExternalAccountRef', 'externalAccountRef', externalAccountRef)
            const localVarPath = `/partner/embedded/bank-account/{externalAccountRef}/remove`
                .replace(`{${"externalAccountRef"}}`, encodeURIComponent(String(externalAccountRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transact Bank Account
         * @param {TransactBankAccountAchTypeEnum} achType 
         * @param {ApiEmbeddedFundingTransferRequest} apiEmbeddedFundingTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactBankAccount: async (achType: TransactBankAccountAchTypeEnum, apiEmbeddedFundingTransferRequest: ApiEmbeddedFundingTransferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'achType' is not null or undefined
            assertParamExists('transactBankAccount', 'achType', achType)
            // verify required parameter 'apiEmbeddedFundingTransferRequest' is not null or undefined
            assertParamExists('transactBankAccount', 'apiEmbeddedFundingTransferRequest', apiEmbeddedFundingTransferRequest)
            const localVarPath = `/partner/embedded/bank-account/transact/{achType}`
                .replace(`{${"achType"}}`, encodeURIComponent(String(achType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiEmbeddedFundingTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the LinkStatus between the bank account and plaid
         * @summary Update LinkStatus for the BankAccount
         * @param {ApiBankAccountLinkStatus} apiBankAccountLinkStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountLinkStatus: async (apiBankAccountLinkStatus: ApiBankAccountLinkStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiBankAccountLinkStatus' is not null or undefined
            assertParamExists('updateAccountLinkStatus', 'apiBankAccountLinkStatus', apiBankAccountLinkStatus)
            const localVarPath = `/partner/embedded/bank-account/link-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiBankAccountLinkStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify and Activate Pending Bank Account
         * @param {ApiBankAccountLinkStatusUpdateRequest} apiBankAccountLinkStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAndActivatePendingBankAccount: async (apiBankAccountLinkStatusUpdateRequest: ApiBankAccountLinkStatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiBankAccountLinkStatusUpdateRequest' is not null or undefined
            assertParamExists('verifyAndActivatePendingBankAccount', 'apiBankAccountLinkStatusUpdateRequest', apiBankAccountLinkStatusUpdateRequest)
            const localVarPath = `/partner/embedded/bank-account/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiBankAccountLinkStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerPartyBankAccountApi - functional programming interface
 * @export
 */
export const PartnerPartyBankAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerPartyBankAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * Links/Relink a new bank account via Plaid. Calls Plaid to get the account details, creates the account in AoF and fetches the Plaid Institution for the account if we don\'t have it already or if it\'s out of date.
         * @summary Create or Update Bank Account
         * @param {ApiCreateBankAccountRequest} apiCreateBankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateBankAccount(apiCreateBankAccountRequest: ApiCreateBankAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateBankAccount(apiCreateBankAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expirePendingBankAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expirePendingBankAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of accounts that have been linked in Plaid (i.e. account.plaid is non-null) for a given status, if any.  If status is not passed the active and all suspended Plaid accounts will be returned.
         * @summary Get Bank Accounts
         * @param {GetBankAccountsStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccounts(status?: GetBankAccountsStatusEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiBankAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankAccounts(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * new type of token that is created by your app\'s server and passed to your app\'s client to initialize Link.
         * @summary Get Link Token
         * @param {ApiLinkTokenRequest} apiLinkTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkToken(apiLinkTokenRequest: ApiLinkTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiLinkTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkToken(apiLinkTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bank accounts pending verification.
         * @param {Set<GetPendingBankAccountsVerificationStatusEnum>} [verificationStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingBankAccounts(verificationStatus?: Set<GetPendingBankAccountsVerificationStatusEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiPendingBankAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingBankAccounts(verificationStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Bank Account
         * @param {string} externalAccountRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeBankAccountByExternalAccountRef(externalAccountRef: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiBankAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeBankAccountByExternalAccountRef(externalAccountRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transact Bank Account
         * @param {TransactBankAccountAchTypeEnum} achType 
         * @param {ApiEmbeddedFundingTransferRequest} apiEmbeddedFundingTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactBankAccount(achType: TransactBankAccountAchTypeEnum, apiEmbeddedFundingTransferRequest: ApiEmbeddedFundingTransferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiEmbeddedFundingTransferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactBankAccount(achType, apiEmbeddedFundingTransferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the LinkStatus between the bank account and plaid
         * @summary Update LinkStatus for the BankAccount
         * @param {ApiBankAccountLinkStatus} apiBankAccountLinkStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountLinkStatus(apiBankAccountLinkStatus: ApiBankAccountLinkStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountLinkStatus(apiBankAccountLinkStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify and Activate Pending Bank Account
         * @param {ApiBankAccountLinkStatusUpdateRequest} apiBankAccountLinkStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest: ApiBankAccountLinkStatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiBankAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerPartyBankAccountApi - factory interface
 * @export
 */
export const PartnerPartyBankAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerPartyBankAccountApiFp(configuration)
    return {
        /**
         * Links/Relink a new bank account via Plaid. Calls Plaid to get the account details, creates the account in AoF and fetches the Plaid Institution for the account if we don\'t have it already or if it\'s out of date.
         * @summary Create or Update Bank Account
         * @param {ApiCreateBankAccountRequest} apiCreateBankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateBankAccount(apiCreateBankAccountRequest: ApiCreateBankAccountRequest, options?: any): AxiosPromise<ApiResponseObject> {
            return localVarFp.createOrUpdateBankAccount(apiCreateBankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expirePendingBankAccount(options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.expirePendingBankAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of accounts that have been linked in Plaid (i.e. account.plaid is non-null) for a given status, if any.  If status is not passed the active and all suspended Plaid accounts will be returned.
         * @summary Get Bank Accounts
         * @param {GetBankAccountsStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccounts(status?: GetBankAccountsStatusEnum, options?: any): AxiosPromise<ApiResponseListApiBankAccount> {
            return localVarFp.getBankAccounts(status, options).then((request) => request(axios, basePath));
        },
        /**
         * new type of token that is created by your app\'s server and passed to your app\'s client to initialize Link.
         * @summary Get Link Token
         * @param {ApiLinkTokenRequest} apiLinkTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToken(apiLinkTokenRequest: ApiLinkTokenRequest, options?: any): AxiosPromise<ApiResponseApiLinkTokenResponse> {
            return localVarFp.getLinkToken(apiLinkTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank accounts pending verification.
         * @param {Set<GetPendingBankAccountsVerificationStatusEnum>} [verificationStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingBankAccounts(verificationStatus?: Set<GetPendingBankAccountsVerificationStatusEnum>, options?: any): AxiosPromise<ApiResponseListApiPendingBankAccount> {
            return localVarFp.getPendingBankAccounts(verificationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Bank Account
         * @param {string} externalAccountRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBankAccountByExternalAccountRef(externalAccountRef: string, options?: any): AxiosPromise<ApiResponseApiBankAccount> {
            return localVarFp.removeBankAccountByExternalAccountRef(externalAccountRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transact Bank Account
         * @param {TransactBankAccountAchTypeEnum} achType 
         * @param {ApiEmbeddedFundingTransferRequest} apiEmbeddedFundingTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactBankAccount(achType: TransactBankAccountAchTypeEnum, apiEmbeddedFundingTransferRequest: ApiEmbeddedFundingTransferRequest, options?: any): AxiosPromise<ApiResponseApiEmbeddedFundingTransferResponse> {
            return localVarFp.transactBankAccount(achType, apiEmbeddedFundingTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the LinkStatus between the bank account and plaid
         * @summary Update LinkStatus for the BankAccount
         * @param {ApiBankAccountLinkStatus} apiBankAccountLinkStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountLinkStatus(apiBankAccountLinkStatus: ApiBankAccountLinkStatus, options?: any): AxiosPromise<ApiResponseVoid> {
            return localVarFp.updateAccountLinkStatus(apiBankAccountLinkStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify and Activate Pending Bank Account
         * @param {ApiBankAccountLinkStatusUpdateRequest} apiBankAccountLinkStatusUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest: ApiBankAccountLinkStatusUpdateRequest, options?: any): AxiosPromise<ApiResponseApiBankAccount> {
            return localVarFp.verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerPartyBankAccountApi - object-oriented interface
 * @export
 * @class PartnerPartyBankAccountApi
 * @extends {BaseAPI}
 */
export class PartnerPartyBankAccountApi extends BaseAPI {
    /**
     * Links/Relink a new bank account via Plaid. Calls Plaid to get the account details, creates the account in AoF and fetches the Plaid Institution for the account if we don\'t have it already or if it\'s out of date.
     * @summary Create or Update Bank Account
     * @param {ApiCreateBankAccountRequest} apiCreateBankAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyBankAccountApi
     */
    public createOrUpdateBankAccount(apiCreateBankAccountRequest: ApiCreateBankAccountRequest, options?: AxiosRequestConfig) {
        return PartnerPartyBankAccountApiFp(this.configuration).createOrUpdateBankAccount(apiCreateBankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyBankAccountApi
     */
    public expirePendingBankAccount(options?: AxiosRequestConfig) {
        return PartnerPartyBankAccountApiFp(this.configuration).expirePendingBankAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of accounts that have been linked in Plaid (i.e. account.plaid is non-null) for a given status, if any.  If status is not passed the active and all suspended Plaid accounts will be returned.
     * @summary Get Bank Accounts
     * @param {GetBankAccountsStatusEnum} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyBankAccountApi
     */
    public getBankAccounts(status?: GetBankAccountsStatusEnum, options?: AxiosRequestConfig) {
        return PartnerPartyBankAccountApiFp(this.configuration).getBankAccounts(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * new type of token that is created by your app\'s server and passed to your app\'s client to initialize Link.
     * @summary Get Link Token
     * @param {ApiLinkTokenRequest} apiLinkTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyBankAccountApi
     */
    public getLinkToken(apiLinkTokenRequest: ApiLinkTokenRequest, options?: AxiosRequestConfig) {
        return PartnerPartyBankAccountApiFp(this.configuration).getLinkToken(apiLinkTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank accounts pending verification.
     * @param {Set<GetPendingBankAccountsVerificationStatusEnum>} [verificationStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyBankAccountApi
     */
    public getPendingBankAccounts(verificationStatus?: Set<GetPendingBankAccountsVerificationStatusEnum>, options?: AxiosRequestConfig) {
        return PartnerPartyBankAccountApiFp(this.configuration).getPendingBankAccounts(verificationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Bank Account
     * @param {string} externalAccountRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyBankAccountApi
     */
    public removeBankAccountByExternalAccountRef(externalAccountRef: string, options?: AxiosRequestConfig) {
        return PartnerPartyBankAccountApiFp(this.configuration).removeBankAccountByExternalAccountRef(externalAccountRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transact Bank Account
     * @param {TransactBankAccountAchTypeEnum} achType 
     * @param {ApiEmbeddedFundingTransferRequest} apiEmbeddedFundingTransferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyBankAccountApi
     */
    public transactBankAccount(achType: TransactBankAccountAchTypeEnum, apiEmbeddedFundingTransferRequest: ApiEmbeddedFundingTransferRequest, options?: AxiosRequestConfig) {
        return PartnerPartyBankAccountApiFp(this.configuration).transactBankAccount(achType, apiEmbeddedFundingTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the LinkStatus between the bank account and plaid
     * @summary Update LinkStatus for the BankAccount
     * @param {ApiBankAccountLinkStatus} apiBankAccountLinkStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyBankAccountApi
     */
    public updateAccountLinkStatus(apiBankAccountLinkStatus: ApiBankAccountLinkStatus, options?: AxiosRequestConfig) {
        return PartnerPartyBankAccountApiFp(this.configuration).updateAccountLinkStatus(apiBankAccountLinkStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify and Activate Pending Bank Account
     * @param {ApiBankAccountLinkStatusUpdateRequest} apiBankAccountLinkStatusUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyBankAccountApi
     */
    public verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest: ApiBankAccountLinkStatusUpdateRequest, options?: AxiosRequestConfig) {
        return PartnerPartyBankAccountApiFp(this.configuration).verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetBankAccountsStatusEnum = {
    Active: 'ACTIVE',
    Pending: 'PENDING',
    Suspended: 'SUSPENDED'
} as const;
export type GetBankAccountsStatusEnum = typeof GetBankAccountsStatusEnum[keyof typeof GetBankAccountsStatusEnum];
/**
 * @export
 */
export const GetPendingBankAccountsVerificationStatusEnum = {
    Complete: 'COMPLETE',
    Expired: 'EXPIRED',
    Pending: 'PENDING'
} as const;
export type GetPendingBankAccountsVerificationStatusEnum = typeof GetPendingBankAccountsVerificationStatusEnum[keyof typeof GetPendingBankAccountsVerificationStatusEnum];
/**
 * @export
 */
export const TransactBankAccountAchTypeEnum = {
    Withdrawal: 'WITHDRAWAL',
    Deposit: 'DEPOSIT'
} as const;
export type TransactBankAccountAchTypeEnum = typeof TransactBankAccountAchTypeEnum[keyof typeof TransactBankAccountAchTypeEnum];


/**
 * PartyChangePasswordApi - axios parameter creator
 * @export
 */
export const PartyChangePasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This is step after consumer sign-up. This call is to send opt to mobile based on email identifier after reset password link expired
         * @summary Send OTP to user mobile
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordPhone: async (apiValidateOTPRequest: ApiValidateOTPRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiValidateOTPRequest' is not null or undefined
            assertParamExists('resetPasswordPhone', 'apiValidateOTPRequest', apiValidateOTPRequest)
            const localVarPath = `/partner/embedded/party/password/reset/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateOTPRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartyChangePasswordApi - functional programming interface
 * @export
 */
export const PartyChangePasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartyChangePasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * This is step after consumer sign-up. This call is to send opt to mobile based on email identifier after reset password link expired
         * @summary Send OTP to user mobile
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordPhone(apiValidateOTPRequest: ApiValidateOTPRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordPhone(apiValidateOTPRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartyChangePasswordApi - factory interface
 * @export
 */
export const PartyChangePasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartyChangePasswordApiFp(configuration)
    return {
        /**
         * This is step after consumer sign-up. This call is to send opt to mobile based on email identifier after reset password link expired
         * @summary Send OTP to user mobile
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordPhone(apiValidateOTPRequest: ApiValidateOTPRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.resetPasswordPhone(apiValidateOTPRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartyChangePasswordApi - object-oriented interface
 * @export
 * @class PartyChangePasswordApi
 * @extends {BaseAPI}
 */
export class PartyChangePasswordApi extends BaseAPI {
    /**
     * This is step after consumer sign-up. This call is to send opt to mobile based on email identifier after reset password link expired
     * @summary Send OTP to user mobile
     * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyChangePasswordApi
     */
    public resetPasswordPhone(apiValidateOTPRequest: ApiValidateOTPRequest, options?: AxiosRequestConfig) {
        return PartyChangePasswordApiFp(this.configuration).resetPasswordPhone(apiValidateOTPRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PartyResetPasswordApi - axios parameter creator
 * @export
 */
export const PartyResetPasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (apiResetPasswordRequest: ApiResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiResetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'apiResetPasswordRequest', apiResetPasswordRequest)
            const localVarPath = `/partner/embedded/party/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize: async (apiFinalizeRequest: ApiFinalizeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('resetPasswordFinalize', 'apiFinalizeRequest', apiFinalizeRequest)
            const localVarPath = `/partner/embedded/party/password/reset/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartyResetPasswordApi - functional programming interface
 * @export
 */
export const PartyResetPasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartyResetPasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(apiResetPasswordRequest: ApiResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(apiResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordFinalize(apiFinalizeRequest: ApiFinalizeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordFinalize(apiFinalizeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartyResetPasswordApi - factory interface
 * @export
 */
export const PartyResetPasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartyResetPasswordApiFp(configuration)
    return {
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(apiResetPasswordRequest: ApiResetPasswordRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.resetPassword(apiResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize(apiFinalizeRequest: ApiFinalizeRequest, options?: any): AxiosPromise<ApiResponseToken> {
            return localVarFp.resetPasswordFinalize(apiFinalizeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartyResetPasswordApi - object-oriented interface
 * @export
 * @class PartyResetPasswordApi
 * @extends {BaseAPI}
 */
export class PartyResetPasswordApi extends BaseAPI {
    /**
     * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
     * @summary Start Reset Password
     * @param {ApiResetPasswordRequest} apiResetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyResetPasswordApi
     */
    public resetPassword(apiResetPasswordRequest: ApiResetPasswordRequest, options?: AxiosRequestConfig) {
        return PartyResetPasswordApiFp(this.configuration).resetPassword(apiResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Second and final step when resetting a password.
     * @summary Finalize Reset Password
     * @param {ApiFinalizeRequest} apiFinalizeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyResetPasswordApi
     */
    public resetPasswordFinalize(apiFinalizeRequest: ApiFinalizeRequest, options?: AxiosRequestConfig) {
        return PartyResetPasswordApiFp(this.configuration).resetPasswordFinalize(apiFinalizeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PartySignUpApi - axios parameter creator
 * @export
 */
export const PartySignUpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available: async (apiAvailabilityRequest: ApiAvailabilityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiAvailabilityRequest' is not null or undefined
            assertParamExists('available', 'apiAvailabilityRequest', apiAvailabilityRequest)
            const localVarPath = `/partner/embedded/party/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAvailabilityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize: async (apiFinalizeRequest: ApiFinalizeRequest, xTrackingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('finalize', 'apiFinalizeRequest', apiFinalizeRequest)
            const localVarPath = `/partner/embedded/party/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp: async (apiValidateOTPRequest: ApiValidateOTPRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiValidateOTPRequest' is not null or undefined
            assertParamExists('otp', 'apiValidateOTPRequest', apiValidateOTPRequest)
            const localVarPath = `/partner/embedded/party/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateOTPRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate: async (apiValidateIdentifierRequest: ApiValidateIdentifierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiValidateIdentifierRequest' is not null or undefined
            assertParamExists('validate', 'apiValidateIdentifierRequest', apiValidateIdentifierRequest)
            const localVarPath = `/partner/embedded/party/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateIdentifierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartySignUpApi - functional programming interface
 * @export
 */
export const PartySignUpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartySignUpApiAxiosParamCreator(configuration)
    return {
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async available(apiAvailabilityRequest: ApiAvailabilityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.available(apiAvailabilityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalize(apiFinalizeRequest: ApiFinalizeRequest, xTrackingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalize(apiFinalizeRequest, xTrackingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async otp(apiValidateOTPRequest: ApiValidateOTPRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.otp(apiValidateOTPRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validate(apiValidateIdentifierRequest: ApiValidateIdentifierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validate(apiValidateIdentifierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartySignUpApi - factory interface
 * @export
 */
export const PartySignUpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartySignUpApiFp(configuration)
    return {
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available(apiAvailabilityRequest: ApiAvailabilityRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.available(apiAvailabilityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest 
         * @param {string} [xTrackingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize(apiFinalizeRequest: ApiFinalizeRequest, xTrackingId?: string, options?: any): AxiosPromise<ApiResponseToken> {
            return localVarFp.finalize(apiFinalizeRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp(apiValidateOTPRequest: ApiValidateOTPRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.otp(apiValidateOTPRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(apiValidateIdentifierRequest: ApiValidateIdentifierRequest, options?: any): AxiosPromise<ApiResponseIdentifier> {
            return localVarFp.validate(apiValidateIdentifierRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartySignUpApi - object-oriented interface
 * @export
 * @class PartySignUpApi
 * @extends {BaseAPI}
 */
export class PartySignUpApi extends BaseAPI {
    /**
     * First step in consumer sign-up. Verifies that the identifier in request is available for use
     * @summary Check Party identifier availability
     * @param {ApiAvailabilityRequest} apiAvailabilityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartySignUpApi
     */
    public available(apiAvailabilityRequest: ApiAvailabilityRequest, options?: AxiosRequestConfig) {
        return PartySignUpApiFp(this.configuration).available(apiAvailabilityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
     * @summary Finalize Party Sign Up
     * @param {ApiFinalizeRequest} apiFinalizeRequest 
     * @param {string} [xTrackingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartySignUpApi
     */
    public finalize(apiFinalizeRequest: ApiFinalizeRequest, xTrackingId?: string, options?: AxiosRequestConfig) {
        return PartySignUpApiFp(this.configuration).finalize(apiFinalizeRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
     * @summary Validate One Time Password (OTP)
     * @param {ApiValidateOTPRequest} apiValidateOTPRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartySignUpApi
     */
    public otp(apiValidateOTPRequest: ApiValidateOTPRequest, options?: AxiosRequestConfig) {
        return PartySignUpApiFp(this.configuration).otp(apiValidateOTPRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
     * @summary Send One Time Password (OTP)
     * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartySignUpApi
     */
    public validate(apiValidateIdentifierRequest: ApiValidateIdentifierRequest, options?: AxiosRequestConfig) {
        return PartySignUpApiFp(this.configuration).validate(apiValidateIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



