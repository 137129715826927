import { cryptoAssetsMap } from "@bakkt/bakkt-ui-components";
import { Box } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";

const plotData: number[] = [0, 0, 0, 0, 0, 0, 0];
const xLabels: number[] = [];

const calculateXlabels = (length: number) => {
  for (let i = 0; i < length; i++) {
    xLabels.push(i);
  }
};

calculateXlabels(plotData.length);

export type MiniChartProps = {
  chartData: number[];
  ticker: string;
};

const MiniLineChart = (props: MiniChartProps) => {
  const { chartData, ticker } = props;

  return (
    <Box sx={{ width: 112, overflow: "hidden" }}>
      <SparkLineChart colors={[cryptoAssetsMap[ticker].color]} data={chartData} height={45} />
    </Box>
  );
};

export default MiniLineChart;
