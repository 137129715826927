import { RootContextType, useRootContext } from "@/RootLayout";
import ModalLoadingIndicator from "@/components/loading/ModalLoadingIndicator.tsx";
import {
  fetchMockDataPromiseWithDelay,
  fullTaxId,
  mockSuccessResponse,
  partnerPartyInfo,
} from "@/services/mockData.ts";
import {
  ApiEmbeddedPartnerPartyProfileResponse,
  ApiEmbeddedPartnerPartyTaxIdResponse,
  ApiEmbeddedPartyUpdateEw9Request,
} from "@/services/openAPI/embedded";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Button, Dialog, Skeleton, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  Alert,
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  TextField,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Await, defer, useFetcher, useLoaderData, useNavigate, useNavigation } from "react-router-dom";

const TaxPayerCert = () => {
  const { fetchPartnerPartyProfilePromise, fetchFullTaxIdPromise } = useLoaderData() as {
    fetchPartnerPartyProfilePromise: Promise<ApiEmbeddedPartnerPartyProfileResponse>;
    fetchFullTaxIdPromise: Promise<ApiEmbeddedPartnerPartyTaxIdResponse>;
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const { addAlert } = useRootContext() as RootContextType;
  const [open, setOpen] = useState<boolean>(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [signature, setSignature] = useState<string>("");
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";

  const fetchUserDetailsPromise = useMemo(
    () => Promise.all([fetchPartnerPartyProfilePromise, fetchFullTaxIdPromise]),
    [fetchPartnerPartyProfilePromise, fetchFullTaxIdPromise],
  );

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        navigate(`/kyc/complete`);
      } else {
        addAlert({
          severity: "error",
          messageHeader: "There was an error saving your information.",
          message: "Failed to save user information. Please try again later or contact support.",
        });
      }
    }
  }, [fetcher.data]);

  const handleSubmit = async () => {
    const updatePartyRequest = { ew9Signature: signature };
    submitActionRequest(fetcher, updatePartyRequest);
  };

  const handleClose = (): void => {
    setOpen(false);
    navigate("/");
  };

  return (
    <Suspense
      fallback={
        <ThemeProvider theme={themes.dark}>
          <Grid>
            <Skeleton variant="rectangular" height={440} />
          </Grid>
        </ThemeProvider>
      }
    >
      <Await resolve={fetchUserDetailsPromise} errorElement={<Alert severity="error">Error loading account</Alert>}>
        {([partnerPartyProfile, fullTaxId]) => {
          const userFullName = `${partnerPartyProfile?.Party?.firstName} ${partnerPartyProfile?.Party?.lastName}`;
          return (
            <ThemeProvider theme={themes.dark}>
              <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="sm" fullScreen={fullScreen}>
                <DialogContent sx={{ p: "0 16px" }}>
                  {isLoading ? (
                    <ModalLoadingIndicator description={"Processing"} />
                  ) : (
                    <>
                      <Grid container justifyContent="center" alignItems="center" sx={{ mb: 8 }}>
                        <Grid>
                          <Typography variant="h3" sx={{ mt: 2.5, textAlign: "center" }}>
                            One more step!
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              textAlign: "center",
                              overflowWrap: "break-word",
                              width: "90%",
                              fontWeight: 500,
                              margin: "auto",
                            }}
                          >
                            Bakkt will use the information you've provided to generate a Form W-9. All of your Bakkt
                            transaction activity will be associated with this IRS tax form. Please review and certify
                            that your taxpayer information below is correct.
                          </Typography>
                        </Grid>
                      </Grid>
                      <hr />
                      <Grid container sx={{ padding: "1.5rem 0" }}>
                        {/* First Column */}
                        <Grid container xs={12} sm={6} direction="column">
                          <Typography variant="h6">Taxpayer Information</Typography>
                          <Typography variant="body1">
                            {partnerPartyProfile.Party?.firstName} {partnerPartyProfile.Party?.lastName}
                            <br />
                            {partnerPartyProfile.Party?.address?.streetLine1},{" "}
                            {partnerPartyProfile.Party?.address?.streetLine2}
                            <br />
                            {partnerPartyProfile.Party?.address?.locality}, {partnerPartyProfile.Party?.address?.region}{" "}
                            {partnerPartyProfile.Party?.address?.postalCode}
                          </Typography>
                        </Grid>

                        {/* Second Column */}
                        <Grid container xs={12} sm={6} direction="column">
                          <Typography variant="h6" sx={{ marginTop: { xs: "1rem", sm: 0 } }}>
                            Federal Tax Classification
                          </Typography>
                          <Typography variant="body1">Individual</Typography>
                          <Typography variant="h6" sx={{ marginTop: { xs: "1rem", sm: "1rem" } }}>
                            SSN
                          </Typography>
                          <Typography variant="body1">{fullTaxId.taxIdentifier}</Typography>
                        </Grid>
                      </Grid>
                      <hr />
                      <Grid container sx={{ padding: "1rem 0" }}>
                        {/* First Column */}
                        <Grid container direction="column">
                          <Typography variant="h6">Certification</Typography>
                          <Typography variant="body1" sx={{ paddingTop: "0.25rem" }}>
                            Under penalties of perjury, I certify that:
                          </Typography>
                          <ol style={{ margin: 0, padding: 0, listStylePosition: "inside" }}>
                            <li>The number shown is my correct SSN</li>
                            <li>I am not subject to backup withholding</li>
                            <li>I am a U.S. citizen or other U.S. person</li>
                          </ol>
                        </Grid>
                      </Grid>
                      <hr />
                      <Box sx={{ backgroundColor: "#212121", padding: "0.5rem 0.75rem", mt: 3 }}>
                        <Grid container sx={{ padding: "1rem 0" }}>
                          {/* First Column */}
                          <Grid container direction="column">
                            <Typography variant="h6">Sign and Certify</Typography>
                            <Typography variant="body1">
                              The Internal Revenue Service (IRS) does not require your consent to any provision of this
                              document other than the certifications required to avoid backup withholding.
                            </Typography>
                            <Typography variant="caption" sx={{ paddingTop: "3rem" }}>
                              Type your name as your digital signature
                            </Typography>
                            <Grid xs={12}>
                              <TextField
                                type="text"
                                label=""
                                name="signature"
                                variant="standard"
                                fullWidth={true}
                                required
                                value={signature}
                                onChange={(e) => setSignature(e.target.value)}
                                error={signature !== "" && signature !== userFullName}
                                helperText={
                                  signature && signature !== userFullName
                                    ? "Signature must match taxpayer information"
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", pt: 6.5 }}>
                  {!isLoading && (
                    <Stack spacing={2} sx={{ width: "66%" }}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        onClick={() => handleSubmit()}
                        // Disable button if signature is empty or does not match the user's name
                        disabled={!signature || signature !== userFullName}
                      >
                        Continue
                      </Button>
                      <Button variant="text" onClick={() => navigate("/")}>
                        Cancel
                      </Button>
                    </Stack>
                  )}
                </DialogActions>
              </Dialog>
            </ThemeProvider>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default TaxPayerCert;

export async function loader() {
  const fetchPartnerPartyProfilePromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(partnerPartyInfo, 3000)
    : EmbeddedPartnerService.fetchPartnerPartyProfile();
  const fetchFullTaxIdPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(fullTaxId, 3000)
    : EmbeddedPartnerService.fetchFullTaxId();

  return defer({
    fetchPartnerPartyProfilePromise,
    fetchFullTaxIdPromise,
  });
}

export async function action({ request }: { request: Request }) {
  try {
    if (shouldUseMockData) {
      const resp = await fetchMockDataPromiseWithDelay(mockSuccessResponse, 3000);
      return formatActionSuccessResponse(resp);
    }
    const updatePartyRequest = (await request.json()) as ApiEmbeddedPartyUpdateEw9Request;
    const response = await EmbeddedPartnerService.updateParty(updatePartyRequest);
    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
