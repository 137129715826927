import { ApiReportQueue } from "@/services/openAPI/embedded";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { shouldUseMockData } from "@/utils/dataUtils.ts";
import { Icons, Paper, Skeleton, Typography, color, themes } from "@bakkt/bakkt-ui-components";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  SvgIcon,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { Await, useLoaderData } from "react-router-dom";

const CryptoTaxLogExport = () => {
  const { taxReportListPromise } = useLoaderData() as {
    taxReportListPromise: Promise<ApiReportQueue[]>;
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedReportFileName, setSelectedReportFileName] = useState("");
  const [confirmDownloadOpen, setConfirmDownloadOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  const handleDownloadReport = async () => {
    const reportLink = shouldUseMockData
      ? `link/to/${selectedReportFileName}` // No tax report file exists to actually download if using mock data
      : await EmbeddedPartnerService.getTaxReportDownloadLink(selectedReportFileName);

    if (!shouldUseMockData) {
      window.open(reportLink as string, "_blank");
    }
    setConfirmDownloadOpen(false);
  };

  const handleConfirmDownloadOpen = async (report: ApiReportQueue) => {
    // Need a filename to actually download a report
    if (report.fileName) {
      setSelectedReportFileName(report.fileName);
      setConfirmDownloadOpen(true);
    }
  };

  return (
    <>
      <Grid sx={{ mb: isMobile ? 3 : 0 }}>
        <Typography variant="h3">Transaction Log Export</Typography>
        <Typography sx={{ mt: 1 }}>
          Download your crypto transaction reports here. You can use these as a reference for your annual taxes
        </Typography>
        <React.Suspense fallback={<Skeleton variant="rectangular" height={440} sx={{ mb: 5, mt: 2 }} />}>
          <Await
            resolve={taxReportListPromise}
            errorElement={<Alert severity="error">Error loading crypto transaction reports</Alert>}
          >
            {(cryptoTaxReportsQueue: ApiReportQueue[]) => (
              <Paper sx={{ borderRadius: 0, mt: 2, p: 5 }}>
                <Stack>
                  {!cryptoTaxReportsQueue || cryptoTaxReportsQueue?.length === 0 ? (
                    <Grid
                      container
                      sx={{
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>
                        <Typography variant="h5">{currentYear}</Typography>
                        <Typography variant="subtitle2">This report is not available</Typography>
                      </Grid>
                      <SvgIcon component={Icons.DownloadIcon} sx={{ mr: 1, mt: 1 }} inheritViewBox />
                    </Grid>
                  ) : (
                    <>
                      {cryptoTaxReportsQueue.map((cryptoTaxReport) => (
                        <Grid
                          container
                          key={cryptoTaxReport.id}
                          sx={{
                            alignItems: "flex-end",
                            borderBottom: `1px solid ${color.designGray.main}`,
                            color: color.text.main,
                            justifyContent: "space-between",
                            mb: 1,
                            pb: 2,
                            "&:last-of-type": {
                              borderBottom: 0,
                              mb: 0,
                            },
                          }}
                        >
                          <Typography variant="h5">{cryptoTaxReport.reportYear}</Typography>
                          <SvgIcon
                            component={Icons.DownloadIcon}
                            onClick={() => handleConfirmDownloadOpen(cryptoTaxReport)}
                            sx={{
                              cursor: "pointer",
                              mr: 1,
                              mt: 1,
                              "&:hover": {
                                color: color.energizingLime.dark,
                              },
                            }}
                            inheritViewBox
                          />
                        </Grid>
                      ))}
                    </>
                  )}
                </Stack>
              </Paper>
            )}
          </Await>
        </React.Suspense>
      </Grid>
      <ThemeProvider theme={themes.dark}>
        <Dialog open={confirmDownloadOpen}>
          <DialogContent style={{ textAlign: "center" }}>
            Do you want to download {selectedReportFileName}?
          </DialogContent>
          <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
            <Stack spacing={2} sx={{ width: "66%" }}>
              <Button variant="contained" fullWidth={true} onClick={handleDownloadReport}>
                Download
              </Button>
              <Button variant="text" onClick={() => setConfirmDownloadOpen(false)}>
                Cancel
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default CryptoTaxLogExport;
