import ProgressCheck from "@/assets/images/progress_indicator.svg";
import {
  ApiAmountCurrencyEnum,
  ApiEmbeddedCryptoTransactionRequestTransactTypeEnum,
} from "@/services/openAPI/embedded";
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
  formatDollarAmountUsd,
  themes,
} from "@bakkt/bakkt-ui-components";
import {
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface BuySellTransactionCompleteProps {
  transactionType: ApiEmbeddedCryptoTransactionRequestTransactTypeEnum;
  selectedCrypto: ApiAmountCurrencyEnum;
  amountUsd: string;
  amountCrypto: string;
}
const BuySellTransactionComplete = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { transactionType, selectedCrypto, amountUsd, amountCrypto } = useLocation()
    ?.state as BuySellTransactionCompleteProps;

  const handleClose = (): void => {
    setOpen(false);
    navigate("/");
  };

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="md" fullWidth fullScreen={isFullScreen}>
        <DialogTitle severity={"success"} style={{ backgroundColor: "black" }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid xs={12}>
              <Typography
                sx={{
                  fontSize: "h3.fontSize",
                  fontWeight: "h3.fontWeight",
                  mb: 3,
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Transaction Complete
              </Typography>
            </Grid>
            <img src={ProgressCheck} alt="Check" />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center" sx={{ mb: 4 }}>
            <Grid xs={12} sm={10}>
              <Box sx={{ mb: 4 }}>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Grid>Coin</Grid>
                  <Grid>{selectedCrypto}</Grid>
                </Stack>
                <Divider />
              </Box>
              <Box sx={{ mb: 4 }}>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Grid>Asset Amount</Grid>
                  <Grid>
                    {Number(amountCrypto).toFixed(8)} {selectedCrypto}
                  </Grid>
                </Stack>
                <Divider />
              </Box>
              <Box sx={{ mb: 4 }}>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Grid>Transaction Type</Grid>
                  <Grid>{transactionType === "BUY" ? "Buy" : "Sell"}</Grid>
                </Stack>
                <Divider />
              </Box>
              <Box sx={{ mb: 4 }}>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Grid>Submitted</Grid>
                  <Grid>
                    {new Date().toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Grid>
                </Stack>
                <Divider />
              </Box>
              <Box sx={{ mb: 4 }}>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Grid>Amount</Grid>
                  <Grid>{formatDollarAmountUsd(Number(amountUsd))}</Grid>
                </Stack>
                <Divider />
              </Box>
              <Box sx={{ mb: 4 }}>
                <Stack direction="row" justifyContent={"space-between"}>
                  <Grid>Filled</Grid>
                  <Grid>
                    {new Date().toLocaleString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}{" "}
                    at{" "}
                    {new Date().toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      timeZoneName: "short",
                    })}
                  </Grid>
                </Stack>
                <Divider />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
          <Stack spacing={1} sx={{ width: "66%" }}>
            <Button variant="contained" onClick={handleClose}>
              Finish
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default BuySellTransactionComplete;
