import { ApiAssetActivityTypeEnum } from "@/services/openAPI/embedded";
import { capitalize } from "@mui/material";
import { FetcherWithComponents } from "react-router-dom";

export const shouldUseMockData = import.meta.env.VITE_USE_MOCK_DATA === "true";

//if token has not yet been replaced by CI/CD process, i.e. you are running locally, use vite env variable
export const API_URL =
  (window as any)["API_URL"] == "{{API_URL}}" ? import.meta.env.VITE_API_URL : (window as any)["API_URL"];

export const submitActionRequest = (fetcher: FetcherWithComponents<any>, request: any) => {
  fetcher.submit(JSON.stringify(request), {
    method: "post",
    encType: "application/json",
  });
};

export const calculatePercentage = (partial: number, total: number) => {
  const percentage = (100 * partial) / total;
  return Math.round(percentage);
};

export enum IsoTimeFormatType {
  SHORT = "short",
  LONG = "long",
}

export const formatIsoTime = (timestamp: string, format: IsoTimeFormatType) => {
  const formats: Record<IsoTimeFormatType, Intl.DateTimeFormatOptions> = {
    short: { day: "numeric", month: "short", year: "numeric" },
    long: {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    },
  };
  const date = new Date(timestamp);
  const options = formats[format];
  return date.toLocaleString("en-US", options);
};

export const pageFooterText = `Copyright ${new Date().getFullYear()} © Bakkt Marketplace, LLC. All rights reserved.`;

export const formatTransactionActivityText = (transactionType: ApiAssetActivityTypeEnum) => {
  let formattedText = "";
  if (transactionType === ApiAssetActivityTypeEnum.SellAll) {
    formattedText = ApiAssetActivityTypeEnum.Sell;
  } else {
    formattedText = transactionType;
  }

  // Convert from e.g. 'LOYALTY_REDEEM' to 'Loyalty Redeem'
  formattedText = formattedText
    ?.split("_")
    .map((word) => capitalize(word.toLowerCase()))
    .join(" ");
  return formattedText || "";
};

// Plain JS version of lodash's omit function
// For any string in given keys parameter, remove it from the given obj's properties if it exists
export const omitFieldsFromObject = (keys: string[], obj: any): any =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)));

export const isProductionEnv = window.location.hostname === "link.bakkt.com";

// Plaid returns KYC_FAILED in non-prod unless a certain first/last name is used, so ignore that status for non-prod
export const USER_MUST_RELINK_STATUSES = isProductionEnv
  ? ["PENDING_EXPIRATION", "REFRESH_REQUIRED", "KYC_FAILED", "BLACKLIST"]
  : ["PENDING_EXPIRATION", "REFRESH_REQUIRED", "BLACKLIST"];
