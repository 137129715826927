import { RootContextType, useRootContext } from "@/RootLayout.tsx";
import BankIcon from "@/assets/images/bank.svg";
import { fetchMockDataPromiseWithDelay, mockSuccessResponse } from "@/services/mockData.ts";
import {
  ApiEmbeddedFundingAccount,
  FiatAmountCurrencyEnum,
  TransactBankAccountAchTypeEnum,
} from "@/services/openAPI/embedded";
import { EmbeddedBankLinkingService } from "@/services/serviceLoader.ts";
import { DepositWithdrawRequest } from "@/utils/customTypes.ts";
import { shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Button, Dialog, Divider, Typography, formatDollarAmountUsd, themes } from "@bakkt/bakkt-ui-components";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  capitalize,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useNavigation } from "react-router-dom";

interface DepositWithdrawReviewProps {
  amount: string;
  fundingType: TransactBankAccountAchTypeEnum;
  fundingAccount: ApiEmbeddedFundingAccount;
}

const DepositWithdrawReview = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { addAlert } = useRootContext() as RootContextType;
  const { amount, fundingType, fundingAccount } = useLocation()?.state as DepositWithdrawReviewProps;
  const [open, setOpen] = useState<boolean>(true);
  const fetcher = useFetcher();
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";
  const isWithdrawal = fundingType === TransactBankAccountAchTypeEnum.Withdrawal;

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const handleSubmit = () => {
    const request: DepositWithdrawRequest = {
      fundingType: fundingType,
      amount: {
        amount: Number(amount),
        currency: FiatAmountCurrencyEnum.Usd,
      },
      externalAccountRef: fundingAccount.externalAccountRef,
    };

    submitActionRequest(fetcher, request);
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        isWithdrawal
          ? addAlert({
              severity: "success",
              message: `Withdrawal of ${formatDollarAmountUsd(Number(amount))} successful!`,
            })
          : addAlert({
              severity: "warning",
              message: `${formatDollarAmountUsd(Number(amount))} worth of funds pending`,
            });
        navigate("/funding/complete", {
          state: { amount: amount, fundingType: fundingType, fundingAccount: fundingAccount },
        });
      } else {
        if (response.message) {
          addAlert({
            severity: "error",
            message: response.message,
          });
        } else {
          addAlert({
            severity: "error",
            message: "An error has occurred. Please try again later or contact support",
          });
        }
        navigate("/");
      }
    }
  }, [fetcher.data]);

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} fullWidth fullScreen={isFullScreen}>
        <DialogContent>
          {isLoading && (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid xs={12}>
                <Typography variant="h3" sx={{ mb: 6, textAlign: "center" }}>
                  Processing
                </Typography>
              </Grid>
              <Grid xs={12} sx={{ textAlign: "center", mb: 6 }}>
                <CircularProgress sx={{ color: "success.main" }} />
              </Grid>
            </Grid>
          )}

          {!isLoading && (
            <Grid container direction="column" justifyContent="center" alignItems="center" pt={1}>
              <Typography variant="h3" textAlign="center">
                Review {!isWithdrawal && capitalize(fundingType.toLowerCase())}
              </Typography>
              <Typography variant="body2" fontSize={"h1.fontSize"} mt={8} mb={4}>{`${formatDollarAmountUsd(
                Number(amount),
              )}`}</Typography>
              <Grid xs={12} md={8} pt={5} pb={6}>
                <Typography variant="caption">{isWithdrawal ? "Destination" : "Funding Source"}</Typography>
                <Grid container alignItems="center" justifyContent="space-between" pb={0.25} mb={0.25}>
                  <Grid container alignItems="center">
                    <img src={BankIcon} alt="Check" />
                    <Typography variant="subtitle1" pl={0.5} pt={0.25}>
                      {fundingAccount &&
                        fundingAccount.name + " - " + capitalize(fundingAccount.accountType.toLowerCase())}
                    </Typography>
                  </Grid>
                  <Typography variant="subtitle1" pl={0.5} pt={0.25}>
                    {fundingAccount?.formattedAccountNumber}
                  </Typography>
                </Grid>
                <Divider sx={{ mb: 0.25 }} />
                <Typography variant="caption">
                  Funds will appear in your account within {isWithdrawal ? "2 to 3" : "3 to 5"} days
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
          {!isLoading && (
            <Stack spacing={2} sx={{ width: "66%" }}>
              <Button variant="contained" onClick={handleSubmit}>
                {isWithdrawal ? "Continue" : "Add Now"}
              </Button>
              <Button variant="text" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Stack>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DepositWithdrawReview;

export async function action({ request }: { request: Request }) {
  try {
    if (shouldUseMockData) {
      const resp = await fetchMockDataPromiseWithDelay(mockSuccessResponse, 3000);
      return formatActionSuccessResponse(resp);
    }

    const depositWithdrawRequest = (await request.json()) as DepositWithdrawRequest;
    // Split out fundingType from request object since API method takes it as separate param
    const { fundingType, ...requestBody } = depositWithdrawRequest;
    const response = await EmbeddedBankLinkingService.transactBankAccount(fundingType, requestBody);
    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
