import {
  ApiEmbeddedFundingAccount,
  ApiEmbeddedPartnerPartyAccount,
  ApiEmbeddedPartnerPartyLinkInfo,
} from "@/services/openAPI/embedded";
import { Paper, Skeleton, Typography } from "@bakkt/bakkt-ui-components";
import { Alert, Box, Unstable_Grid2 as Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useMemo } from "react";
import { Await, useRouteLoaderData } from "react-router-dom";
import DashboardCoin from "./DashboardCoin";

const AvailableCoins = () => {
  const { fundingAccountsPromise, userCryptoAccountsPromise, userInfo } = useRouteLoaderData("root") as {
    fundingAccountsPromise: Promise<ApiEmbeddedFundingAccount[]>;
    userCryptoAccountsPromise: Promise<ApiEmbeddedPartnerPartyAccount[]>;
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };

  const availableCoinsDataPromise = useMemo(
    () => Promise.all([fundingAccountsPromise, userCryptoAccountsPromise]),
    [fundingAccountsPromise, userCryptoAccountsPromise],
  );

  const theme = useTheme();

  const userAvailableCryptoCurrencies = userInfo.partner?.cryptoCurrencies;
  const userPartyLevel = userInfo?.party?.level;
  const eligibleBuyLevels = ["LEVEL_4", "LEVEL_5"];
  const eligibleSellLevels = ["LEVEL_4", "LEVEL_4_LIQUIDATED", "LEVEL_5", "LEVEL_5_LIQUIDATED"];

  const availableCoinsSx = {
    borderRadius: 0,
    p: 3,
    [theme.breakpoints.down("md")]: {
      background: "none",
      p: 0,
    },
  };

  const availableCoinsColsSx = {
    mb: 1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  };

  const loadingFallback = (
    <Grid xs={12} sm={7} md={8}>
      <Paper sx={availableCoinsSx}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Skeleton variant="rectangular" height={250} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );

  return (
    <>
      <Grid xs={12}>
        <Typography variant="h3">Available Coins</Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          These are the crypto coins we offer and their current market value. Click each coin to learn more.
        </Typography>
      </Grid>
      <React.Suspense fallback={loadingFallback}>
        <Await
          resolve={availableCoinsDataPromise}
          errorElement={<Alert severity="error">Error loading accounts</Alert>}
        >
          {([fundingAccounts, userCryptoAccounts]) => {
            const cashAccount = fundingAccounts.find(
              (account: ApiEmbeddedFundingAccount) => account.accountType === "CASH",
            );

            return (
              <Grid xs={12} sm={7} md={8}>
                <Paper sx={availableCoinsSx}>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Grid container justifyContent="space-between" alignContent="center" sx={availableCoinsColsSx}>
                        <Grid>
                          <Grid container spacing={1.2} justifyContent="space-between" alignContent="center">
                            <Grid sx={{ width: 180 }}>
                              <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                                COIN
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                                TREND
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid sx={{ display: { xs: "none", md: "block" } }}>
                          <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                            MARKET VALUE
                          </Typography>
                        </Grid>
                      </Grid>

                      {userAvailableCryptoCurrencies &&
                        userAvailableCryptoCurrencies.map((coin, index) => (
                          <Box key={index}>
                            <DashboardCoin
                              ticker={coin}
                              eligibleToBuy={
                                eligibleBuyLevels.includes(userPartyLevel || "") &&
                                (cashAccount?.availableBalance?.amount || 0) > 0
                              }
                              eligibleToSell={
                                eligibleSellLevels.includes(userPartyLevel || "") &&
                                userCryptoAccounts?.find(
                                  (account: ApiEmbeddedPartnerPartyAccount) =>
                                    account.accountBalance?.currency === coin && account.buyBalance?.amount,
                                )
                              }
                            />
                          </Box>
                        ))}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          }}
        </Await>
      </React.Suspense>
    </>
  );
};

export default AvailableCoins;
