import BakktLogo from "@/assets/images/bakkt_logo.svg";
import { ApiEmbeddedPartnerPartyLinkInfo } from "@/services/openAPI/embedded";
import { handleLogout } from "@/utils/sessionUtils.ts";
import { Alert, Button, Icons, Typography } from "@bakkt/bakkt-ui-components";
import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Menu,
  MenuItem,
  Stack,
  StyledEngineProvider,
  SvgIcon,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MouseEvent, useState } from "react";
import { useLoaderData, useLocation, useNavigate, useNavigation } from "react-router-dom";
import styles from "./Header.module.css";

export const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const location = useLocation();
  const { state } = useLocation();
  const { userInfo } = useLoaderData() as { userInfo: ApiEmbeddedPartnerPartyLinkInfo };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOnProfilePage = location.pathname === "/profile";

  const open = Boolean(anchorEl || false);
  const pwUpdateSuccess = state && state.status && state.status === "password-reset-success" ? true : false;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <Box sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
          <Container maxWidth="lg" disableGutters sx={{ p: 3 }}>
            <Grid container justifyContent="space-between">
              <Grid sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                <img src={BakktLogo} alt="Bakkt logo" className={styles["header_logo"]} />
              </Grid>
              <Grid>
                {navigation.state !== "loading" && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Button
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      sx={{ textTransform: "none" }}
                      color="inherit"
                      className="header-footer-links"
                    >
                      Welcome, {userInfo.party?.firstName} <SvgIcon component={Icons.ChevronDownIcon} />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className={styles["MuiLink-Nav-Drop"]}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate(isOnProfilePage ? "/" : "/profile");
                        }}
                      >
                        <Typography variant="body2">{isOnProfilePage ? "Portfolio" : "Profile"}</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>
                        <Typography variant="body2">Logout</Typography>
                      </MenuItem>
                    </Menu>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </StyledEngineProvider>
      {pwUpdateSuccess && (
        <Grid container sx={{ padding: 0 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert variant="filled" severity="success" sx={{ justifyContent: "center" }}>
              <Typography variant="body1">Password successfully reset!</Typography>
            </Alert>
          </Stack>
        </Grid>
      )}
    </>
  );
};
