import BakktLogo from "@/assets/images/bakkt_logo.svg";
import CaesarsRewardsLogo from "@/assets/images/caesars_rewards_logo.png";
import ModalLoadingIndicator from "@/components/loading/ModalLoadingIndicator.tsx";
import styles from "@/pages/auth/Login.module.css";
import { ApiResetPasswordRequest } from "@/services/openAPI/embedded";
import { EmbeddedAuthService } from "@/services/serviceLoader.ts";
import { pageFooterText, submitActionRequest } from "@/utils/dataUtils.ts";
import { isValidEmail } from "@/utils/formValidation";
import { formatActionErrorResponse } from "@/utils/responseHandlingUtils.ts";
import { Alert, Button, Dialog, TextField, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useFetcher, useNavigate, useNavigation } from "react-router-dom";
import CaesarsBg from "../../assets/images/caesars_bg.svg";
import LoginBg from "../../assets/images/login_bg.svg";

const ChangePasswordEnterEmail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const [isFormValid, setIsFormValid] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";
  const caesarsToken = sessionStorage.getItem("CAESARS_TOKEN");

  const backgroundSx = caesarsToken
    ? {
        backgroundImage: "url(" + CaesarsBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        minHeight: 470,
        minWidth: 500,
      }
    : {
        backgroundImage: "url(" + LoginBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "3000px",
        minHeight: 470,
        minWidth: 500,
      };
  const [formData, setFormData] = useState<Record<"email", string>>({
    email: "",
  });
  const [fieldErrors, setFieldErrors] = useState<Record<"email", string>>({
    email: "",
  });

  const validateField = (name: string, value: string): string => {
    const validators: { [key: string]: (val: string) => string } = {
      email: isValidEmail,
    };

    if (name in validators) {
      return validators[name](value);
    }
    return "";
  };

  useEffect(() => {
    if (formData.email && isValidEmail(formData.email).length === 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.identifierToken) {
        navigate("/change-password-otp", {
          state: {
            email: formData.email,
            identifierToken: response.identifierToken,
          },
        });
      } else {
        setErrorMessage(response.message);
      }
    }
  }, [fetcher.data]);

  const handleSubmit = () => {
    if (isFormValid) {
      setErrorMessage("");
      const resetPasswordRequest: ApiResetPasswordRequest = {
        identifier: { email: formData.email },
      };

      submitActionRequest(fetcher, resetPasswordRequest);
    }
  };

  const handleSubmitKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && isFormValid) {
      handleSubmit();
    }
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!name || value === undefined) {
      return;
    }

    const newFieldErrors: Record<"email", string> = { ...fieldErrors };

    if (!value.trim()) {
      newFieldErrors[name as keyof Record<"email", string>] = "Input required";
    } else {
      const validationErrorMessage = validateField(name, value);
      newFieldErrors[name as keyof Record<"email", string>] = validationErrorMessage;
    }

    if (
      Object.keys(newFieldErrors).find(
        (fieldErrorKey) => newFieldErrors[fieldErrorKey as keyof Record<"email", string>] !== "",
      )
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }

    setFieldErrors(newFieldErrors);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    // Check if the name is a valid key
    if (name in formData) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  return (
    <ThemeProvider theme={themes.light}>
      <Dialog
        scroll="body"
        open={true}
        maxWidth="sm"
        fullScreen={isFullScreen}
        sx={isFullScreen ? undefined : backgroundSx}
        hideBackdrop={true}
      >
        {errorMessage && (
          <Grid sx={{ mb: 2, left: 0, position: "fixed", right: 0, top: 0 }}>
            <Alert
              severity="error"
              sx={{
                backgroundColor: "error.500",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">{errorMessage}</Typography>
            </Alert>
          </Grid>
        )}
        {isLoading ? (
          <ModalLoadingIndicator description={"Processing"} />
        ) : (
          <>
            <DialogContent
              sx={{
                alignItems: "center",
                textAlign: "center",
                overflowY: "hidden",
                minWidth: isFullScreen ? "initial" : 500,
              }}
            >
              <Grid container xs={12} sx={{ mb: 5, pt: errorMessage && isFullScreen ? 6 : 0 }}>
                <Grid xs={12}>
                  <Grid container justifyContent="center">
                    {caesarsToken && (
                      <Grid sx={{ borderRight: "1px solid black", mr: 3, pr: 2 }}>
                        <img src={CaesarsRewardsLogo} alt="Caesars Rewards logo" style={{ width: "106px" }} />
                      </Grid>
                    )}
                    <img src={BakktLogo} alt="Bakkt logo" className={styles["login_logo"]} />
                  </Grid>
                  <Typography variant="h3" sx={{ mt: 1.6 }}>
                    Reset Password
                  </Typography>
                  <Grid mt={1} sx={{ textAlign: "left" }}>
                    <Typography>
                      Enter the email associated with your Bakkt account for an email to reset your password
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid xs={12}>
                  <TextField
                    type="email"
                    label="Email"
                    name="email"
                    variant="standard"
                    fullWidth
                    required
                    value={formData.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(fieldErrors.email)}
                    helperText={fieldErrors.email}
                    onKeyUp={handleSubmitKeyPress}
                    sx={{ mt: 2 }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", pt: 5 }}>
              {!isLoading && (
                <Stack spacing={2} alignItems="center">
                  <Button variant="contained" disabled={!isFormValid} onClick={handleSubmit} sx={{ width: 190 }}>
                    Submit
                  </Button>
                </Stack>
              )}
            </DialogActions>
          </>
        )}
        <Grid sx={{ textAlign: "-webkit-center" }}>
          <Box sx={{ mt: "20px", mb: "20px", width: "70%" }}>
            <Box>
              <Grid xs={6}>
                <Typography variant="caption" sx={{ textAlign: "center" }}>
                  {pageFooterText}
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
};

export default ChangePasswordEnterEmail;

export async function action({ request }: { request: Request }) {
  try {
    const resetPasswordRequest = (await request.json()) as ApiResetPasswordRequest;
    const response = await EmbeddedAuthService.resetPassword(resetPasswordRequest);
    return response;
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
