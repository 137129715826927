import { ApiAssetActivity } from "@/services/openAPI/embedded";
import { formatTransactionActivityText } from "@/utils/dataUtils.ts";
import { Icons, Paper, Skeleton, Typography, cryptoAssetsMap, formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import {
  Alert,
  Unstable_Grid2 as Grid,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";

const TransactionHistory = () => {
  const { transactionHistoryPromise } = useLoaderData() as {
    transactionHistoryPromise: Promise<ApiAssetActivity[]>;
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  function renderTypeCell(record: ApiAssetActivity): React.ReactElement {
    let cellText = "";
    let svgIcon = Icons.UsdCoinIcon;
    switch (record.type) {
      case "BUY":
        cellText = cryptoAssetsMap[record.destinationCurrency || ""].name;
        if (isMobile) {
          cellText += " " + formatTransactionActivityText(record.type);
        }
        svgIcon = cryptoAssetsMap[record.destinationCurrency || ""].icon;
        break;
      case "DEPOSIT":
        cellText = "Funds";
        svgIcon = Icons.CashIcon;
        break;
      case "LOYALTY_REDEEM":
        cellText = "Rewards";
        if (isMobile) {
          cellText = "Loyalty Redeem";
        }
        svgIcon = Icons.RewardsIcon;
        break;
      case "SELL":
      case "SELL_ALL":
        cellText = cryptoAssetsMap[record.sourceCurrency || ""].name;
        if (isMobile) {
          cellText += " " + formatTransactionActivityText(record.type);
        }
        svgIcon = cryptoAssetsMap[record.sourceCurrency || ""].icon;
        break;
      case "WITHDRAW":
        cellText = "Funds";
        svgIcon = Icons.CashIcon;
        break;
    }
    return (
      <>
        <Grid container alignItems={"center"}>
          <SvgIcon
            component={svgIcon}
            sx={{
              width: 18,
              height: 18,
              mr: 1,
            }}
            inheritViewBox
          />
          <Typography variant="body1">{cellText}</Typography>
          {isMobile && <Typography variant="body2">{dayjs(record?.eventTime).format("DD MMM YYYY")}</Typography>}
        </Grid>
      </>
    );
  }

  function renderAmountCell(record: ApiAssetActivity): React.ReactElement {
    let cellText = "";
    let cellSubtext = "";
    switch (record.type) {
      case "BUY":
        cellText = formatDollarAmountUsd(record.costBasis || 0);
        cellSubtext = Number(record.destinationAmount).toFixed(8) + " " + record.destinationCurrency;
        break;
      case "DEPOSIT":
        cellText = formatDollarAmountUsd(record.destinationAmount || 0);
        if (record.status === "PENDING") {
          cellSubtext = "Pending - ";
        }
        if (record.assetType === "CASH") {
          cellSubtext += "Bank Deposit";
        } else if (record.assetType === "DEBIT_CARD") {
          cellSubtext += "Debit Card Deposit";
        }
        break;
      case "LOYALTY_REDEEM":
        cellText = formatDollarAmountUsd(record.destinationAmount || 0);
        cellSubtext = `${Math.abs(record.sourceAmount || 0)} Reward Credits`;
        break;
      case "SELL":
      case "SELL_ALL":
        cellText = formatDollarAmountUsd(record.costBasis || 0);
        cellSubtext = Math.abs(Number(record.sourceAmount)).toFixed(8) + " " + record.sourceCurrency;
        break;
      case "WITHDRAW":
        cellText = formatDollarAmountUsd(record.destinationAmount || 0);
        if (record.status === "PENDING") {
          cellSubtext = "Pending - ";
        }
        if (record.assetType === "CASH") {
          cellSubtext += "Bank Withdraw";
        } else if (record.assetType === "DEBIT_CARD") {
          cellSubtext += "Debit Card Withdraw";
        }
        break;
    }
    return (
      <>
        <Typography variant="body1">{cellText}</Typography>
        <Typography variant="body2">{cellSubtext}</Typography>
      </>
    );
  }

  return (
    <>
      <Grid container alignItems={"flex-end"} justifyContent={"space-between"} xs={8} sx={{ mt: 4, pl: 2 }}>
        <Typography variant="h3">Activity Log</Typography>
        {!isMobile && (
          <Typography variant="body1" sx={{ pr: 2 }}>
            <Link color="info.main" onClick={() => navigate(`/profile`)}>
              View All
            </Link>
          </Typography>
        )}
      </Grid>
      <React.Suspense
        fallback={
          <Grid xs={12} sm={7} md={8}>
            <Skeleton variant="rectangular" height={110} />
          </Grid>
        }
      >
        <Await
          resolve={transactionHistoryPromise}
          errorElement={<Alert severity="error">Error loading transaction history</Alert>}
        >
          {(transactionHistory: ApiAssetActivity[]) => (
            <Grid xs={12} sm={7} md={8}>
              <Paper sx={{ borderRadius: 0, p: 3 }}>
                {transactionHistory.length === 0 && (
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Typography variant="h4" fontWeight={500} sx={{ mb: 0.5 }}>
                        You have no transactions
                      </Typography>
                      <Typography variant="subtitle2">Transactions will appear here after you add funds.</Typography>
                    </Grid>
                  </Grid>
                )}
                {transactionHistory.length > 0 && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ASSET</TableCell>
                          {!isMobile && (
                            <>
                              <TableCell>ACTIVITY</TableCell>
                              <TableCell>DATE</TableCell>
                            </>
                          )}
                          <TableCell align="right">AMOUNT</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactionHistory?.map((transactionItem: ApiAssetActivity, index) => (
                          <TableRow key={index}>
                            <TableCell>{renderTypeCell(transactionItem)}</TableCell>
                            {!isMobile && (
                              <>
                                <TableCell>
                                  <Typography variant="body1">
                                    {transactionItem.type && formatTransactionActivityText(transactionItem.type)}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">
                                    {dayjs(transactionItem?.eventTime).format("DD MMM YYYY")}
                                  </Typography>
                                </TableCell>
                              </>
                            )}
                            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                              {renderAmountCell(transactionItem)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      {isMobile && (
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={2} variant="body" align="center">
                              <Typography variant="body1">
                                <Link color="info.main" onClick={() => navigate(`/profile`)}>
                                  View All
                                </Link>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      )}
                    </Table>
                  </TableContainer>
                )}
              </Paper>
            </Grid>
          )}
        </Await>
      </React.Suspense>
    </>
  );
};

export default TransactionHistory;
