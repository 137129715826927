// Adding these script tags to index.html did not work, so inserting them here instead
export function configureCookieBanner() {
  if (!window.location.href.includes("/finance/link-plaid")) {
    const scriptTag = document.getElementsByTagName("script")[0];
    let loader = document.createElement("script");
    loader.src = "https://cdn.cookielaw.org/consent/4daad1ab-6014-4efb-b78a-54e856329a31/OtAutoBlock.js";
    loader.type = "text/javascript";
    loader.async = true;
    scriptTag?.parentNode?.insertBefore(loader, scriptTag);

    loader = document.createElement("script");
    loader.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    loader.type = "text/javascript";
    loader.async = true;
    loader.charset = "UTF-8";
    loader.setAttribute("data-document-language", "true");
    loader.setAttribute("data-domain-script", "4daad1ab-6014-4efb-b78a-54e856329a31");
    scriptTag?.parentNode?.insertBefore(loader, scriptTag);

    loader = document.createElement("script");
    loader.type = "text/javascript";
    loader.innerText = "function OptanonWrapper() { }";
    scriptTag?.parentNode?.insertBefore(loader, scriptTag);
  }
}
