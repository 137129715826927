import { Button, Dialog, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LinkBankAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = (): void => {
    setOpen(false);
    navigate("/");
  };

  return (
    <>
      <ThemeProvider theme={themes.dark}>
        <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="sm" fullScreen={fullScreen}>
          <DialogContent sx={{ textAlign: "left", overflowY: "hidden", p: "0 2" }}>
            <Grid container justifyContent="center" alignItems="center" sx={{ mb: 4 }}>
              <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography
                  variant="h4"
                  sx={{ mt: 2.5, textAlign: "center", borderBottom: " 2px solid yellow", mb: 6 }}
                >
                  Bank Account
                </Typography>
                <Typography variant="h3" sx={{ textAlign: "center", fontWeight: "500" }}>
                  Add a bank account
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    overflowWrap: "break-word",
                    width: fullScreen ? "95%" : "70%",
                    margin: "2 auto auto",
                  }}
                >
                  <Grid sx={{ my: 2 }}>
                    Bank account transactions may take 3-5 days to appear in your Available Funds.
                  </Grid>
                  <Grid sx={{ mt: 1 }}>Click ‘Continue’ to link a bank account using Plaid.</Grid>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
            <Stack spacing={2} sx={{ width: "66%" }}>
              <Button variant="contained" fullWidth={true} onClick={() => navigate("/finance/link-plaid")}>
                Continue
              </Button>
              <Button variant="text" onClick={() => navigate("/")}>
                Cancel
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default LinkBankAccount;
