import {
  Configuration,
  EmbeddedPartnerAPIApiAxiosParamCreator,
  EmbeddedPartnerAPIApiFactory,
  EmbeddedPartnerDebitCardAPIApiFactory,
  EmbeddedPartnerSharedWalletAuthAPIApiFactory,
  PartnerPartyBankAccountApiFactory,
  PartySignUpApiFactory,
} from "@/services/openAPI/embedded";
import { API_URL } from "../utils/dataUtils.ts";

const configuration: Configuration = new Configuration();
const baseUrl = API_URL;

export const EmbeddedPartnerService = EmbeddedPartnerAPIApiFactory(configuration, baseUrl);
export const EmbeddedAuthService = EmbeddedPartnerSharedWalletAuthAPIApiFactory(configuration, baseUrl);
export const EmbeddedBankLinkingService = PartnerPartyBankAccountApiFactory(configuration, baseUrl);
export const EmbeddedDebitLinkingService = EmbeddedPartnerDebitCardAPIApiFactory(configuration, baseUrl);
export const EmbeddedAssetActivityHistoryService = EmbeddedPartnerAPIApiAxiosParamCreator(configuration);
export const EmbeddedPartySignUpService = PartySignUpApiFactory(configuration, baseUrl);
