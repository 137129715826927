import CryptoSelect from "@/components/cryptoSelect/CryptoSelect";
import { ApiAmountCurrencyEnum } from "@/services/openAPI/embedded";
import { Button, Divider, formatDollarAmountUsd, Typography } from "@bakkt/bakkt-ui-components";
import { Box, Unstable_Grid2 as Grid, TextField } from "@mui/material";
import React from "react";

interface AmountInputProps {
  amount: string;
  cryptoAmount?: number;
  handleAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus: () => void;
  handleBlur: () => void;
  helperText: string | null;
  handlePresetAmount: (presetAmount: string) => void;
  selectedCrypto?: ApiAmountCurrencyEnum;
  minError: boolean;
  maxError: boolean;
  balanceError?: boolean;
  balance?: number;
  updateSelectedCrypto?: (selectedCrypto: ApiAmountCurrencyEnum) => void;
  isWithdrawal?: boolean;
  minAmount?: number;
  maxAmount?: number;
  disableExpand?: boolean;
  isRedeemTransaction?: boolean;
  isSellTransaction?: boolean;
  handleSellAll?: () => void;
  handleWithdrawAll?: () => void;
  rewardsPoints?: number;
  rewardsConversionRate?: number;
}

const AmountInput: React.FC<AmountInputProps> = ({
  amount,
  handleAmountChange,
  handleFocus,
  handleBlur,
  helperText,
  handlePresetAmount,
  selectedCrypto,
  cryptoAmount,
  minError,
  maxError,
  balanceError,
  balance,
  updateSelectedCrypto,
  isWithdrawal,
  minAmount = 1,
  maxAmount,
  disableExpand,
  isRedeemTransaction,
  isSellTransaction,
  handleSellAll,
  handleWithdrawAll,
  rewardsPoints = 0,
  rewardsConversionRate = 0,
}) => {
  return (
    <Grid container alignItems={"center"} justifyContent={"center"} sx={{ mt: 5 }}>
      {helperText && (
        <Grid xs={12} sx={{ backgroundColor: "error.300", p: 2, mb: 3, textAlign: "center" }}>
          <Typography variant="body2" sx={{ color: "primary.contrastText" }}>
            {helperText}
          </Typography>
        </Grid>
      )}
      {selectedCrypto && (
        <CryptoSelect
          preSelectedCrypto={selectedCrypto}
          updateSelectedCrypto={updateSelectedCrypto}
          disableExpand={disableExpand}
        />
      )}
      <Grid xs={12}>
        <Grid container alignItems="center" direction="column">
          <TextField
            type="number"
            name="amountInput"
            required
            value={amount}
            onChange={handleAmountChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={minError || maxError}
            InputProps={{
              startAdornment: <Box sx={{ fontSize: "h1.fontSize", mt: -0.25 }}>$</Box>,
              style: {
                appearance: "textfield",
              },
            }}
            sx={{
              mt: 1,
              mb: 1,
              fontSize: "h1.fontSize",
              "& input": {
                fontSize: "h1.fontSize",
                width: `${amount.length + 0.5}ch`,
                // Styles for hiding default up/down arrows when <TextField type="number" ...>
                "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                  appearance: "none",
                  margin: "0",
                },
              },
            }}
          />
          {(minError || maxError || balanceError) && (
            <Typography variant="caption" sx={{ color: "error.dark", mt: -0.75, mb: 0.5 }}>
              {minError ? "Minimum amount not met" : null}
              {maxError ? "Maximum amount exceeded" : null}
              {balanceError ? "Available balance exceeded" : null}
            </Typography>
          )}
          {isRedeemTransaction && (
            <Typography variant="h6" sx={{ mb: 1 }}>
              {`${Number(amount) * rewardsConversionRate} Reward Credits`}
            </Typography>
          )}
          {!isRedeemTransaction && selectedCrypto && selectedCrypto.length > 0 && (
            <Typography variant="h6" sx={{ mb: 1 }}>
              {`${cryptoAmount} ${selectedCrypto}`}
            </Typography>
          )}
          <Grid container gap={1} sx={{ mb: 1.5 }}>
            <Typography variant="caption">Minimum {formatDollarAmountUsd(minAmount)}</Typography>
            {maxAmount && (
              <>
                <Divider orientation="vertical" flexItem />
                <Typography variant="caption">Maximum {formatDollarAmountUsd(maxAmount)}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} container justifyContent="center" gap={3} sx={{ mt: isWithdrawal ? 0 : 2 }}>
        {(() => {
          if (isWithdrawal) {
            return (
              <Button variant="outlined" onClick={handleWithdrawAll}>
                Withdraw All
              </Button>
            );
          } else if (isRedeemTransaction) {
            return (
              <>
                <Button variant="outlined" sx={{ flexDirection: "column" }} onClick={() => handlePresetAmount("5")}>
                  <Typography>$5</Typography>
                  <Typography variant="caption">{5 * rewardsConversionRate} Credits</Typography>
                </Button>
                <Button variant="outlined" sx={{ flexDirection: "column" }} onClick={() => handlePresetAmount("10")}>
                  <Typography>$10</Typography>
                  <Typography variant="caption">{10 * rewardsConversionRate} Credits</Typography>
                </Button>
                <Button variant="outlined" sx={{ flexDirection: "column" }} onClick={() => handlePresetAmount("20")}>
                  <Typography>$20</Typography>
                  <Typography variant="caption">{20 * rewardsConversionRate} Credits</Typography>
                </Button>
              </>
            );
          } else if (isSellTransaction) {
            return (
              <Button variant="outlined" onClick={handleSellAll}>
                Sell All
              </Button>
            );
          } else {
            return (
              <>
                <Button variant="outlined" onClick={() => handlePresetAmount("25")}>
                  $25
                </Button>
                <Button variant="outlined" onClick={() => handlePresetAmount("50")}>
                  $50
                </Button>
                <Button variant="outlined" onClick={() => handlePresetAmount("100")}>
                  $100
                </Button>
              </>
            );
          }
        })()}
      </Grid>
      {isWithdrawal && (
        <Typography variant="body2" marginTop={2}>
          {`Available Balance ${formatDollarAmountUsd(balance || 0)}`}
        </Typography>
      )}
      {isRedeemTransaction && (
        <Typography variant="body2" marginTop={2}>
          {`Available Balance ${Math.floor(Number(rewardsPoints))?.toLocaleString()} Reward Credits`}
        </Typography>
      )}
    </Grid>
  );
};

export default AmountInput;
