import { Typography, cryptoAssetsMap } from "@bakkt/bakkt-ui-components";
import { Box, Stack, SvgIcon } from "@mui/material";

interface PortfolioPercentLineProps {
  cryptoTicker: string;
  portfolioPercent: number;
}

export default function PortfolioPercentLine(props: PortfolioPercentLineProps) {
  const { cryptoTicker, portfolioPercent } = props;

  const percentLineSx = {
    height: 14,
    borderRadius: 7,
    mt: 0.64,
    mr: 1,
    backgroundColor: cryptoAssetsMap[cryptoTicker].color,
    width: `${portfolioPercent}%`,
  };

  const cryptoIconSx = {
    width: 24,
    height: 24,
    mr: 1,
  };

  return (
    <Stack direction="row" sx={{ mt: 1 }}>
      <SvgIcon component={cryptoAssetsMap[cryptoTicker].icon} inheritViewBox sx={cryptoIconSx} />
      <Box sx={percentLineSx}>{/* Crypto color bar line */}</Box>
      <Typography variant="body2" sx={{ mt: 0.3 }}>
        {portfolioPercent}%
      </Typography>
    </Stack>
  );
}
