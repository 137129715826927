import LoadingIndicator from "@/components/loading/LoadingIndicator.tsx";
import { fetchMockDataPromiseWithDelay, mockSuccessResponse } from "@/services/mockData.ts";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { pageFooterText, shouldUseMockData, submitActionRequest } from "@/utils/dataUtils.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Alert, Button, Dialog, Typography, color, themes } from "@bakkt/bakkt-ui-components";
import {
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Unstable_Grid2 as Grid,
  Link,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useFetcher, useNavigate } from "react-router-dom";
import BakktLogo from "../../assets/images/bakkt_logo.svg";
import styles from "./Login.module.css";

const TermsOfService = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const [acceptTosChecked, setAcceptTosChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response?.data?.success) {
        navigate("/");
      } else {
        setErrorMessage("An error occurred while updating your account.");
      }
    }
  }, [fetcher.data]);

  const handleContinue = () => {
    setErrorMessage(undefined);

    // No request body for EmbeddedPartnerService.updatePartyTermsAndConditions
    submitActionRequest(fetcher, {});
  };

  return (
    <ThemeProvider theme={themes.light}>
      <Dialog
        scroll="body"
        open={true}
        maxWidth="xs"
        fullScreen={isFullScreen}
        sx={{ backgroundColor: color.designGray.main }}
        hideBackdrop={true}
      >
        <DialogContent
          sx={{ alignItems: "center", mx: "auto", overflowY: "hidden", textAlign: "center", width: "70%" }}
        >
          <Grid container xs={12} md={12} sx={{ mb: 5, mt: isFullScreen ? 7 : 0 }}>
            <Grid xs={12} md={12}>
              <img src={BakktLogo} alt="Bakkt logo" className={styles["login_logo"]} />
              <Typography variant={isFullScreen ? "h4" : "h3"} sx={{ fontWeight: 400, mt: 1.6 }}>
                Terms of Service
              </Typography>
            </Grid>
          </Grid>

          {errorMessage && (
            <Grid sx={{ mb: 2 }}>
              <Alert severity="error" sx={{ justifyContent: "center" }}>
                <Typography variant="body1">{errorMessage}</Typography>
              </Alert>
            </Grid>
          )}

          {fetcher.state === "submitting" ? (
            <Stack>
              <LoadingIndicator />
            </Stack>
          ) : (
            <Grid xs={8} sm={12}>
              <FormGroup>
                <FormControlLabel
                  sx={{ mr: 0 }}
                  control={
                    <Checkbox
                      checked={acceptTosChecked}
                      onChange={() => {
                        setAcceptTosChecked(!acceptTosChecked);
                      }}
                      sx={{ mt: isFullScreen ? -3 : -1, pt: 0 }}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ textAlign: "left" }}>
                      I am at least 18 years old and agree to Bakkt’s{" "}
                      <Link href="https://bakkt.com/terms-of-service" target="_blank">
                        Terms of Service
                      </Link>{" "}
                      and{" "}
                      <Link href="https://bakkt.com/privacy-policy" target="_blank">
                        Privacy Policy
                      </Link>
                    </Typography>
                  }
                  value={acceptTosChecked}
                  onChange={() => {
                    setAcceptTosChecked(!acceptTosChecked);
                  }}
                />
              </FormGroup>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mb: 4, pt: isFullScreen ? 8 : 5 }}>
          {fetcher.state !== "submitting" && (
            <Stack spacing={2} sx={{ width: isFullScreen ? "65%" : "50%" }}>
              <Button variant="contained" disabled={!acceptTosChecked} fullWidth onClick={handleContinue}>
                Continue
              </Button>
            </Stack>
          )}
        </DialogActions>
        <Grid sx={{ textAlign: "-webkit-center" }}>
          <Box sx={{ bottom: 0, mt: "20px", mb: "40px", position: isFullScreen ? "fixed" : "static" }}>
            <Box>
              <Grid xs={12} sx={{ width: "90%" }}>
                <Typography variant="caption" sx={{ textAlign: "center" }}>
                  {pageFooterText}
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
};

export default TermsOfService;

export async function action() {
  try {
    if (shouldUseMockData) {
      const resp = await fetchMockDataPromiseWithDelay(mockSuccessResponse, 3000);
      return { data: formatActionSuccessResponse(resp) };
    }
    const response = await EmbeddedPartnerService.updatePartyTermsAndConditions();
    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
