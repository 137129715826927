import axios from "axios";

export const redirectToLogin = () => {
  window.location.assign("/login");
};

const globalSuccessHandler = (response: any) => {
  if (response.headers["content-type"]?.includes("application/json") && response.data?.payload) {
    // For paginated responses, data is always stored in payload.content
    if (response.data.payload.content) {
      return response.data.payload.content;
    }
    return response.data.payload;
  } else {
    return response;
  }
};
const globalErrorHandler = (error: any) => {
  if (error.response && error.response.status === 401) {
    // Needed so that the Login component can handle its own errors without hard redirecting, and so that we can render the create account page when not signed in
    const pathname = window.location.pathname;
    if (
      !error.config?.url?.endsWith("/partner/embedded/party/login") &&
      !pathname.includes("/create-account") &&
      !pathname.includes("/verify-email-address") &&
      !pathname.includes("/change-password")
    ) {
      if (pathname && pathname !== "/") {
        // Needed so app will continue forwarding them where they were trying to go after successful login
        // Side note: for non-existent routes, they will hit the 404 page before coming here so this correctly will not get set
        sessionStorage.setItem("LOGIN_DEEPLINK_PATH", pathname);
      }
      redirectToLogin();
    }
  }

  return Promise.reject({ ...error });
};

export function registerAxiosConfig() {
  axios.interceptors.response.use(globalSuccessHandler, globalErrorHandler);
  axios.defaults.withCredentials = true;
}
