import ProgressCheck from "@/assets/images/progress_indicator.svg";
import RedeemComplete from "@/assets/images/redeem_complete.png";
import { ApiEmbeddedFundingAccount } from "@/services/openAPI/embedded";
import { Button, Dialog, DialogTitle, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { Await, useNavigate, useRouteLoaderData } from "react-router-dom";

const RedeemTransactionComplete = () => {
  const { fundingAccountsPromise } = useRouteLoaderData("root") as {
    fundingAccountsPromise: Promise<ApiEmbeddedFundingAccount[]>;
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = (): void => {
    setOpen(false);
    navigate("/");
  };

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="sm" fullWidth fullScreen={isFullScreen}>
        <DialogTitle severity={"success"} style={{ backgroundColor: "black", paddingBottom: 0 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid xs={12}>
              <Typography
                sx={{
                  fontSize: "h3.fontSize",
                  fontWeight: "h3.fontWeight",
                  mb: 3,
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Transaction Complete
              </Typography>
            </Grid>
            <img src={ProgressCheck} alt="Check" style={{ marginTop: 20 }} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center" sx={{ mb: 4, textAlign: "center" }}>
            <Grid xs={12} sm={10}>
              <Box>
                <Typography variant="h4">But wait, you can buy more!</Typography>
                <Typography>Explore a variety of cryptocurrencies for your portfolio</Typography>
                <Box
                  component="img"
                  sx={{
                    maxWidth: "200px",
                    marginTop: 4,
                  }}
                  alt="Redeem complete"
                  src={RedeemComplete}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center", pt: 0 }}>
          <Stack spacing={1} sx={{ width: "66%" }}>
            <Await resolve={fundingAccountsPromise}>
              {(fundingAccounts: ApiEmbeddedFundingAccount[]) => {
                if (fundingAccounts.length <= 1) {
                  return (
                    <>
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate("/finance/link-bank-account");
                        }}
                      >
                        Add A Bank Account
                      </Button>
                      <Button variant="text" onClick={handleClose}>
                        Return to Dashboard
                      </Button>
                    </>
                  );
                } else {
                  return (
                    <Button variant="contained" onClick={handleClose}>
                      Return to Dashboard
                    </Button>
                  );
                }
              }}
            </Await>
          </Stack>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default RedeemTransactionComplete;
