import { Typography } from "@bakkt/bakkt-ui-components";
import { Unstable_Grid2 as Grid, useTheme } from "@mui/material";

export default function BannerFundsAvailable() {
  const theme = useTheme();
  const headerCtaSection = {
    mb: 3,
    mt: 1,
    [theme.breakpoints.down("sm")]: {
      mt: 34,
    },
  };

  return (
    <Grid container>
      <Grid xs={12} sm={9} md={6}>
        <Typography variant="h3" sx={headerCtaSection}>
          Cha-ching! <br />
          Your funds are now available to buy crypto
        </Typography>
      </Grid>
    </Grid>
  );
}
