import Alerts from "@/components/alerts/Alerts.tsx";
import {
  ApiEmbeddedPartnerPartyLinkInfo,
  GetCryptoCurrencyPricesCryptoCurrencyEnum,
} from "@/services/openAPI/embedded";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { ThemeProvider, TimeoutModal, color, themes } from "@bakkt/bakkt-ui-components";
import { Container, useTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { grey } from "@mui/material/colors";
import { LicenseInfo } from "@mui/x-license-pro";
import React, { useEffect, useState } from "react";
import { Outlet, defer, redirect, useLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import { Footer } from "./components/footer/Footer";
import { Header } from "./components/header/Header";
import { CaesarsBanner } from "./components/partnerBanner/CaesarsBanner";
import {
  btcPrice,
  caesarsTokenInfo,
  cryptoAccounts as cryptoAccountsMock,
  ethPrice,
  fetchMockDataPromiseWithDelay,
  fundingAccounts as fundingAccountsMock,
  userInfo as userInfoMock,
} from "./services/mockData.ts";
import { AlertDisplay } from "./utils/customTypes.ts";
import { shouldUseMockData } from "./utils/dataUtils.ts";
import { handleLogout } from "./utils/sessionUtils.ts";
LicenseInfo.setLicenseKey(
  "3ae214fa0120d6bf6b5d84afa5a9950dTz02NzAyNyxFPTE3MTYzOTc0OTYzMDQsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
);

function RootLayout() {
  const { userInfo } = useLoaderData() as {
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState<AlertDisplay[]>([]);

  // Navigate to Terms of Service page if the user needs to accept updated ToS
  useEffect(() => {
    if (userInfo?.party?.termsAndConditionAcceptanceRequired) {
      navigate("/terms-of-service");
    }
  }, [userInfo]);

  function addAlert(alert: AlertDisplay) {
    setAlerts((prevAlerts) => {
      return [alert, ...prevAlerts];
    });
  }

  return (
    <ThemeProvider theme={themes.light}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: color.oysterGray.main,
          },
          a: {
            textDecoration: "underline !important",
          },
          "a:hover": {
            cursor: "pointer",
          },
          ".header-footer-links": {
            textDecoration: "none !important",
            cursor: "pointer",
            "&:hover": {
              color: `${color.energizingLime.main} !important`,
            },
          },
          ".dialog-action-buttons": {
            [theme.breakpoints.down("sm")]: {
              bottom: 30,
              display: "flex",
              left: 0,
              position: "fixed",
              right: 0,
            },
          },
          ".MuiMenuItem-root": {
            "&:hover": {
              color: `${color.techBlack.main} !important`,
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "none !important",
            borderBottom: `1px solid ${theme.palette.primary.contrastText} !important`,
            borderRadius: "0 !important",
            paddingLeft: "0 !important",
          },
          ".MuiOutlinedInput-input": {
            padding: "0 0 5px 0 !important",
          },
          ".datePickerWrapper label.MuiInputLabel-animated": {
            transform: "translate(0px, 0px)",
          },
          ".datePickerWrapper label.MuiInputLabel-animated.MuiInputLabel-shrink": {
            transform: "translate(0px, -20px) scale(0.75)",
          },
          ".datePickerWrapper .MuiIconButton-edgeEnd": {
            paddingRight: "0 !important",
            marginRight: "-16px",
          },
          ".MuiDialog-paperScrollBody": {
            borderRadius: "0 0 4px 4px !important",
          },
          ".MuiDialog-paperFullScreen": {
            backgroundImage: "none !important",
          },
          ".MuiAlert-root": {
            borderRadius: "0 !important",
          },
          ".MuiToggleButton-root": {
            borderRadius: "5px !important",
            marginBottom: "8px",
            textTransform: "none",
            alignItems: "start",
            justifyContent: "start",
            minHeight: "74px",
            border: "1px solid transparent",
            backgroundColor: grey[900],
          },
          ".MuiToggleButton-root.Mui-selected": {
            backgroundColor: color.techBlack.main,
            border: "1px solid white !important",
          },
          ".MuiTouchRipple-root": {
            display: "none",
          },
          ".MuiDialogActions-root": {
            ".MuiButton-contained:hover": {
              backgroundColor: `${color.energizingLime.main}`,
            },
            ".MuiButton-text:hover": {
              backgroundColor: `transparent`,
              color: "#FFFFFF",
              textDecoration: "underline",
            },
          },
        }}
      />
      <CaesarsBanner />
      <Header />
      <div id="page-header-portal"></div>
      <Alerts alerts={alerts} setAlerts={setAlerts} />
      <Container maxWidth="lg" disableGutters className="content_wrapper" sx={{ p: 3 }}>
        <Outlet
          context={{
            alerts,
            setAlerts,
            addAlert,
          }}
        />
      </Container>
      <Footer />
      <ThemeProvider theme={themes.dark}>
        <TimeoutModal handleLogout={handleLogout} />
      </ThemeProvider>
    </ThemeProvider>
  );
}

export default RootLayout;

export type RootContextType = {
  alerts: AlertDisplay[];
  setAlerts: React.Dispatch<React.SetStateAction<AlertDisplay[]>>;
  addAlert: (alert: AlertDisplay) => void;
};

export function useRootContext() {
  return useOutletContext<RootContextType>();
}

export async function loader() {
  //If the user comes from an Access domain, don't load anything and send them to our redirect page
  if (window.location.hostname.includes("access")) {
    return redirect("/access");
  }

  const userInfoPromise: any = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(userInfoMock, 200)
    : EmbeddedPartnerService.getPartnerPartyLinkInfo();
  const userInfo = await userInfoPromise;

  const fundingAccountsPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(fundingAccountsMock, 200)
    : EmbeddedPartnerService.fetchFundingAccounts("USD");

  const userCryptoAccountsPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(cryptoAccountsMock, 5000)
    : EmbeddedPartnerService.fetchAccounts();

  const btcPricePromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(btcPrice, 2000)
    : EmbeddedPartnerService.getCryptoCurrencyPrices(GetCryptoCurrencyPricesCryptoCurrencyEnum.Btc, "USD");

  const ethPricePromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(ethPrice, 2000)
    : EmbeddedPartnerService.getCryptoCurrencyPrices(GetCryptoCurrencyPricesCryptoCurrencyEnum.Eth, "USD");

  let caesarsTokenInfoPromise;
  const caesarsToken = sessionStorage.getItem("CAESARS_TOKEN");
  if (caesarsToken) {
    caesarsTokenInfoPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(caesarsTokenInfo, 2000)
      : EmbeddedPartnerService.processToken(caesarsToken);
  }

  return defer({
    userInfo,
    fundingAccountsPromise,
    userCryptoAccountsPromise,
    btcPricePromise,
    ethPricePromise,
    caesarsTokenInfoPromise,
  });
}
