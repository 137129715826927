import ProgressCheck from "@/assets/images/progress_indicator.svg";
import { ApiEmbeddedFundingAccount, TransactBankAccountAchTypeEnum } from "@/services/openAPI/embedded";
import { Button, Dialog, DialogTitle, Typography, formatDollarAmountUsd, themes } from "@bakkt/bakkt-ui-components";
import {
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface DepositWithdrawCompleteProps {
  amount: string;
  fundingType: TransactBankAccountAchTypeEnum;
  fundingAccount: ApiEmbeddedFundingAccount;
}

const DepositWithdrawComplete = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { amount, fundingType, fundingAccount } = useLocation()?.state as DepositWithdrawCompleteProps;
  const isWithdrawal = fundingType === TransactBankAccountAchTypeEnum.Withdrawal;

  const handleClose = (): void => {
    setOpen(false);
    navigate("/");
  };

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={open} onClose={handleClose} fullWidth fullScreen={isFullScreen}>
        <DialogTitle severity={"success"} style={{ backgroundColor: "black" }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid xs={12}>
              <Typography sx={{ fontSize: "h3.fontSize", fontWeight: "h3.fontWeight", mb: 3, textAlign: "center" }}>
                {isWithdrawal ? "Withdrawal Pending" : "Funds Pending"}
              </Typography>
            </Grid>
            <img src={ProgressCheck} alt="Check" />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" justifyContent="center" alignItems="center" pt={1}>
            <Grid xs={12} md={9} container justifyContent="center" alignItems="center" pb={6}>
              <Typography variant="h4" textAlign="center">
                {isWithdrawal ? (
                  <>
                    {`You sent ${formatDollarAmountUsd(Number(amount))} to your ${
                      fundingAccount.name
                    } account ending in ${fundingAccount.formattedAccountNumber}. `}
                    <Grid sx={{ display: isFullScreen ? "inline" : "block" }}>Funds will take 2-3 days to post.</Grid>
                  </>
                ) : (
                  `Your ${formatDollarAmountUsd(Number(amount))} deposit is pending`
                )}
              </Typography>
              <Typography variant="body2" pt={2}>
                {!isWithdrawal &&
                  "Please note that transactions made to or from a bank account will take 3-5 days to post."}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
          <Stack spacing={2} sx={{ width: "66%" }}>
            <Button variant="contained" onClick={() => navigate("/")}>
              {isWithdrawal ? "View Portfolio" : "Return To Dashboard"}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DepositWithdrawComplete;
