import ProgressCheck from "@/assets/images/progress_indicator.svg";
import { Button, Dialog, DialogTitle, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const KycComplete = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isFromRedeemFlow = Boolean(sessionStorage.getItem("KYC_FROM_REDEEM_FLOW"));

  const handleClose = (): void => {
    setOpen(false);
    navigate("/");
  };

  return (
    <>
      <ThemeProvider theme={themes.dark}>
        <Dialog scroll="body" open={open} onClose={handleClose} maxWidth="sm" fullScreen={fullScreen}>
          <DialogTitle severity="success">{/* Leave empty to only show the color bar */}</DialogTitle>
          <DialogContent sx={{ textAlign: "left", overflowY: "hidden", p: "0 16px" }}>
            <Grid container justifyContent="center" alignItems="center" sx={{ mb: 8 }}>
              <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="h3" sx={{ mt: 2.5, textAlign: "center" }}>
                  Thanks!
                </Typography>
                <img src={ProgressCheck} alt="Check" style={{ display: "block", margin: "2rem auto" }} />
                <Typography variant="subtitle1" sx={{ fontSize: "1.5rem", textAlign: "center" }}>
                  {isFromRedeemFlow
                    ? "Continue to redeem Reward Credits for bitcoin"
                    : "Add a funding source to buy crypto"}
                </Typography>
                {!isFromRedeemFlow && (
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "center",
                      overflowWrap: "break-word",
                      width: "95%",
                      margin: "1rem auto auto",
                    }}
                  >
                    Bank account transactions may take 3-5 days to appear in your Available Funds.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", pt: 2 }}>
            <Stack spacing={2} sx={{ width: "66%" }}>
              <Button
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  navigate(isFromRedeemFlow ? "/redeem" : "/finance/link-bank-account");
                }}
              >
                Continue
              </Button>
              <Button variant="text" onClick={() => navigate("/")}>
                Cancel
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default KycComplete;
