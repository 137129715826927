import FeesWaived from "@/assets/images/fees_waived.png";
import { ApiEmbeddedPartnerPartyLinkInfo } from "@/services/openAPI/embedded";
import { Button, Dialog, DialogTitle, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useRouteLoaderData } from "react-router-dom";

const RedeemFeesWaived = () => {
  const { userInfo } = useRouteLoaderData("root") as {
    userInfo: ApiEmbeddedPartnerPartyLinkInfo;
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const setFromRedeemFlow = () => {
    sessionStorage.setItem("KYC_FROM_REDEEM_FLOW", "true");
  };

  //TODO: Move this routing logic to CaesarsBanner component because this fees modal will eventually go away
  const handleContinueClick = () => {
    const userPartyLevel = userInfo?.party?.level;
    switch (userPartyLevel) {
      case "LEVEL_5_LIQUIDATED":
        navigate("/redeem");
        break;
      case "LEVEL_5":
        navigate("/redeem");
        break;
      case "LEVEL_4_LIQUIDATED":
        navigate("/redeem");
        break;
      case "LEVEL_4":
        navigate("/redeem");
        break;
      case "LEVEL_3":
        setFromRedeemFlow();
        navigate("/kyc/tax-payer-cert");
        break;
      default:
        setFromRedeemFlow();
        navigate("/kyc/user-info");
        break;
    }
  };

  return (
    <ThemeProvider theme={themes.dark}>
      <Dialog scroll="body" open={true} maxWidth="sm" fullScreen={isFullScreen} fullWidth>
        <DialogTitle severity="success" style={{ backgroundColor: "black", paddingTop: 0, paddingBottom: 0 }}>
          &nbsp;
        </DialogTitle>
        <DialogContent sx={{ mt: 0, pt: 0 }}>
          <Grid xs={12} container justifyContent={"center"} alignItems={"center"}>
            <img
              src={FeesWaived}
              alt="Fees Waived"
              style={{ height: "auto", maxWidth: "100%", marginBottom: "20px" }}
            />
            <Typography variant="h5" fontWeight="700" textAlign={"center"}>
              For a limited time, fees in Bakkt&reg; Link will be waived for Caesars Rewards&reg; members
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action-buttons" sx={{ justifyContent: "center" }}>
          <Stack spacing={1} sx={{ width: "66%", mb: 1.5 }}>
            <Button variant="contained" onClick={handleContinueClick}>
              Continue
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default RedeemFeesWaived;
