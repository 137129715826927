import PortfolioPercentLine from "@/components/header/portfolio/PortfolioPercentLine.tsx";
import PortfolioPercentLinePlaceholder from "@/components/header/portfolio/PortfolioPercentLinePlaceholder.tsx";
import {
  ApiAmountCurrencyEnum,
  ApiEmbeddedCryptoPriceResponse,
  ApiEmbeddedFundingAccount,
  ApiEmbeddedPartnerPartyAccount,
  GetHistoricalCryptoCurrencyPriceResponse,
  HistoricalPrice,
} from "@/services/openAPI/embedded";
import { PortfolioItemType } from "@/utils/customTypes.ts";
import { calculatePercentage } from "@/utils/dataUtils.ts";
import { Icons, Typography, cryptoAssetsMap, formatDollarAmountUsd, themes } from "@bakkt/bakkt-ui-components";
import {
  Alert,
  Box,
  Unstable_Grid2 as Grid,
  Stack,
  SvgIcon,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Await, useRouteLoaderData } from "react-router-dom";

export default function Portfolio() {
  const { fundingAccountsPromise, userCryptoAccountsPromise, btcPricePromise, ethPricePromise } = useRouteLoaderData(
    "root",
  ) as {
    fundingAccountsPromise: Promise<ApiEmbeddedFundingAccount[]>;
    userCryptoAccountsPromise: Promise<ApiEmbeddedPartnerPartyAccount[]>;
    btcPricePromise: Promise<ApiEmbeddedCryptoPriceResponse>;
    ethPricePromise: Promise<ApiEmbeddedCryptoPriceResponse>;
  };

  const { btcOneDayHistoricalPricesPromise, ethOneDayHistoricalPricesPromise } = useRouteLoaderData("dashboard") as {
    btcOneDayHistoricalPricesPromise: Promise<GetHistoricalCryptoCurrencyPriceResponse>;
    ethOneDayHistoricalPricesPromise: Promise<GetHistoricalCryptoCurrencyPriceResponse>;
  };

  const portfolioDataPromise = useMemo(
    () =>
      Promise.all([
        userCryptoAccountsPromise,
        btcPricePromise,
        ethPricePromise,
        btcOneDayHistoricalPricesPromise,
        ethOneDayHistoricalPricesPromise,
        fundingAccountsPromise,
      ]),
    [
      userCryptoAccountsPromise,
      btcPricePromise,
      ethPricePromise,
      btcOneDayHistoricalPricesPromise,
      ethOneDayHistoricalPricesPromise,
      fundingAccountsPromise,
    ],
  );

  const [selectedPortfolioItem, setSelectedPortfolioItem] = useState<ApiAmountCurrencyEnum | PortfolioItemType>(
    PortfolioItemType.PORTFOLIO,
  );
  const [cryptoAmount, setCryptoAmount] = useState<number>();
  const [cryptoAmountUsd, setCryptoAmountUsd] = useState<number>(0.0);
  const [btcPrice, setBtcPrice] = useState<number>();
  const [ethPrice, setEthPrice] = useState<number>();
  const [btcOneDayHistoricalPrices, setBtcOneDayHistoricalPrices] =
    useState<GetHistoricalCryptoCurrencyPriceResponse>();
  const [ethOneDayHistoricalPrices, setEthOneDayHistoricalPrices] =
    useState<GetHistoricalCryptoCurrencyPriceResponse>();
  const [userCryptoAccounts, setUserCryptoAccounts] = useState<ApiEmbeddedPartnerPartyAccount[]>();

  const theme = useTheme();
  const isBelowSmBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePortfolioItemChange = (
    event: SyntheticEvent,
    nextPortfolioItem: ApiAmountCurrencyEnum | PortfolioItemType,
  ) => {
    if (event && nextPortfolioItem != null) {
      setSelectedPortfolioItem(nextPortfolioItem);
    }
  };

  function getPortfolioTotalIndicativeSum(userCryptoAccounts: ApiEmbeddedPartnerPartyAccount[]): number {
    return userCryptoAccounts.reduce((accumulator, cryptoAccount) => {
      return accumulator + (cryptoAccount.buyBalance?.amount || 0);
    }, 0);
  }

  type CurrentAndHistoricalCryptoPrices = {
    currentPrice?: number;
    historicalPrices?: HistoricalPrice[];
  };

  const getCurrentAndHistoricalCryptoPrices = (crypto: ApiAmountCurrencyEnum): CurrentAndHistoricalCryptoPrices => {
    let currentPrice;
    let historicalPrices;
    switch (crypto) {
      case "BTC":
        currentPrice = btcPrice;
        historicalPrices = btcOneDayHistoricalPrices?.historicalPrices;
        break;
      case "ETH":
        currentPrice = ethPrice;
        historicalPrices = ethOneDayHistoricalPrices?.historicalPrices;
        break;
    }
    return { currentPrice, historicalPrices };
  };

  type IntradayAmountPercentageChange = {
    amountUsd: number;
    percent: number;
  };

  const getIntradayChange = (
    crypto: ApiAmountCurrencyEnum | PortfolioItemType,
  ): IntradayAmountPercentageChange | undefined => {
    if (crypto !== PortfolioItemType.PORTFOLIO) {
      const { currentPrice, historicalPrices } = getCurrentAndHistoricalCryptoPrices(crypto);
      const initialPrice = historicalPrices?.slice(-1)[0].price;

      if (initialPrice && currentPrice) {
        const percentChange = ((currentPrice - initialPrice) / initialPrice) * 100;

        const cryptoAccount = userCryptoAccounts?.find(
          (cryptoAccount) => cryptoAccount.accountBalance?.currency === crypto,
        );
        const cryptoAmountUsd = cryptoAccount?.buyBalance?.amount || 0;

        return {
          amountUsd: cryptoAmountUsd * (percentChange / 100),
          percent: percentChange,
        };
      }
    } else {
      if (userCryptoAccounts) {
        const totalAmountChanged = [ApiAmountCurrencyEnum.Btc, ApiAmountCurrencyEnum.Eth].reduce(
          (accumulator: number, crypto: ApiAmountCurrencyEnum) => {
            const amountChange = getIntradayChange(crypto)?.amountUsd || 0;
            return accumulator + amountChange;
          },
          0,
        );
        const totalPortfolioAmountSum = getPortfolioTotalIndicativeSum(userCryptoAccounts);
        return {
          amountUsd: totalAmountChanged,
          percent: (totalAmountChanged / totalPortfolioAmountSum) * 100,
        };
      }
    }
  };
  const intradayChange = getIntradayChange(selectedPortfolioItem);

  const highlightCryptoBar = (selected: string) => {
    if (selectedPortfolioItem === PortfolioItemType.PORTFOLIO || selectedPortfolioItem === selected) {
      return { opacity: 1 };
    } else {
      return { opacity: 0.3 };
    }
  };

  const upDownIconsSx = {
    height: "18px",
    width: "18px",
    mt: 0.7,
  };

  const portfolioAmountSx = {
    fontWeight: 500,
    fontSize: "56px",
  };

  const cryptoDetailsSx = {
    [theme.breakpoints.up("sm")]: {
      pr: 4,
    },
    [theme.breakpoints.up("md")]: {
      pr: 10,
    },
  };

  useEffect(() => {
    async function getCryptoPrices() {
      const btcPrice = await btcPricePromise;
      setBtcPrice(btcPrice.indicativePrice?.amount);
      const ethPrice = await ethPricePromise;
      setEthPrice(ethPrice.indicativePrice?.amount);

      const btcOneDayHistoricalPrices = await btcOneDayHistoricalPricesPromise;
      setBtcOneDayHistoricalPrices(btcOneDayHistoricalPrices);
      const ethOneDayHistoricalPrices = await ethOneDayHistoricalPricesPromise;
      setEthOneDayHistoricalPrices(ethOneDayHistoricalPrices);

      const userCryptoAccounts = await userCryptoAccountsPromise;
      setUserCryptoAccounts(userCryptoAccounts);
    }
    getCryptoPrices();
  }, [
    btcPricePromise,
    ethPricePromise,
    btcOneDayHistoricalPricesPromise,
    ethOneDayHistoricalPricesPromise,
    userCryptoAccountsPromise,
  ]);

  useEffect(() => {
    async function updateCryptoDisplay() {
      if (selectedPortfolioItem !== PortfolioItemType.PORTFOLIO) {
        const userCryptoAccounts = await userCryptoAccountsPromise;
        handleChangeCryptoDisplay(userCryptoAccounts);
      }
    }
    updateCryptoDisplay();
  }, [selectedPortfolioItem]);

  const handleChangeCryptoDisplay = (userCryptoAccounts: ApiEmbeddedPartnerPartyAccount[]) => {
    const portfolioItem = userCryptoAccounts.find(
      (cryptoAccount) => cryptoAccount.accountBalance?.currency === selectedPortfolioItem,
    );
    if (portfolioItem?.accountBalance?.amount && portfolioItem?.buyBalance?.amount) {
      setCryptoAmount(portfolioItem.accountBalance?.amount);
      setCryptoAmountUsd(portfolioItem.buyBalance?.amount);
    }
  };

  return (
    <>
      <Grid container sx={{ mt: 3 }}>
        <React.Suspense>
          <Await
            resolve={portfolioDataPromise}
            errorElement={<Alert severity="error">Error loading portfolio data</Alert>}
          >
            {([userCryptoAccounts]) => (
              <>
                <Grid xs={12} sm={7} md={8} sx={[cryptoDetailsSx, { mb: 5 }]}>
                  <Grid minHeight={145}>
                    <Grid container spacing={1}>
                      <Grid>
                        <Typography variant="h5" sx={{ mt: 0.25, ml: 0.25 }}>
                          {selectedPortfolioItem === PortfolioItemType.PORTFOLIO
                            ? "Crypto Portfolio"
                            : cryptoAssetsMap[selectedPortfolioItem].name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography variant="h2" sx={portfolioAmountSx}>
                      {selectedPortfolioItem === PortfolioItemType.PORTFOLIO
                        ? formatDollarAmountUsd(getPortfolioTotalIndicativeSum(userCryptoAccounts))
                        : formatDollarAmountUsd(cryptoAmountUsd)}
                    </Typography>

                    {selectedPortfolioItem !== PortfolioItemType.PORTFOLIO && (
                      <Typography variant="body1">{cryptoAmount + " " + selectedPortfolioItem}</Typography>
                    )}

                    <Stack direction="row">
                      {intradayChange?.amountUsd && intradayChange.amountUsd < 0 ? (
                        <SvgIcon component={Icons.ArrowDownrightLargeIcon} sx={upDownIconsSx} />
                      ) : (
                        <SvgIcon component={Icons.ArrowUprightLargeIcon} sx={upDownIconsSx} />
                      )}

                      <Typography variant="subtitle1" sx={{ fontWeight: 700, mr: 1 }}>
                        {intradayChange?.amountUsd && intradayChange?.percent && (
                          <>
                            <Box component="span">{formatDollarAmountUsd(intradayChange?.amountUsd)}</Box>{" "}
                            <Box component="span">({intradayChange.percent.toFixed(2)}%)</Box>
                          </>
                        )}
                      </Typography>

                      <Typography variant="body2">Today</Typography>
                    </Stack>
                  </Grid>
                  {userCryptoAccounts
                    .filter(
                      (userCryptoAccount: ApiEmbeddedPartnerPartyAccount) =>
                        (userCryptoAccount?.buyBalance?.amount || 0) > 0,
                    )
                    .map((userCryptoAccount: ApiEmbeddedPartnerPartyAccount, index: number) => (
                      <Grid key={index}>
                        <Grid sx={highlightCryptoBar(userCryptoAccount.accountBalance?.currency || "")}>
                          <PortfolioPercentLine
                            cryptoTicker={userCryptoAccount.accountBalance?.currency || ""}
                            portfolioPercent={calculatePercentage(
                              userCryptoAccount.buyBalance?.amount || 0,
                              getPortfolioTotalIndicativeSum(userCryptoAccounts),
                            )}
                          />
                        </Grid>
                        {selectedPortfolioItem === PortfolioItemType.PORTFOLIO && userCryptoAccounts.length === 1 && (
                          <PortfolioPercentLinePlaceholder />
                        )}
                      </Grid>
                    ))}
                </Grid>
                <Grid xs={12} sm={5} md={4} sx={{ mb: 1 }}>
                  <Typography variant="h5" sx={{ fontWeight: 500, pb: isBelowSmBreakpoint ? 1 : 2 }}>
                    Your Holdings
                  </Typography>
                  <ThemeProvider theme={themes.dark}>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                      }}
                    >
                      <ToggleButtonGroup
                        orientation={isBelowSmBreakpoint ? "horizontal" : "vertical"}
                        value={selectedPortfolioItem}
                        onChange={handlePortfolioItemChange}
                        sx={{
                          mb: isBelowSmBreakpoint ? 1 : 5,
                          overflow: isBelowSmBreakpoint ? "scroll" : "visible",
                          pt: isBelowSmBreakpoint ? 0.6 : 0,
                          width: "100%",
                        }}
                        exclusive
                      >
                        <ToggleButton
                          value={PortfolioItemType.PORTFOLIO}
                          sx={{
                            mr: isBelowSmBreakpoint ? 1 : 0,
                            minWidth: 90,
                            p: isBelowSmBreakpoint ? 1.5 : 1.3,
                            pt: isBelowSmBreakpoint ? 2.1 : 3.5,
                          }}
                        >
                          <Grid container justifyContent="space-between" sx={{ width: "100%" }}>
                            <Grid
                              container
                              direction={isBelowSmBreakpoint ? "column" : "row"}
                              spacing={1}
                              sx={{ pt: 0 }}
                            >
                              <Grid
                                sx={{
                                  height: isBelowSmBreakpoint ? 28 : "auto",
                                  pb: isBelowSmBreakpoint ? 0 : 0.5,
                                  textAlign: "left",
                                }}
                              >
                                <SvgIcon component={Icons.CryptoIcon} inheritViewBox sx={{ width: 18, height: 18 }} />
                              </Grid>
                              <Grid sx={{ pt: isBelowSmBreakpoint ? 0 : 0.5 }} xs={isBelowSmBreakpoint ? 1 : false}>
                                <Typography variant="h6">Crypto Portfolio</Typography>
                              </Grid>
                            </Grid>
                            <Grid>
                              <Typography variant="body2" sx={{ textAlign: "right" }}>
                                {formatDollarAmountUsd(getPortfolioTotalIndicativeSum(userCryptoAccounts))}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ToggleButton>

                        {userCryptoAccounts
                          .filter(
                            (userCryptoAccount: ApiEmbeddedPartnerPartyAccount) =>
                              (userCryptoAccount?.buyBalance?.amount || 0) > 0,
                          )
                          .map((userCryptoAccount: any, index: number) => (
                            <ToggleButton
                              value={userCryptoAccount.accountBalance?.currency}
                              sx={{ mr: isBelowSmBreakpoint ? 1 : 0, pt: 2.1 }}
                              key={index}
                            >
                              <Grid container justifyContent="space-between" sx={{ width: "100%" }}>
                                <Grid container direction={isBelowSmBreakpoint ? "column" : "row"} spacing={1}>
                                  <Grid sx={{ pb: isBelowSmBreakpoint ? 0 : 0.5, textAlign: "left" }}>
                                    <SvgIcon
                                      component={cryptoAssetsMap[userCryptoAccount.accountBalance?.currency].icon}
                                      inheritViewBox
                                      sx={{ width: 18, height: 18 }}
                                    />
                                  </Grid>
                                  <Grid sx={{ pt: isBelowSmBreakpoint ? 0 : 0.5, textAlign: "left" }}>
                                    <Typography variant="h6">
                                      {cryptoAssetsMap[userCryptoAccount.accountBalance?.currency].name}
                                    </Typography>
                                    <Typography variant="body1">
                                      {formatDollarAmountUsd(
                                        getCurrentAndHistoricalCryptoPrices(userCryptoAccount.accountBalance?.currency)
                                          ?.currentPrice || 0,
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  sx={{
                                    mt: isBelowSmBreakpoint ? 0.5 : 0,
                                    textAlign: isBelowSmBreakpoint ? "left" : "right",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{ display: isBelowSmBreakpoint ? "inline" : "block" }}
                                  >
                                    {formatDollarAmountUsd(userCryptoAccount.buyBalance?.amount || 0)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: theme.palette.text.secondary, textWrap: "nowrap" }}
                                  >
                                    {userCryptoAccount.accountBalance.amount.toFixed(8) +
                                      " " +
                                      userCryptoAccount.accountBalance.currency}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ToggleButton>
                          ))}
                      </ToggleButtonGroup>
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </>
            )}
          </Await>
        </React.Suspense>
      </Grid>
    </>
  );
}
