import {
  ApiAssetActivity,
  ApiBankAccount,
  ApiEmbeddedCaesarsCryptoTransactionResponse,
  ApiEmbeddedCaesarsTokenResponse,
  ApiEmbeddedCryptoExecutePriceResponse,
  ApiEmbeddedCryptoPriceResponse,
  ApiEmbeddedCryptoTransactionResponse,
  ApiEmbeddedFundingAccount,
  ApiEmbeddedPartnerPartyAccount,
  ApiEmbeddedPartnerPartyLinkInfo,
  ApiEmbeddedPartnerPartyProfileResponse,
  ApiEmbeddedPartnerPartyTaxIdResponse,
  ApiReportQueue,
  GetHistoricalCryptoCurrencyPriceResponse,
  Identifier,
  Token,
} from "@/services/openAPI/embedded";

export function fetchMockDataPromiseWithDelay(data: object, delay: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, delay);
  });
}

export const mockSuccessResponse = { message: "Successfully updated.", data: {} };

export const bankAccount: ApiBankAccount[] = [
  {
    accountId: "17b987cc-457a-4a6a-a3e8-1cfe0c8b1914",
    plaidAccountId: "62a4bfd9-38e3-4837-8246-af05f3ee8fec",
    created: "2023-03-16T03:27:25.384703Z",
    partnerId: "bacc8bac-c8ba-cc8b-acc8-bacc8bacc888",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    currency: "USD",
    name: "Plaid Saving",
    mask: "1111",
    officialName: "Plaid Silver Standard 0.1% Interest Saving",
    institutionName: "Chase",
    status: "ACTIVE",
    linkStatus: "REFRESH_REQUIRED",
    kycStatus: "FAILED",
    blacklisted: false,
    maskedRoutingNumber: "1533",
  },
];

export const fundingAccounts: ApiEmbeddedFundingAccount[] = [
  {
    externalAccountRef: "17b987cc-457a-4a6a-a3e8-1cfe0c8b1914",
    accountType: "CASH",
    formattedAccountNumber: "CASH",
    currentBalance: {
      currency: "USD",
      amount: 99940.67,
    },
    availableBalance: {
      currency: "USD",
      amount: 99940.67,
    },
    pendingDepositAmount: {
      currency: "USD",
      amount: 90000,
    },
    status: "N/A",
  },
  {
    externalAccountRef: "BANK:0fc6eb9b-5e51-465b-8cf3-15a7b1fc9cd2",
    accountType: "CHECKING",
    formattedAccountNumber: "0000",
    currentBalance: {
      currency: "USD",
      amount: 110,
    },
    availableBalance: {
      currency: "USD",
      amount: 100,
    },
    status: "ACTIVE",
    name: "Bank of America",
  },
  {
    externalAccountRef: "DEBIT:1db932a0-72a5-41aa-a1e6-adbb7b2a00d8",
    accountType: "DEBIT",
    formattedAccountNumber: "VISA-0013",
    currentBalance: {
      currency: "USD",
    },
    availableBalance: {
      currency: "USD",
    },
    status: "ACTIVE",
  },
];

export const btcPrice: ApiEmbeddedCryptoPriceResponse = {
  indicativePrice: {
    currency: "USD",
    amount: 35927.4,
  },
  buyPrice: {
    currency: "USD",
    amount: 35900.4,
  },
  sellPrice: {
    currency: "USD",
    amount: 35800.4,
  },
};

export const ethPrice: ApiEmbeddedCryptoPriceResponse = {
  indicativePrice: {
    currency: "USD",
    amount: 1961.79,
  },
  buyPrice: {
    currency: "USD",
    amount: 1940.4,
  },
  sellPrice: {
    currency: "USD",
    amount: 1975.1,
  },
};

export const btcExecutePrice: ApiEmbeddedCryptoExecutePriceResponse = {
  buySellIndicator: "BUY",
  price: {
    currency: "USD",
    amount: 272.05,
  },
  quantity: {
    currency: "BTC",
    amount: 0.00764216,
  },
  unitPrice: {
    currency: "USD",
    amount: 35598.67,
  },
  indicativeValue: {
    currency: "USD",
    amount: 272.05,
  },
  fees: {
    currency: "USD",
    amount: 0.0,
  },
  total: {
    currency: "USD",
    amount: 272.05,
  },
};

export const ethExecutePrice: ApiEmbeddedCryptoExecutePriceResponse = {
  buySellIndicator: "BUY",
  price: {
    currency: "USD",
    amount: 25.0,
  },
  quantity: {
    currency: "ETH",
    amount: 0.01284178408337913,
  },
  unitPrice: {
    currency: "USD",
    amount: 1946.77,
  },
  indicativeValue: {
    currency: "USD",
    amount: 25.0,
  },
  fees: {
    currency: "USD",
    amount: 0.0,
  },
  total: {
    currency: "USD",
    amount: 25.0,
  },
};

export const cryptoCurrencyTransaction: ApiEmbeddedCryptoTransactionResponse = {
  transactionId: "01f0508c-e9a5-4a49-89eb-1f3567e1b50d",
  transactionType: "CRYPTO_CURRENCY",
  price: {
    currency: "USD",
    amount: 25,
  },
  quantity: {
    currency: "BTC",
    amount: 0.00058038,
  },
};

export const caesarsTokenInfo: ApiEmbeddedCaesarsTokenResponse = {
  rewardsPoints: 100000000,
  rewardsConversionRate: 250,
  rewardsAccountId: "19500637129",
  tokenId: "97cfdbc2-7623-42fc-9869-69adf76e840c",
};

export const ceasarsRedemptionResponse: ApiEmbeddedCaesarsCryptoTransactionResponse = {
  transactionId: "d4808cc8-6e26-456f-94c5-64c67af11957",
  transactionType: "REWARD",
  price: {
    currency: "USD",
    amount: 10,
  },
  quantity: {
    currency: "BTC",
    amount: 0.00764216,
  },
  rewardPointsBalance: 99995000.0,
};

export const cryptoAccounts: ApiEmbeddedPartnerPartyAccount[] = [
  {
    partnerPartyLinkId: "c42b4103-4297-4492-b213-8eaa4080015b",
    accountBalance: {
      currency: "BTC",
      amount: 0.014,
    },
    indicativeBalance: {
      currency: "USD",
      amount: 400.0,
    },
    sellBalance: {
      currency: "USD",
      amount: 404.0,
    },
  },
  {
    partnerPartyLinkId: "c42b4103-4297-4492-b213-8eaa4080015b",
    accountBalance: {
      currency: "ETH",
      amount: 0.13,
    },
    indicativeBalance: {
      currency: "USD",
      amount: 200.0,
    },
    sellBalance: {
      currency: "USD",
      amount: 202.0,
    },
  },
  {
    partnerPartyLinkId: "c42b4103-4297-4492-b213-8eaa4080015b",
    accountBalance: {
      currency: "BCH",
      amount: 0,
    },
    indicativeBalance: {
      currency: "USD",
      amount: 0,
    },
    sellBalance: {
      currency: "USD",
      amount: 0,
    },
  },
  {
    partnerPartyLinkId: "c42b4103-4297-4492-b213-8eaa4080015b",
    accountBalance: {
      currency: "LTC",
      amount: 1.28,
    },
    indicativeBalance: {
      currency: "USD",
      amount: 80.0,
    },
    sellBalance: {
      currency: "USD",
      amount: 81.0,
    },
  },
  {
    partnerPartyLinkId: "c42b4103-4297-4492-b213-8eaa4080015b",
    accountBalance: {
      currency: "DOGE",
      amount: 669.95,
    },
    indicativeBalance: {
      currency: "USD",
      amount: 40.0,
    },
    sellBalance: {
      currency: "USD",
      amount: 41.0,
    },
  },
  {
    partnerPartyLinkId: "c42b4103-4297-4492-b213-8eaa4080015b",
    accountBalance: {
      currency: "USDC",
      amount: 150,
    },
    indicativeBalance: {
      currency: "USD",
      amount: 150.0,
    },
    sellBalance: {
      currency: "USD",
      amount: 152.0,
    },
  },
];

export const userInfo: ApiEmbeddedPartnerPartyLinkInfo = {
  partner: {
    id: "bacc8bac-c8ba-cc8b-acc8-bacc8bacc888",
    name: "Synthetic Bakkt Partner (represents Bakkt)",
    status: "ACTIVE",
    serviceTypes: ["CRYPTO_CURRENCY", "INCENTIVE", "PARTY", "PAYOUT", "FUND_TRANSFER"],
    cryptoCurrencies: ["BTC", "ETH"],
    mobileUrls: {},
    desktopUrls: {},
    trusted: false,
    contactInfo: {
      name: "",
      phone: "",
      email: "help@bakkt.com",
    },
    inactivityTimeoutInSeconds: 1800,
    featureFlag: {
      debitCardLinkEnabled: true,
      achLinkEnabled: true,
      cashActivityReportEnabled: false,
      fundingAccessOnly: false,
      cashBalanceReportEnabled: false,
    },
  },
  party: {
    id: "8317656e-7263-44c0-8a16-b5a402d63f53",
    firstName: "Nichole",
    lastName: "Toy",
    alias: "qhcwuzkt2444",
    siftPartyRef: "45b799e6-6d2f-9692-d9ac-dcaa9255e307",
    sardinePartyRef: "aec38a0a-3106-db62-2e9f-394a63b8b9e9",
    brazePartyRef: "aec38a0a-3106-db62-2e9f-394a63b8b9e9",
    level: "LEVEL_4",
    status: "OPEN",
    ew9CertificationRequired: false,
    termsAndConditionAcceptanceRequired: false,
  },
  partnerPartyLink: {
    userName: "8317656e-7263-44c0-8a16-b5a402d63f53",
    tenant: "bakkt",
    partnerSsoRef: "8317656e-7263-44c0-8a16-b5a402d63f53bakkt",
    partnerPartyLink: {
      created: "2023-01-26T20:01:11.771563Z",
      id: "8c2b4be7-83d4-43dd-b2a0-89c76cf09697",
      status: "ACTIVE",
      partnerPartyRef: "8317656e-7263-44c0-8a16-b5a402d63f53bakkt",
    },
  },
  currencyConfigs: [
    {
      currency: "LTC",
      enabled: true,
      release: 0,
      functions: ["trade"],
      bgColor: "#345D9D",
      iconColor: "#FFFFFF",
      iconURL: "https://download.dev.bakkt.com/images/logo-litecoin.png",
      minimumBuy: 0.01,
      minimumSell: 0.01,
      minimumTransfer: 0.00000001,
      supportedPrecision: 8,
      name: "Litecoin",
      precision: 18,
    },
    {
      currency: "ETH",
      enabled: true,
      release: 0,
      functions: ["trade"],
      bgColor: "#FFFFFF",
      iconColor: "#3C416A",
      iconURL: "https://download.dev.bakkt.com/images/logo-ethereum.png",
      minimumBuy: 0.001,
      minimumSell: 0.001,
      minimumTransfer: 0.00000001,
      supportedPrecision: 8,
      name: "Ethereum",
      precision: 18,
    },
    {
      currency: "BTC",
      enabled: true,
      release: 0,
      functions: ["trade", "p2p", "merchant-payment"],
      bgColor: "#FF9416",
      iconColor: "#FFFFFF",
      iconURL: "https://download.dev.bakkt.com/images/logo-bitcoin.png",
      minimumBuy: 0.0001,
      minimumSell: 0.0001,
      minimumTransfer: 0.00000001,
      supportedPrecision: 8,
      name: "Bitcoin",
      precision: 8,
    },
    {
      currency: "BCH",
      enabled: false,
      release: 0,
      functions: ["trade"],
      bgColor: "#0AC18E",
      iconColor: "#FFFFFF",
      iconURL: "https://download.dev.bakkt.com/images/logo-bitcoin-cash.png",
      minimumBuy: 0.001,
      minimumSell: 0.001,
      minimumTransfer: 0.00000001,
      supportedPrecision: 8,
      name: "Bitcoin Cash",
      precision: 8,
    },
    {
      currency: "DOGE",
      enabled: false,
      release: 0,
      functions: ["trade"],
      bgColor: "#C2A633",
      iconColor: "#FFFFFF",
      iconURL: "https://download.dev.bakkt.com/images/logo-DOGE.png",
      minimumBuy: 0.01,
      minimumSell: 0.01,
      minimumTransfer: 0.01,
      supportedPrecision: 5,
      name: "Dogecoin",
      precision: 8,
    },
    {
      currency: "USDC",
      enabled: false,
      release: 0,
      functions: ["trade"],
      bgColor: "#2775CA",
      iconColor: "#FFFFFF",
      iconURL: "https://download.dev.bakkt.com/images/logo-USDC.png",
      minimumBuy: 0.01,
      minimumSell: 0.01,
      minimumTransfer: 0.01,
      supportedPrecision: 6,
      name: "USD Coin",
      precision: 18,
    },
  ],
};

export const partnerPartyInfo: ApiEmbeddedPartnerPartyProfileResponse = {
  profileId: "1d582dfa-c8d6-4d92-aede-6d331d8b1c69",
  fieldsAvailableFromPartner: [
    "TAX_ID_NUMBER",
    "OCCUPATION",
    "FIRST_NAME",
    "LAST_NAME",
    "ADDRESS",
    "PHONE",
    "DATE_OF_BIRTH",
    "EMAIL",
  ],
  Party: {
    partnerPartyRef: "1d582dfa-c8d6-4d92-aede-6d331d8b1c69bakkt",
    firstName: "Alberta",
    lastName: "Bobbeth Charleson",
    email: "cryptodemouser1@gmail.com",
    phone: "+18188364826",
    dateOfBirth: "1980-01-01",
    occupation: "developer",
    ew9Signature: "byungkwon jeon",
    address: {
      streetLine1: "3770 Canopy Chase",
      locality: "Cumming",
      region: "GA",
      postalCode: "30041",
      country: "AND",
    },
    termsAndConditionsPublishTime: "2023-04-04T16:26:50.185Z",
    privacyPolicyPublishTime: "2023-03-27T18:51:21.731Z",
    taxIdentifier: "*****2662",
  },
};

export const fullTaxId: ApiEmbeddedPartnerPartyTaxIdResponse = {
  taxIdentifier: "839982662",
};

export const assetActivity: ApiAssetActivity[] = [
  {
    id: "eab10480-ddd5-482b-b6aa-f8724df3441c",
    created: "2023-04-24T11:04:34.126308Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    partnerId: "bacc8bac-c8ba-cc8b-acc8-bacc8bacc888",
    type: "BUY",
    eventTime: "2023-04-24T11:04:33.776932Z",
    status: "COMPLETE",
    externalRef: "7888eb3b-32e4-4f1f-9abc-0197c1e808df",
    unitPrice: 27625.0,
    costBasisCurrency: "USD",
    costBasis: 11.0,
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -11.0,
    sourceCurrency: "USD",
    destinationName: "CRYPTO",
    destinationAssetType: "CRYPTO",
    destinationAmount: 0.00039819,
    destinationCurrency: "BTC",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Synthetic Bakkt Partner (represents Bakkt)",
      transactionId: "5b157f75-1dd3-44d3-89ea-8294e26bb542",
      transactionRef: "807f9bbc-75ee-44cd-a7ce-e38c0f312661",
      fundingType: "PARTY",
    },
    assetType: "CRYPTO",
  },
  {
    id: "34fa7d9e-4426-4a45-ade4-aeb27fce9f37",
    created: "2023-04-06T13:43:38.04039Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    partnerId: "bacc8bac-c8ba-cc8b-acc8-bacc8bacc888",
    type: "BUY",
    eventTime: "2023-04-06T13:43:37.518977Z",
    status: "COMPLETE",
    externalRef: "2c4bbb55-e3f7-48e1-8a67-01356992a3a7",
    unitPrice: 28021.41,
    costBasisCurrency: "USD",
    costBasis: 10.0,
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -10.0,
    sourceCurrency: "USD",
    destinationName: "CRYPTO",
    destinationAssetType: "CRYPTO",
    destinationAmount: 0.00035687,
    destinationCurrency: "BTC",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Synthetic Bakkt Partner (represents Bakkt)",
      transactionId: "e0869711-6a8c-4905-a525-f308f0aa0671",
      transactionRef: "4ee10eba-f06f-4f97-a931-7d50dd7b67fa",
      fundingType: "PARTY",
    },
    assetType: "CRYPTO",
  },
  {
    id: "99d71353-c645-435e-879e-10fd484f228b",
    created: "2023-04-24T11:03:12.219323Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "SELL",
    eventTime: "2023-04-24T11:03:11.796432Z",
    status: "COMPLETE",
    externalRef: "fde1ce6d-1e91-4e1f-9884-003dfca5d769",
    unitPrice: 1833.8,
    costBasisCurrency: "USD",
    costBasis: 10.0,
    sourceName: "CRYPTO",
    sourceAssetType: "CRYPTO",
    sourceAmount: -0.00545317,
    sourceCurrency: "ETH",
    destinationName: "CASH",
    destinationAssetType: "CASH",
    destinationAmount: 10.0,
    destinationCurrency: "USD",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Synthetic Bakkt Partner (represents Bakkt)",
      transactionId: "b150647c-a071-4529-8233-0dc6ce221fe9",
      transactionRef: "a78ce2b4-7539-4213-b59c-4ce82af2aac1",
      fundingType: "PARTY",
    },
    assetType: "CRYPTO",
  },
  {
    id: "5c826e07-9e82-4f35-9677-24c30e4cdeaa",
    created: "2023-04-24T11:01:47.554292Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    partnerId: "bacc8bac-c8ba-cc8b-acc8-bacc8bacc888",
    type: "BUY",
    eventTime: "2023-04-24T11:01:47.14266Z",
    status: "COMPLETE",
    externalRef: "490be2e0-3f63-4a19-bdeb-df749e46bb24",
    unitPrice: 1858.38,
    costBasisCurrency: "USD",
    costBasis: 10.0,
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -10.0,
    sourceCurrency: "USD",
    destinationName: "CRYPTO",
    destinationAssetType: "CRYPTO",
    destinationAmount: 0.00538103,
    destinationCurrency: "ETH",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Synthetic Bakkt Partner (represents Bakkt)",
      transactionId: "069d6f95-9437-40e2-a278-a2b8f094df0c",
      transactionRef: "9eebe641-0749-4c25-9e77-c2a210e2bc8c",
      fundingType: "PARTY",
    },
    assetType: "CRYPTO",
  },
  {
    id: "6dcadcbc-2fe3-475d-aa91-3602dffe1c05",
    created: "2023-04-24T09:45:14.718896Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "WITHDRAW",
    eventTime: "2023-04-24T09:45:14.718862Z",
    status: "COMPLETE",
    externalRef: "28ed9c1f-7022-4b3c-a93e-f6ffa18bdc5d",
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -10.0,
    sourceCurrency: "USD",
    sourceDetails: "CASH",
    destinationName: "Navy Federal Credit Union",
    destinationAssetType: "DEBIT_CARD",
    destinationAmount: 10.0,
    destinationCurrency: "USD",
    destinationDetails: "0013",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Bakkt",
      fundingType: "PARTY",
    },
    assetType: "DEBIT_CARD",
  },
  {
    id: "01696c34-4931-44f2-9668-5adbd52f60d8",
    created: "2023-04-24T09:44:32.698465Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "DEPOSIT",
    eventTime: "2023-04-24T09:44:32.698435Z",
    status: "COMPLETE",
    externalRef: "41208193-abd6-489b-ae71-89a799fbc81a",
    sourceName: "Navy Federal Credit Union",
    sourceAssetType: "DEBIT_CARD",
    sourceAmount: -10.0,
    sourceCurrency: "USD",
    sourceDetails: "0013",
    destinationName: "CASH",
    destinationAssetType: "CASH",
    destinationAmount: 10.0,
    destinationCurrency: "USD",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Bakkt",
      fundingType: "PARTY",
    },
    assetType: "DEBIT_CARD",
  },
  {
    id: "617d91ab-095a-48e3-8a4f-381d9a10a24a",
    created: "2023-04-12T12:19:26.349422Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    partnerId: "bacc8bac-c8ba-cc8b-acc8-bacc8bacc888",
    type: "BUY",
    eventTime: "2023-04-12T12:19:26.019935Z",
    status: "COMPLETE",
    externalRef: "db2fd8b7-972f-409d-9254-ad8fc3017341",
    unitPrice: 1883.8,
    costBasisCurrency: "USD",
    costBasis: 10.0,
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -10.0,
    sourceCurrency: "USD",
    destinationName: "CRYPTO",
    destinationAssetType: "CRYPTO",
    destinationAmount: 0.00530843,
    destinationCurrency: "ETH",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Synthetic Bakkt Partner (represents Bakkt)",
      transactionId: "f727aa9d-fcc1-4a27-99f6-e40e0413025c",
      transactionRef: "9de0c818-6f48-46e8-88ea-c47c591fd857",
      fundingType: "PARTY",
    },
    assetType: "CRYPTO",
  },
  {
    id: "f4f642fc-bba6-4f12-96fc-a91d74d10cf0",
    created: "2023-04-05T18:21:27.007535Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "SELL",
    eventTime: "2023-04-05T18:21:26.62245Z",
    status: "COMPLETE",
    externalRef: "acd70ddf-661a-4f7a-a658-1eb1a03bcb7c",
    unitPrice: 1886.03,
    costBasisCurrency: "USD",
    costBasis: 10.0,
    sourceName: "CRYPTO",
    sourceAssetType: "CRYPTO",
    sourceAmount: -0.00530213,
    sourceCurrency: "ETH",
    destinationName: "CASH",
    destinationAssetType: "CASH",
    destinationAmount: 10.0,
    destinationCurrency: "USD",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Synthetic Bakkt Partner (represents Bakkt)",
      transactionId: "1f0c350b-3a12-4f73-b423-92c3f8312d32",
      transactionRef: "af18c465-3e84-4432-8051-5b1eac01ebb9",
      fundingType: "PARTY",
    },
    assetType: "CRYPTO",
  },
  {
    id: "f29c6ec8-5193-407e-abf0-fa946f027171",
    created: "2023-04-04T15:09:09.844177Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    partnerId: "bacc8bac-c8ba-cc8b-acc8-bacc8bacc888",
    type: "BUY",
    eventTime: "2023-04-04T15:09:09.388503Z",
    status: "COMPLETE",
    externalRef: "d0bd8652-5e9a-498d-82ad-0efc139b37fa",
    unitPrice: 28307.76,
    costBasisCurrency: "USD",
    costBasis: 15.0,
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -15.0,
    sourceCurrency: "USD",
    destinationName: "CRYPTO",
    destinationAssetType: "CRYPTO",
    destinationAmount: 0.00052989,
    destinationCurrency: "BTC",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Synthetic Bakkt Partner (represents Bakkt)",
      transactionId: "ee8865cb-30bc-4af3-a701-e46b49aae6f9",
      transactionRef: "dc9bc0f8-ccc6-4d58-98ba-505d375dce53",
      fundingType: "PARTY",
    },
    assetType: "CRYPTO",
  },
  {
    id: "392ccaa6-4602-4ee4-ba58-90dc0e16b948",
    created: "2023-03-29T19:29:18.541455Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "WITHDRAW",
    eventTime: "2023-03-29T19:29:18.541396Z",
    status: "COMPLETE",
    externalRef: "704b7169-2576-4d35-9e56-1867907fa10b",
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -10.0,
    sourceCurrency: "USD",
    destinationName: "Chase Savings",
    destinationAmount: 10.0,
    destinationCurrency: "USD",
    destinationDetails: "**** 1111",
    fee: 0,
    feeCurrency: "USD",
    groupIdentifier: "5ddf7e74-be6a-48bf-ba9a-4f9ef6a12137",
    groupAssetType: "CASH",
    groupActivityType: "WITHDRAW",
    groupedActivities: [
      {
        id: "8e595dff-571e-4e72-ba21-293d3ece8899",
        created: "2023-03-29T19:29:18.541446Z",
        partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
        type: "WITHDRAW",
        eventTime: "2023-03-29T19:29:18.541396Z",
        status: "PENDING",
        externalRef: "704b7169-2576-4d35-9e56-1867907fa10b",
        sourceName: "CASH",
        sourceAssetType: "CASH",
        sourceAmount: -10.0,
        sourceCurrency: "USD",
        destinationName: "Chase Savings",
        destinationAmount: 10.0,
        destinationCurrency: "USD",
        destinationDetails: "**** 1111",
        fee: 0,
        feeCurrency: "USD",
        groupIdentifier: "5ddf7e74-be6a-48bf-ba9a-4f9ef6a12137",
        groupAssetType: "CASH",
        groupActivityType: "WITHDRAW",
        assetType: "CASH",
      },
    ],
    principalActivityDetail: {
      name: "Bakkt",
      fundingType: "PARTY",
    },
    assetType: "CASH",
  },
  {
    id: "11fd6d66-f9a4-49fa-8c98-f6f7bfcf3138",
    created: "2023-03-29T19:28:12.09728Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "DEPOSIT",
    eventTime: "2023-03-29T19:28:12.097104Z",
    status: "COMPLETE",
    externalRef: "3549eca1-e35e-4ef8-9f28-c656e549f749",
    sourceName: "Chase Savings",
    sourceAmount: -10.0,
    sourceCurrency: "USD",
    sourceDetails: "**** 1111",
    destinationName: "CASH",
    destinationAssetType: "CASH",
    destinationAmount: 10.0,
    destinationCurrency: "USD",
    fee: 0,
    feeCurrency: "USD",
    groupIdentifier: "a44b1af2-a6ae-4fad-96dd-9bd285bd26ab",
    groupAssetType: "CASH",
    groupActivityType: "DEPOSIT",
    groupedActivities: [
      {
        id: "803c3c82-1bdc-4aa6-abca-d91e2876b70e",
        created: "2023-03-29T19:28:12.097148Z",
        partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
        type: "DEPOSIT",
        eventTime: "2023-03-29T19:28:12.097104Z",
        status: "PENDING",
        externalRef: "3549eca1-e35e-4ef8-9f28-c656e549f749",
        sourceName: "Chase Savings",
        sourceAmount: -10.0,
        sourceCurrency: "USD",
        sourceDetails: "**** 1111",
        destinationName: "CASH",
        destinationAssetType: "CASH",
        destinationAmount: 10.0,
        destinationCurrency: "USD",
        fee: 0,
        feeCurrency: "USD",
        groupIdentifier: "a44b1af2-a6ae-4fad-96dd-9bd285bd26ab",
        groupAssetType: "CASH",
        groupActivityType: "DEPOSIT",
        assetType: "CASH",
      },
      {
        id: "11fd6d66-f9a4-49fa-8c98-f6f7bfcf3138",
        created: "2023-03-29T19:28:12.09728Z",
        partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
        type: "DEPOSIT",
        eventTime: "2023-03-29T19:28:12.097104Z",
        status: "COMPLETE",
        externalRef: "3549eca1-e35e-4ef8-9f28-c656e549f749",
        sourceName: "Chase Savings",
        sourceAmount: -10.0,
        sourceCurrency: "USD",
        sourceDetails: "**** 1111",
        destinationName: "CASH",
        destinationAssetType: "CASH",
        destinationAmount: 10.0,
        destinationCurrency: "USD",
        fee: 0,
        feeCurrency: "USD",
        groupIdentifier: "a44b1af2-a6ae-4fad-96dd-9bd285bd26ab",
        groupAssetType: "CASH",
        groupActivityType: "DEPOSIT",
        assetType: "CASH",
      },
    ],
    principalActivityDetail: {
      name: "Bakkt",
      fundingType: "PARTY",
    },
    assetType: "CASH",
  },
  {
    id: "b70f1aae-9d2b-4539-84d3-dd90ba81f087",
    created: "2023-03-28T23:52:56.624943Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "WITHDRAW",
    eventTime: "2023-03-28T23:52:56.624877Z",
    status: "COMPLETE",
    externalRef: "81393c0f-d4d9-4e58-b5bf-bbdf81f3be12",
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -100.0,
    sourceCurrency: "USD",
    sourceDetails: "CASH",
    destinationName: "Navy Federal Credit Union",
    destinationAssetType: "DEBIT_CARD",
    destinationAmount: 100.0,
    destinationCurrency: "USD",
    destinationDetails: "0013",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Bakkt",
      fundingType: "PARTY",
    },
    assetType: "DEBIT_CARD",
  },
  {
    id: "f4dc509b-a830-40c8-9e1a-6e5e5f89e3f1",
    created: "2023-03-26T21:12:02.491849Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "DEPOSIT",
    eventTime: "2023-03-26T21:12:02.491704Z",
    status: "COMPLETE",
    externalRef: "a85f866f-3d98-4259-8ca5-d0cd5fb8f9fa",
    sourceName: "Chase Savings",
    sourceAmount: -100.0,
    sourceCurrency: "USD",
    sourceDetails: "**** 1111",
    destinationName: "CASH",
    destinationAssetType: "CASH",
    destinationAmount: 100.0,
    destinationCurrency: "USD",
    fee: 0,
    feeCurrency: "USD",
    groupIdentifier: "a7b9b0d8-1224-47cd-ad2b-e3df3847ef2d",
    groupAssetType: "CASH",
    groupActivityType: "DEPOSIT",
    groupedActivities: [
      {
        id: "5cda64df-3ea5-49ea-9cbe-646b7edd9517",
        created: "2023-03-26T21:12:02.491807Z",
        partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
        type: "DEPOSIT",
        eventTime: "2023-03-26T21:12:02.491704Z",
        status: "PENDING",
        externalRef: "a85f866f-3d98-4259-8ca5-d0cd5fb8f9fa",
        sourceName: "Chase Savings",
        sourceAmount: -100.0,
        sourceCurrency: "USD",
        sourceDetails: "**** 1111",
        destinationName: "CASH",
        destinationAssetType: "CASH",
        destinationAmount: 100.0,
        destinationCurrency: "USD",
        fee: 0,
        feeCurrency: "USD",
        groupIdentifier: "a7b9b0d8-1224-47cd-ad2b-e3df3847ef2d",
        groupAssetType: "CASH",
        groupActivityType: "DEPOSIT",
        assetType: "CASH",
      },
      {
        id: "f4dc509b-a830-40c8-9e1a-6e5e5f89e3f1",
        created: "2023-03-26T21:12:02.491849Z",
        partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
        type: "DEPOSIT",
        eventTime: "2023-03-26T21:12:02.491704Z",
        status: "COMPLETE",
        externalRef: "a85f866f-3d98-4259-8ca5-d0cd5fb8f9fa",
        sourceName: "Chase Savings",
        sourceAmount: -100.0,
        sourceCurrency: "USD",
        sourceDetails: "**** 1111",
        destinationName: "CASH",
        destinationAssetType: "CASH",
        destinationAmount: 100.0,
        destinationCurrency: "USD",
        fee: 0,
        feeCurrency: "USD",
        groupIdentifier: "a7b9b0d8-1224-47cd-ad2b-e3df3847ef2d",
        groupAssetType: "CASH",
        groupActivityType: "DEPOSIT",
        assetType: "CASH",
      },
    ],
    principalActivityDetail: {
      name: "Bakkt",
      fundingType: "PARTY",
    },
    assetType: "CASH",
  },
  {
    id: "f3e16eca-78dd-41e8-9b7a-7b809819d1cc",
    created: "2023-03-16T03:28:46.940824Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "SELL",
    eventTime: "2023-03-16T03:28:46.801452Z",
    status: "COMPLETE",
    externalRef: "a8458db9-0303-4689-ad9f-b2d1e09ce958",
    unitPrice: 24190.23,
    costBasisCurrency: "USD",
    costBasis: 20.0,
    sourceName: "CRYPTO",
    sourceAssetType: "CRYPTO",
    sourceAmount: -0.00082678,
    sourceCurrency: "BTC",
    destinationName: "CASH",
    destinationAssetType: "CASH",
    destinationAmount: 20.0,
    destinationCurrency: "USD",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Synthetic Bakkt Partner (represents Bakkt)",
      transactionId: "26813eb1-097b-47ca-a8bc-e79ea66a63a9",
      transactionRef: "3af6ed47-ccfc-4a0d-8647-51239a732a2f",
      fundingType: "PARTY",
    },
    assetType: "CRYPTO",
  },
  {
    id: "e0cee7cb-5d9b-4322-bbbc-78b770708542",
    created: "2023-03-16T03:28:24.575696Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    partnerId: "bacc8bac-c8ba-cc8b-acc8-bacc8bacc888",
    type: "BUY",
    eventTime: "2023-03-16T03:28:24.407524Z",
    status: "COMPLETE",
    externalRef: "2ce7330a-07b7-45ab-9a6a-7d353a643846",
    unitPrice: 1660.05,
    costBasisCurrency: "USD",
    costBasis: 10.0,
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -10.0,
    sourceCurrency: "USD",
    destinationName: "CRYPTO",
    destinationAssetType: "CRYPTO",
    destinationAmount: 0.00602393,
    destinationCurrency: "ETH",
    fee: 0,
    feeCurrency: "USD",
    principalActivityDetail: {
      name: "Synthetic Bakkt Partner (represents Bakkt)",
      transactionId: "ede3af4b-0901-43b7-93e2-eb07b7d4cbba",
      transactionRef: "735951f2-bfdf-4eb9-a096-ccd739e9aa03",
      fundingType: "PARTY",
    },
    assetType: "CRYPTO",
  },
  {
    id: "d6f3fcb4-7e74-4968-9be5-8be437ee6d24",
    created: "2023-03-16T03:28:07.070456Z",
    partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
    type: "WITHDRAW",
    eventTime: "2023-03-16T03:28:07.070351Z",
    status: "COMPLETE",
    externalRef: "6046c887-77e0-4b96-b170-0fb63aaf50a1",
    sourceName: "CASH",
    sourceAssetType: "CASH",
    sourceAmount: -100.0,
    sourceCurrency: "USD",
    destinationName: "Chase Savings",
    destinationAmount: 100.0,
    destinationCurrency: "USD",
    destinationDetails: "**** 1111",
    fee: 0,
    feeCurrency: "USD",
    groupIdentifier: "a6246aaa-a622-4b2e-af11-5b7d969ec2ea",
    groupAssetType: "CASH",
    groupActivityType: "WITHDRAW",
    groupedActivities: [
      {
        id: "2f347e71-05a7-4a32-9697-a67cc48d962d",
        created: "2023-03-16T03:28:07.070434Z",
        partyId: "8317656e-7263-44c0-8a16-b5a402d63f53",
        type: "WITHDRAW",
        eventTime: "2023-03-16T03:28:07.070351Z",
        status: "PENDING",
        externalRef: "6046c887-77e0-4b96-b170-0fb63aaf50a1",
        sourceName: "CASH",
        sourceAssetType: "CASH",
        sourceAmount: -100.0,
        sourceCurrency: "USD",
        destinationName: "Chase Savings",
        destinationAmount: 100.0,
        destinationCurrency: "USD",
        destinationDetails: "**** 1111",
        fee: 0,
        feeCurrency: "USD",
        groupIdentifier: "a6246aaa-a622-4b2e-af11-5b7d969ec2ea",
        groupAssetType: "CASH",
        groupActivityType: "WITHDRAW",
        assetType: "CASH",
      },
    ],
    principalActivityDetail: {
      name: "Bakkt",
      fundingType: "PARTY",
    },
    assetType: "CASH",
  },
];

export const historicalCryptoCurrencyPrices: GetHistoricalCryptoCurrencyPriceResponse = {
  sourceCurrency: "BTC",
  targetCurrency: "USD",
  historicalPrices: [
    {
      price: 28175.15,
      time: "2023-10-17T21:00:00Z",
    },
    {
      price: 28401.26,
      time: "2023-10-16T21:00:00Z",
    },
    {
      price: 27194.75,
      time: "2023-10-15T21:00:00Z",
    },
    {
      price: 26849.95,
      time: "2023-10-14T21:00:00Z",
    },
    {
      price: 26977.65,
      time: "2023-10-13T21:00:00Z",
    },
    {
      price: 26737.05,
      time: "2023-10-12T21:00:01Z",
    },
    {
      price: 26717.85,
      time: "2023-10-11T21:00:00Z",
    },
    {
      price: 27390.05,
      time: "2023-10-10T21:00:00Z",
    },
    {
      price: 27572.65,
      time: "2023-10-09T21:00:01Z",
    },
    {
      price: 27923.85,
      time: "2023-10-08T21:00:00Z",
    },
    {
      price: 27933.35,
      time: "2023-10-07T21:00:00Z",
    },
    {
      price: 27992.85,
      time: "2023-10-06T21:00:00Z",
    },
    {
      price: 27477.95,
      time: "2023-10-05T21:00:00Z",
    },
    {
      price: 27663.95,
      time: "2023-10-04T21:00:00Z",
    },
    {
      price: 27389.77,
      time: "2023-10-03T21:00:00Z",
    },
    {
      price: 27838.55,
      time: "2023-10-02T21:00:00Z",
    },
    {
      price: 27113.5,
      time: "2023-10-01T21:00:00Z",
    },
    {
      price: 27072.85,
      time: "2023-09-30T21:00:03Z",
    },
    {
      price: 26904.15,
      time: "2023-09-29T21:00:00Z",
    },
    {
      price: 27089.59,
      time: "2023-09-28T21:00:00Z",
    },
    {
      price: 26237.94,
      time: "2023-09-27T21:00:00Z",
    },
    {
      price: 26293.01,
      time: "2023-09-25T21:00:00Z",
    },
    {
      price: 26502.4,
      time: "2023-09-24T21:00:00Z",
    },
    {
      price: 26596.48,
      time: "2023-09-23T21:00:00Z",
    },
    {
      price: 26536.05,
      time: "2023-09-22T21:00:00Z",
    },
    {
      price: 26600.01,
      time: "2023-09-21T21:00:00Z",
    },
    {
      price: 27092.83,
      time: "2023-09-20T21:00:00Z",
    },
    {
      price: 27192.52,
      time: "2023-09-19T21:00:00Z",
    },
    {
      price: 26771.13,
      time: "2023-09-18T21:00:00Z",
    },
    {
      price: 26445.08,
      time: "2023-09-17T21:00:00Z",
    },
    {
      price: 26546.66,
      time: "2023-09-16T21:00:00Z",
    },
    {
      price: 26417.1,
      time: "2023-09-15T21:00:00Z",
    },
    {
      price: 26580.23,
      time: "2023-09-14T21:00:00Z",
    },
    {
      price: 26223.18,
      time: "2023-09-13T21:00:00Z",
    },
    {
      price: 26076.48,
      time: "2023-09-12T21:00:00Z",
    },
    {
      price: 25086.85,
      time: "2023-09-11T21:00:00Z",
    },
    {
      price: 25817.1,
      time: "2023-09-10T21:00:00Z",
    },
    {
      price: 25861.9,
      time: "2023-09-09T21:00:00Z",
    },
    {
      price: 25901.08,
      time: "2023-09-08T21:00:00Z",
    },
    {
      price: 26004.48,
      time: "2023-09-07T21:00:00Z",
    },
    {
      price: 25670.65,
      time: "2023-09-06T21:00:00Z",
    },
    {
      price: 25702.16,
      time: "2023-09-05T21:00:00Z",
    },
    {
      price: 25823.55,
      time: "2023-09-04T21:00:00Z",
    },
    {
      price: 26043.5,
      time: "2023-09-03T21:00:00Z",
    },
    {
      price: 25849.16,
      time: "2023-09-02T21:00:00Z",
    },
    {
      price: 25760.75,
      time: "2023-09-01T21:00:00Z",
    },
    {
      price: 26002.86,
      time: "2023-08-31T21:00:00Z",
    },
    {
      price: 27253.44,
      time: "2023-08-30T21:00:00Z",
    },
    {
      price: 27581.48,
      time: "2023-08-29T21:00:00Z",
    },
    {
      price: 25982.35,
      time: "2023-08-28T21:00:00Z",
    },
    {
      price: 26081.42,
      time: "2023-08-27T21:00:00Z",
    },
    {
      price: 26024.0,
      time: "2023-08-26T21:00:00Z",
    },
    {
      price: 26047.6,
      time: "2023-08-25T21:00:00Z",
    },
    {
      price: 26002.75,
      time: "2023-08-24T21:00:00Z",
    },
    {
      price: 26591.64,
      time: "2023-08-23T21:00:00Z",
    },
    {
      price: 25849.9,
      time: "2023-08-22T21:00:00Z",
    },
    {
      price: 26109.92,
      time: "2023-08-21T21:00:00Z",
    },
    {
      price: 26230.2,
      time: "2023-08-20T21:00:00Z",
    },
    {
      price: 26053.65,
      time: "2023-08-19T21:00:00Z",
    },
    {
      price: 26076.46,
      time: "2023-08-18T21:01:19Z",
    },
    {
      price: 27629.0,
      time: "2023-08-17T21:00:00Z",
    },
    {
      price: 28934.28,
      time: "2023-08-16T21:00:00Z",
    },
    {
      price: 29171.6,
      time: "2023-08-15T21:00:00Z",
    },
    {
      price: 29365.8,
      time: "2023-08-14T21:00:00Z",
    },
    {
      price: 29401.83,
      time: "2023-08-13T21:00:00Z",
    },
    {
      price: 29410.43,
      time: "2023-08-12T21:00:00Z",
    },
    {
      price: 29381.8,
      time: "2023-08-11T21:00:00Z",
    },
    {
      price: 29417.2,
      time: "2023-08-10T21:00:00Z",
    },
    {
      price: 29479.55,
      time: "2023-08-09T21:00:00Z",
    },
    {
      price: 29993.3,
      time: "2023-08-08T21:00:00Z",
    },
    {
      price: 29168.25,
      time: "2023-08-07T21:01:27Z",
    },
    {
      price: 29096.75,
      time: "2023-08-06T21:00:00Z",
    },
    {
      price: 29026.55,
      time: "2023-08-05T21:00:00Z",
    },
    {
      price: 28933.74,
      time: "2023-08-04T21:00:00Z",
    },
    {
      price: 29283.16,
      time: "2023-08-03T21:00:00Z",
    },
    {
      price: 29118.84,
      time: "2023-08-02T21:00:00Z",
    },
    {
      price: 29210.32,
      time: "2023-08-01T21:00:17Z",
    },
    {
      price: 29209.25,
      time: "2023-07-31T21:00:00Z",
    },
    {
      price: 29266.88,
      time: "2023-07-30T21:00:00Z",
    },
    {
      price: 29355.54,
      time: "2023-07-29T21:00:00Z",
    },
    {
      price: 29341.36,
      time: "2023-07-28T21:00:00Z",
    },
    {
      price: 29133.95,
      time: "2023-07-27T21:00:00Z",
    },
    {
      price: 29593.3,
      time: "2023-07-26T21:00:00Z",
    },
    {
      price: 29228.65,
      time: "2023-07-25T21:00:00Z",
    },
    {
      price: 29142.29,
      time: "2023-07-24T21:00:00Z",
    },
    {
      price: 30140.8,
      time: "2023-07-23T21:00:00Z",
    },
    {
      price: 29832.34,
      time: "2023-07-22T21:00:00Z",
    },
    {
      price: 29883.48,
      time: "2023-07-21T21:00:00Z",
    },
    {
      price: 29724.38,
      time: "2023-07-20T21:00:00Z",
    },
    {
      price: 29963.61,
      time: "2023-07-19T21:00:00Z",
    },
    {
      price: 29781.84,
      time: "2023-07-18T21:00:00Z",
    },
    {
      price: 29928.0,
      time: "2023-07-17T21:00:00Z",
    },
    {
      price: 30278.2,
      time: "2023-07-16T21:00:00Z",
    },
    {
      price: 30314.18,
      time: "2023-07-15T21:00:00Z",
    },
    {
      price: 30205.0,
      time: "2023-07-14T21:00:00Z",
    },
    {
      price: 31366.52,
      time: "2023-07-13T21:00:00Z",
    },
    {
      price: 30350.29,
      time: "2023-07-12T21:00:00Z",
    },
    {
      price: 30579.8,
      time: "2023-07-11T21:00:00Z",
    },
    {
      price: 30777.82,
      time: "2023-07-10T21:00:00Z",
    },
    {
      price: 30202.56,
      time: "2023-07-09T21:00:00Z",
    },
    {
      price: 30159.4,
      time: "2023-07-08T21:00:00Z",
    },
    {
      price: 30263.0,
      time: "2023-07-07T21:00:00Z",
    },
    {
      price: 30311.24,
      time: "2023-07-06T21:00:00Z",
    },
    {
      price: 30473.7,
      time: "2023-07-05T21:00:00Z",
    },
    {
      price: 30802.69,
      time: "2023-07-04T21:00:00Z",
    },
    {
      price: 31132.72,
      time: "2023-07-03T21:00:00Z",
    },
    {
      price: 30592.1,
      time: "2023-07-02T21:00:00Z",
    },
    {
      price: 30594.0,
      time: "2023-07-01T21:00:00Z",
    },
    {
      price: 30393.02,
      time: "2023-06-30T21:00:00Z",
    },
    {
      price: 30402.9,
      time: "2023-06-29T21:00:00Z",
    },
    {
      price: 30101.5,
      time: "2023-06-28T21:00:00Z",
    },
    {
      price: 30656.55,
      time: "2023-06-27T21:00:00Z",
    },
    {
      price: 30160.51,
      time: "2023-06-26T21:00:00Z",
    },
    {
      price: 30374.06,
      time: "2023-06-25T21:00:00Z",
    },
    {
      price: 30521.5,
      time: "2023-06-24T21:00:00Z",
    },
    {
      price: 30935.64,
      time: "2023-06-23T21:00:00Z",
    },
    {
      price: 30158.92,
      time: "2023-06-22T21:00:01Z",
    },
    {
      price: 29990.2,
      time: "2023-06-21T21:00:00Z",
    },
    {
      price: 28170.58,
      time: "2023-06-20T21:00:00Z",
    },
    {
      price: 26717.48,
      time: "2023-06-19T21:00:01Z",
    },
    {
      price: 26472.66,
      time: "2023-06-18T21:00:00Z",
    },
    {
      price: 26535.42,
      time: "2023-06-17T21:00:47Z",
    },
    {
      price: 26393.38,
      time: "2023-06-16T21:00:00Z",
    },
    {
      price: 25546.5,
      time: "2023-06-15T21:00:00Z",
    },
    {
      price: 24919.52,
      time: "2023-06-14T21:00:01Z",
    },
    {
      price: 25850.05,
      time: "2023-06-13T21:00:00Z",
    },
    {
      price: 25894.2,
      time: "2023-06-12T21:00:00Z",
    },
    {
      price: 26132.16,
      time: "2023-06-11T21:00:00Z",
    },
    {
      price: 25769.66,
      time: "2023-06-10T21:00:00Z",
    },
    {
      price: 26448.08,
      time: "2023-06-09T21:00:00Z",
    },
    {
      price: 26643.23,
      time: "2023-06-08T21:00:00Z",
    },
    {
      price: 26364.66,
      time: "2023-06-07T21:00:00Z",
    },
    {
      price: 26944.85,
      time: "2023-06-06T21:00:00Z",
    },
    {
      price: 25638.0,
      time: "2023-06-05T21:00:00Z",
    },
    {
      price: 27249.14,
      time: "2023-06-04T21:00:00Z",
    },
    {
      price: 27071.6,
      time: "2023-06-03T21:00:00Z",
    },
    {
      price: 27184.02,
      time: "2023-06-02T21:00:00Z",
    },
    {
      price: 26864.52,
      time: "2023-06-01T21:00:00Z",
    },
    {
      price: 27114.68,
      time: "2023-05-31T21:00:00Z",
    },
    {
      price: 27772.31,
      time: "2023-05-30T21:00:00Z",
    },
    {
      price: 27689.24,
      time: "2023-05-29T21:00:00Z",
    },
    {
      price: 27568.31,
      time: "2023-05-28T21:00:00Z",
    },
    {
      price: 26759.02,
      time: "2023-05-27T21:00:00Z",
    },
    {
      price: 26759.5,
      time: "2023-05-26T21:00:00Z",
    },
    {
      price: 26490.53,
      time: "2023-05-25T21:00:00Z",
    },
    {
      price: 26398.94,
      time: "2023-05-24T21:00:00Z",
    },
    {
      price: 27218.36,
      time: "2023-05-23T21:00:00Z",
    },
    {
      price: 26892.2,
      time: "2023-05-22T21:00:00Z",
    },
    {
      price: 26847.08,
      time: "2023-05-21T21:00:00Z",
    },
    {
      price: 27023.05,
      time: "2023-05-20T21:00:00Z",
    },
    {
      price: 26837.82,
      time: "2023-05-19T21:00:00Z",
    },
    {
      price: 26725.92,
      time: "2023-05-18T21:00:00Z",
    },
    {
      price: 27339.2,
      time: "2023-05-17T21:00:00Z",
    },
    {
      price: 26953.24,
      time: "2023-05-16T21:00:00Z",
    },
    {
      price: 27354.22,
      time: "2023-05-15T21:00:00Z",
    },
    {
      price: 26948.6,
      time: "2023-05-14T21:00:00Z",
    },
    {
      price: 26921.4,
      time: "2023-05-13T21:00:00Z",
    },
    {
      price: 26448.45,
      time: "2023-05-12T21:00:00Z",
    },
    {
      price: 27005.24,
      time: "2023-05-11T21:00:00Z",
    },
    {
      price: 27884.08,
      time: "2023-05-10T21:00:00Z",
    },
    {
      price: 27653.96,
      time: "2023-05-09T21:00:00Z",
    },
    {
      price: 27560.76,
      time: "2023-05-08T21:00:00Z",
    },
    {
      price: 28941.35,
      time: "2023-05-07T21:00:00Z",
    },
    {
      price: 28849.32,
      time: "2023-05-06T21:00:00Z",
    },
    {
      price: 29541.63,
      time: "2023-05-05T21:00:00Z",
    },
    {
      price: 28885.28,
      time: "2023-05-04T21:00:00Z",
    },
    {
      price: 28530.85,
      time: "2023-05-03T21:00:00Z",
    },
    {
      price: 28697.38,
      time: "2023-05-02T21:00:00Z",
    },
    {
      price: 27689.75,
      time: "2023-05-01T21:00:00Z",
    },
    {
      price: 29350.01,
      time: "2023-04-30T21:00:00Z",
    },
    {
      price: 29247.16,
      time: "2023-04-29T21:00:00Z",
    },
    {
      price: 29349.47,
      time: "2023-04-28T21:00:00Z",
    },
    {
      price: 29647.63,
      time: "2023-04-27T21:00:00Z",
    },
    {
      price: 28382.56,
      time: "2023-04-26T21:00:00Z",
    },
    {
      price: 27984.88,
      time: "2023-04-25T21:00:00Z",
    },
    {
      price: 27464.46,
      time: "2023-04-24T21:00:00Z",
    },
    {
      price: 27501.12,
      time: "2023-04-23T21:00:00Z",
    },
    {
      price: 27639.0,
      time: "2023-04-22T21:00:00Z",
    },
    {
      price: 27270.85,
      time: "2023-04-21T21:00:00Z",
    },
    {
      price: 28207.0,
      time: "2023-04-20T21:00:00Z",
    },
    {
      price: 29245.2,
      time: "2023-04-19T21:00:00Z",
    },
    {
      price: 30409.68,
      time: "2023-04-18T21:00:00Z",
    },
    {
      price: 29459.18,
      time: "2023-04-17T21:00:00Z",
    },
    {
      price: 30357.06,
      time: "2023-04-16T21:00:00Z",
    },
    {
      price: 30311.99,
      time: "2023-04-15T21:00:00Z",
    },
    {
      price: 30472.34,
      time: "2023-04-14T21:00:00Z",
    },
    {
      price: 30290.1,
      time: "2023-04-13T21:00:00Z",
    },
    {
      price: 29963.98,
      time: "2023-04-12T21:00:00Z",
    },
    {
      price: 30192.2,
      time: "2023-04-11T21:00:00Z",
    },
    {
      price: 29143.32,
      time: "2023-04-10T21:00:00Z",
    },
    {
      price: 28128.0,
      time: "2023-04-09T21:00:00Z",
    },
    {
      price: 27934.16,
      time: "2023-04-08T21:00:00Z",
    },
    {
      price: 27889.0,
      time: "2023-04-07T21:00:00Z",
    },
    {
      price: 27993.25,
      time: "2023-04-06T21:00:00Z",
    },
    {
      price: 28152.4,
      time: "2023-04-05T21:00:00Z",
    },
    {
      price: 28256.55,
      time: "2023-04-04T21:00:00Z",
    },
    {
      price: 27585.3,
      time: "2023-04-03T21:00:00Z",
    },
    {
      price: 28072.79,
      time: "2023-04-02T21:00:00Z",
    },
    {
      price: 28451.02,
      time: "2023-04-01T21:00:00Z",
    },
    {
      price: 28393.76,
      time: "2023-03-31T21:00:00Z",
    },
    {
      price: 28150.88,
      time: "2023-03-30T21:00:00Z",
    },
    {
      price: 28376.7,
      time: "2023-03-29T21:00:00Z",
    },
    {
      price: 27314.08,
      time: "2023-03-28T21:00:00Z",
    },
    {
      price: 27048.03,
      time: "2023-03-27T21:00:00Z",
    },
    {
      price: 27802.18,
      time: "2023-03-26T21:00:00Z",
    },
    {
      price: 27383.14,
      time: "2023-03-25T21:00:00Z",
    },
    {
      price: 27614.02,
      time: "2023-03-24T21:00:00Z",
    },
    {
      price: 28331.9,
      time: "2023-03-23T21:00:00Z",
    },
    {
      price: 27388.58,
      time: "2023-03-22T21:00:00Z",
    },
    {
      price: 28132.92,
      time: "2023-03-21T21:00:00Z",
    },
    {
      price: 28079.33,
      time: "2023-03-20T21:00:00Z",
    },
    {
      price: 27974.02,
      time: "2023-03-19T21:00:00Z",
    },
    {
      price: 27405.34,
      time: "2023-03-18T21:00:00Z",
    },
    {
      price: 26821.5,
      time: "2023-03-17T21:00:00Z",
    },
    {
      price: 24752.97,
      time: "2023-03-16T21:00:00Z",
    },
    {
      price: 24401.55,
      time: "2023-03-15T21:00:00Z",
    },
    {
      price: 24637.9,
      time: "2023-03-14T21:00:00Z",
    },
    {
      price: 24220.7,
      time: "2023-03-13T21:00:00Z",
    },
    {
      price: 21503.64,
      time: "2023-03-12T21:00:00Z",
    },
    {
      price: 20506.1,
      time: "2023-03-11T22:00:00Z",
    },
    {
      price: 20105.8,
      time: "2023-03-10T22:00:00Z",
    },
    {
      price: 20232.36,
      time: "2023-03-09T22:00:00Z",
    },
    {
      price: 22005.36,
      time: "2023-03-08T22:00:00Z",
    },
    {
      price: 22054.34,
      time: "2023-03-07T22:00:00Z",
    },
    {
      price: 22407.06,
      time: "2023-03-06T22:00:00Z",
    },
    {
      price: 22488.42,
      time: "2023-03-05T22:00:00Z",
    },
    {
      price: 22216.57,
      time: "2023-03-04T22:00:00Z",
    },
    {
      price: 22241.59,
      time: "2023-03-03T22:00:00Z",
    },
    {
      price: 23415.24,
      time: "2023-03-02T22:00:00Z",
    },
    {
      price: 23559.48,
      time: "2023-03-01T22:00:00Z",
    },
    {
      price: 23143.4,
      time: "2023-02-28T22:00:00Z",
    },
    {
      price: 23560.34,
      time: "2023-02-26T22:00:00Z",
    },
    {
      price: 22942.76,
      time: "2023-02-25T22:00:00Z",
    },
    {
      price: 23102.16,
      time: "2023-02-24T22:00:00Z",
    },
    {
      price: 23745.0,
      time: "2023-02-23T22:00:00Z",
    },
    {
      price: 23745.0,
      time: "2023-02-22T22:00:00Z",
    },
    {
      price: 24196.09,
      time: "2023-02-21T22:00:00Z",
    },
    {
      price: 24769.22,
      time: "2023-02-20T22:00:00Z",
    },
    {
      price: 24547.63,
      time: "2023-02-19T22:00:00Z",
    },
    {
      price: 24619.55,
      time: "2023-02-18T22:00:00Z",
    },
    {
      price: 24490.01,
      time: "2023-02-17T22:00:00Z",
    },
    {
      price: 24535.98,
      time: "2023-02-16T22:00:00Z",
    },
    {
      price: 24182.09,
      time: "2023-02-15T22:00:00Z",
    },
    {
      price: 22252.5,
      time: "2023-02-14T22:00:00Z",
    },
    {
      price: 21628.32,
      time: "2023-02-13T22:00:00Z",
    },
    {
      price: 21740.72,
      time: "2023-02-12T22:00:00Z",
    },
    {
      price: 21828.5,
      time: "2023-02-11T22:00:00Z",
    },
    {
      price: 21536.32,
      time: "2023-02-10T22:00:00Z",
    },
    {
      price: 21859.59,
      time: "2023-02-09T22:00:00Z",
    },
    {
      price: 22956.72,
      time: "2023-02-08T22:00:00Z",
    },
    {
      price: 23200.0,
      time: "2023-02-07T22:00:00Z",
    },
    {
      price: 22918.7,
      time: "2023-02-06T22:00:00Z",
    },
    {
      price: 23649.14,
      time: "2023-02-05T22:00:00Z",
    },
    {
      price: 23649.14,
      time: "2023-02-04T22:00:00Z",
    },
    {
      price: 23649.14,
      time: "2023-02-03T22:00:00Z",
    },
    {
      price: 23462.93,
      time: "2023-02-02T22:00:00Z",
    },
    {
      price: 23679.86,
      time: "2023-02-01T22:00:00Z",
    },
    {
      price: 22948.76,
      time: "2023-01-31T22:00:00Z",
    },
    {
      price: 22746.86,
      time: "2023-01-30T22:00:00Z",
    },
    {
      price: 23600.86,
      time: "2023-01-29T22:00:00Z",
    },
    {
      price: 23007.64,
      time: "2023-01-28T22:00:00Z",
    },
    {
      price: 23090.36,
      time: "2023-01-27T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-26T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-25T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-24T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-23T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-22T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-21T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-20T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-19T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-18T22:00:00Z",
    },
    {
      price: 21785.0,
      time: "2023-01-17T22:00:00Z",
    },
    {
      price: 19256.43,
      time: "2023-01-16T22:00:00Z",
    },
    {
      price: 19256.43,
      time: "2023-01-15T22:00:00Z",
    },
    {
      price: 19256.43,
      time: "2023-01-14T22:00:00Z",
    },
    {
      price: 19256.43,
      time: "2023-01-13T22:00:00Z",
    },
    {
      price: 18833.3,
      time: "2023-01-12T22:00:00Z",
    },
    {
      price: 17248.58,
      time: "2023-01-11T22:00:00Z",
    },
    {
      price: 17248.58,
      time: "2023-01-10T22:00:00Z",
    },
    {
      price: 17179.72,
      time: "2023-01-09T22:00:00Z",
    },
    {
      price: 16955.32,
      time: "2023-01-08T22:00:00Z",
    },
    {
      price: 16936.76,
      time: "2023-01-07T22:00:00Z",
    },
    {
      price: 16927.56,
      time: "2023-01-06T22:00:00Z",
    },
    {
      price: 16843.73,
      time: "2023-01-05T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2023-01-04T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2023-01-03T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2023-01-02T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2023-01-01T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-31T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-30T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-29T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-28T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-27T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-27T14:39:25Z",
    },
    {
      price: 17040.0,
      time: "2022-12-24T00:33:48Z",
    },
    {
      price: 17040.0,
      time: "2022-12-22T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-21T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-20T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-19T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-18T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-17T22:00:00Z",
    },
    {
      price: 17040.0,
      time: "2022-12-16T22:00:00Z",
    },
    {
      price: 17396.8,
      time: "2022-12-15T22:00:00Z",
    },
    {
      price: 16849.06,
      time: "2022-12-14T22:00:00Z",
    },
    {
      price: 16458.6,
      time: "2022-11-29T22:00:00Z",
    },
    {
      price: 16193.42,
      time: "2022-11-28T22:00:00Z",
    },
    {
      price: 16572.04,
      time: "2022-11-27T22:00:00Z",
    },
    {
      price: 16506.48,
      time: "2022-11-26T22:00:00Z",
    },
    {
      price: 16492.0,
      time: "2022-11-25T22:00:00Z",
    },
    {
      price: 16541.16,
      time: "2022-11-24T22:00:00Z",
    },
    {
      price: 16469.22,
      time: "2022-11-23T22:00:00Z",
    },
    {
      price: 16012.41,
      time: "2022-11-22T22:00:00Z",
    },
    {
      price: 15628.83,
      time: "2022-11-21T22:00:00Z",
    },
    {
      price: 16244.24,
      time: "2022-11-20T22:00:00Z",
    },
    {
      price: 16660.5,
      time: "2022-11-19T22:00:00Z",
    },
    {
      price: 16636.5,
      time: "2022-11-18T22:00:00Z",
    },
    {
      price: 16682.16,
      time: "2022-11-17T22:00:00Z",
    },
    {
      price: 16534.94,
      time: "2022-11-16T22:00:00Z",
    },
    {
      price: 16889.0,
      time: "2022-11-15T22:00:00Z",
    },
    {
      price: 16384.18,
      time: "2022-11-14T22:00:00Z",
    },
    {
      price: 16366.84,
      time: "2022-11-13T22:00:00Z",
    },
    {
      price: 16794.39,
      time: "2022-11-12T22:00:00Z",
    },
    {
      price: 16754.0,
      time: "2022-11-11T22:00:00Z",
    },
    {
      price: 17802.64,
      time: "2022-11-10T22:00:00Z",
    },
    {
      price: 15754.52,
      time: "2022-11-09T22:00:00Z",
    },
    {
      price: 17425.5,
      time: "2022-11-08T22:00:00Z",
    },
    {
      price: 20674.5,
      time: "2022-11-07T22:00:00Z",
    },
    {
      price: 21128.28,
      time: "2022-11-06T22:00:00Z",
    },
    {
      price: 21355.5,
      time: "2022-11-05T21:00:00Z",
    },
    {
      price: 20922.36,
      time: "2022-11-04T21:00:00Z",
    },
  ],
};

export const taxReports: ApiReportQueue[] = [
  {
    fileName: "tax-report-2022.pdf",
    id: "1",
    partyId: "test-party-id-1",
    reportYear: 2022,
  },
  {
    fileName: "tax-report-2021.pdf",
    id: "2",
    partyId: "test-party-id-1",
    reportYear: 2021,
  },
];

export const partySignupAvailableResponse: Identifier = {
  available: true,
  identifierToken: "abcd1234",
};

export const partySignupValidateResponse: Identifier = {
  identifierToken: "efgh5678",
};

export const partySignupOTPResponse: Identifier = {
  identifierToken: "ijkl9012",
};

export const partySignupVerifyResponse: Token = {
  access_token: "ijkl9012",
};
