import dayjs from "dayjs";

export type CaesarsUserSignupFormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export type CaesarsUserSignupFieldErrors = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export const allCaesarsUserSignupFieldsFilled = (
  data: CaesarsUserSignupFormData,
  errors: CaesarsUserSignupFieldErrors,
) => {
  // Check if all fields are filled
  for (const key in data) {
    if (key === "address2") continue;

    if (data[key as keyof CaesarsUserSignupFormData] === "") {
      return false;
    }
  }

  // Check if any fields have errors
  for (const key in errors) {
    if (errors[key as keyof CaesarsUserSignupFieldErrors]) {
      return false;
    }
  }

  return true;
};

export type LoginFormData = {
  email: string;
  password: string;
};

export type LoginFormFieldErrors = {
  email: string;
  password: string;
};

export type ChangePasswordFormData = {
  password: string;
  confirmPassword: string;
};

export type ChangePasswordFormFieldErrors = {
  password: string;
  confirmPassword: string;
};

export type UserInfoFormData = {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  ssn: string;
  phone: string;
  occupation: string;
  dob: dayjs.Dayjs | null;
};

export type UserInfoFieldErrors = {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  ssn: string;
  phone: string;
  occupation: string;
  dob: string;
};

export const allFieldsFilled = (data: UserInfoFormData, errors: UserInfoFieldErrors) => {
  // Check if all fields are filled
  for (const key in data) {
    if (key === "address2") continue;

    if (!data[key as keyof UserInfoFormData]) {
      return false;
    }
  }

  // Check if any fields have errors
  for (const key in errors) {
    if (errors[key as keyof UserInfoFormData]) {
      return false;
    }
  }

  return true;
};

export const isValidAddress = (address: string): string => {
  const regex = /^[0-9a-zA-Z\s,]+$/;
  return regex.test(address) ? "" : "Invalid address format";
};

export const isValidCity = (city: string): string => {
  const regex = /^[a-zA-Z\s]+$/; // Only letters and spaces allowed
  return regex.test(city) ? "" : "Invalid city name";
};

export const isValidZipCode = (zip: string): string => {
  const regex = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return regex.test(zip) ? "" : "Invalid Zip Code";
};

export const isValidDateOfBirth = (dob: string): string => {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  return regex.test(dob) ? "" : "Invalid Date of Birth format (MM/DD/YYYY)";
};

export const isValidSSN = (ssn: string): string => {
  if (ssn.length < 9) {
    return "SSN must be 9 characters long, numbers only";
  }
  const regex = /^[0-9]{1,9}$/;
  return regex.test(ssn) ? "" : "Invalid SSN format";
};

export const isValidEmail = (email: string): string => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line */
  return regex.test(email) ? "" : "Invalid email address";
};

export const isValidPassword = (text: string) => {
  const regex = /[\w@$#%^&*!\-+=[\]{}|\\:`’,.?/~“();]/;
  const minPasswordLength = 10;
  const maxPasswordLength = 32;
  const valid = false;
  const error = "";

  if (!regex.test(text)) {
    return "Invalid password";
  } else if (text.length < minPasswordLength) {
    return "Minimum 10 characters";
  } else if (text.length > maxPasswordLength) {
    return "Maximum 32 characters";
  } else {
    return "";
  }
};

export const isValidOTP = (otp?: string) => {
  return otp && otp.length === 6 && /^\d+$/.test(otp);
};
