import { Typography, color } from "@bakkt/bakkt-ui-components";
import { Box, CircularProgress, Unstable_Grid2 as Grid } from "@mui/material";

interface ModalLoadingIndicatorProps {
  description?: string;
}

export default function ModalLoadingIndicator({ description }: ModalLoadingIndicatorProps) {
  return (
    <Box sx={{ minHeight: 300, minWidth: 300, pt: 2 }}>
      <Grid xs={12}>
        <Typography variant="h3" sx={{ mb: 6, textAlign: "center" }}>
          {description}
        </Typography>
      </Grid>
      <Grid container xs={12} sx={{ textAlign: "center", mb: 6 }} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress sx={{ color: color.energizingLime.main }} />
      </Grid>
    </Box>
  );
}
