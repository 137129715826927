import { pageFooterText } from "@/utils/dataUtils.ts";
import { Box, Container, Unstable_Grid2 as Grid, Link, StyledEngineProvider, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BakktLogo from "../../assets/images/bakkt_logo.svg";

export const Footer = () => {
  const theme = useTheme();
  const today = new Date();
  const year = today.getFullYear();

  const footerLinksHeaderSx = {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "700",
    [theme.breakpoints.down("md")]: {
      mt: 4,
    },
  };

  const footerLinksSx = {
    fontSize: "12px",
    lineHeight: "20px",
  };

  return (
    <StyledEngineProvider injectFirst>
      <Box sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
        <Container maxWidth="lg" disableGutters sx={{ p: 3 }}>
          <Grid container justifyContent="space-between">
            <Grid>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid>
                  <img
                    src={BakktLogo}
                    alt="Bakkt Logo"
                    style={{
                      width: "95px",
                      filter: "brightness(0) invert(1)",
                      marginTop: "6px",
                      marginRight: "16px",
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography variant="body2" sx={{ fontSize: "12px" }}>
                    {pageFooterText}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid container justifyContent="space-between" spacing={4}>
                <Grid>
                  <Typography variant="body1" sx={footerLinksHeaderSx}>
                    Legal
                  </Typography>
                  <Typography variant="body2" sx={footerLinksSx}>
                    <Link className="header-footer-links" href="https://bakkt.com/terms-of-service">
                      Terms of Service
                    </Link>
                  </Typography>
                  <Typography variant="body2" sx={footerLinksSx}>
                    <Link className="header-footer-links" href="https://bakkt.com/privacy-policy">
                      Privacy Policy
                    </Link>
                  </Typography>
                  <Typography variant="body2" sx={footerLinksSx}>
                    <Link className="header-footer-links" href="https://bakkt.com/disclosures">
                      Licenses & Disclosures
                    </Link>
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="body1" sx={footerLinksHeaderSx}>
                    Support
                  </Typography>
                  <Typography variant="body2" sx={footerLinksSx}>
                    <Link className="header-footer-links" href="mailto:help@bakkt.com">
                      help@bakkt.com
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </StyledEngineProvider>
  );
};
