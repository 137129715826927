import { useRootContext } from "@/RootLayout";
import CryptoTaxLogExport from "@/components/profile/CryptoTaxLogExport";
import LoginInformation from "@/components/profile/LoginInformation";
import PersonalInformation from "@/components/profile/PersonalInformation";
import {
  assetActivity as assetActivityMock,
  fetchMockDataPromiseWithDelay,
  partnerPartyInfo,
  taxReports,
} from "@/services/mockData.ts";
import { GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum } from "@/services/openAPI/embedded";
import { EmbeddedPartnerService } from "@/services/serviceLoader.ts";
import { shouldUseMockData } from "@/utils/dataUtils.ts";
import { Unstable_Grid2 as Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Outlet, ScrollRestoration, defer } from "react-router-dom";
import AllTransactionsGrid from "./AllTransactionsGrid";
import UserProfileHeader from "./UserProfileHeader";

const UserProfile = () => {
  const theme = useTheme();
  const [portalReady, setPortalReady] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const pageHeaderPortalId = "page-header-portal";

  useEffect(() => {
    const pageHeaderPortal = document.getElementById(pageHeaderPortalId);

    if (pageHeaderPortal) {
      setPortalReady(true);
    }
  }, []);

  return (
    <>
      <Outlet context={useRootContext()} />
      <ScrollRestoration />
      {portalReady && createPortal(<UserProfileHeader />, document.getElementById(pageHeaderPortalId) as Element)}
      <Grid container spacing={3} sx={{ flexDirection: isMobile ? "column-reverse" : "row", mt: 2, mb: 3 }}>
        <AllTransactionsGrid />
        <Grid xs={12} md={4} sx={{ p: 0 }}>
          <PersonalInformation />
          <LoginInformation />
          <CryptoTaxLogExport />
        </Grid>
      </Grid>
    </>
  );
};

export default UserProfile;

export async function loader() {
  const partyDetailsPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(partnerPartyInfo, 3000)
    : EmbeddedPartnerService.fetchPartnerPartyProfile();

  const taxReportListPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(taxReports, 3000)
    : EmbeddedPartnerService.getTaxReportList();

  const transactionHistoryPromise = shouldUseMockData
    ? fetchMockDataPromiseWithDelay(assetActivityMock, 3000)
    : EmbeddedPartnerService.getAssetActivitiesHistory(
        undefined,
        [
          GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum.Transaction,
          GetAssetActivitiesHistoryAssetActivityTypeGroupsEnum.Transfer,
        ],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        0,
        1000,
      );

  return defer({ partyDetailsPromise, taxReportListPromise, transactionHistoryPromise });
}
