import LoadingIndicator from "@/components/loading/LoadingIndicator.tsx";
import { ApiFinalizeRequest } from "@/services/openAPI/embedded";
import { EmbeddedAuthService } from "@/services/serviceLoader.ts";
import { pageFooterText, submitActionRequest } from "@/utils/dataUtils.ts";
import { ChangePasswordFormData, ChangePasswordFormFieldErrors, isValidPassword } from "@/utils/formValidation.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Alert, Button, Dialog, TextField, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useNavigation } from "react-router-dom";
import BakktLogo from "../../assets/images/bakkt_logo.svg";
import CaesarsBg from "../../assets/images/caesars_bg.svg";
import CaesarsRewardsLogo from "../../assets/images/caesars_rewards_logo.png";
import LoginBg from "../../assets/images/login_bg.svg";
import styles from "./Login.module.css";

interface ChangePasswordProps {
  identifierToken: string;
}

const ChangePassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";
  const { identifierToken } = useLocation()?.state as ChangePasswordProps;
  const caesarsToken = sessionStorage.getItem("CAESARS_TOKEN");

  const backgroundSx = caesarsToken
    ? {
        backgroundImage: "url(" + CaesarsBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        minHeight: 470,
        minWidth: 500,
      }
    : {
        backgroundImage: "url(" + LoginBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "3000px",
        minHeight: 470,
        minWidth: 500,
      };
  const pageFooterTextSx = {
    [theme.breakpoints.up("sm")]: {
      mt: "20px",
      mb: "20px",
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "30px",
      left: "5%",
      right: "5%",
      position: "fixed",
    },
  };

  const [formData, setFormData] = useState<ChangePasswordFormData>({
    password: "",
    confirmPassword: "",
  });
  const [fieldErrors, setFieldErrors] = useState<ChangePasswordFormFieldErrors>({
    password: "",
    confirmPassword: "",
  });

  const validateField = (name: string, value: string): string => {
    // Validator mappings
    const validators: { [key: string]: (val: string) => string } = {
      password: isValidPassword,
    };

    if (name in validators) {
      return validators[name](value);
    }
    return "";
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        navigate("/login", {
          state: {
            forwardedSuccessMessage: "Password successfully changed! Please login below.",
          },
        });
      } else {
        navigate("/login", {
          state: {
            forwardedErrorMessage: response.message
              ? response.message
              : "Unable to update password. Please contact help@bakkt.com.",
          },
        });
      }
    }
  }, [fetcher.data]);

  const handleChangePassword = () => {
    if (isFormValid) {
      setErrorMessage(undefined);
      const changePasswordRequest: ApiFinalizeRequest = {
        identifierToken: identifierToken,
        password: formData.password,
      };

      submitActionRequest(fetcher, changePasswordRequest);
    }
  };

  const handleChangePasswordKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && isFormValid) {
      handleChangePassword();
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (!name || value === undefined) {
      return;
    }

    const newFieldErrors: ChangePasswordFormFieldErrors = { ...fieldErrors };

    if (name === "confirmPassword" && value !== formData.password) {
      newFieldErrors[name as keyof ChangePasswordFormFieldErrors] = "Passwords must match";
    } else if (!value.trim()) {
      newFieldErrors[name as keyof ChangePasswordFormFieldErrors] = "Input required";
    } else {
      const validationErrorMessage = validateField(name, value);
      newFieldErrors[name as keyof ChangePasswordFormFieldErrors] = validationErrorMessage;
    }

    let areAllFieldsFilled = true;
    for (const key in formData) {
      if (formData[key as keyof ChangePasswordFormData] === "") {
        areAllFieldsFilled = false;
      }
    }

    let areAnyFieldErrors = false;
    for (const key in newFieldErrors) {
      if (newFieldErrors[key as keyof ChangePasswordFormFieldErrors]) {
        areAnyFieldErrors = true;
      }
    }

    if (areAllFieldsFilled && !areAnyFieldErrors) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }

    setFieldErrors(newFieldErrors);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    // Check if the name is a valid key
    if (name in formData) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  return (
    <ThemeProvider theme={themes.light}>
      <Dialog
        scroll="body"
        open={true}
        maxWidth="sm"
        fullScreen={isFullScreen}
        sx={isFullScreen ? undefined : backgroundSx}
        hideBackdrop={true}
      >
        <DialogContent
          sx={{
            alignItems: "center",
            textAlign: "center",
            overflowY: "hidden",
            minWidth: isFullScreen ? "initial" : 500,
          }}
        >
          <Grid container xs={12} sx={{ mb: 5, pt: errorMessage && isFullScreen ? 6 : 0 }}>
            <Grid xs={12}>
              <Grid container justifyContent="center">
                {caesarsToken && (
                  <Grid sx={{ borderRight: "1px solid black", mr: 3, pr: 2 }}>
                    <img src={CaesarsRewardsLogo} alt="Caesars Rewards logo" style={{ width: "106px" }} />
                  </Grid>
                )}
                <img src={BakktLogo} alt="Bakkt logo" className={styles["login_logo"]} />
              </Grid>
              <Typography variant="h3" sx={{ mt: 1.6, mb: 1 }}>
                Reset Password
              </Typography>
              <Typography variant="body2">Choose a new password that is 10-32 characters</Typography>
            </Grid>
          </Grid>

          {errorMessage && (
            <Grid sx={{ mb: 2, left: 0, position: "fixed", right: 0, top: 0 }}>
              <Alert
                severity="error"
                sx={{
                  backgroundColor: "error.500",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1">{errorMessage}</Typography>
              </Alert>
            </Grid>
          )}

          {isLoading ? (
            <Stack justifyContent={"center"} alignItems={"center"} sx={{ minWidth: 300, minHeight: 200 }}>
              <LoadingIndicator />
            </Stack>
          ) : (
            <Grid container spacing={2}>
              <Grid xs={12}>
                <TextField
                  type="password"
                  label="Password"
                  name="password"
                  variant="standard"
                  fullWidth
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleChangePasswordKeyPress}
                  error={Boolean(fieldErrors.password)}
                  helperText={fieldErrors.password}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  type="password"
                  label="Re-enter password"
                  name="confirmPassword"
                  variant="standard"
                  fullWidth
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleChangePasswordKeyPress}
                  error={Boolean(fieldErrors.confirmPassword)}
                  helperText={fieldErrors.confirmPassword}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {!isLoading && (
          <DialogActions sx={{ justifyContent: "center", pt: 5 }}>
            <Stack spacing={2} sx={{ width: "49%" }}>
              <Button variant="contained" disabled={!isFormValid} fullWidth onClick={handleChangePassword}>
                Update Password
              </Button>
            </Stack>
          </DialogActions>
        )}
        <Grid sx={{ textAlign: "-webkit-center" }}>
          <Box sx={pageFooterTextSx}>
            <Box>
              <Grid xs={6}>
                <Typography variant="caption" sx={{ textAlign: "center" }}>
                  {pageFooterText}
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
};

export default ChangePassword;

export async function action({ request }: { request: Request }) {
  try {
    const { identifierToken, password } = await request.json();
    const changePasswordRequest: ApiFinalizeRequest = {
      identifierToken,
      password,
    };
    const response = await EmbeddedAuthService.resetPasswordFinalize(changePasswordRequest);
    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
