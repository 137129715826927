import LoadingIndicator from "@/components/loading/LoadingIndicator.tsx";
import { LoginRequest } from "@/services/openAPI/embedded";
import { EmbeddedAuthService } from "@/services/serviceLoader.ts";
import { pageFooterText, submitActionRequest } from "@/utils/dataUtils.ts";
import { LoginFormData, LoginFormFieldErrors, isValidEmail } from "@/utils/formValidation.ts";
import { formatActionErrorResponse, formatActionSuccessResponse } from "@/utils/responseHandlingUtils.ts";
import { Alert, Button, Dialog, TextField, Typography, themes } from "@bakkt/bakkt-ui-components";
import {
  Box,
  DialogActions,
  DialogContent,
  Unstable_Grid2 as Grid,
  Link,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useNavigation } from "react-router-dom";
import BakktLogo from "../../assets/images/bakkt_logo.svg";
import CaesarsBg from "../../assets/images/caesars_bg.svg";
import CaesarsRewardsLogo from "../../assets/images/caesars_rewards_logo.png";
import LoginBg from "../../assets/images/login_bg.svg";
import styles from "./Login.module.css";

interface LoginProps {
  forwardedErrorMessage: string;
  forwardedSuccessMessage: string;
}

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const [errorMessage, setErrorMessage] = useState<string>();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let isLoading = fetcher.state === "submitting" || fetcher.state === "loading" || navigation.state === "loading";
  const { forwardedErrorMessage, forwardedSuccessMessage } = (useLocation()?.state as LoginProps) || {};
  const caesarsToken = sessionStorage.getItem("CAESARS_TOKEN");

  const backgroundSx = caesarsToken
    ? {
        backgroundImage: "url(" + CaesarsBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        minHeight: 470,
        minWidth: 500,
      }
    : {
        backgroundImage: "url(" + LoginBg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "3000px",
        minHeight: 470,
        minWidth: 500,
      };
  const pageFooterTextSx = {
    [theme.breakpoints.up("sm")]: {
      mt: "20px",
      mb: "20px",
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "30px",
      left: "5%",
      right: "5%",
      position: "fixed",
    },
  };

  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });
  const [fieldErrors, setFieldErrors] = useState<LoginFormFieldErrors>({
    email: "",
    password: "",
  });

  const validateField = (name: string, value: string): string => {
    // Validator mappings
    const validators: { [key: string]: (val: string) => string } = {
      email: isValidEmail,
    };

    if (name in validators) {
      return validators[name](value) ? "Invalid entry" : "";
    }
    return "";
  };

  useEffect(() => {
    if (forwardedErrorMessage) {
      setErrorMessage(forwardedErrorMessage);
    }
  }, []);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        // Ensures loading indicator remains on screen while navigation is happening
        isLoading = true;

        const deepLinkPath = sessionStorage.getItem("LOGIN_DEEPLINK_PATH");
        if (deepLinkPath) {
          navigate(deepLinkPath);
          sessionStorage.removeItem("LOGIN_DEEPLINK_PATH");
        } else {
          navigate("/");
        }
      } else {
        if (response.message === "PARTY_SUSPENDED") {
          setErrorMessage("This account has been suspended. Please contact Customer Service at help@bakkt.com");
        } else {
          setErrorMessage("Invalid email or password.");
        }
      }
    }
  }, [fetcher.data]);

  const handleLogin = () => {
    setErrorMessage(undefined);
    const loginRequest: LoginRequest = {
      userName: formData.email,
      password: formData.password,
    };

    submitActionRequest(fetcher, loginRequest);
  };

  const handleLoginKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && isFormValid()) {
      handleLogin();
    }
  };

  const isFormValid = () => {
    return formData.email && formData.password && isValidEmail(formData.email).length === 0;
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (!name || value === undefined) {
      return;
    }

    const newFieldErrors: LoginFormFieldErrors = { ...fieldErrors };

    if (!value.trim()) {
      newFieldErrors[name as keyof LoginFormFieldErrors] = "Invalid entry";
    } else {
      const errorMessage = validateField(name, value);
      newFieldErrors[name as keyof LoginFormFieldErrors] = errorMessage;
    }

    setFieldErrors(newFieldErrors);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    // Check if the name is a valid key
    if (name in formData) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  return (
    <ThemeProvider theme={themes.light}>
      <Dialog
        scroll="body"
        open={true}
        maxWidth="sm"
        fullScreen={isFullScreen}
        sx={isFullScreen ? undefined : backgroundSx}
        hideBackdrop={true}
      >
        <DialogContent
          sx={{
            alignItems: "center",
            textAlign: "center",
            overflowY: "hidden",
            minWidth: isFullScreen ? "initial" : 500,
          }}
        >
          <Grid container xs={12} sx={{ mb: 5, pt: (errorMessage || forwardedSuccessMessage) && isFullScreen ? 6 : 0 }}>
            <Grid xs={12}>
              <Grid container justifyContent="center">
                {caesarsToken && (
                  <Grid sx={{ borderRight: "1px solid black", mr: 3, pr: 2 }}>
                    <img src={CaesarsRewardsLogo} alt="Caesars Rewards logo" style={{ width: "106px" }} />
                  </Grid>
                )}
                <img src={BakktLogo} alt="Bakkt logo" className={styles["login_logo"]} />
              </Grid>
              <Typography variant="h3" sx={{ mt: 1.6 }}>
                Log in with Bakkt
              </Typography>
            </Grid>
          </Grid>

          {errorMessage && (
            <Grid sx={{ mb: 2, left: 0, position: "fixed", right: 0, top: 0 }}>
              <Alert
                severity="error"
                sx={{
                  backgroundColor: "error.500",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1">{errorMessage}</Typography>
              </Alert>
            </Grid>
          )}

          {forwardedSuccessMessage && (
            <Grid sx={{ mb: 2, left: 0, position: "fixed", right: 0, top: 0 }}>
              <Alert
                severity="success"
                sx={{
                  backgroundColor: "success.500",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1">{forwardedSuccessMessage}</Typography>
              </Alert>
            </Grid>
          )}

          {isLoading ? (
            <Stack justifyContent={"center"} alignItems={"center"} sx={{ minWidth: 300, minHeight: 200 }}>
              <LoadingIndicator />
            </Stack>
          ) : (
            <Grid container spacing={2}>
              <Grid xs={12}>
                <TextField
                  type="email"
                  label="Email"
                  name="email"
                  variant="standard"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleLoginKeyPress}
                  error={Boolean(fieldErrors.email)}
                  helperText={fieldErrors.email}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  type="password"
                  label="Password"
                  name="password"
                  variant="standard"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleLoginKeyPress}
                  error={Boolean(fieldErrors.password)}
                  helperText={fieldErrors.password}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {!isLoading && (
          <DialogActions sx={{ justifyContent: "center", pt: 5 }}>
            <Stack spacing={2} sx={{ width: "49%" }}>
              <Button variant="contained" disabled={!isFormValid()} fullWidth onClick={handleLogin}>
                Log in
              </Button>
              <Link sx={{ cursor: "pointer" }}>
                <Grid spacing={2}>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "center" }}
                    onClick={() => navigate("/change-password-email")}
                  >
                    Forgot password?
                  </Typography>
                </Grid>
              </Link>
            </Stack>
          </DialogActions>
        )}
        <Grid sx={{ textAlign: "-webkit-center" }}>
          <Box sx={pageFooterTextSx}>
            <Box>
              <Grid xs={6}>
                <Typography variant="caption" sx={{ textAlign: "center" }}>
                  {pageFooterText}
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
};

export default Login;

export async function action({ request }: { request: Request }) {
  try {
    const loginRequest = (await request.json()) as LoginRequest;
    const response = await EmbeddedAuthService.loginParty(loginRequest);
    return formatActionSuccessResponse(response);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
